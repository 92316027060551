import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Button,
  Alert,
  Snackbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '95%',
    maxWidth: '1800px',
    margin: '16px'
  }
});

const StyledTableCell = styled(TableCell)({
  padding: '6px',
  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    paddingLeft: '4px',
  },
  '&:last-of-type': {
    paddingRight: '4px',
  },
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#666'
  }
});

const Verification = ({ open, onClose, data }) => {
  const [openAlert, setOpenAlert] = useState(false);

  const handleApply = () => {
    setOpenAlert(true);
    // 여기에 실제 적용 로직 추가
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    onClose();
  };

  const verificationDataList = [
    {
      status: '검증완료',
      merchantName: data?.description || '테스트가맹점1',
      tid: 'T' + (data?.reference || '').padStart(8, '0'),
      amount: '54,000',
      transactionDate: '2024-01-15 14:30:22',
      approvalNo: '30098713',
      transactionNo: 'TX' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      rootTransactionNo: 'RTX' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      pg: 'Galaxia',
      uniqueId: 'UID' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      cardCompany: '신한카드',
      cardType: '신용',
      bin: '940021',
      last4: '1234',
      installment: '일시불'
    },
    {
      status: '검증완료',
      merchantName: '테스트가맹점2',
      tid: 'T30098714',
      amount: '32,000',
      transactionDate: '2024-01-15 15:45:33',
      approvalNo: '30098714',
      transactionNo: 'TX' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      rootTransactionNo: 'RTX' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      pg: 'Galaxia',
      uniqueId: 'UID' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      cardCompany: '국민카드',
      cardType: '신용',
      bin: '940022',
      last4: '5678',
      installment: '일시불'
    },
    {
      status: '검증완료',
      merchantName: '테스트가맹점3',
      tid: 'T30098715',
      amount: '78,000',
      transactionDate: '2024-01-15 16:20:45',
      approvalNo: '30098715',
      transactionNo: 'TX' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      rootTransactionNo: 'RTX' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      pg: 'Galaxia',
      uniqueId: 'UID' + (Math.random() * 1000000).toFixed(0).padStart(8, '0'),
      cardCompany: '우리카드',
      cardType: '신용',
      bin: '940023',
      last4: '9012',
      installment: '일시불'
    }
  ];

  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle sx={{ 
          m: 0, 
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(224, 224, 224, 0.4)'
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
            에러내역 검증결과
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{
              color: '#666',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          <Typography sx={{ mb: 3, mt: 1, color: '#666' }}>
            오류데이터가 기준데이터와 매칭됩니다.
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">검증</StyledTableCell>
                  <StyledTableCell className="header">가맹점명</StyledTableCell>
                  <StyledTableCell className="header">TID</StyledTableCell>
                  <StyledTableCell className="header">거래금액</StyledTableCell>
                  <StyledTableCell className="header">거래일시</StyledTableCell>
                  <StyledTableCell className="header">승인번호</StyledTableCell>
                  <StyledTableCell className="header">거래번호</StyledTableCell>
                  <StyledTableCell className="header">거래번호(root)</StyledTableCell>
                  <StyledTableCell className="header">PG</StyledTableCell>
                  <StyledTableCell className="header">고유번호</StyledTableCell>
                  <StyledTableCell className="header">카드사</StyledTableCell>
                  <StyledTableCell className="header">카드종류</StyledTableCell>
                  <StyledTableCell className="header">BIN</StyledTableCell>
                  <StyledTableCell className="header">LAST4</StyledTableCell>
                  <StyledTableCell className="header">할부</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {verificationDataList.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{row.status}</StyledTableCell>
                    <StyledTableCell>{row.merchantName}</StyledTableCell>
                    <StyledTableCell>{row.tid}</StyledTableCell>
                    <StyledTableCell>{row.amount}</StyledTableCell>
                    <StyledTableCell>{row.transactionDate}</StyledTableCell>
                    <StyledTableCell>{row.approvalNo}</StyledTableCell>
                    <StyledTableCell>{row.transactionNo}</StyledTableCell>
                    <StyledTableCell>{row.rootTransactionNo}</StyledTableCell>
                    <StyledTableCell>{row.pg}</StyledTableCell>
                    <StyledTableCell>{row.uniqueId}</StyledTableCell>
                    <StyledTableCell>{row.cardCompany}</StyledTableCell>
                    <StyledTableCell>{row.cardType}</StyledTableCell>
                    <StyledTableCell>{row.bin}</StyledTableCell>
                    <StyledTableCell>{row.last4}</StyledTableCell>
                    <StyledTableCell>{row.installment}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions sx={{ 
          p: 2, 
          borderTop: '1px solid rgba(224, 224, 224, 0.4)',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1
        }}>
          <Button 
            variant="outlined" 
            onClick={onClose}
            sx={{ 
              minWidth: '80px',
              color: '#666',
              borderColor: '#666',
              '&:hover': {
                borderColor: '#444',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            닫기
          </Button>
          <Button 
            variant="contained" 
            onClick={handleApply}
            sx={{ 
              minWidth: '80px',
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#1565c0'
              }
            }}
          >
            적용
          </Button>
        </DialogActions>
      </StyledDialog>

      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          검증결과가 반영되었습니다.
        </Alert>
      </Snackbar>
    </>
  );
};

export default Verification;

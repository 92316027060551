import React, { useState } from 'react';
import { Box, Typography, styled } from '@mui/material';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#fff',
  padding: '16px 20px',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666',
    },
  },
});

const TableRow = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  minWidth: '600px',
  width: '100%',
  padding: '10px 0',
  '& > *': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.95rem',
    color: '#333',
  },
  '&:first-of-type': {
    borderBottom: '1px solid #f0f0f0',
    marginBottom: '4px',
    '& > *': {
      fontWeight: 500,
      color: '#666',
    }
  }
});

const SecurityCheck = () => {
  const [securityData, setSecurityData] = useState([]);

  return (
    <StyledBox>
      <TableRow>
        <Typography>로그인아이디</Typography>
        <Typography>상호</Typography>
        <Typography>보안정책</Typography>
      </TableRow>
      
      {securityData.length > 0 ? (
        securityData.map((item, index) => (
          <TableRow key={index}>
            <Typography>{item.loginId}</Typography>
            <Typography>{item.company}</Typography>
            <Typography>{item.security}</Typography>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <Typography 
            sx={{ 
              color: '#2196f3', 
              fontSize: '0.9rem',
              gridColumn: '1 / span 3',
              textAlign: 'center'
            }}
          >
            보안정책 데이터가 없습니다.
          </Typography>
        </TableRow>
      )}

      {!securityData.length && (
        <TableRow>
          <Typography 
            sx={{ 
              color: '#2196f3', 
              fontSize: '0.9rem',
              gridColumn: '1 / span 3',
              textAlign: 'center'
            }}
          >
            전체 데이터가 없습니다.
          </Typography>
        </TableRow>
      )}
    </StyledBox>
  );
};

export default SecurityCheck;

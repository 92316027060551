import React from 'react';
import {
  Box,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QuickMenu from '../../../Common/QuickMenu';
import Gibon from './Section/Gibon';
import Service from './Section/Service';
import Jeongsan from './Section/Jeongsan';

function CashInput() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ 
      display: 'flex',
      position: 'relative',
      height: 'calc(100vh - 64px)',
    }}>
      <QuickMenu />

      <Box sx={{ 
        flexGrow: 1,
        overflow: 'auto',
        p: { xs: 1, sm: 2, md: 3 },
        ml: { xs: 0, md: '90px' },
        '& .MuiGrid-root': {
          width: {
            xs: '100%',
            md: 'auto'
          }
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '280px',
            md: 'auto'
          }
        }
      }}>
        <Grid 
          container 
          spacing={2}
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            '& > .MuiGrid-item': {
              width: '100%',
              maxWidth: { xs: '100%', md: '50%' }
            }
          }}
        >
          {/* 좌측 섹션 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: { xs: 2, md: 0 }
            }}>
              <Gibon />
            </Box>
          </Grid>

          {/* 우측 섹션 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
              <Service />
              <Jeongsan />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CashInput; 
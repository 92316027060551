import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popover,
  Chip,
  MenuItem,
  IconButton,
  Tooltip
} from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DownButton from './DownButton';
import DownloadIcon from '@mui/icons-material/Download';
import SummarizeIcon from '@mui/icons-material/Summarize';

const AccountSearch = ({ onSearch, onClose, onExcelDownload, isMobile }) => {
  // 기본 검색 필드
  const [searchFields, setSearchFields] = useState({
    companyName: '',    // 상호명
    ownerName: '',     // 대표자명
    settlementDate: ''
  });

  // 팝오버 상태
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedToggleOptions, setSelectedToggleOptions] = useState([]);
  const [toggleSearchText, setToggleSearchText] = useState('');
  const [filteredToggleOptions, setFilteredToggleOptions] = useState([]);

  // 라디오 버튼 상태들
  const [loginStatus, setLoginStatus] = useState('');
  const [taxType, setTaxType] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [loginPermission, setLoginPermission] = useState('');
  const [paymentPermission, setPaymentPermission] = useState('');
  const [dataCount, setDataCount] = useState('50');
  const [depositStatus, setDepositStatus] = useState('');
  const [withdrawalLimit, setWithdrawalLimit] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  // 검색 옵션 데이터
  const searchOptions = [
    { value: 'master', label: '마스터' },
    { value: 'branch', label: '지사' },
    { value: 'distributor', label: '총판' },
    { value: 'agency', label: '대리점' },
    { value: 'retail', label: '리테일' },
    { value: 'sales1', label: '영업1' },
    { value: 'sales2', label: '영업2' },
    { value: 'sales3', label: '영업3' },
    { value: 'walletAll', label: '월렛 계정 전체' },
    { value: 'regularPaymentAll', label: '정기결제 계정 전체' },
    { value: 'status', label: '상태여부' },
    { value: 'pg', label: 'PG' },
    { value: 'mid', label: 'MID' },
    { value: 'cancelAuth', label: '취소권한' },
    { value: 'performanceSms', label: '실적문자' },
    { value: 'certification', label: '인증여부' },
    { value: 'bankAll', label: '은행' },
    { value: 'settlementDate', label: '정산일' }
  ];

  // 토글 옵션 데이터
  const toggleOptions = {
    userType: [
      { value: 'admin', label: '관리자' },
      { value: 'user', label: '일반사용자' },
      { value: 'guest', label: '게스트' }
    ],
    settlementDate: [
      { value: 'instant', label: '즉결' },
      { value: 'D+1', label: 'D+1' },
      { value: 'D+2', label: 'D+2' },
      { value: 'D+3', label: 'D+3' },
      { value: 'D+4', label: 'D+4' },
      { value: 'D+5', label: 'D+5' },
      { value: 'D+7', label: 'D+7' },
      { value: 'D+10', label: 'D+10' },
      { value: 'D+15', label: 'D+15' },
      { value: 'D+30', label: 'D+30' }
    ],
    status: [
      { value: 'active', label: '정상' },
      { value: 'suspended', label: '정지' },
      { value: 'terminated', label: '해지' }
    ],
    pg: [
      { value: 'nice', label: '나이스' },
      { value: 'kicc', label: 'KICC' },
      { value: 'kcp', label: 'KCP' }
    ],
    mid: [
      { value: 'credit', label: '신용카드' },
      { value: 'account', label: '계좌이체' },
      { value: 'virtual', label: '가상계좌' }
    ],
    cancelAuth: [
      { value: 'allowed', label: '허용' },
      { value: 'denied', label: '차단' }
    ],
    performanceSms: [
      { value: 'on', label: '수신' },
      { value: 'off', label: '미수신' }
    ],
    certification: [
      { value: 'certified', label: '인증' },
      { value: 'uncertified', label: '미인증' }
    ],
    walletAll: [
      { value: 'yes', label: '사용' },
      { value: 'no', label: '미사용' }
    ],
    regularPaymentAll: [
      { value: 'yes', label: '사용' },
      { value: 'no', label: '미사용' }
    ]
  };

  // 검색옵션 내부 검색 핸들러
  const handleToggleSearch = (e) => {
    const searchText = e.target.value;
    setToggleSearchText(searchText);

    if (selectedOption && toggleOptions[selectedOption]) {
      const filtered = toggleOptions[selectedOption].filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredToggleOptions(filtered);
    }
  };

  const handleSearchOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchOptionClose = () => {
    setAnchorEl(null);
    setSelectedOption('');
    setToggleSearchText('');
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.value);
    setFilteredToggleOptions(toggleOptions[option.value] || []);
    setToggleSearchText('');
  };

  // 필터 제거 핸들러
  const removeFilter = (filterToRemove) => {
    const [category] = filterToRemove.split(': ');
    
    setActiveFilters(prev => 
      prev.filter(filter => filter !== filterToRemove)
    );
    
    // 라디오 버튼 상태 초기화
    switch(category) {
      case '접속여부':
        setLoginStatus('');
        break;
      case '과세구분':
        setTaxType('');
        break;
      case '원천구분':
        setSourceType('');
        break;
      case '로그인':
        setLoginPermission('');
        break;
      case '결제':
        setPaymentPermission('');
        break;
    }
  };

  // 라디오 버튼 변경 핸들러
  const handleRadioChange = (type, value) => {
    let filterLabel = '';
    let currentValue = '';
    let newState = {};
    
    switch(type) {
      case 'login':
        currentValue = loginStatus;
        newState = { loginStatus: value === currentValue ? '' : value };
        setLoginStatus(newState.loginStatus);
        filterLabel = `접속여부: ${value === 'online' ? '접속중' : '미접속'}`;
        break;
      case 'tax':
        currentValue = taxType;
        newState = { taxType: value === currentValue ? '' : value };
        setTaxType(newState.taxType);
        filterLabel = `과세구분: ${value === 'taxable' ? '과세' : '비과세'}`;
        break;
      case 'source':
        currentValue = sourceType;
        newState = { sourceType: value === currentValue ? '' : value };
        setSourceType(newState.sourceType);
        filterLabel = `원천구분: ${value === 'withholding' ? '원천' : '비원천'}`;
        break;
      case 'loginPermission':
        currentValue = loginPermission;
        newState = { loginPermission: value === currentValue ? '' : value };
        setLoginPermission(newState.loginPermission);
        filterLabel = `로그인: ${value === 'allowed' ? '허용' : '차단'}`;
        break;
      case 'paymentPermission':
        currentValue = paymentPermission;
        newState = { paymentPermission: value === currentValue ? '' : value };
        setPaymentPermission(newState.paymentPermission);
        filterLabel = `결제: ${value === 'allowed' ? '허용' : '차단'}`;
        break;
      case 'deposit':
        currentValue = depositStatus;
        newState = { depositStatus: value === currentValue ? '' : value };
        setDepositStatus(newState.depositStatus);
        filterLabel = `보증금: ${value === 'yes' ? '있음' : '없음'}`;
        break;
      case 'withdrawal':
        currentValue = withdrawalLimit;
        newState = { withdrawalLimit: value === currentValue ? '' : value };
        setWithdrawalLimit(newState.withdrawalLimit);
        filterLabel = `출금제한: ${value === 'limited' ? '제한' : '가능'}`;
        break;
      case 'paymentMethod':
        currentValue = paymentMethod;
        newState = { paymentMethod: value === currentValue ? '' : value };
        setPaymentMethod(newState.paymentMethod);
        filterLabel = `결제수단: ${value}`;
        break;
    }

    // 필터 업데이트
    setActiveFilters(prev => {
      const isRemoving = value === currentValue;
      if (isRemoving) {
        return prev.filter(filter => !filter.startsWith(filterLabel.split(':')[0]));
      } else {
        const filtered = prev.filter(filter => !filter.startsWith(filterLabel.split(':')[0]));
        return [...filtered, filterLabel];
      }
    });
  };

  // 스타일 정의
  const styles = {
    radioGroupsContainer: {
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      p: 1,  // 원래대로 복구
      '& > div': {
        backgroundColor: 'transparent',
        mb: 0.5,  // 원래대로 복구
        '&:last-child': {
          mb: 0
        }
      }
    },
    radioGroupLabel: {
      fontSize: '0.8rem',
      color: '#495057',
      mb: 0.2,
      ml: 0.5  // 라벨 왼쪽 정렬 유지
    },
    radioGroup: {
      ml: 0.5,  // 라디오 그룹 왼쪽 정렬 유지
      '& .MuiFormControlLabel-root': {
        marginRight: 1.5,  // 라디오 버튼 사이 간격만 조정
        marginY: 0,
        '& .MuiTypography-root': {
          fontSize: '0.8rem'
        }
      },
      '& .MuiRadio-root': {
        padding: '4px'
      }
    }
  };

  // 패널이 닫힐 때 포커스 해제
  const handleClose = () => {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    onClose();
  };

  // 옵션 클릭 핸들러 추가
  const handleOptionClick = (field) => {
    setSearchFields(prev => ({
      ...prev,
      [field]: prev[field] ? '' : (field === 'settlementDate' ? 'instant' : true)
    }));
  };

  // 필드 변경 핸들러 추가
  const handleFieldChange = (field, value) => {
    setSearchFields(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // 토글 옵션 선택 핸들러 추가
  const handleToggleOptionSelect = (option) => {
    // 선택된 옵션을 activeFilters에 추가
    if (!activeFilters.includes(option.label)) {
      setActiveFilters(prev => [...prev, option.label]);
    }
    
    // 정산일 옵션인 경우 settlementDate 필드 업데이트
    if (selectedOption.value === 'settlementDate') {
      setSearchFields(prev => ({
        ...prev,
        settlementDate: option.value
      }));
    }
    
    // 팝오버 닫기
    setAnchorEl(null);
    setSelectedOption(null);
    setToggleSearchText('');
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ 
        position: 'fixed',
        right: 0,
        top: '80px',
        width: '300px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px 0 0 12px',
        boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
        pl: 2,
        pr: '5px',
        pt: 2,
        pb: 2,
        height: 'calc(100vh - 100px)',
        zIndex: 900,
        '@media (max-width: 600px)': {
          overflowY: 'auto',
          overflowX: 'visible',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}>
        {/* 닫기 버튼 */}
        <IconButton 
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: '-20px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            zIndex: 1001,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            '@media (max-width: 600px)': {
              zIndex: 1001,
              left: '15px',
              position: 'fixed'
            }
          }}
        >
          <ChevronRight />
        </IconButton>

        {/* 기존 컨텐츠 */}
        <Box sx={{ p: 1.5 }}>
          <Typography variant="subtitle2" sx={{ mb: 1.5, fontSize: '0.875rem' }}>검색 조건</Typography>
          
          {/* 텍스트 필드들 */}
          <TextField
            fullWidth
            size="small"
            label="아이디, GID, MID, TID, 가맹점명, 대표자명"
            value={searchFields.companyName}
            onChange={(e) => setSearchFields(prev => ({ ...prev, companyName: e.target.value }))}
            sx={{ mb: 1.2 }}  // margin 증가
          />
          <TextField
            fullWidth
            size="small"
            label="예금주, 계좌번호"
            value={searchFields.ownerName}
            onChange={(e) => setSearchFields(prev => ({ ...prev, ownerName: e.target.value }))}
            sx={{ mb: 1.2 }}  // margin 증가
          />

          {/* 검색 옵션 선택 버튼 */}
          <Button
            variant="outlined"
            fullWidth
            onClick={handleSearchOptionClick}
            sx={{ mb: 1 }}
          >
            검색옵션 선택
          </Button>

          

          {/* 검색 옵션 팝오버 */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleSearchOptionClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 2, width: 250 }}>
              {/* 토글 옵션 검색 필드 */}
              {selectedOption && (
                <TextField
                  size="small"
                  placeholder="검색..."
                  value={toggleSearchText}
                  onChange={handleToggleSearch}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}

              {/* 옵션 목록 */}
              {!selectedOption ? (
                searchOptions.map((option) => (
                  <Button
                    key={option.value}
                    fullWidth
                    onClick={() => handleOptionSelect(option)}
                    sx={{ justifyContent: 'flex-start', mb: 1 }}
                  >
                    {option.label}
                  </Button>
                ))
              ) : (
                <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  {filteredToggleOptions.map((option) => (
                    <Button
                      key={option.value}
                      fullWidth
                      onClick={() => handleToggleOptionSelect(option)}
                      sx={{ justifyContent: 'flex-start', mb: 1 }}
                    >
                      {option.label}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          </Popover>

          {/* 버디오 버튼 그룹들을 하나의 컨테이너로 묶기 */}
          <Box sx={styles.radioGroupsContainer}>
            <Box>
              <Typography sx={styles.radioGroupLabel}>접속여부</Typography>
              <RadioGroup 
                row 
                value={loginStatus} 
                onChange={(e) => handleRadioChange('login', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="online" control={<Radio size="small" />} label="접속중" />
                <FormControlLabel value="offline" control={<Radio size="small" />} label="미접속" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>과세/비과세</Typography>
              <RadioGroup 
                row 
                value={taxType} 
                onChange={(e) => handleRadioChange('tax', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="taxable" control={<Radio size="small" />} label="과세" />
                <FormControlLabel value="taxFree" control={<Radio size="small" />} label="비과세" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>원천/비원천</Typography>
              <RadioGroup 
                row 
                value={sourceType} 
                onChange={(e) => handleRadioChange('source', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="withholding" control={<Radio size="small" />} label="원천" />
                <FormControlLabel value="nonWithholding" control={<Radio size="small" />} label="비원천" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>로그인 허용</Typography>
              <RadioGroup 
                row 
                value={loginPermission} 
                onChange={(e) => handleRadioChange('loginPermission', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="allowed" control={<Radio size="small" />} label="허용" />
                <FormControlLabel value="denied" control={<Radio size="small" />} label="차단" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>결제 허용</Typography>
              <RadioGroup 
                row 
                value={paymentPermission} 
                onChange={(e) => handleRadioChange('paymentPermission', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="allowed" control={<Radio size="small" />} label="허용" />
                <FormControlLabel value="denied" control={<Radio size="small" />} label="차단" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>보증금 여부</Typography>
              <RadioGroup 
                row 
                value={depositStatus} 
                onChange={(e) => handleRadioChange('deposit', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="yes" control={<Radio size="small" />} label="있음" />
                <FormControlLabel value="no" control={<Radio size="small" />} label="없음" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>출금 제한</Typography>
              <RadioGroup 
                row 
                value={withdrawalLimit} 
                onChange={(e) => handleRadioChange('withdrawal', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="limited" control={<Radio size="small" />} label="제한" />
                <FormControlLabel value="available" control={<Radio size="small" />} label="가능" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>결제수단</Typography>
              <RadioGroup 
                row 
                value={paymentMethod}
                onChange={(e) => handleRadioChange('paymentMethod', e.target.value)}
                sx={styles.radioGroup}
              >
                {['수기', '단말기', '온라인'].map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio size="small" />}
                    label={option}
                    sx={{
                      marginRight: 1.5,
                      marginY: 0,
                      '& .MuiTypography-root': {
                        fontSize: '0.8rem'
                      }
                    }}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Box>

          {/* 데이터 선택 개수 */}
          <TextField
            select
            fullWidth
            size="small"
            label="데이터 개수"
            value={dataCount}
            onChange={(e) => setDataCount(e.target.value)}
            sx={{ 
              mt: 1.2, 
              mb: 1.2,
              '& .MuiInputLabel-root': {
                fontSize: '0.825rem'
              },
              '& .MuiSelect-select': {
                fontSize: '0.825rem',
                padding: '6px 14px'
              }
            }}
          >
            {[10, 20, 50, 100].map(num => (
              <MenuItem key={num} value={num} sx={{ fontSize: '0.825rem' }}>{num}개</MenuItem>
            ))}
          </TextField>

          {/* 버튼 그룹 */}
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 1, 
            mt: 0.5
          }}>
            {/* 검색 버튼 */}
            <Button 
              variant="contained" 
              onClick={() => onSearch(searchFields)}
              sx={{ 
                fontSize: '0.825rem', 
                py: 0.8,
                width: '100%'
              }}
            >
              검색
            </Button>

            {/* 다운로드 버튼 그룹 */}
            <Box sx={{ 
              display: 'flex', 
              gap: 0.5,
              justifyContent: 'flex-end'
            }}>
              <Tooltip title="엑셀 다운로드">
                <IconButton
                  onClick={onExcelDownload}
                  sx={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="계정종합표">
                <IconButton
                  onClick={() => console.log('계정종합표')}
                  sx={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: '#fff',
                    border: '1px solid #1976d2',
                    color: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1976d210',
                    }
                  }}
                >
                  <SummarizeIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          

          {/* 선택된 필터 표시 */}
          {activeFilters.length > 0 && (
            <Box sx={{ mt: 1.2, mb: 1.2 }}>  {/* margin 증가 */}
              <Typography variant="subtitle2" sx={{ mb: 0.8, fontSize: '0.825rem' }}>적용된 필터</Typography>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 0.8  // gap 증가
              }}>
                {activeFilters.map((filter, index) => (
                  <Chip
                    key={index}
                    label={filter}
                    onDelete={() => removeFilter(filter)}
                    size="small"
                    sx={{ 
                      width: '100%',
                      height: '24px',  // 칩 높이 증가
                      '& .MuiChip-label': {
                        fontSize: '0.825rem',  // 칩 텍스트 크기 증가
                        px: 1.2
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}

          
          

        
        </Box>
      </Box>
    </Box>
  );
};

export default AccountSearch;
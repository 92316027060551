import React, { useState } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  IconButton,
  Tooltip,
  Checkbox,
  Typography,
  Card,
  CardContent,
  Button
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import ArticleIcon from '@mui/icons-material/Article';
import DetailInfo from '../../Common/SetModal/Details';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  height: '28px',
  lineHeight: '1.2',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
  },

  '&.MuiTableCell-paddingCheckbox': {
    width: '32px',
    padding: '0 4px',
    '& .MuiCheckbox-root': {
      padding: '4px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  maxHeight: 'calc(100vh - 300px)',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  },

  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px',
    padding: '0',
    margin: '0',
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    minWidth: 'auto',
    '& .MuiTableCell-root': {
      padding: '6px 4px',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '4px 3px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'checkbox', label: '일괄체크', align: 'center', color: '#1976d2', textColor: '#fff' },
  { id: 'no', label: 'No', align: 'center', color: '#1976d2', textColor: '#fff' },
  { id: 'merchantMid', label: '가맹점MID', align: 'left', color: '#1976d2', textColor: '#fff' },
  { id: 'merchantTid', label: '가맹점TID', align: 'left', color: '#1976d2', textColor: '#fff' },
  { id: 'merchantName', label: '가맹점명', align: 'left', color: '#1976d2', textColor: '#fff' },
  { id: 'totalCount', label: '총 매출건수', align: 'right', color: '#03a9f4', textColor: '#fff' },
  { id: 'cancelCount', label: '취소건수', align: 'right', color: '#03a9f4', textColor: '#fff' },
  { id: 'detailInfo', label: '세부내역', align: 'center', color: '#03a9f4', textColor: '#fff' },
  { id: 'checkedCount', label: '세부체크건수', align: 'center', color: '#03a9f4', textColor: '#fff' },
  { id: 'approvalAmount', label: '승인금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'cancelAmount', label: '취소금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'actualSales', label: '실매출금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'merchantFeeRate', label: '가맹점수수료', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'feeAmount', label: '수수료(VAT포함)', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'depositRate', label: '보증금율', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'depositAmount', label: '보증금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'paymentAmount', label: '입금액', align: 'center', color: '#ffffff', textColor: '#000' }
];

const dummyData = Array(5).fill(null).map((_, index) => ({
  id: index + 1,
  no: (index + 1).toString(),
  merchantMid: `MID${String(index + 1).padStart(8, '0')}`,
  merchantTid: `TID${String(index + 1).padStart(8, '0')}`,
  merchantName: `테스트가맹점${index + 1}`,
  totalCount: Math.floor(Math.random() * 50) + 10,
  cancelCount: Math.floor(Math.random() * 5),
  detailInfo: '보기',
  checkedCount: Math.floor(Math.random() * 10),
  approvalAmount: (Math.floor(Math.random() * 1000000) + 500000).toLocaleString(),
  cancelAmount: (Math.floor(Math.random() * 100000)).toLocaleString(),
  actualSales: (Math.floor(Math.random() * 900000) + 100000).toLocaleString(),
  merchantFeeRate: `${(Math.random() * 2000).toFixed(0)}/${(Math.random() * 3).toFixed(2)}%`,
  feeAmount: (Math.floor(Math.random() * 50000) + 5000).toLocaleString(),
  depositRate: `${(Math.random() * 2).toFixed(2)}%`,
  depositAmount: (Math.floor(Math.random() * 200000) + 50000).toLocaleString(),
  paymentAmount: (Math.floor(Math.random() * 700000) + 300000).toLocaleString()
}));

function RestoreTables() {
  const [detailInfoOpen, setDetailInfoOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});

  const handleDetailInfoClick = () => {
    setDetailInfoOpen(true);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    
    const newSelectedItems = {};
    dummyData.forEach(row => {
      newSelectedItems[row.id] = isChecked;
    });
    setSelectedItems(newSelectedItems);
  };

  const handleSingleSelect = (id) => {
    setSelectedItems(prev => {
      const newSelected = { ...prev, [id]: !prev[id] };
      setSelectAll(Object.values(newSelected).every(Boolean));
      return newSelected;
    });
  };

  return (
    <Card sx={{ 
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      backgroundColor: '#fff',
      mb: 2
    }}>
      <CardContent sx={{ padding: '12px', '&:last-child': { paddingBottom: '12px' } }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button
            variant="contained"
            startIcon={<RestoreIcon />}
            sx={{
              backgroundColor: '#2196f3',
              color: '#fff',
              alignSelf: 'flex-start',
              '&:hover': {
                backgroundColor: '#1976d2'
              },
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 600,
              padding: '6px 12px',
              height: '32px'
            }}
          >
            상계복원
          </Button>

          <TableContainer component={StyledTableWrapper}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <StyledTableCell 
                      key={header.id}
                      align={header.align}
                      style={{ 
                        backgroundColor: header.color,
                        color: header.textColor,
                        fontWeight: 'bold',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                      }}
                    >
                      {header.id === 'checkbox' ? (
                        <Checkbox
                          size="small"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          sx={{
                            color: '#fff',
                            '&.Mui-checked': {
                              color: '#fff',
                            },
                          }}
                        />
                      ) : (
                        header.label
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyData.map((row) => (
                  <TableRow key={row.id}>
                    {tableHeaders.map((header) => (
                      <StyledTableCell 
                        key={`${row.id}-${header.id}`}
                        align={header.align}
                      >
                        {header.id === 'checkbox' ? (
                          <Checkbox 
                            size="small"
                            checked={selectedItems[row.id] || false}
                            onChange={() => handleSingleSelect(row.id)}
                          />
                        ) : header.id === 'detailInfo' ? (
                          <Tooltip title="세부내역 보기">
                            <IconButton 
                              size="small" 
                              onClick={handleDetailInfoClick}
                              sx={{
                                padding: '4px',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                              }}
                            >
                              <ArticleIcon sx={{ fontSize: 20, color: '#6435c9' }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          row[header.id]
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>

      <DetailInfo
        open={detailInfoOpen}
        onClose={() => setDetailInfoOpen(false)}
      />
    </Card>
  );
}

export default RestoreTables;

import React from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Divider 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// 테이블 셀 스타일 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057'
}));

// 상단 요약 박스 스타일
const SummaryBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  padding: '12px 0',
  borderRadius: '4px',
  backgroundColor: '#f8f9fa',
  marginBottom: '16px'
});

const WalletModal = ({ open, onClose }) => {
  // 더미 데이터
  const walletData = {
    승인금액: 3792700,
    취소금액: -1000,
    합계: 3791700,
    상계금액: 0,
    정산예정액: 3791700,
    details: [
      {
        No: 1,
        상태: '계좌 검증대기',
        정산주기: 'D+3',
        가맹점TID: '1047208152',
        가맹점명: '(주)오픈크코리아4',
        거래일시: '2025-01-14 12:42:20',
        취소여부: '취소',
        할부개월: '일시불',
        승인번호: '40536669',
        발급사: '하나카드',
        승인금액: 0,
        취소금액: -1000,
        상계금액: 0,
        정산반영금액: -1000,
        가맹점수수료율: '2.97%',
        수수료: -29.70,
        보증금율: 0,
        보증금: 0,
        상품명: '테스트상품',
        고객연락처: '010-****-1234',
        정산액: -970
      },
      {
        No: 2,
        상태: '계좌 검증대기',
        정산주기: 'D+3',
        가맹점TID: '1047208152',
        가맹점명: '(주)오픈크코리아4',
        거래일시: '2025-01-16 17:14:08',
        취소여부: '승인',
        할부개월: '06',
        승인번호: '04341073',
        발급사: '신한(올)카드',
        승인금액: 2524500,
        취소금액: 0,
        상계금액: 0,
        정산반영금액: 2524500,
        가맹점수수료율: '2.97%',
        수수료: 74977.65,
        보증금율: 0,
        보증금: 0,
        상품명: '테스트상품2',
        고객연락처: '010-****-5678',
        정산액: 2449522
      }
    ]
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="wallet-modal-title"
      aria-describedby="wallet-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: '1200px',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
      }}>
        {/* 닫기 버튼 */}
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {/* 상단 요약 정보 */}
        <SummaryBox>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">승인금액</Typography>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
              {walletData.승인금액.toLocaleString()}원
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">취소금액</Typography>
            <Typography variant="h6" color="error" sx={{ fontWeight: 600 }}>
              {walletData.취소금액.toLocaleString()}원
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">합계</Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {walletData.합계.toLocaleString()}원
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">상계금액</Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {walletData.상계금액.toLocaleString()}원
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">정산예정액</Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {walletData.정산예정액.toLocaleString()}원
            </Typography>
          </Box>
        </SummaryBox>

        {/* 상세 내역 테이블 */}
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>상태</StyledTableCell>
                <StyledTableCell>정산주기</StyledTableCell>
                <StyledTableCell>가맹점TID</StyledTableCell>
                <StyledTableCell>가맹점명</StyledTableCell>
                <StyledTableCell>거래일시</StyledTableCell>
                <StyledTableCell>취소여부</StyledTableCell>
                <StyledTableCell>할부개월</StyledTableCell>
                <StyledTableCell>승인번호</StyledTableCell>
                <StyledTableCell>발급사</StyledTableCell>
                <StyledTableCell align="right">승인금액(원)</StyledTableCell>
                <StyledTableCell align="right">취소금액(원)</StyledTableCell>
                <StyledTableCell align="right">상계금액(원)</StyledTableCell>
                <StyledTableCell align="right">정산반영금액(원)</StyledTableCell>
                <StyledTableCell align="right">가맹점수수료율</StyledTableCell>
                <StyledTableCell align="right">수수료(VAT포함)</StyledTableCell>
                <StyledTableCell align="right">보증금율(%)</StyledTableCell>
                <StyledTableCell align="right">보증금액</StyledTableCell>
                <StyledTableCell align="right">상품명</StyledTableCell>
                <StyledTableCell align="center">고객연락처</StyledTableCell>
                <StyledTableCell align="center">정산액</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletData.details.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{row.No}</StyledTableCell>
                  <StyledTableCell>{row.상태}</StyledTableCell>
                  <StyledTableCell>{row.정산주기}</StyledTableCell>
                  <StyledTableCell>{row.가맹점TID}</StyledTableCell>
                  <StyledTableCell>{row.가맹점명}</StyledTableCell>
                  <StyledTableCell>{row.거래일시}</StyledTableCell>
                  <StyledTableCell>{row.취소여부}</StyledTableCell>
                  <StyledTableCell>{row.할부개월}</StyledTableCell>
                  <StyledTableCell>{row.승인번호}</StyledTableCell>
                  <StyledTableCell>{row.발급사}</StyledTableCell>
                  <StyledTableCell align="right">{row.승인금액.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.취소금액.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.상계금액.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.정산반영금액.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.가맹점수수료율}</StyledTableCell>
                  <StyledTableCell align="right">{row.수수료.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.보증금율}</StyledTableCell>
                  <StyledTableCell align="right">{row.보증금.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.상품명}</StyledTableCell>
                  <StyledTableCell align="center">{row.고객연락처}</StyledTableCell>
                  <StyledTableCell align="center">{row.정산액.toLocaleString()}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default WalletModal; 
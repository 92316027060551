import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  useMediaQuery,
  useTheme,
  Paper
} from '@mui/material';
import Notice from '../../Notice/Notice'; // 공지사항 컴포넌트
import DateRangePicker from '../../Common/DateRangePicker'; // DateRangePicker 추가
import QuickMenu from '../../Common/QuickMenu'; // 퀵메뉴 추가
import ScSummaryTable from './ScSummaryTable'; // 테이블 컴포넌트 추가
import CurrentLocation from '../../Common/CurrentLocation';
import SearchOptions from '../../Common/SearchOptions';

function ScheduledSettlementSummary() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  useEffect(() => {
    const handleResize = () => {
      // 모바일 처리 로직
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="정산집계 내역" />
        <DateRangePicker 
          startDate={startDate} 
          endDate={endDate} 
          onStartDateChange={setStartDate} 
          onEndDateChange={setEndDate} 
        />
        <ScSummaryTable startDate={startDate} endDate={endDate} />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default ScheduledSettlementSummary;
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  useMediaQuery,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 필요한 컴포넌트들 import
import Notice from '../../Notice/Notice';
import QuickMenu from '../../Common/QuickMenu';
import SearchArea from '../../Common/SettlementSearch';
import OutpuyTable from './OutputTable';
import SettlementDownButton from '../../Common/SettlementDownButton';
import SummaryTable from './SummaryTable';
import SecurityCheck from './SecurityCheck';
import WalletInOutTable from './WalletInOutTable';
import CurrentLocation from '../../Common/CurrentLocation';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    ml: '40px',  // QuickMenu 기준
    mr: '40px',  // SearchPanel 없음
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    '@media (max-width: 960px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  contentBox: {
    width: '100%'
  },
  noticeBox: { 
    width: '100%',
    mb: 2
  }
};

// 테이블 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '10px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard',
  backgroundColor: '#fff',
  color: '#1e293b',
  whiteSpace: 'nowrap',
  borderBottom: '1px solid #edf2f7',
  fontWeight: 400,
  width: '20%',
  '&:not(:last-child)': {
    borderRight: '1px solid #edf2f7',
  },
  '&.label': {
    backgroundColor: '#f8fafc',
    color: '#475569',
    fontWeight: 700,
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#f1f5f9',
    }
  },
  '&.value': {
    textAlign: 'right',
    paddingRight: '24px',
    '&.negative': {
      color: '#ef4444',
    }
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    '& .column-hover': {
      '& .label': {
        backgroundColor: '#f1f5f9',
      },
      '& .value': {
        backgroundColor: '#fafafa',
      }
    }
  }
});

const StyledTableContainer = styled(TableContainer)({
  borderRadius: '12px',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  border: '1px solid #edf2f7',
  transition: 'all 0.2s ease',
  marginBottom: '24px',
  overflowX: 'auto',
  '&:hover': {
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  }
});

function WalletInOutHistory() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  useEffect(() => {
    console.log('WalletInOutHistory 컴포넌트가 마운트되었습니다.');
    document.title = '월렛입출금 내역'; // 페이지 타이틀 설정
  }, []);

  const tableData = [
    [
      { label: '머천트 입금액', value: '1,234,567' },
      { label: '마스터 입금액', value: '2,345,678' },
      { label: '계정 입금액', value: '3,456,789' },
      { label: '적립금 합계', value: '567,890' }
    ],
    [
      { label: '머천트 출금액', value: '-234,567', isNegative: true },
      { label: '마스터 출금액', value: '-345,678', isNegative: true },
      { label: '계정 출금액', value: '-456,789', isNegative: true },
      { label: '마스터 적립금 합계', value: '678,901' }
    ],
    [
      { label: '머천트 잔액', value: '1,000,000' },
      { label: '마스터 잔액', value: '351,750' },
      { label: '계정 잔액', value: '3,000,000' },
      { label: '지사 적립금 합계', value: '789,012' }
    ],
    [
      { label: '송금 수수료', value: '4,950' },
      { label: '', value: '' },
      { label: '중지계정 잔액', value: '890,123' },
      { label: '대리점 적립금 합계', value: '901,234' }
    ],
    [
      { label: '월렛 수수료', value: '4,950' },
      { label: '', value: '' },
      { label: '계정 미출금액', value: '-123,456', isNegative: true },
      { label: '', value: '' }
    ],
    [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '계정 출금 가능액', value: '-123,456', isNegative: true },
      { label: '', value: '' }
    ],
    [
      { label: '머천트(정액+정률) 합계', value: '2,234,567' },
      { label: '마스터(정액+정률) 합계', value: '2,345,678' },
      { label: '계정(정액+정률) 합계', value: '5,456,789' },
      { label: '', value: '' }
    ],
    [
      { label: '미확인 입금액', value: '234,567' },
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: '' }
    ]
  ];

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="월렛입출금 내역" />

        <Box sx={styles.noticeBox}>
          <SearchArea />
        </Box>

        <Box sx={{ mb: 3, overflowX: 'auto' }}>
          <StyledTableContainer>
            <Table 
              size="small" 
              sx={{ 
                tableLayout: 'fixed',
                minWidth: '1200px', // 최소 너비 설정
                '& td': {
                  minWidth: '150px' // 각 셀의 최소 너비
                }
              }}
            >
              <TableBody>
                {tableData.map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <React.Fragment key={cellIndex}>
                        <StyledTableCell 
                          className={`label column-hover-${Math.floor(cellIndex/2)}`}
                        >
                          {cell.label}
                        </StyledTableCell>
                        <StyledTableCell 
                          className={`value column-hover-${Math.floor(cellIndex/2)} ${cell.isNegative ? 'negative' : ''}`}
                        >
                          {cell.value}
                        </StyledTableCell>
                      </React.Fragment>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        <Box sx={styles.noticeBox}>
          <OutpuyTable />
        </Box>

        <Box sx={styles.noticeBox}>
          <SettlementDownButton />
        </Box>

        <Box sx={styles.noticeBox}>
          <SummaryTable />
        </Box>

        <Box sx={styles.noticeBox}>
          <SecurityCheck />
        </Box>

        <Box sx={styles.noticeBox}>
          <WalletInOutTable />
        </Box>
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default WalletInOutHistory;

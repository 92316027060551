import React, { useState } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Box,
  Button,
  TextField,
  IconButton,
  FormControlLabel,
  Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '90%',
    maxWidth: '1200px',
    maxHeight: 'calc(100% - 64px)'
  }
});

const SearchButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      startIcon={<SearchIcon />}
      onClick={onClick}
      sx={{
        bgcolor: '#fff',
        color: '#1976d2',
        border: '1px solid #1976d2',
        fontSize: '0.875rem',
        height: '36px',
        padding: '6px 16px',
        minWidth: 'auto',
        boxShadow: 'none',
        '&:hover': {
          bgcolor: '#1976d210',
          border: '1px solid #1976d2',
          boxShadow: 'none'
        }
      }}
    >
      검색
    </Button>
  );
};

const DownloadButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      startIcon={<FileDownloadIcon />}
      onClick={onClick}
      sx={{
        bgcolor: '#fff',
        color: '#4caf50',
        border: '1px solid #4caf50',
        fontSize: '0.875rem',
        height: '36px',
        padding: '6px 16px',
        minWidth: 'auto',
        boxShadow: 'none',
        '&:hover': {
          bgcolor: '#4caf5010',
          border: '1px solid #4caf50',
          boxShadow: 'none'
        }
      }}
    >
      미수금 목록 다운로드
    </Button>
  );
};

function SearchTable({ open, onClose }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchParams, setSearchParams] = useState({
    merchantName: '',
    loginId: '',
    unmatched: false
  });

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedItems(dummyData.map(row => row.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (checked, id) => {
    if (checked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter(item => item !== id));
    }
  };

  const dummyData = Array(10).fill(null).map((_, index) => ({
    id: index + 1,
    merchantName: `가맹점${index + 1}`,
    loginId: `user${index + 1}`,
    approvalNo: `AP${100000 + index}`,
    amount: `${(index + 1) * 10000}`,
    refundAmount: `${(index + 1) * 5000}`,
    refundDate: '2024-03-21'
  }));

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
        padding: '16px 24px'
      }}>
        <Typography variant="h6">환입 내역 조회</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        {/* 검색 옵션 */}
        <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            label="상호명"
            size="small"
            value={searchParams.merchantName}
            onChange={(e) => setSearchParams({...searchParams, merchantName: e.target.value})}
          />
          <TextField
            label="LoginID"
            size="small"
            value={searchParams.loginId}
            onChange={(e) => setSearchParams({...searchParams, loginId: e.target.value})}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.unmatched}
                onChange={(e) => setSearchParams({...searchParams, unmatched: e.target.checked})}
              />
            }
            label="환입금 미매칭"
          />
          <SearchButton onClick={() => console.log('검색:', searchParams)} />
          <DownloadButton onClick={() => console.log('다운로드')} />
        </Box>

        {/* 테이블 */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                  />
                </TableCell>
                <TableCell>상호명</TableCell>
                <TableCell>로그인아이디</TableCell>
                <TableCell>승인번호</TableCell>
                <TableCell align="right">결제금액</TableCell>
                <TableCell align="right">환입금</TableCell>
                <TableCell>환입날짜</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedItems.includes(row.id)}
                      onChange={(e) => handleSelectItem(e.target.checked, row.id)}
                    />
                  </TableCell>
                  <TableCell>{row.merchantName}</TableCell>
                  <TableCell>{row.loginId}</TableCell>
                  <TableCell>{row.approvalNo}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.refundAmount}</TableCell>
                  <TableCell>{row.refundDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
}

export default SearchTable;

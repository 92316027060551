import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Button
} from '@mui/material';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileUploadHistory = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText, setSearchText] = useState('');

  // 더미 데이터 추가
  const rows = [
    {
      no: 1,
      id: 'm20240927',
      files: [
        '20240925_223908.png',
        '20240926_214035.png',
        '20240926_215612.png',
        '20240926_215548.png'
      ]
    },
    {
      no: 2,
      id: 'mt20240305',
      files: [
        '20240208_115336.png',
        '20240208_140404.png',
        '20240208_140107.png',
        '20240207_132330.png',
        '20240208_140133.png',
        '20240208_140345.png',
        '20240208_140142.png'
      ]
    },
    {
      no: 3,
      id: 'm20242024',
      files: [
        '170919203031.png',
        '170919203261.png',
        '170919203380.png'
      ]
    },
    {
      no: 4,
      id: 'm39469493',
      files: ['1-3.png']
    },
    {
      no: 5,
      id: 'm26509866',
      files: ['1-2.png']
    },
    {
      no: 6,
      id: 'm241117',
      files: [
        '20240926_215554.png',
        '20240926_215606.png',
        '20240926_215612.png',
        '20240926_223908.png',
        '20240926_215600.png'
      ]
    },
    {
      no: 7,
      id: 'm12044',
      files: [
        '20240926_215600.png',
        '20240926_215613.png'
      ]
    }
  ];

  const handleSearch = () => {
    // Implement search logic here
    console.log('Searching for:', searchText);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* 제목 영역 추가 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <CloudUploadIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          파일 업로드 내역
        </Typography>
      </Box>

      {/* 필터/검색 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'nowrap'
        }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ 
              width: '300px',
              ml: 2
            }}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSearch} // Add onClick handler
          >
            검색
          </Button>
        </Box>
      </Paper>

      {/* 테이블 영역 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={{ 
                  padding: '12px 8px',
                  textAlign: 'center',
                  color: '#344767',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  textTransform: 'uppercase'
                }}>No</th>
                <th style={{ 
                  padding: '12px 8px',
                  color: '#344767',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  textTransform: 'uppercase'
                }}>ID</th>
                <th style={{ 
                  padding: '12px 8px',
                  color: '#344767',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  textTransform: 'uppercase'
                }}>파일명</th>
                <th style={{ 
                  padding: '12px 8px',
                  textAlign: 'center',
                  color: '#51cbce',
                  fontWeight: 700,
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                  backgroundColor: '#f8f9fa'
                }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.no}>
                  <td style={{ 
                    padding: '12px 8px',
                    textAlign: 'center',
                    fontSize: '0.875rem'
                  }}>{row.no}</td>
                  <td style={{ 
                    padding: '12px 8px',
                    fontSize: '0.875rem'
                  }}>{row.id}</td>
                  <td style={{ 
                    padding: '12px 8px',
                    fontSize: '0.875rem'
                  }}>
                    {row.files.map((file, index) => (
                      <Box 
                        key={index}
                        sx={{
                          display: 'inline-block',
                          backgroundColor: '#e3f2fd',
                          borderRadius: '4px',
                          padding: '4px 8px',
                          margin: '2px',
                          fontSize: '0.8125rem',
                          color: '#1976d2'
                        }}
                      >
                        {file}
                      </Box>
                    ))}
                  </td>
                  <td style={{ 
                    padding: '12px 8px',
                    textAlign: 'center',
                    backgroundColor: '#fafbfc'
                  }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center',
                      gap: '4px'
                    }}>
                      <Tooltip title="다운로드">
                        <IconButton
                          size="medium"
                          sx={{
                            backgroundColor: '#51cbce',
                            color: '#fff',
                            width: '36px',
                            height: '36px',
                            '&:hover': {
                              backgroundColor: '#34b5b8'
                            }
                          }}
                        >
                          <DownloadIcon sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="보기">
                        <IconButton
                          size="medium"
                          sx={{
                            backgroundColor: '#6bd098',
                            color: '#fff',
                            width: '36px',
                            height: '36px',
                            '&:hover': {
                              backgroundColor: '#44c47d'
                            }
                          }}
                        >
                          <VisibilityIcon sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title="삭제">
                        <IconButton
                          size="medium"
                          sx={{
                            backgroundColor: '#ef8157',
                            color: '#fff',
                            width: '36px',
                            height: '36px',
                            '&:hover': {
                              backgroundColor: '#eb6532'
                            }
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>
    </Box>
  );
};

export default FileUploadHistory;

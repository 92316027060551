import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, 
  IconButton, Tooltip, Modal, Paper, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import DnsIcon from '@mui/icons-material/Dns';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)( {
  height: 'calc(100vh - 380px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const HeaderBox = styled(Box)( {
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const SystemNotice = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [notices, setNotices] = useState([
    {
      action: '변경',
      group: '어드민',
      public: 1,
      pinned: 1, // 고정여부 추가
      title: '고객센터 업무시간 안내',
      content: '업무시간 09:30~17:30\n(점심 12:00~13:00) 토,일 공휴일 휴무\n\n1566-9805 로 연락주시기 바랍니다.\nhelp@b19inc.com',
      date: '2022-07-20'
    },
    {
      action: '추가',
      group: '어드민',
      public: 1,
      pinned: 0, // 고정여부 추가
      title: '업데이트 안내 ver.2.1.1',
      content: 'Payment management\n- 사용자 UI/UX 개선\n- 마이페이지 오픈\n- 플러그인 고객센터 상시 오픈\n- 정기결제 기능 개선\n- 환불 모듈 기능 개\n- 디자인에 관련승급 오픈\n- 기타 버그 수정',
      date: '2024-03-06'
    },
    {
      action: '변경',
      group: '어드민',
      public: 0,
      pinned: 1, // 고정여부 추가
      title: '팝업 점검 작업 공지',
      content: '안녕하세요.\n2023년 10월 3일 10:00 ~ 16:00 팝업 점검 작업이 예정되어 있습니다.\n업무에 참고하시기 바랍니다.',
      date: '2023-10-03'
    }
  ]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNotice, setEditNotice] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [searchPanelOpen, setSearchPanelOpen] = useState(false);
  const showCloseButton = !editModalOpen;

  // 공지 추가 모달 상태 관리
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newNotice, setNewNotice] = useState({
    group: '어드민',
    public: 0,
    pinned: 0, // 고정여부 추가
    title: '',
    content: '',
    date: new Date().toISOString().split('T')[0]
  });

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedNotices = [...notices].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setNotices(sortedNotices);
  };

  const handleEditClick = (notice) => {
    setEditNotice(notice);
    setEditContent(notice.content);
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
    setEditNotice(null);
    setEditContent('');
  };

  const handleEditSubmit = () => {
    console.log('수정된 내용:', {
      ...editNotice,
      content: editContent
    });
    handleEditClose();
  };

  // 모달 핸들러
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewNotice({
      group: '어드민',
      public: 0,
      pinned: 0, // 고정여부 초기화
      title: '',
      content: '',
      date: new Date().toISOString().split('T')[0]
    });
  };

  const handleAddSubmit = () => {
    console.log('새로운 공지:', newNotice);
    handleAddClose();
  };

  return (
    <>
      <StyledCard>
        <HeaderBox>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DnsIcon sx={{ color: '#d32f2f' }} />
            <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
              본전산 공지사항
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            onClick={handleAddClick}
            sx={{ 
              backgroundColor: '#d32f2f',
              '&:hover': {
                backgroundColor: '#9a0007'
              },
              boxShadow: '0 2px 4px rgba(211, 47, 47, 0.3)',
              textTransform: 'none',
              fontWeight: 500
            }}
          >
            공지추가
          </Button>
        </HeaderBox>
        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>그룹</StyledTableCell>
                <StyledTableCell>공개/비공개</StyledTableCell>
                <StyledTableCell>고정여부</StyledTableCell> {/* 고정여부 추가 */}
                <StyledTableCell>제목</StyledTableCell>
                <StyledTableCell>내용</StyledTableCell>
                <StyledTableCell 
                  onClick={handleSortClick}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eaeaea'
                    }
                  }}
                >
                  등록시간 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                </StyledTableCell>
                <StyledTableCell>작업</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notices.map((notice, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{notice.group}</StyledTableCell>
                  <StyledTableCell>{notice.public ? '0' : '1'}</StyledTableCell>
                  <StyledTableCell>{notice.pinned ? '1' : '0'}</StyledTableCell> {/* 고정여부 표시 */}
                  <StyledTableCell>{notice.title}</StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {notice.content}
                  </StyledTableCell>
                  <StyledTableCell>{notice.date}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                      <Tooltip title="수정">
                        <IconButton 
                          size="small" 
                          sx={{ color: '#fbc658' }}
                          onClick={() => handleEditClick(notice)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="삭제">
                        <IconButton size="small" sx={{ color: '#ef8157' }}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </StyledCard>

      <Modal
        open={editModalOpen}
        onClose={handleEditClose}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          pt: '15vh'
        }}
      >
        <Paper sx={{ 
          width: '600px',
          p: 3,
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          borderRadius: '4px',
          bgcolor: '#f5f5f5'
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            공지사항 수정
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={editContent}
            onChange={(e) => setEditContent(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 1
          }}>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              sx={{ width: '80px' }}
            >
              확인
            </Button>
            <Button
              onClick={handleEditClose}
              variant="outlined"
              sx={{ width: '80px' }}
            >
              취소
            </Button>
          </Box>
        </Paper>
      </Modal>

      {/* 공지 추가 모달 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          공지등록
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 1,
          p: 3,
          '& .MuiFormControl-root': {
            minHeight: '65px'
          }
        }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1
                }}>그룹</InputLabel>
                <Select
                  value={newNotice.group}
                  onChange={(e) => setNewNotice({...newNotice, group: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="어드민">어드민</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1
                }}>공개여부</InputLabel>
                <Select
                  value={newNotice.public}
                  onChange={(e) => setNewNotice({...newNotice, public: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value={0}>비공개</MenuItem>
                  <MenuItem value={1}>공개</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="제목"
                value={newNotice.title}
                onChange={(e) => setNewNotice({...newNotice, title: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                type="date"
                label="일자"
                value={newNotice.date}
                onChange={(e) => setNewNotice({...newNotice, date: e.target.value})}
                InputLabelProps={{ 
                  shrink: true,
                  sx: { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
                sx={{ '& .MuiInputBase-root': { height: '40px' } }}
              />
            </Grid>
            <Grid item xs={12}>  {/* 내용은 전체 너비 사용 */}
              <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
                label="내용"
                value={newNotice.content}
                onChange={(e) => setNewNotice({...newNotice, content: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  },
                  '& .MuiInputBase-root': {
                    height: 'auto',
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
          <Button 
            variant="contained" 
            color="error"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SystemNotice;
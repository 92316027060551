import React, { useState } from 'react';
import { 
  Box, 
  TextField,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Chip,
  Popover,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangePicker from './DateRangePicker';
import dayjs from 'dayjs';

const FILTER_TYPES = {
  WALLET: 'WALLET',
  TAX: 'TAX',
  SOURCE: 'SOURCE',
  BUSINESS: 'BUSINESS'
};

function StatisticsGiganSearch({ onSearch, onClose }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText, setSearchText] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);
  const [walletType, setWalletType] = useState('');
  const [taxType, setTaxType] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const searchOptions = [
    { value: 'master', label: '마스터' },
    { value: 'branch', label: '지사' },
    { value: 'distributor', label: '총판' },
    { value: 'agency', label: '대리점' },
    { value: 'retail', label: '리테일' },
    { value: 'sales1', label: '영업1' },
    { value: 'sales2', label: '영업2' },
    { value: 'sales3', label: '영업3' },
    { value: 'pg', label: 'PG' },
    { value: 'merchantMID', label: '상점MID' },
    { value: 'paymentType', label: '결제형식' },
    { value: 'authType', label: '인증방식' },
  ];

  const removeFilter = (filterToRemove) => {
    const [category] = filterToRemove.split(': ');
    setActiveFilters(prev => prev.filter(filter => filter !== filterToRemove));

    switch(category) {
      case '월렛':
        setWalletType('');
        break;
      case '과세구분':
        setTaxType('');
        break;
      case '원천구분':
        setSourceType('');
        break;
      case '사업자구분':
        setBusinessType('');
        break;
    }
  };

  const handleRadioChange = (type, value) => {
    let filterLabel = '';
    switch(type) {
      case FILTER_TYPES.WALLET:
        setWalletType(value);
        filterLabel = `월렛: ${value}`;
        break;
      case FILTER_TYPES.TAX:
        setTaxType(value);
        filterLabel = `과세구분: ${value === 'taxable' ? '과세' : '비과세'}`;
        break;
      case FILTER_TYPES.SOURCE:
        setSourceType(value);
        filterLabel = `원천구분: ${value}`;
        break;
      case FILTER_TYPES.BUSINESS:
        setBusinessType(value);
        filterLabel = `사업자구분: ${value}`;
        break;
    }

    setActiveFilters(prev => {
      const newFilters = prev.filter(filter => !filter.startsWith(filterLabel.split(':')[0]));
      return [...newFilters, filterLabel];
    });
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchClick = () => {
    const searchParams = {
      startDate,
      endDate,
      searchText,
      walletType,
      taxType,
      sourceType,
      businessType,
      activeFilters
    };
    onSearch(searchParams);
  };

  const handleDateIconClick = (dateType) => {
    const today = dayjs();

    switch (dateType) {
      case 'today':
        setStartDate(today.format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'yesterday':
        const yesterday = today.subtract(1, 'day');
        setStartDate(yesterday.format('YYYY-MM-DD'));
        setEndDate(yesterday.format('YYYY-MM-DD'));
        break;
      case 'last7days':
        setStartDate(today.subtract(7, 'day').format('YYYY-MM-DD'));
        setEndDate(today.subtract(1, 'day').format('YYYY-MM-DD'));
        break;
      case 'lastMonth':
        const lastMonth = today.subtract(1, 'month');
        setStartDate(lastMonth.startOf('month').format('YYYY-MM-DD'));
        setEndDate(lastMonth.endOf('month').format('YYYY-MM-DD'));
        break;
      case 'thisMonth':
        setStartDate(today.startOf('month').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      default:
        return;
    }
  };

  const styles = {
    radioGroupBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: 2
    },
    radioGroupLabel: {
      fontSize: '0.875rem',
      minWidth: '80px',
      color: '#475569',
      fontWeight: 600
    },
    radioGroup: {
      margin: 0
    },
    radioOption: {
      mr: 2,
      '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem'
      },
      '& .MuiRadio-root': {
        padding: '4px'
      }
    }
  };

  return (
    <Box sx={{ 
      position: 'fixed',
      right: 0,
      top: '80px',
      width: '300px',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '12px 0 0 12px',
      boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
      pl: 2,
      pr: '5px',
      pt: 2,
      pb: 2,
      height: 'calc(100vh - 100px)',
      zIndex: 900,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderRadius: '3px',
      }
    }}>
      <Box sx={{ position: 'relative' }}>
        <IconButton 
          onClick={onClose}
          sx={{
            position: 'fixed',
            left: 'calc(100vw - 320px)',
            top: '50vh',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            zIndex: 901,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        <TextField
          size="small"
          placeholder="MID, TID, 가맹점명, 상품명"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ width: '260px', mb: 3 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          sx={{ width: '100%', mb: 2 }}
        />

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          mt: 2,
          mb: 2,
          width: '260px'
        }}>
          <Tooltip title="오늘">
            <TodayIcon onClick={() => handleDateIconClick('today')} />
          </Tooltip>
          <Tooltip title="어제">
            <EventIcon onClick={() => handleDateIconClick('yesterday')} />
          </Tooltip>
          <Tooltip title="지난 7일">
            <DateRangeIcon onClick={() => handleDateIconClick('last7days')} />
          </Tooltip>
          <Tooltip title="지난달">
            <CalendarMonthIcon onClick={() => handleDateIconClick('lastMonth')} />
          </Tooltip>
          <Tooltip title="이번달">
            <CalendarTodayIcon onClick={() => handleDateIconClick('thisMonth')} />
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>월렛여부:</Typography>
            <RadioGroup 
              row 
              value={walletType} 
              onChange={(e) => handleRadioChange(FILTER_TYPES.WALLET, e.target.value)}
              sx={styles.radioGroup}
            >
              <FormControlLabel 
                value="yes" 
                control={<Radio size="small" />} 
                label="있음"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="no" 
                control={<Radio size="small" />} 
                label="없음"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>과세구분:</Typography>
            <RadioGroup 
              row 
              value={taxType} 
              onChange={(e) => handleRadioChange(FILTER_TYPES.TAX, e.target.value)}
              sx={styles.radioGroup}
            >
              <FormControlLabel 
                value="taxable" 
                control={<Radio size="small" />} 
                label="과세"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="taxFree" 
                control={<Radio size="small" />} 
                label="비과세"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>원천구분:</Typography>
            <RadioGroup 
              row 
              value={sourceType} 
              onChange={(e) => handleRadioChange(FILTER_TYPES.SOURCE, e.target.value)}
              sx={styles.radioGroup}
            >
              <FormControlLabel 
                value="원천" 
                control={<Radio size="small" />} 
                label="원천"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="비원천" 
                control={<Radio size="small" />} 
                label="비원천"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>사업자구분:</Typography>
            <RadioGroup 
              row 
              value={businessType} 
              onChange={(e) => handleRadioChange(FILTER_TYPES.BUSINESS, e.target.value)}
              sx={styles.radioGroup}
            >
              <FormControlLabel 
                value="사업자" 
                control={<Radio size="small" />} 
                label="사업자"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="개인" 
                control={<Radio size="small" />} 
                label="개인"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>
        </Box>

        <Button
          variant="contained"
          onClick={handleSearchClick}
          sx={{
            width: '260px',
            mt: 2,
            mb: 2,
            backgroundColor: '#2196f3',
            '&:hover': {
              backgroundColor: '#1976d2'
            }
          }}
        >
          검색
        </Button>

        {activeFilters.length > 0 && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>적용된 필터</Typography>
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 1,
              width: '100%'
            }}>
              {activeFilters.map((filter, index) => (
                <Chip
                  key={index}
                  label={filter}
                  onDelete={() => removeFilter(filter)}
                  size="small"
                  sx={{ 
                    width: '100%',
                    '.MuiChip-label': {
                      whiteSpace: 'normal',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden'
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default StatisticsGiganSearch;

import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Checkbox, TablePagination, Box, Button, IconButton, Tooltip, Modal, Dialog } from '@mui/material';
import styled from '@emotion/styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MaechulGamangOne from '../../Common/StatisticsModal/MaechulGamangOne';
import MaechulGamangTwo from '../../Common/StatisticsModal/MaechulGamangTwo';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.group-white': {
    backgroundColor: '#ffffff',
  },
  '&.group-gray': {
    backgroundColor: '#f5f5f5',
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

function MaechulYeongeopTables({ 
  searchParams,
  onCheckboxChange,
  selectedApprovals,
  setSelectedApprovals,
  searchPanelOpen,
  onReceiptClick,
  onDataUpdate
}) {
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [expandedRows, setExpandedRows] = useState(new Set());

  // 버튼 확장/축소 토글 함수 추가
  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  // 수정된 임시 데이터
  const mockData = [
    {
      id: 1,
      accountStatus: '정상',
      grade: 'VIP',
      userId: 'user123',
      salesPerson: '홍길동',
      businessType: '개인(원천)',
      approvalAmount: 1000000,
      cancelAmount: 50000,
      totalAmount: 950000,
      feeAmount: 28500,
      paymentAmount: 921500,
      reserveAmount: 0,
      deductionAmount: 10000,
      branch: {
        name: '서울지사',
        feeRate: 3.3,
        payment: 150000
      },
      subRows: [
        {
          id: '1-1',
          accountStatus: '정상',
          grade: 'GOLD',
          userId: 'user456',
          salesPerson: '김영업',
          businessType: '개인(원천)',
          approvalAmount: 800000,
          cancelAmount: 30000,
          totalAmount: 770000,
          feeAmount: 23100,
          paymentAmount: 746900,
          reserveAmount: 0,
          businessInfo: '갤럭시아, 한일세탁소, 2' // "-" 컬럼에 표시될 정보
        }
      ]
    }
  ];

  // 텍스트 자르기 함수
  const truncateText = (text, maxLength = 15) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <StyledTableWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell className="primary-header">계정상태</StyledTableCell>
              <StyledTableCell className="primary-header">등급</StyledTableCell>
              <StyledTableCell className="primary-header">세부</StyledTableCell>
              <StyledTableCell className="primary-header">아이디</StyledTableCell>
              <StyledTableCell className="primary-header">영업자명</StyledTableCell>
              <StyledTableCell className="primary-header">개인/사업자(원천/비원천)</StyledTableCell>
              <StyledTableCell className="primary-header">승인금액</StyledTableCell>
              <StyledTableCell className="primary-header">취소합계</StyledTableCell>
              <StyledTableCell className="primary-header">합계</StyledTableCell>
              <StyledTableCell className="primary-header">수수료금액</StyledTableCell>
              <StyledTableCell className="primary-header">지급액</StyledTableCell>
              <StyledTableCell className="primary-header">유보액</StyledTableCell>
              <StyledTableCell className="primary-header">차감액</StyledTableCell>
              <StyledTableCell className="primary-header">-</StyledTableCell>
              <StyledTableCell className="primary-header">지사</StyledTableCell>
              <StyledTableCell className="primary-header">수수료율(vat포함)</StyledTableCell>
              <StyledTableCell className="primary-header">지급액</StyledTableCell>
              <StyledTableCell className="primary-header">-</StyledTableCell>
              <StyledTableCell className="primary-header">그래프</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockData.map((row) => (
              <>
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center">{row.accountStatus}</StyledTableCell>
                  <StyledTableCell align="center">{row.grade}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <IconButton
                        size="small"
                        onClick={() => toggleRowExpansion(row.id)}
                      >
                        {expandedRows.has(row.id) ? (
                          <RemoveCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#ef4444' }} />
                        ) : (
                          <AddCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#2e7d32' }} />
                        )}
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{row.userId}</StyledTableCell>
                  <StyledTableCell>{row.salesPerson}</StyledTableCell>
                  <StyledTableCell align="center">{row.businessType}</StyledTableCell>
                  <StyledTableCell align="right">{row.approvalAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.cancelAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.totalAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.feeAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.paymentAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.reserveAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.deductionAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell>{row.branch.name}</StyledTableCell>
                  <StyledTableCell align="right">{row.branch.feeRate}%</StyledTableCell>
                  <StyledTableCell align="right">{row.branch.payment.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ 
                      width: '100px', 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: '2px'
                    }}>
                      <Box sx={{ 
                        height: '20px',
                        backgroundColor: '#2e7d32',
                        width: '60px'
                      }} />
                      <Box sx={{ 
                        height: '20px',
                        backgroundColor: '#d32f2f',
                        width: '20px'
                      }} />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
                {expandedRows.has(row.id) && row.subRows?.map((subRow) => (
                  <StyledTableRow 
                    key={subRow.id}
                    sx={{ 
                      backgroundColor: '#f8f9fa'
                    }}
                  >
                    <StyledTableCell align="center">{subRow.accountStatus}</StyledTableCell>
                    <StyledTableCell align="center">{subRow.grade}</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{subRow.userId}</StyledTableCell>
                    <StyledTableCell>{subRow.salesPerson}</StyledTableCell>
                    <StyledTableCell align="center">{subRow.businessType}</StyledTableCell>
                    <StyledTableCell align="right">{subRow.approvalAmount.toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">{subRow.cancelAmount.toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">{subRow.totalAmount.toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">{subRow.feeAmount.toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">{subRow.paymentAmount.toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">{subRow.reserveAmount.toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">-</StyledTableCell>
                    <StyledTableCell 
                      align="left" 
                      sx={{ 
                        minWidth: '200px',
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <Tooltip title={subRow.businessInfo} placement="top">
                        <span>{truncateText(subRow.businessInfo)}</span>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>

      <MaechulGamangOne 
        open={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        businessName={selectedBusiness}
      />
      <MaechulGamangTwo
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        businessName={selectedBusiness}
      />
    </Paper>
  );
}

export default MaechulYeongeopTables;

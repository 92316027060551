import React, { useState, useEffect } from 'react';
import { 
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuickMenu from '../../Common/QuickMenu';
import StatisticsGiganSearch from '../../Common/StatisticsGiganSearch';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import IlbyeolData from './bunseokGiganSection/ilbyeolData';
import PgData from './bunseokGiganSection/PgData';
import GamengData from './bunseokGiganSection/GamengData';
import InJeungData from './bunseokGiganSection/InJeungData';
import IlbyeolgwaseData from './bunseokGiganSection/ilbyeolgwaseData';
import IlsaeobjaData from './bunseokGiganSection/ilsaeobjaData';

function BunseokGigan() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '10px'
    },
    ml: {
      xs: '10px',
      md: '50px'
    },
    '@media (max-width: 960px)': {
      ml: '10px',
      pr: '10px'
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 공지사항 */}
      <Notice />

      {/* 현재 위치 */}
      <CurrentLocation mainTitle="통계" subTitle="분석기간" />
      
      <Box sx={{ mb: '20px' }} />
      
      {/* 메인 컨텐츠 영역 */}
      <Box>
        <IlbyeolData />
        <PgData />
        <br />
        <GamengData />
        <br />
        <InJeungData />
        <br />
        <IlbyeolgwaseData />
        <br />
        <IlsaeobjaData />
      </Box>

      <Box sx={{ mb: '20px' }} />

      {/* 퀵 메뉴 */}
      <QuickMenu />

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <Tooltip title="검색 패널 열기" placement="left">
          <IconButton
            onClick={() => setSearchPanelOpen(true)}
            sx={{
              position: 'fixed',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#fff',
              borderRadius: '12px 0 0 12px',
              boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              },
              zIndex: 900
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* 검색 패널 */}
      {searchPanelOpen && (
        <StatisticsGiganSearch 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
        />
      )}
    </Box>
  );
}

export default BunseokGigan;

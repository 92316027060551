import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Switch,
  Paper,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
    height: '28px',
    padding: '4px 8px'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    backgroundColor: '#f8f9fa'
  }
}));

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '6px 8px',
    fontSize: '0.813rem',
    textAlign: 'right'
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  }
});

const StyledSelect = styled(Select)({
  fontSize: '0.813rem',
  '& .MuiSelect-select': {
    padding: '6px 8px'
  }
});

const CardLimitChange = ({ open, onClose }) => {
  const cardCompanies = [
    '신한카드', '삼성카드', '현대카드', '롯데카드', 
    'BC카드', 'KB국민', '하나카드', '농협카드'
  ];

  // 중복결제 가능횟수 옵션 (1~10회)
  const duplicateOptions = [...Array(10)].map((_, i) => i + 1);

  const handleNumberInput = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    e.target.value = value === '' ? '0' : value;
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ 
        backgroundColor: '#f8f9fa',
        fontSize: '1rem',
        fontWeight: 600,
        padding: '12px 20px'
      }}>
        카드별 한도 변경
      </DialogTitle>
      <DialogContent sx={{ padding: '20px' }}>
        <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>카드사</StyledTableCell>
                <StyledTableCell>건한도</StyledTableCell>
                <StyledTableCell>일한도</StyledTableCell>
                <StyledTableCell>월한도</StyledTableCell>
                <StyledTableCell>할부개월</StyledTableCell>
                <StyledTableCell>중복결제 가능횟수</StyledTableCell>
                <StyledTableCell>활성여부</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {cardCompanies.map((card) => (
                <StyledTableRow key={card}>
                  <StyledTableCell align="center">{card}</StyledTableCell>
                  <StyledTableCell>
                    <StyledTextField
                      size="small"
                      fullWidth
                      type="number"
                      defaultValue="0"
                      onInput={handleNumberInput}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTextField
                      size="small"
                      fullWidth
                      type="number"
                      onInput={handleNumberInput}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTextField
                      size="small"
                      fullWidth
                      type="number"
                      onInput={handleNumberInput}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledSelect
                      size="small"
                      fullWidth
                      defaultValue={12}
                    >
                      {[...Array(12)].map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>{i + 1}개월</MenuItem>
                      ))}
                    </StyledSelect>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledSelect
                      size="small"
                      fullWidth
                      defaultValue={1}
                    >
                      {duplicateOptions.map((num) => (
                        <MenuItem key={num} value={num}>{num}회</MenuItem>
                      ))}
                    </StyledSelect>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Switch size="small" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default CardLimitChange; 
import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  TablePagination,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Popover,
  Modal
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import OptionButton from '../../Common/ManagementData/OptionButton';
import DetailPopup from './CashUserInput/Section/DetailPopup';
import DepositConfirmModal from './DepositConfirmModal';

// 스타일 컴포넌트 정의
const StyledTableWrapper = styled(Box)({
  overflowX: 'auto',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 350px)',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  }
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#1976d2',
    color: '#ffffff',
    textAlign: 'center',
    height: '32px',
    padding: '4px 8px',
  }
}));

// 체크박스 스타일 추가
const StyledCheckbox = styled(Checkbox)({
  color: '#1976d2',
  '&.Mui-checked': {
    color: '#1976d2',
  },
  padding: '0px'
});

// 테이블 헤더 정의
const columns = [
  { id: 'deductionRate', label: '충전수수료', width: 90 },
  { id: 'chargeAmount', label: '충전액', width: 100 },
  { id: 'chargeMoney', label: '충전금', width: 100 },
  { id: 'memberId', label: '회원아이디', width: 120 },
  { id: 'name', label: '이름', width: 100 },
  { id: 'phoneNumber', label: '휴대폰번호', width: 120 },
  { id: 'settlement', label: '정산', width: 80 },
  { id: 'wallet', label: '월렛', width: 80 },
  { id: 'withdrawalDelay', label: '출금딜레이', width: 100 },
  { id: 'settlementStatus', label: '정산상태', width: 90 },
  { id: 'withdrawalStatus', label: '출금상태', width: 80 },
  { id: 'manage', label: '관리', width: 60 },
  { id: 'registDate', label: '등록일', width: 100 },
  { id: 'modifyDate', label: '수정일', width: 100 },
  { id: 'workerId', label: '작업자 아이디', width: 120 }
];

function CashUserTables({ onDataUpdate }) {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [depositorName, setDepositorName] = useState('');
  const [depositAmount, setDepositAmount] = useState('');

  useEffect(() => {
    const dummyData = [
      {
        id: 1,
        property: {
          checkbox: false,
          value: ''
        },
        deductionRate: {
          amount: 500,
          percentage: 3
        },
        chargeAmount: '0',
        chargeMoney: '2,000,000',
        memberId: 'user123',
        name: '김철수',
        phoneNumber: '010-1234-5678',
        settlement: '완료',
        wallet: '활성화',
        withdrawalDelay: '3일',
        settlementStatus: '정산완료',
        withdrawalStatus: '가능',
        manage: '조회용',
        registDate: '2024-10-15 19:50:33',
        modifyDate: '2024-10-15 19:50:33',
        workerId: 'admin01'
      },
      {
        id: 2,
        property: {
          checkbox: false,
          value: ''
        },
        deductionRate: {
          amount: 1000,
          percentage: 5
        },
        chargeAmount: '0',
        chargeMoney: '1,500,000',
        memberId: 'user456',
        name: '이영희',
        phoneNumber: '010-2345-6789',
        settlement: '대기',
        wallet: '비활성화',
        withdrawalDelay: '7일',
        settlementStatus: '정산대기',
        withdrawalStatus: '불가',
        manage: '조회용',
        registDate: '2024-10-15 19:50:33',
        modifyDate: '2024-10-15 19:50:33',
        workerId: 'admin02'
      },
      {
        id: 3,
        property: {
          checkbox: false,
          value: ''
        },
        deductionRate: {
          amount: 2000,
          percentage: 7
        },
        chargeAmount: '0',
        chargeMoney: '3,000,000',
        memberId: 'user789',
        name: '박민수',
        phoneNumber: '010-3456-7890',
        settlement: '완료',
        wallet: '활성화',
        withdrawalDelay: '5일',
        settlementStatus: '정산완료',
        withdrawalStatus: '가능',
        manage: '조회용',
        registDate: '2024-10-15 19:50:33',
        modifyDate: '2024-10-15 19:50:33',
        workerId: 'admin03'
      }
    ];
    
    setMerchants(dummyData);
    setSelectedMerchants([]);
    onDataUpdate({ 
      data: dummyData,
      getAllData: dummyData
    });
  }, [onDataUpdate]);

  // 옵션 클릭 핸들러
  const handleOptionClick = useCallback((option) => {
    try {
      console.log('선택된 옵션:', option);
      console.log('선택된 항목들:', selectedMerchants);
      // TODO: 선택된 옵션에 따른 처리 구현
    } catch (error) {
      console.error('옵션 처리 중 오류:', error);
    }
  }, [selectedMerchants]);

  // 전체 선택 핸들러 단순화
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    
    // 모든 merchant의 체크박스 상태 변경
    setMerchants(prev => {
      return prev.map(merchant => ({
        ...merchant,
        property: {
          ...merchant.property,
          checkbox: isChecked  // 모두 선택 또는 모두 해제
        }
      }));
    });

    // selectedMerchants 상태도 함께 업데이트
    if (isChecked) {
      setSelectedMerchants(merchants.map(merchant => merchant.id));
    } else {
      setSelectedMerchants([]);
    }
  };

  // 개별 선택 핸들러
  const handleSelect = useCallback((id) => {
    setSelectedMerchants(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  // 차감율 체크박스 핸들러
  const handleDeductionCheckbox = useCallback((id) => {
    setMerchants(prev => {
      const updatedMerchants = prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            deductionRate: {
              ...merchant.deductionRate,
              checkbox: !merchant.deductionRate.checkbox
            }
          };
        }
        return merchant;
      });
      return updatedMerchants;
    });
  }, []);

  // 차감율 값 변경 핸들러
  const handleDeductionValueChange = useCallback((id, value) => {
    setMerchants(prev => {
      const updatedMerchants = prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            deductionRate: {
              ...merchant.deductionRate,
              value: value
            }
          };
        }
        return merchant;
      });
      return updatedMerchants;
    });
  }, []);

  // 충전액 변경 핸들러
  const handleChargeAmountChange = useCallback((id, value) => {
    setMerchants(prev => {
      const updatedMerchants = prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            chargeAmount: value
          };
        }
        return merchant;
      });
      return updatedMerchants;
    });
  }, []);

  // 충전금 분배 핸들러 추가
  const handleChargeDistribution = () => {
    if (selectedMerchants.length === 0) {
      alert('항목을 선택해주세요.');
      return;
    }
    // 충전금 분배 로직 구현
  };

  // 속성 체크박스 핸들러 수정
  const handlePropertyCheckbox = (id) => {
    console.log('체크박스 클릭:', id);
    
    setMerchants(prev => {
      return prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            property: {
              ...merchant.property,
              checkbox: !merchant.property?.checkbox
            }
          };
        }
        return merchant;
      });
    });

    // selectedMerchants 업데이트 로직 수정 - 다중 선택 가능하도록
    setSelectedMerchants(prev => {
      const isSelected = prev.includes(id);
      console.log('현재 선택된 항목들:', prev);
      if (isSelected) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id]; // 기존 선택 항목 유지하면서 새로운 항목 추가
      }
    });
  };

  // 속성 값 변경 핸들러 수정
  const handlePropertyValueChange = (id, value) => {
    // 숫자와 음수 기호만 허용
    const sanitizedValue = value.replace(/[^0-9-]/g, '');

    setMerchants(prev => {
      return prev.map(merchant => {
        if (!merchant || merchant.id !== id) return merchant;

        try {
          // 입력값이 없거나 0인 경우 처리
          if (!sanitizedValue || sanitizedValue === '0') {
            return {
              ...merchant,
              property: {
                ...merchant.property,
                value: sanitizedValue
              },
              chargeAmount: '0'
            };
          }

          // 계산 로직
          const inputValue = Number(sanitizedValue);
          const deductionAmount = merchant.deductionRate?.amount || 0;
          const deductionPercentage = merchant.deductionRate?.percentage || 0;
          
          const percentageDeduction = Math.abs(inputValue) * (deductionPercentage / 100);
          // 올림 처리 추가
          const chargeAmount = Math.ceil(inputValue - percentageDeduction - deductionAmount);

          return {
            ...merchant,
            property: {
              ...merchant.property,
              value: sanitizedValue
            },
            chargeAmount: chargeAmount < 0 ? 
              `-${Math.abs(chargeAmount).toLocaleString()}` : 
              chargeAmount.toLocaleString()
          };
        } catch (error) {
          console.error('Calculation error:', error);
          return merchant;
        }
      });
    });
  };

  // 상세 클릭 핸들러
  const handleDetailClick = (merchant) => {
    setSelectedDetail(merchant);
    setDetailOpen(true);
  };

  // 입금확인 핸들러 수정
  const handleDepositConfirm = () => {
    // if (selectedMerchants.length === 0) {
    //   alert('항목을 선택해주세요.');
    //   return;
    // }
    setDepositModalOpen(true);
  };

  // 입금계좌확인 핸들러 수정
  const handleAccountCheck = () => {
    // if (selectedMerchants.length === 0) {
    //   alert('항목을 선택해주세요.');
    //   return;
    // }
    setShowNameInput(true);
  };

  const handleClose = () => {
    setShowNameInput(false);
    setShowAmountInput(false);
    setDepositorName('');
    setDepositAmount('');
  };

  // 입금자명 입력 후 처리
  const handleNameSubmit = () => {
    setShowNameInput(false);
    setShowAmountInput(true);
  };

  // 입금액 입력 후 처리
  const handleAmountSubmit = () => {
    handleClose();
    alert('입금자명과 입금액이 일치하는 내역이 없습니다.');
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '24px',
          borderRadius: '8px',
          mb: 3,
          width: '100%'
        }}>
          <Box sx={{ 
            display: 'flex',
            gap: 3,
            alignItems: 'flex-start'
          }}>
            <OptionButton 
              onOptionClick={handleOptionClick}
              selectedItems={selectedMerchants}
            />

            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontSize: '0.875rem', 
                  fontWeight: 600,
                  color: '#495057',
                  mb: 1.5,
                  pl: '4px'
                }}
              >
                입금 옵션
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<AccountBalanceIcon />}
                  onClick={handleAccountCheck}
                  sx={{
                    bgcolor: '#fff',
                    color: '#1ABC9C',
                    border: '1px solid #1ABC9C',
                    fontSize: '0.75rem',
                    height: '32px',
                    padding: '4px 12px',
                    minWidth: 'auto',
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#1ABC9C',
                      color: '#fff',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    },
                    '& .MuiButton-startIcon': {
                      marginRight: '4px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1rem'
                      }
                    }
                  }}
                >
                  입금확인
                </Button>
                <Button
                  variant="contained"
                  startIcon={<AccountBalanceWalletOutlinedIcon />}
                  onClick={handleDepositConfirm}
                  sx={{
                    bgcolor: '#fff',
                    color: '#1ABC9C',
                    border: '1px solid #1ABC9C',
                    fontSize: '0.75rem',
                    height: '32px',
                    padding: '4px 12px',
                    minWidth: 'auto',
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#1ABC9C',
                      color: '#fff',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    },
                    '& .MuiButton-startIcon': {
                      marginRight: '4px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1rem'
                      }
                    }
                  }}
                >
                  입금계좌확인
                </Button>
              </Box>
            </Box>

            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontSize: '0.875rem', 
                  fontWeight: 600,
                  color: '#495057',
                  mb: 1.5,
                  pl: '4px'
                }}
              >
                충전금 관리
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<AccountBalanceWalletIcon />}
                  onClick={() => handleChargeDistribution()}
                  sx={{
                    bgcolor: '#fff',
                    color: '#1976d2',
                    border: '1px solid #1976d2',
                    fontSize: '0.75rem',
                    height: '32px',
                    padding: '4px 12px',
                    minWidth: 'auto',
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#1976d2',
                      color: '#fff',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    },
                    '& .MuiButton-startIcon': {
                      marginRight: '4px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1rem'
                      }
                    }
                  }}
                >
                  충전금 분배
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <StyledTableWrapper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No</StyledTableCell>
                <StyledTableCell align="center">상세</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StyledCheckbox
                      checked={merchants.some(m => m.property?.checkbox)}
                      onChange={handleSelectAll}
                      sx={{
                        color: '#ffffff',
                        '&.Mui-checked': {
                          color: '#ffffff'
                        }
                      }}
                    />
                    <Typography variant="subtitle2" sx={{ fontSize: '0.813rem' }}>선택</Typography>
                  </Box>
                </StyledTableCell>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    style={{ minWidth: column.width }}
                    align="center"
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants.map((merchant) => merchant && (
                <TableRow key={merchant.id}>
                  <StyledTableCell align="center">{merchant.id}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton 
                      size="small" 
                      onClick={() => handleDetailClick(merchant)}
                      sx={{ 
                        color: '#3498db',
                        '&:hover': {
                          color: '#2980b9'
                        }
                      }}
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StyledCheckbox
                        checked={selectedMerchants.includes(merchant?.id)}
                        onChange={() => handlePropertyCheckbox(merchant?.id)}
                      />
                      <TextField
                        placeholder="값입력"
                        size="small"
                        value={merchant?.property?.value || ''}
                        onChange={(e) => handlePropertyValueChange(merchant?.id, e.target.value)}
                        inputProps={{
                          inputMode: 'numeric', // 모바일에서 숫자 키패드 표시
                          pattern: '[0-9]*'    // 숫자만 입력 가능
                        }}
                        sx={{ 
                          width: '80px',
                          '& .MuiInputBase-input': { 
                            padding: '4px 8px',
                            fontSize: '0.813rem',
                            textAlign: 'right'  // 우측 정렬 추가
                          }
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${merchant?.deductionRate?.amount?.toLocaleString() || '0'}(${merchant?.deductionRate?.percentage || '0'}%)`}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      value={merchant?.chargeAmount || '0'}
                      readOnly
                      InputProps={{
                        readOnly: true,
                        sx: {
                          '& .MuiInputBase-input': {
                            textAlign: 'right',
                            padding: '4px 8px',
                            fontSize: '0.813rem',
                            cursor: 'default',
                            backgroundColor: '#f8f9fa',
                            // 음수일 경우 빨간색으로 표시
                            color: merchant?.chargeAmount?.startsWith('-') ? '#ff0000' : 'inherit'
                          }
                        }
                      }}
                      sx={{ width: '120px' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">{merchant?.chargeMoney}</StyledTableCell>
                  <StyledTableCell>{merchant?.memberId}</StyledTableCell>
                  <StyledTableCell>{merchant?.name}</StyledTableCell>
                  <StyledTableCell>{merchant?.phoneNumber}</StyledTableCell>
                  <StyledTableCell align="center">{merchant?.settlement}</StyledTableCell>
                  <StyledTableCell align="center">{merchant?.wallet}</StyledTableCell>
                  <StyledTableCell align="center">{merchant?.withdrawalDelay}</StyledTableCell>
                  <StyledTableCell align="center">{merchant?.settlementStatus}</StyledTableCell>
                  <StyledTableCell align="center">{merchant?.withdrawalStatus}</StyledTableCell>
                  <StyledTableCell align="center">{merchant?.manage}</StyledTableCell>
                  <StyledTableCell>{merchant?.registDate}</StyledTableCell>
                  <StyledTableCell>{merchant?.modifyDate}</StyledTableCell>
                  <StyledTableCell>{merchant?.workerId}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>
      </Paper>

      <DetailPopup 
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        data={selectedDetail}
      />

      <DepositConfirmModal 
        open={depositModalOpen}
        onClose={() => setDepositModalOpen(false)}
      />

      {/* 입금자명 입력 모달 */}
      <Modal
        open={showNameInput}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          pt: '15vh'
        }}
      >
        <Paper sx={{ 
          width: '400px',
          p: 2,
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          borderRadius: '4px',
          bgcolor: '#f5f5f5'
        }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            cashbill.kr 내용: <br />
            입금자명을 입력하세요.
          </Typography>
          <TextField
            autoFocus
            fullWidth
            value={depositorName}
            onChange={(e) => setDepositorName(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 1
          }}>
            <Button
              onClick={handleNameSubmit}
              variant="contained"
              sx={{ width: '80px' }}
            >
              확인
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ width: '80px' }}
            >
              취소
            </Button>
          </Box>
        </Paper>
      </Modal>

      {/* 입금액 입력 모달 */}
      <Modal
        open={showAmountInput}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          pt: '15vh'
        }}
      >
        <Paper sx={{ 
          width: '400px',
          p: 2,
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          borderRadius: '4px',
          bgcolor: '#f5f5f5'
        }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            cashbill.kr 내용: <br />
            입금액을 입력하세요
          </Typography>
          <TextField
            autoFocus
            fullWidth
            value={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            type="number"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 1
          }}>
            <Button
              onClick={handleAmountSubmit}
              variant="contained"
              sx={{ width: '80px' }}
            >
              확인
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ width: '80px' }}
            >
              취소
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default CashUserTables; 
import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Button
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import ExcelJS from 'exceljs'; // XLSX 대신 ExcelJS 사용

// 테이블 래퍼 스타일 추가
const StyledTableWrapper = styled(Box)( {
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 테이블 셀 스타일 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

const DatabaseIntegrityCheck = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');

  // 더미 데이터 수정
  const rows = [
    { 
      no: 1, 
      delete_flag: '일반',
      date: '2024-03-15 10:12:11',
      company: '(주)페이허브',
      owner: '김지훈',
      business_no: '123-45-67890',
      resident_no: '901225-*******',
      business_type: '전자상거래',
      account_no: '352-1234-5678-90',
      phone: '010-1234-5678',
      match: '일치'
    },
    { 
      no: 2, 
      delete_flag: '삭제',
      date: '2024-03-14 15:22:43',
      company: '디지털페이먼츠',
      owner: '이서연',
      business_no: '456-78-90123',
      resident_no: '880503-*******',
      business_type: '소프트웨어개발',
      account_no: '110-9876-5432-10',
      phone: '010-9876-5432',
      match: '불일치'
    },
    { 
      no: 3, 
      delete_flag: '일반',
      date: '2024-03-14 09:45:30',
      company: '스마트결제시스템',
      owner: '박민준',
      business_no: '789-01-23456',
      resident_no: '920714-*******',
      business_type: '전자금융업',
      account_no: '233-4567-8901-23',
      phone: '010-2345-6789',
      match: '일치'
    },
    { 
      no: 4, 
      delete_flag: '일반',
      date: '2024-03-13 16:33:22',
      company: '퍼스트페이',
      owner: '최수아',
      business_no: '234-56-78901',
      resident_no: '861130-*******',
      business_type: '전자상거래',
      account_no: '457-8901-2345-67',
      phone: '010-3456-7890',
      match: '일치'
    },
    { 
      no: 5, 
      delete_flag: '삭제',
      date: '2024-03-13 11:20:15',
      company: '이지페이먼트',
      owner: '정도현',
      business_no: '567-89-01234',
      resident_no: '950822-*******',
      business_type: '소프트웨어개발',
      account_no: '144-5678-9012-34',
      phone: '010-4567-8901',
      match: '불일치'
    },
    { 
      no: 6, 
      delete_flag: '일반',
      date: '2024-03-12 14:55:40',
      company: '페이테크솔루션',
      owner: '강지원',
      business_no: '890-12-34567',
      resident_no: '891005-*******',
      business_type: '전자금융업',
      account_no: '365-6789-0123-45',
      phone: '010-5678-9012',
      match: '일치'
    },
    { 
      no: 7, 
      delete_flag: '일반',
      date: '2024-03-12 09:15:33',
      company: '네오페이',
      owner: '윤서준',
      business_no: '345-67-89012',
      resident_no: '930217-*******',
      business_type: '전자상거래',
      account_no: '221-8901-2345-67',
      phone: '010-6789-0123',
      match: '일치'
    },
    { 
      no: 8, 
      delete_flag: '삭제',
      date: '2024-03-11 17:40:25',
      company: '블루페이먼츠',
      owner: '임하은',
      business_no: '678-90-12345',
      resident_no: '910428-*******',
      business_type: '소프트웨어개발',
      account_no: '189-0123-4567-89',
      phone: '010-7890-1234',
      match: '불일치'
    }
  ];

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    fileInputRef.current.value = '';
  };

  const handleRequestSort = (property) => {
    if (property === 'match') {
      // 일치여부 컬럼의 경우 특별 처리
      if (orderBy === 'match') {
        setOrderBy('no');
        setOrder('asc');
      } else {
        setOrderBy('match');
        setOrder('asc');
      }
    } else if (property === 'delete_flag') {
      // 삭제여부 컬럼의 경우 특별 처리
      if (orderBy === 'delete_flag') {
        setOrderBy('no');
        setOrder('asc');
      } else {
        setOrderBy('delete_flag');
        setOrder('asc');
      }
    } else {
      // 다른 컬럼들은 기존 정렬 로직 유지
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  // 정렬된 데이터 반환
  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (orderBy === 'match') {
        // 불일치가 위로 오도록 정렬
        return a.match === '불일치' ? -1 : 1;
      } else if (orderBy === 'delete_flag') {
        // 삭제가 위로 오도록 정렬
        return a.delete_flag === '삭제' ? -1 : 1;
      }
      // 다른 컬럼들의 정렬 로직
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };

    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  const handleDownloadExcel = async () => {
    try {
      if (!rows || rows.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      // ExcelJS Workbook 생성
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('데이터베이스 일치 검사');

      // 헤더 설정
      const headers = [
        'No', '상호명', '대표자명',  '사업자번호', '주민번호',
        '업종', '계좌번호', '휴대폰', '일치 여부', '등록일'
      ];

      // 컬럼 매핑 정의
      const columnMapping = {
        'No': 'no',
        '상호명': 'company',
        '대표자명': 'owner',
      
        '사업자번호': 'business_no',
        '주민번호': 'resident_no',
        '업종': 'business_type',
        '계좌번호': 'account_no',
        '휴대폰': 'phone',
        '일치 여부': 'match',
        '등록일': 'date',
      };

      // 각 컬럼별 최대 길이 찾기
      const getMaxColumnWidth = (data, header) => {
        const fieldName = columnMapping[header];
        
        const maxDataLength = Math.max(
          header.length,
          ...data.map(row => {
            const value = String(row[fieldName] || '');
            return value.length;
          })
        );

        return maxDataLength + 2; // 여유 공간 추가
      };

      // 정렬 설정
      const columnAlignments = {
        'No': 'center',
        '상호명': 'left',
        '대표자명': 'left',
       
        '사업자번호': 'center',
        '주민번호': 'center',
        '업종': 'center',
        '계좌번호': 'center',
        '휴대폰': 'center',
        '일치 여부': 'center',
        '등록일': 'center',
      };

      // 컬럼 설정
      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header)
      }));

      // 데이터 추가
      rows.forEach(row => {
        worksheet.addRow({
          [columnMapping['No']]: row.no,
          [columnMapping['상호명']]: row.company,
          [columnMapping['대표자명']]: row.owner,
         
          [columnMapping['사업자번호']]: row.business_no,
          [columnMapping['주민번호']]: row.resident_no,
          [columnMapping['업종']]: row.business_type,
          [columnMapping['계좌번호']]: row.account_no,
          [columnMapping['휴대폰']]: row.phone,
          [columnMapping['일치 여부']]: row.match,
          [columnMapping['등록일']]: row.date
        });
      });

      // 스타일 적용
      worksheet.eachRow((row, rowNumber) => {
        row.height = 25;
        row.eachCell(cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.font = { size: 10, name: '맑은 고딕' };
          
          // 정렬 설정
          const columnHeader = headers[cell.col - 1];
          cell.alignment = {
            vertical: 'middle',
            horizontal: columnAlignments[columnHeader] || 'left'
          };
        });

        // 헤더 행 스타일
        if (rowNumber === 1) {
          row.height = 30;
          row.eachCell(cell => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF1976D2' }
            };
            cell.font = {
              size: 10,
              color: { argb: 'FFFFFFFF' },
              bold: true,
              name: '맑은 고딕'
            };
          });
        }
      });

      // 엑셀 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `데이터베이스_일치검사_${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* 제목 영역 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <StorageIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          데이터베이스 일치 검사
        </Typography>
      </Box>

      {/* 파일 업로드 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          mb: 2
        }}>
          {/* 파일 선택 영역 */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              accept=".xlsx,.xls"
            />
            {selectedFile && (
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#f5f5f5',
                padding: '4px 8px',
                borderRadius: '4px'
              }}>
                <Typography variant="body2" color="textSecondary">
                  {selectedFile.name}
                </Typography>
                <IconButton
                  onClick={handleCancelFile}
                  size="small"
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#d32f2f',
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </IconButton>
              </Box>
            )}
            <Tooltip title="파일 선택">
              <IconButton
                onClick={handleFileButtonClick}
                sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: '1px solid #1976d2',
                  color: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#1976d210',
                  }
                }}
              >
                <UploadFileIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="엑셀 양식 다운로드">
              <IconButton
                sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: '1px solid #2e7d32',
                  color: '#2e7d32',
                  '&:hover': {
                    backgroundColor: '#2e7d3210',
                  }
                }}
              >
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* 통계 정보 */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>
              총 데이터: 36862건
            </Typography>
            <Typography sx={{ color: '#d32f2f', fontWeight: 700 }}>
              일치된 데이터는 총 22건 입니다.
            </Typography>
          </Box>
        </Box>

        {/* 검색 옵션 영역 */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          mt: 2
        }}>
          {/* 데이터 개수 선택 */}
          <RadioGroup
            row
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            sx={{ gap: 2 }}
          >
            {[50, 100, 200, 500].map((value) => (
              <FormControlLabel
                key={value}
                value={value === 'All' ? -1 : value}
                control={<Radio size="small" />}
                label={value}
              />
            ))}
          </RadioGroup>

          {/* 검색어 입력 */}
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ width: '300px' }}
          />

          {/* 엑셀 다운로드 버튼 */}
          <Tooltip title="엑셀 다운로드">
            <IconButton
              onClick={handleDownloadExcel} // 다운로드 클릭 핸들러 추가
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: '1px solid #2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#2e7d3210',
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Paper>

      {/* 테이블 영역 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'no'}
                      direction={orderBy === 'no' ? order : 'asc'}
                      onClick={() => handleRequestSort('no')}
                    >
                      No
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'delete_flag'}
                      direction={orderBy === 'delete_flag' ? order : 'asc'}
                      onClick={() => handleRequestSort('delete_flag')}
                    >
                      삭제 여부
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'date'}
                      direction={orderBy === 'date' ? order : 'asc'}
                      onClick={() => handleRequestSort('date')}
                    >
                      등록일
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">상호명</StyledTableCell>
                  <StyledTableCell align="center">대표자명</StyledTableCell>
                  <StyledTableCell align="center">사업자번호</StyledTableCell>
                  <StyledTableCell align="center">주민번호</StyledTableCell>
                  <StyledTableCell align="center">업종</StyledTableCell>
                  <StyledTableCell align="center">계좌번호</StyledTableCell>
                  <StyledTableCell align="center">휴대폰</StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'match'}
                      direction={orderBy === 'match' ? order : 'asc'}
                      onClick={() => handleRequestSort('match')}
                    >
                      일치 여부
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.no}>
                    <StyledTableCell align="center">{row.no}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          backgroundColor: '#d32f2f', // 삭제 버튼의 기본 배경색
                          color: '#ffffff', // 텍스트 색상
                          fontWeight: 600,
                          '&:hover': {
                            backgroundColor: '#b71c1c', // 호버 시 버튼 색상
                          },
                        }}
                        // onClick={() => handleDelete(row.no)} // 삭제 버튼 클릭 시 실행할 함수
                      >
                        삭제
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.date}</StyledTableCell>
                    <StyledTableCell align="center">{row.company}</StyledTableCell>
                    <StyledTableCell align="center">{row.owner}</StyledTableCell>
                    <StyledTableCell align="center">{row.business_no}</StyledTableCell>
                    <StyledTableCell align="center">{row.resident_no}</StyledTableCell>
                    <StyledTableCell align="center">{row.business_type}</StyledTableCell>
                    <StyledTableCell align="center">{row.account_no}</StyledTableCell>
                    <StyledTableCell align="center">{row.phone}</StyledTableCell>
                    <StyledTableCell 
                      align="center"
                      sx={{ 
                        color: row.match === '일치' ? '#2e7d32' : '#e91e63',
                        fontWeight: 600,
                      }}
                    >
                      {row.match}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>
      </Paper>
    </Box>
  );
};

export default DatabaseIntegrityCheck;
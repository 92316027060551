import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  minWidth: 'max-content',
  
  '&.total-column': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  },
  
  '&:last-child': {
    '& td': {
      fontWeight: 600,
      backgroundColor: '#f8f9fa'
    }
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function IlsiHalbuBiyul({ searchParams }) {
  // 더미 데이터
  const halbuData = [
    { period: '일시불', count: 985, ratio: 73.73 },
    { period: '할부', count: 351, ratio: 26.27 },
    { period: '2개월', count: 45, ratio: 3.37 },
    { period: '3개월', count: 89, ratio: 6.66 },
    { period: '4개월', count: 32, ratio: 2.39 },
    { period: '5개월', count: 28, ratio: 2.09 },
    { period: '6개월', count: 67, ratio: 5.01 },
    { period: '7개월', count: 12, ratio: 0.90 },
    { period: '8개월', count: 15, ratio: 1.12 },
    { period: '9개월', count: 8, ratio: 0.60 },
    { period: '10개월', count: 22, ratio: 1.65 },
    { period: '11개월', count: 5, ratio: 0.37 },
    { period: '12개월 이상', count: 28, ratio: 2.09 },
    { period: '총계', count: 1336, ratio: 100.00 }
  ];

  return (
    <Paper elevation={1} sx={{ p: 3, backgroundColor: '#ffffff', borderRadius: '8px' }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#424242' }}>
        일시불/할부 비율
      </Typography>
      
      <StyledTableWrapper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>할부기간</StyledTableCell>
                <StyledTableCell align="right" className="primary-header">건수</StyledTableCell>
                <StyledTableCell align="right" className="secondary-header">비율(%)</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {halbuData.map((row) => (
                <StyledTableRow key={row.period}>
                  <StyledTableCell>{row.period}</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {row.count.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.ratio.toFixed(2)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default IlsiHalbuBiyul; 
import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

const ChartWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

function WolseobjaData() {
  // 더미 데이터 생성 (6개월치)
  const dummyData = {
    '거래금액': {
      건수: 17157,
      합계: 0,
      '01월': 1033106140,
      '02월': 1618073021,
      '03월': 1430456789,
      '04월': 1550789012,
      '05월': 1780234567,
      '06월': 1440567890
    },
    '개인 거래금액': {
      건수: 8500,
      합계: 0,
      '01월': 533106140,
      '02월': 818073021,
      '03월': 630456789,
      '04월': 750789012,
      '05월': 880234567,
      '06월': 640567890
    },
    '사업자 거래금액': {
      건수: 8657,
      합계: 0,
      '01월': 500000000,
      '02월': 800000000,
      '03월': 800000000,
      '04월': 800000000,
      '05월': 900000000,
      '06월': 800000000
    }
  };

  // 합계 계산
  Object.values(dummyData).forEach(data => {
    data.합계 = ['01월', '02월', '03월', '04월', '05월', '06월']
      .reduce((sum, month) => sum + data[month], 0);
  });

  const tableCellStyle = {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '8px',
    textAlign: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap'
  };

  // 막대 차트 옵션
  const barChartOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    legend: {
      data: ['거래금액', '개인 거래금액', '사업자 거래금액'],
      bottom: 0,
      textStyle: { fontSize: 11 }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '4%',
      bottom: '15%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'],
      axisLabel: {
        fontSize: 11,
        color: '#666'
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => {
          return (value / 100000000).toFixed(1) + '억';
        },
        fontSize: 11,
        color: '#666'
      }
    },
    series: Object.entries(dummyData).map(([name, data]) => ({
      name: name,
      type: 'bar',
      barWidth: '15%',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'].map(month => data[month])
    }))
  };

  // 도넛 차트 옵션
  const donutChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      right: '10%',
      top: 'center',
      textStyle: { fontSize: 11 }
    },
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['40%', '50%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      data: [
        { value: dummyData['사업자 거래금액'].건수, name: '사업자' },
        { value: dummyData['개인 거래금액'].건수, name: '개인' }
      ]
    }]
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>월별 사업자 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">구분</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">합계</StyledTableCell>
                  {['01월', '02월', '03월', '04월', '05월', '06월'].map((month) => (
                    <StyledTableCell key={month} align="right" className="secondary-header">
                      {month}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.entries(dummyData).map(([name, data]) => (
                  <StyledTableRow key={name}>
                    <StyledTableCell className="sticky-column">{name}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      textAlign: 'right',
                      backgroundColor: '#f8f9fa'
                    }}>{data.건수.toLocaleString()}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      textAlign: 'right',
                      backgroundColor: '#f8f9fa'
                    }}>{data.합계.toLocaleString()}</StyledTableCell>
                    {['01월', '02월', '03월', '04월', '05월', '06월'].map((month) => (
                      <StyledTableCell key={month} sx={{ textAlign: 'right' }}>
                        {data[month].toLocaleString()}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
                <ReactECharts 
                  option={barChartOption}
                  style={{ height: '300px' }}
                  className="echarts-for-react boot-chart"
                />
              </Box>
            </ChartWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <Box sx={{ minWidth: window.innerWidth <= 600 ? '600px' : 'auto' }}>
                <ReactECharts 
                  option={donutChartOption}
                  style={{ height: '300px' }}
                  className="echarts-for-react boot-chart"
                />
              </Box>
            </ChartWrapper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default WolseobjaData;

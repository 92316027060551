import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, IconButton,
  Tooltip, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Grid, Card
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 380px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const HeaderBox = styled(Box)({
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const AddButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d32f2f',
  color: 'white',
  '&:hover': {
    backgroundColor: '#b71c1c'
  }
}));

const ErrorMessage = () => {
  // State declarations
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedError, setSelectedError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [errorMessages, setErrorMessages] = useState([
    { id: 1, message: '머천트 잔액부족', details: '머천트 잔액이 부족합니다.', date: '2024-07-30' },
    { id: 2, message: 'B012', details: '오류 코드 B012 발생', date: '2024-07-29' },
    { id: 3, message: 'asasd', details: '알 수 없는 오류', date: '2024-07-28' }
  ]);
  const [newError, setNewError] = useState({
    message: '',
    details: '',
    date: new Date().toISOString().split('T')[0]
  });

  // Event handlers
  const handleEditClick = (error) => {
    setSelectedError(error);
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
    setSelectedError(null);
  };

  const handleEditSubmit = () => {
    // Edit logic here
    console.log('Edited error:', selectedError);
    handleEditClose();
  };

  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewError({
      message: '',
      details: '',
      date: new Date().toISOString().split('T')[0]
    });
  };

  const handleAddSubmit = () => {
    // Add logic here
    console.log('New error:', newError);
    handleAddClose();
  };

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);

    const sortedMessages = [...errorMessages].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setErrorMessages(sortedMessages);
  };

  return (
    <StyledCard>
      <HeaderBox>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ErrorIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            오류메세지
          </Typography>
        </Box>
        <AddButton 
          variant="contained" 
          startIcon={<AddIcon />} 
          onClick={handleAddClick}
        >
          에러메세지 추가
        </AddButton>
      </HeaderBox>

      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>에러 메시지</StyledTableCell>
              <StyledTableCell>상세 내용</StyledTableCell>
              <StyledTableCell 
                onClick={handleSortClick}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eaeaea'
                  }
                }}
              >
                등록시간 {sortOrder === 'asc' ? '▲' : '▼'}
              </StyledTableCell>
              <StyledTableCell>작업</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errorMessages.map((error) => (
              <StyledTableRow key={error.id}>
                <StyledTableCell>{error.message}</StyledTableCell>
                <StyledTableCell>{error.details}</StyledTableCell>
                <StyledTableCell>{error.date}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                    <Tooltip title="수정">
                      <IconButton 
                        size="small" 
                        sx={{ color: '#fbc658' }}
                        onClick={() => handleEditClick(error)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <IconButton 
                        size="small" 
                        sx={{ color: '#ef8157' }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Edit Modal */}
      <Dialog 
        open={editModalOpen} 
        onClose={handleEditClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          에러 메시지 수정
        </DialogTitle>
        <DialogContent sx={{ mt: 4, p: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="에러메세지"
                value={selectedError?.message || ''}
                onChange={(e) => setSelectedError({
                  ...selectedError,
                  message: e.target.value
                })}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="상세 내용"
                value={selectedError?.details || ''}
                onChange={(e) => setSelectedError({
                  ...selectedError,
                  details: e.target.value
                })}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleEditClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleEditSubmit}
            variant="contained" 
            color="primary"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Modal */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          에러 메시지 추가
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 4,
          p: 3,
          '& .MuiFormControl-root': {
            minHeight: '85px'
          }
        }}>
          <br/>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="에러메세지"
                value={newError.message}
                onChange={(e) => setNewError({...newError, message: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                type="date"
                label="일자"
                value={newError.date}
                onChange={(e) => setNewError({...newError, date: e.target.value})}
                InputLabelProps={{ 
                  shrink: true,
                  sx: { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
                sx={{ '& .MuiInputBase-root': { height: '40px' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
                label="상세내용"
                value={newError.details}
                onChange={(e) => setNewError({...newError, details: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  },
                  '& .MuiInputBase-root': {
                    height: 'auto',
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
          <Button 
            variant="contained" 
            color="error"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
};

export default ErrorMessage;

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Paper,
  Grid,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  IconButton,
  InputAdornment,
  TableSortLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  FormLabel
} from '@mui/material';
import { Search as SearchIcon, Today as TodayIcon, Event as EventIcon, DateRange as DateRangeIcon, CalendarMonth as CalendarMonthIcon, CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from '../../Sidebar';
import Notice from '../../Notice/Notice';
import DateRangePicker from '../../Common/DateRangePicker';
import Breadcrumb from '../../Common/Breadcrumb';
import QuickMenu from '../../Common/QuickMenu';
import { debounce } from 'lodash';
import CurrentLocation from '../../Common/CurrentLocation';
import dayjs from 'dayjs';
// import SearchOptions from '../../Common/SearchOptions';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '40px',
    width: 'calc(100% - 80px)',
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '40px',
    pr: '40px',
    ml: '0px'
  },
  noticeBox: { 
    width: '100%',
    margin: '0 auto', 
    mb: 2 
  }
};

const AlarmHistory = () => {
  // 초기 더미 데이터를 상태로 관리
  const [alarmData, setAlarmData] = useState({
    '전체보기': [],
    '월렛': [
      { time: '2025-01-24 10:52:02', content: '머천트 잔액부족5000000 기준금액 미달', status: '안읽음', reader: '', readTime: '2025-01-24 10:52:02' },
      { time: '2025-01-24 10:51:02', content: '월렛 충전 요청 - 가맹점A', status: '읽음', reader: 'admin', readTime: '2025-01-24 10:55:02' },
    ],
    '결제/오류': [
      { time: '2025-01-24 10:50:02', content: '[결제실패] 카드번호 오류', status: '안읽음', reader: '', readTime: '2025-01-24 10:50:02' },
      { time: '2025-01-24 09:15:02', content: '[오류] 시스템 연동 실패', status: '읽음', reader: 'admin', readTime: '2025-01-24 09:20:02' },
    ],
    '알림': [
      { time: '2025-01-23 22:18:02', content: '시스템 정기점검 안내', status: '읽음', reader: 'bt9003', readTime: '2025-01-23 22:54:50' },
      { time: '2025-01-23 20:13:02', content: '새로운 공지사항이 등록되었습니다', status: '읽음', reader: 'admin', readTime: '2025-01-23 20:27:11' },
    ],
    '등록신청': [
      { time: '2025-01-23 20:12:01', content: '신규 가맹점 등록 신청 - 업체B', status: '읽음', reader: 'admin', readTime: '2025-01-23 20:27:11' },
      { time: '2025-01-23 19:37:02', content: '가맹점 정보 수정 요청 - 업체C', status: '안읽음', reader: '', readTime: '' },
    ],
    '서브전산': [
      { time: '2025-01-23 18:44:11', content: '[출금실패] m99986962머천트 전액출금 ERROR_CODE: B012', status: '읽음', reader: 'admin', readTime: '2025-01-23 18:45:13' },
      { time: '2025-01-23 18:44:06', content: 'm99986962 출금요청금액이 머천트 전액을 초과했습니다', status: '읽음', reader: 'admin', readTime: '2025-01-23 18:45:13' },
    ]
  });

  const [currentTab, setCurrentTab] = useState('전체보기');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [statusSort, setStatusSort] = useState(null);
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText1, setSearchText1] = useState('');
  const [searchText2, setSearchText2] = useState('');
  const [authType, setAuthType] = useState('인증');

  // 전체보기 데이터 초기화 - 컴포넌트 마운트 시 한 번만 실행
  useEffect(() => {
    const allData = Object.entries(alarmData)
      .filter(([key]) => key !== '전체보기')
      .flatMap(([_, value]) => value);
    
    setAlarmData(prev => ({
      ...prev,
      '전체보기': allData
    }));
    setFilteredData(allData);
  }, []);

  // 검색어 디바운싱 처리
  const debouncedSearch = debounce((term, tabData) => {
    if (!tabData) return;
    
    let filtered = [...tabData];
    
    // 검색어 필터링
    if (term.trim()) {
      filtered = filtered.filter(item =>
        item.content.toLowerCase().includes(term.toLowerCase()) ||
        item.time.includes(term) ||
        item.status.toLowerCase().includes(term.toLowerCase()) ||
        item.reader.toLowerCase().includes(term.toLowerCase())
      );
    }

    // 상태 정렬 적용
    if (statusSort === 'unread') {
      filtered.sort((a, b) => {
        if (a.status === '안읽음' && b.status !== '안읽음') return -1;
        if (a.status !== '안읽음' && b.status === '안읽음') return 1;
        return 0;
      });
    }

    setFilteredData(filtered);
  }, 300);

  // 탭 또는 검색어 변경 시 데이터 필터링
  useEffect(() => {
    const currentTabData = alarmData[currentTab];
    debouncedSearch(searchTerm, currentTabData);
    
    return () => {
      debouncedSearch.cancel();
    };
  }, [currentTab, searchTerm, statusSort, alarmData]);

  // 상태 정렬 핸들러
  const handleStatusSort = () => {
    setStatusSort(statusSort === 'unread' ? null : 'unread');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 날짜 아이콘 클릭 핸들러
  const handleDateIconClick = (type) => {
    const today = dayjs();
    
    switch(type) {
      case 'today':
        setStartDate(today.format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'yesterday':
        const yesterday = today.subtract(1, 'day');
        setStartDate(yesterday.format('YYYY-MM-DD'));
        setEndDate(yesterday.format('YYYY-MM-DD'));
        break;
      case 'last7days':
        setStartDate(today.subtract(6, 'day').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'lastMonth':
        const lastMonth = today.subtract(1, 'month');
        setStartDate(lastMonth.startOf('month').format('YYYY-MM-DD'));
        setEndDate(lastMonth.endOf('month').format('YYYY-MM-DD'));
        break;
      case 'thisMonth':
        setStartDate(today.startOf('month').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
    }
  };

  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '40px' : '40px',  // SearchPanel 기준
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '4px 0 0 4px',
            boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
            zIndex: 1000,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* 검색 패널 */}
      {/* <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: '280px',
            boxSizing: 'border-box',
            mt: '64px',
            height: 'calc(100% - 64px)',
            border: 'none',
            backgroundColor: 'transparent',
            zIndex: 1100
          }
        }}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer> */}

      {/* 메인 컨텐츠 영역 */}
      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="알람내역" />
        <Box sx={{ mb: 2 }} />

        {/* 탭 메뉴 */}
        <Tabs 
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab 
            value="전체보기"
            label="전체보기"
            sx={{
              '&.Mui-selected': {
                color: '#1976d2',
                fontWeight: 'bold',
              }
            }}
          />
          {Object.keys(alarmData)
            .filter(key => key !== '전체보기')
            .map((tab) => (
              <Tab 
                key={tab} 
                label={tab} 
                value={tab}
                sx={{
                  '&.Mui-selected': {
                    color: '#1976d2',
                    fontWeight: 'bold',
                  }
                }}
              />
          ))}
        </Tabs>

        

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '80px', md: '15%' },
                    minWidth: '80px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  시간
                </TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '150px', md: '40%' },
                    minWidth: '150px',
                    whiteSpace: 'nowrap'
                  }}
                >내용</TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '70px', md: '15%' },
                    minWidth: '70px',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                  onClick={handleStatusSort}
                >
                  <TableSortLabel
                    active={statusSort !== null}
                    direction={statusSort === 'unread' ? 'desc' : 'asc'}
                  >
                    상태
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '80px', md: '15%' },
                    minWidth: '80px',
                    whiteSpace: 'nowrap'
                  }}
                >읽은이</TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '120px', md: '15%' },
                    minWidth: '120px',
                    whiteSpace: 'nowrap'
                  }}
                >읽은시간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{row.time}</TableCell>
                  <TableCell sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{row.content}</TableCell>
                  <TableCell align="center">
                    <Box 
                      sx={{ 
                        display: 'inline-block',
                        bgcolor: row.status === '읽음' ? '#4caf50' : '#f44336',
                        color: 'white',
                        px: 2,
                        py: 0.5,
                        borderRadius: '12px',
                      }}
                    >
                      {row.status}
                    </Box>
                  </TableCell>
                  <TableCell align="center">{row.reader}</TableCell>
                  <TableCell align="center">{row.readTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <QuickMenu />
    </Box>
  );
};

export default AlarmHistory;

import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Box
} from '@mui/material';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.purchase': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.cancel': {
    color: '#dc3545',
    textAlign: 'right'
  },
  '&.total': {
    color: '#1565c0',
    textAlign: 'right',
    fontWeight: 'bold'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'no', label: 'No' },
  { id: 'pg', label: 'PG' },
  { id: 'mid', label: 'MID' },
  { id: 'tid', label: 'TID' },
  { id: 'transactionDate', label: '거래일시' },
  { id: 'merchantName', label: '상호명' },
  // 매입 데이터 (파란색)
  { id: 'blueAmount', label: '금액', type: 'purchase' },
  { id: 'blueCount', label: '건수', type: 'purchase' },
  { id: 'blueSettlement', label: '정산금액', type: 'purchase' },
  { id: 'blueSupply', label: '공급가', type: 'purchase' },
  { id: 'blueVat', label: '부가세', type: 'purchase' },
  { id: 'blueTotal', label: '합계', type: 'purchase' },
  // 취소 데이터 (빨간색)
  { id: 'redAmount', label: '금액', type: 'cancel' },
  { id: 'redCount', label: '건수', type: 'cancel' },
  { id: 'redSettlement', label: '정산금액', type: 'cancel' },
  { id: 'redSupply', label: '공급가', type: 'cancel' },
  { id: 'redVat', label: '부가세', type: 'cancel' },
  { id: 'redTotal', label: '합계', type: 'cancel' },
  // 총합 (진한 파란색)
  { id: 'grandTotal', label: '총합', type: 'total' }
];

const dummyData = [
  {
    no: 1,
    pg: 'KICC',
    mid: 'MID123',
    tid: 'TID123',
    transactionDate: '2024-01-01',
    merchantName: '스타벅스 강남점',
    // 매입 데이터
    blueAmount: 500000,
    blueCount: 5,
    blueSettlement: 480000,
    blueSupply: 490000,
    blueVat: 10000,
    blueTotal: 500000,
    // 취소 데이터
    redAmount: 100000,
    redCount: 1,
    redSettlement: 96000,
    redSupply: 98000,
    redVat: 2000,
    redTotal: 100000,
    // 총합
    grandTotal: 400000
  },
  {
    no: 2,
    pg: 'PayUp',
    mid: 'MID456',
    tid: 'TID456',
    transactionDate: '2024-01-02',
    merchantName: '이디야커피 서초점',
    // 매입 데이터
    blueAmount: 300000,
    blueCount: 3,
    blueSettlement: 288000,
    blueSupply: 294000,
    blueVat: 6000,
    blueTotal: 300000,
    // 취소 데이터
    redAmount: 50000,
    redCount: 1,
    redSettlement: 48000,
    redSupply: 49000,
    redVat: 1000,
    redTotal: 50000,
    // 총합
    grandTotal: 250000
  },
  {
    no: 3,
    pg: 'NICE',
    mid: 'MID789',
    tid: 'TID789',
    transactionDate: '2024-01-02',
    merchantName: '투썸플레이스 종로점',
    // 매입 데이터
    blueAmount: 800000,
    blueCount: 8,
    blueSettlement: 768000,
    blueSupply: 784000,
    blueVat: 16000,
    blueTotal: 800000,
    // 취소 데이터
    redAmount: 200000,
    redCount: 2,
    redSettlement: 192000,
    redSupply: 196000,
    redVat: 4000,
    redTotal: 200000,
    // 총합
    grandTotal: 600000
  }
];

const DepositHistoryTable = () => {
  return (
    <TableContainer component={StyledTableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <StyledTableCell 
                key={header.id}
                className={header.type ? 'secondary-header' : 'primary-header'}
              >
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row) => (
            <StyledTableRow key={row.no}>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={`${row.no}-${header.id}`}
                  align={
                    ['no', 'pg', 'mid', 'tid', 'transactionDate', 'merchantName'].includes(header.id)
                      ? 'center'
                      : 'right'
                  }
                >
                  {typeof row[header.id] === 'number' 
                    ? row[header.id].toLocaleString() 
                    : row[header.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DepositHistoryTable;

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 로컬 컴포넌트 import
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';

import QuickMenu from '../../Common/QuickMenu';
import GamengSalesTables from './GamengSalesTables';
import ResultText from './ResultText';
import { dummyData } from './GamengSalesTables';  // dummyData import
import CurrentLocation from '../../Common/CurrentLocation';
// import GamengChart from './GamengChart';
import SummaryTable from '../../Common/PaymentResultData/PayHisSummaryTable';


// PaymentHistory의 스타일을 재사용
const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '280px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100%)', 
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '320px',
      boxSizing: 'border-box',
      mt: '64px',
      height: 'calc(100% - 64px)',
      border: 'none',
      backgroundColor: 'transparent',
      '@media (max-width: 768px)': {
        width: '100%',
        mt: '56px',
        height: 'calc(100% - 56px)'
      }
    }
  }
};

function GamengSales() {
  // searchPanelOpen의 초기값을 true로 설정
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  
  // 화면 크기 감지를 위한 상태
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // 데이터 변경 핸들러 추가
  const handleDataChange = (data) => {
    console.log('Table data changed:', data);
    // 필요한 경우 여기서 데이터 상태를 관리하거나 다른 작업을 수행할 수 있습니다
  };

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    // 검색 로직 구현
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    }
  };

  const [tableData, setTableData] = useState(dummyData); // 초기값으로 dummyData 설정

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch} 
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      {/* Notice 영역 */}
      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>
      
      <CurrentLocation mainTitle="통계" subTitle="가맹별 통계" />

      <ResultText data={tableData} />

      <GamengSalesTables 
        searchPanelOpen={searchPanelOpen}
      />

      <SummaryTable/>

      {/* 차트 섹션을 마지막으로 이동
      <Box sx={{ mt: 3, mb: 3 }}>
        <GamengChart data={tableData} />
      </Box> */}

      {/* QuickMenu */}
      <QuickMenu />
      
    </Box>
  );
}

export default GamengSales;

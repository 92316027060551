import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../ResultText';
import ResultTable from '../../ResultTable';
import PayMentAggregationTable from './PayMentAggregationTable';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';

function PaymentAggregation() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '4px 0 0 4px',
            boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
            zIndex: 1000,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: '280px',
            boxSizing: 'border-box',
            mt: '64px',
            height: 'calc(100% - 64px)',
            border: 'none',
            backgroundColor: 'transparent',
            zIndex: 1100
          }
        }}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="결제내역 집계" />
        <Box sx={{ mb: 2 }} />
        <ResultText />
        <ResultTable />
        <PayMentAggregationTable searchPanelOpen={searchPanelOpen} />
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default PaymentAggregation;
import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import ExcelJS from 'exceljs';

// 테이블 래퍼 스타일 추가
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxWidth: '100%',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 테이블 셀 스타일 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

// 컬럼 너비 계산 함수 (쿼리 컬럼 길이 제한 추가)
const getMaxColumnWidth = (data, header) => {
  const fieldName = columnMapping[header];
  
  const getAdjustedWidth = (value) => {
    // 쿼리 컬럼인 경우 최대 길이 제한
    if (header === '적용 쿼리') {
      return Math.min(String(value).length, 100); // 최대 100자로 제한
    }
    return String(value).length;
  };

  const maxDataLength = Math.max(
    header.length,
    ...data.map(row => getAdjustedWidth(row[fieldName] || ''))
  );

  const getTextWidth = (text) => {
    return Array.from(text).reduce((width, char) => {
      return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
    }, 0);
  };

  // 쿼리 컬럼인 경우 고정 너비 반환
  if (header === '적용 쿼리') {
    return 80; // 적용 쿼리 컬럼 고정 너비
  }

  const maxWidth = Math.max(
    getTextWidth(header),
    ...data.map(row => getTextWidth(String(row[fieldName] || '')))
  );

  return maxWidth + Math.ceil(maxWidth * 0.2);
};

// 컬럼 정렬 설정
const columnAlignments = {
  'No': 'center',
  '작업자': 'center',
  '작업 페이지': 'center',
  '적용 쿼리': 'left',
  '실행시간': 'center'
};

// 엑셀 스타일 설정 (기본 스타일 사용)
const defaultCellStyle = {
  border: {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
  },
  font: { 
    size: 10, 
    name: '맑은 고딕' 
  }
};

const headerCellStyle = {
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF1976D2' }
  },
  font: {
    size: 10,
    color: { argb: 'FFFFFFFF' },
    bold: true,
    name: '맑은 고딕'
  },
  height: 30
};

const defaultRowHeight = 25;

// 엑셀 다운로드 설정
const excelDownloadSettings = {
  fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  worksheetName: '서버 쿼리 로그',
  fileNamePrefix: '서버_쿼리_로그_'
};

// 컬럼 매핑 정의
const columnMapping = {
  'No': 'no',
  '작업자': 'action',
  '작업 페이지': 'query_type',
  '적용 쿼리': 'query',
  '실행시간': 'query_time'
};

// 엑셀 헤더 정의
const headers = [
  'No',
  '작업자',
  '작업 페이지',
  '적용 쿼리',
  '실행시간'
];

const ServerQueryLogs = () => {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');

  // 더미 데이터로 rows 정의
  const [rows] = useState([
    {
      no: 1,
      action: 'admin',
      query_type: '계정관리',
      query: 'SELECT * FROM users WHERE id = 1234',
      query_time: '2024-03-19 14:30:22'
    },
    {
      no: 2,
      action: 'system',
      query_type: '통계',
      query: 'UPDATE statistics SET total_count = total_count + 1 WHERE date = "2024-03-19"',
      query_time: '2024-03-19 14:31:00'
    },
    // ... 더 많은 더미 데이터
  ]);

  // 엑셀 다운로드 핸들러 수정
  const handleExcelDownload = async () => {
    try {
      if (!rows || rows.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      // 컬럼 설정
      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header),
        style: {
          alignment: {
            horizontal: columnAlignments[header],
            vertical: 'middle',
            wrapText: header === '적용 쿼리'
          }
        }
      }));

      // 데이터 추가
      const processedRows = rows.map(row => ({
        ...row,
        query: row.query.length > 100 ? row.query.substring(0, 97) + '...' : row.query
      }));

      worksheet.addRows(processedRows);

      // 스타일 적용
      worksheet.eachRow((row, rowNumber) => {
        row.height = defaultRowHeight;
        row.eachCell(cell => Object.assign(cell, defaultCellStyle));
        
        if (rowNumber === 1) {
          row.height = headerCellStyle.height;
          row.eachCell(cell => Object.assign(cell, headerCellStyle));
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 버튼 정의 수정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2',
      onClick: () => console.log('Copy clicked')
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: handleExcelDownload
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2',
      onClick: () => console.log('Print clicked')
    }
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 정렬된 rows 계산
  const sortedRows = React.useMemo(() => {
    if (!rows) return [];
    
    return [...rows].sort((a, b) => {
      if (orderBy === 'no' || orderBy === 'query_time') {
        return order === 'asc' 
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy];
      }
      return 0;
    });
  }, [rows, orderBy, order]);

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BuildIcon sx={{ color: '#00acc1' }} />
            <Typography variant="h6">서버 쿼리 로그</Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 2,
            alignItems: 'center'
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ 
                minWidth: '200px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '40px'
                }
              }}
            />
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              {buttons.map((button, index) => (
                <Tooltip key={index} title={button.label}>
                  <IconButton
                    onClick={button.onClick}
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: `1px solid ${button.color}`,
                      color: button.color,
                      '&:hover': {
                        backgroundColor: `${button.color}10`,
                      }
                    }}
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          </Box>

          <StyledTableWrapper>
            <TableContainer sx={{ 
              width: '100%',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                height: '8px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#555'
                }
              }
            }}>
              <Table sx={{ 
                minWidth: '1200px',
                tableLayout: 'fixed'
              }}>
                <colgroup>
                  <col style={{ width: '80px' }} />  {/* No */}
                  <col style={{ width: '120px' }} />  {/* 작업자 */}
                  <col style={{ width: '150px' }} />  {/* 작업 페이지 */}
                  <col style={{ width: '600px' }} />  {/* 적용 쿼리 */}
                  <col style={{ width: '150px' }} />  {/* 실행시간 */}
                </colgroup>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                    <StyledTableCell align="center">
                      <TableSortLabel
                        active={orderBy === 'no'}
                        direction={orderBy === 'no' ? order : 'asc'}
                        onClick={() => handleRequestSort('no')}
                      >
                        No
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="center">작업자</StyledTableCell>
                    <StyledTableCell align="center">작업 페이지</StyledTableCell>
                    <StyledTableCell align="center">적용 쿼리</StyledTableCell>
                    <StyledTableCell align="center">
                      <TableSortLabel
                        active={orderBy === 'query_time'}
                        direction={orderBy === 'query_time' ? order : 'asc'}
                        onClick={() => handleRequestSort('query_time')}
                      >
                        실행시간
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows.map((row) => (
                    <TableRow key={row.no}>
                      <StyledTableCell align="center">{row.no}</StyledTableCell>
                      <StyledTableCell align="center">{row.action}</StyledTableCell>
                      <StyledTableCell align="center">{row.query_type}</StyledTableCell>
                      <StyledTableCell sx={{ 
                        whiteSpace: 'normal',
                        wordBreak: 'break-all'
                      }}>
                        {row.query}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.query_time}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledTableWrapper>
        </Box>
      </Paper>
    </Box>
  );
};

export default ServerQueryLogs;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Card, Typography, Button, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, 
  TextField, ButtonGroup
} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 450px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const PGProviderOptions = () => {
  const [selectedTab, setSelectedTab] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([
    { 행동: '변경', 이름: 'country2', KEY: 'QkZJRlBDRTl1eTQc1QUtBMw==', INV: 'PRJ59QZGHPT844TQ' },
    { 행동: '변경', 이름: 'country', KEY: 'UUJQRUJCR0pBWkwyMkZRSA==', INV: 'VQX41KJXZKPF6TYD' },
    { 행동: '변경', 이름: 'happy1', KEY: 'VzJVQTlEQZJDTktEYFlSTw==', INV: 'JWS1N4VD4ARJ0V6R' },
    { 행동: '변경', 이름: 'm2248053', KEY: 'SEJRU082U09BRzNVVzg0SA==', INV: 'JTZREIXEZWDLPTMH' },
    { 행동: '변경', 이름: '1', KEY: 'QlJORJJOTUNIRVSSFZHOw==', INV: 'NSEQP0TFPFRFR9AL' },
    { 행동: '변경', 이름: 'default', KEY: 'QlJORJJOTUNIRVSSFZHOw==', INV: 'NSEQP0TFPFRFR9AL' },
    { 행동: '변경', 이름: 'test', KEY: '1', INV: '2' },
    { 행동: '변경', 이름: 'sdsa', KEY: '1231233', INV: '132132' }
  ]);

  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <ReceiptLongIcon sx={{ color: '#ed6c02' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            PG사 설정
          </Typography>
        </Box>

        <ButtonGroup variant="outlined" size="small" sx={{ mb: 2 }}>
          <Button 
            onClick={() => setSelectedTab('갤럭시아')}
            variant={selectedTab === '갤럭시아' ? 'contained' : 'outlined'}
          >
            갤럭시아 세팅
          </Button>
          <Button 
            onClick={() => setSelectedTab('헥토')}
            variant={selectedTab === '헥토' ? 'contained' : 'outlined'}
          >
            헥토 코리아 세팅
          </Button>
          <Button 
            onClick={() => setSelectedTab('다페이')}
            variant={selectedTab === '다페이' ? 'contained' : 'outlined'}
          >
            다페이 세팅
          </Button>
          <Button 
            onClick={() => setSelectedTab('네스트페이')}
            variant={selectedTab === '네스트페이' ? 'contained' : 'outlined'}
          >
            네스트페이 세팅
          </Button>
        </ButtonGroup>

        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: '300px' }}
          />
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: '#00bcd4',
              '&:hover': {
                backgroundColor: '#00acc1'
              }
            }}
          >
            조회
          </Button>
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: '#4CAF50',
              '&:hover': {
                backgroundColor: '#45a049'
              }
            }}
          >
            추가
          </Button>
        </Box>

        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>행동</StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>이름</StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>KEY</StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>INV</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow 
                  key={index}
                >
                  <StyledTableCell>
                    <Button 
                      variant="contained" 
                      size="small" 
                      sx={{ 
                        backgroundColor: '#00bcd4',
                        '&:hover': {
                          backgroundColor: '#00acc1'
                        }
                      }}
                    >
                      {row.행동}
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>{row.이름}</StyledTableCell>
                  <StyledTableCell>{row.KEY}</StyledTableCell>
                  <StyledTableCell>{row.INV}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </StyledCard>
  );
};

export default PGProviderOptions;

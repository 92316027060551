import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Collapse,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import QuickMenu from '../../../Common/QuickMenu';
import Gamengipryeok from './Section/Gamengipryeok';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Jeongsan from './Section/Jeongsan';
import ChugaInput from './Section/ChugaInput';
import Service from './Section/Service';
import PgKey from './Section/PgKey';
import DetailsSection from './Section/DetailsSection';
import Auth from './Section/Auth';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  '& .MuiGrid-root': {
    gap: '8px'
  }
}));

const StyledSelect = styled(Select)({
  minWidth: '200px',
  height: '40px',
  '& .MuiSelect-select': {
    padding: '6px 12px',
  }
});

const SectionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#1976d2',
  marginBottom: '16px'
});

const AgentLabel = styled(Typography)({
  fontSize: '0.875rem',
  color: '#666',
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  '& .required': {
    color: '#f44336',
    marginLeft: '2px'
  }
});

const ServiceSection = styled(Box)({
  maxWidth: '600px',
  '& .MuiFormControl-root': {
    marginBottom: '16px'
  },
  '& .required': {
    color: '#f44336',
    marginLeft: '4px'
  }
});

const FieldLabel = styled(Typography)({
  fontSize: '0.875rem',
  color: '#666',
  marginBottom: '4px'
});

const ActionButton = styled(Button)({
  height: '40px',
  fontSize: '0.875rem',
  padding: '0 12px',
  minWidth: 'auto',
  whiteSpace: 'nowrap'
});

function MerchantInput() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selections, setSelections] = useState({
    master: '',
    branch: '',
    distributor: '',
    agency: '',
    retail: '',
    sales1: '',
    sales2: '',
    sales3: '',
    empty: ''
  });

  // 선택 옵션들
  const options = {
    master: ['마스터1', '마스터2', '마스터3'],
    branch: ['지사1', '지사2', '지사3'],
    distributor: ['총판1', '총판2', '총판3'],
    agency: ['대리점1', '대리점2', '대리점3'],
    retail: ['리테일1', '리테일2', '리테일3'],
    sales1: ['영업1-1', '영업1-2', '영업1-3'],
    sales2: ['영업2-1', '영업2-2', '영업2-3'],
    sales3: ['영업3-1', '영업3-2', '영업3-3']
  };

  const handleChange = (field) => (event) => {
    setSelections(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      position: 'relative', 
      height: 'calc(100vh - 64px)',
      overflow: 'hidden'
    }}>
      <QuickMenu />
      <Box sx={{ 
        flexGrow: 1, 
        overflow: 'auto', 
        p: { xs: 1, md: 3 },
        ml: { xs: '0px', md: '90px' }
      }}>
        <Grid container spacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: 1, md: 2 },
              mb: { xs: 1, md: 0 }
            }}>
              <Gamengipryeok />
              <Jeongsan />
              <ChugaInput />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: 1, md: 2 }
            }}>
              <Service />
              <PgKey />
              <DetailsSection />
              <Auth />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MerchantInput;

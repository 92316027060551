import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

const ChartWrapper = styled(Box)({
  width: '100%',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function WolgwaseData() {
  // 더미 데이터 생성
  const dummyData = {
    '거래금액': {
      건수: 17157,
      합계: 0,  // 합계 초기화
      '01월': 1033106140,
      '02월': 1618073021,
      '03월': 1430456789,
      '04월': 1550789012,
      '05월': 1780234567,
      '06월': 1440567890,
    },
    '비과세 거래금액': {
      건수: 8500,
      합계: 0,  // 합계 초기화
      '01월': 533106140,
      '02월': 818073021,
      '03월': 630456789,
      '04월': 750789012,
      '05월': 880234567,
      '06월': 640567890,
    },
    '과세 거래금액': {
      건수: 8657,
      합계: 0,  // 합계 초기화
      '01월': 500000000,
      '02월': 800000000,
      '03월': 800000000,
      '04월': 800000000,
      '05월': 900000000,
      '06월': 800000000,
    }
  };

  // 합계 계산
  Object.values(dummyData).forEach(data => {
    data.합계 = ['01월', '02월', '03월', '04월', '05월', '06월']
      .reduce((sum, month) => sum + data[month], 0);
  });

  const tableCellStyle = {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '8px',
    textAlign: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap'
  };

  // 선 차트 옵션
  const lineChartOption = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff',
      borderWidth: 1,
      borderColor: '#fff',
      textStyle: {
        color: '#666',
        fontSize: 11
      },
      padding: 10,
      formatter: function(params) {
        return `${params[0].name}<br/>` +
          params.map(param => {
            return `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>` +
            `${param.seriesName}: <span style="font-weight:bold">${param.value.toLocaleString()}원</span>`
          }).join('<br/>');
      }
    },
    legend: {
      data: ['거래금액', '비과세 거래금액', '과세 거래금액'],
      bottom: 0,
      icon: 'circle',
      itemGap: 30,
      textStyle: {
        color: '#666666',
        fontSize: 12
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '60px',
      top: '20px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'],
      axisLine: {
        lineStyle: {
          color: '#eaeaea'
        }
      },
      axisLabel: {
        color: '#666',
        fontSize: 11
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisLabel: {
        color: '#666',
        fontSize: 11,
        formatter: (value) => {
          if (value === 0) return '0';
          return (value / 100000000) + '억';
        }
      },
      splitLine: {
        lineStyle: {
          color: '#eaeaea',
          type: 'dashed'
        }
      }
    },
    series: Object.entries(dummyData).map(([name, data]) => ({
      name: name,
      type: 'line',
      symbol: 'circle',
      symbolSize: 8,
      data: ['01월', '02월', '03월', '04월', '05월', '06월'].map(month => data[month]),
      itemStyle: {
        color: name === '거래금액' ? '#6387f9' : 
               name === '비과세 거래금액' ? '#ff9933' : '#36c6d3'
      },
      lineStyle: {
        width: 2
      }
    }))
  };

  // 도넛 차트 옵션
  const donutChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      right: '10%',
      top: 'center',
      textStyle: { fontSize: 11 }
    },
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['40%', '50%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      data: [
        { value: dummyData['과세 거래금액'].건수, name: '과세' },
        { value: dummyData['비과세 거래금액'].건수, name: '비과세' }
      ]
    }]
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>월별 과세 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">구분</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">합계</StyledTableCell>
                  {['01월', '02월', '03월', '04월', '05월', '06월'].map((month) => (
                    <StyledTableCell key={month} align="right" className="secondary-header">
                      {month}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.entries(dummyData).map(([name, data]) => (
                  <StyledTableRow key={name}>
                    <StyledTableCell className="sticky-column">{name}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      textAlign: 'right',
                      backgroundColor: '#f8f9fa'
                    }}>{data.건수.toLocaleString()}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      textAlign: 'right',
                      backgroundColor: '#f8f9fa'
                    }}>{data.합계.toLocaleString()}</StyledTableCell>
                    {['01월', '02월', '03월', '04월', '05월', '06월'].map((month) => (
                      <StyledTableCell key={month} sx={{ textAlign: 'right' }}>
                        {data[month].toLocaleString()}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
                <ReactECharts 
                  option={lineChartOption}
                  style={{ height: '300px' }}
                  className="echarts-for-react boot-chart"
                />
              </Box>
            </ChartWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <Box sx={{ minWidth: window.innerWidth <= 600 ? '600px' : 'auto' }}>
                <ReactECharts 
                  option={donutChartOption}
                  style={{ height: '300px' }}
                  className="echarts-for-react boot-chart"
                />
              </Box>
            </ChartWrapper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default WolgwaseData;

import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Checkbox,
  Button,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  fontSize: '0.875rem',
  borderBottom: '1px solid #e0e0e0',
  borderRight: '1px solid #e0e0e0',
  '&:last-child': {
    borderRight: 'none',
  },
  '&.MuiTableCell-head': {
    backgroundColor: '#f5f5f5',
    fontWeight: 600,
    color: '#333'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const SetOff = ({ onClose }) => {
  const columns = [
    { id: 'select', label: '', width: '40px' },
    { id: 'no', label: 'No', width: '60px' },
    { id: 'group', label: '구분', width: '100px' },
    { id: 'merchantMID', label: '가맹점MID', width: '120px' },
    { id: 'merchantTID', label: '가맹점TID', width: '120px' },
    { id: 'merchantName', label: '가맹점명', width: '200px' },
    { id: 'transactionDate', label: '거래일시', width: '160px' },
    { id: 'amount', label: '거래금액', width: '120px' },
    { id: 'cardCompany', label: '발급사', width: '100px' },
    { id: 'cardNumber', label: '카드번호', width: '140px' },
    { id: 'approvalNumber', label: '승인번호', width: '120px' }
  ];

  const dummyData = [
    {
      no: 1,
      group: 'Galaxia',
      merchantMID: '472-88-02517',
      merchantTID: 'm20230926',
      merchantName: '갤럭시아 테스트',
      transactionDate: '2024-12-16 18:00:11',
      amount: '1,004',
      cardCompany: '국민카드',
      cardNumber: '5570428541621068',
      approvalNumber: '30098421'
    }
  ];

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        borderBottom: '1px solid #e0e0e0',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          상계
        </Typography>
        <IconButton 
          onClick={onClose}
          size="small"
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ p: 3, flex: 1, overflow: 'auto' }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            size="small"
            sx={{
              textTransform: 'none',
              fontSize: '0.875rem'
            }}
          >
            전체삭제
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.id === 'amount' ? 'right' : 'center'}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    <Checkbox size="small" />
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.no}</StyledTableCell>
                  <StyledTableCell align="center">{row.group}</StyledTableCell>
                  <StyledTableCell align="center">{row.merchantMID}</StyledTableCell>
                  <StyledTableCell align="center">{row.merchantTID}</StyledTableCell>
                  <StyledTableCell align="left">{row.merchantName}</StyledTableCell>
                  <StyledTableCell align="center">{row.transactionDate}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                  <StyledTableCell align="center">{row.cardCompany}</StyledTableCell>
                  <StyledTableCell align="center">{row.cardNumber}</StyledTableCell>
                  <StyledTableCell align="center">{row.approvalNumber}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SetOff;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db'
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  '& .MuiFormLabel-root': {
    minWidth: '280px',
    color: '#34495e',
    fontWeight: '400',
    fontSize: '0.875rem'
  }
}));

const FieldLabel = styled(Typography)({
  minWidth: '280px', // 나의계좌 출금 공제 수수료 정률(%) 기준
  color: '#34495e',
  fontWeight: '400',
  fontSize: '0.9rem'
});

const ActionButton = styled(Button)({
  height: '40px',
  minWidth: '100px'
});

const StatusButton = styled(Button)({
  minWidth: 'auto',
  padding: '4px 12px',
  fontSize: '0.875rem',
  height: '32px'
});

const VerifyButton = styled(Button)({
  minWidth: 'auto',
  padding: '4px 12px',
  fontSize: '0.875rem',
  height: '32px'
});

const VirtualAccountButton = styled(Button)({
  minWidth: 'auto',
  padding: '4px 12px',
  fontSize: '0.875rem',
  height: '32px'
});

const AgentLabel = styled(Typography)({
  minWidth: '280px',
  color: '#34495e',
  fontWeight: '400',
  fontSize: '0.9rem'
});

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '8px 16px',
  },
}));

const StyledTextField = styled(TextField)({
  width: '250px',
  height: '35px',
  '& input': {
    padding: '6px 8px',
    fontSize: '0.875rem'
  }
});

// 선택 필드 한글 매핑
const fieldLabels = {
  master: '마스터',
  branch: '지사',
  distributor: '총판',
  agency: '대리점',
  retail: '리테일',
  sales1: '영업1',
  sales2: '영업2',
  sales3: '영업3'
};

// 선택 옵션들 (키는 영문 유지)
const options = {
  master: ['마스터1', '마스터2', '마스터3'],
  branch: ['지사1', '지사2', '지사3'],
  distributor: ['총판1', '총판2', '총판3'],
  agency: ['대리점1', '대리점2', '대리점3'],
  retail: ['리테일1', '리테일2', '리테일3'],
  sales1: ['영업1-1', '영업1-2', '영업1-3'],
  sales2: ['영업2-1', '영업2-2', '영업2-3'],
  sales3: ['영업3-1', '영업3-2', '영업3-3']
};

const Jeongsan = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selections, setSelections] = useState({
    master: '',
    branch: '',
    distributor: '',
    agency: '',
    retail: '',
    sales1: '',
    sales2: '',
    sales3: '',
    empty: ''
  });

  const handleChange = (field) => (event) => {
    setSelections(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  return (
    <StyledPaper>
      <SectionTitle>정산정보</SectionTitle>
      <Box>
        {/* 보증보험 가입여부 */}
        <StyledFormControl>
          <FieldLabel>보증보험 가입여부</FieldLabel>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="가입"
          />
        </StyledFormControl>

        {/* 보증보험 가입일 */}
        <StyledFormControl>
          <FieldLabel>보증보험 가입일</FieldLabel>
          <TextField
            type="date"
            size="small"
            sx={{ width: '300px' }}
          />
        </StyledFormControl>

        {/* 보증금(%) */}
        <StyledFormControl>
          <FieldLabel sx={{ width: '250px', display: 'block' }}>보증금(%)</FieldLabel>
          <Box sx={{ width: '250px' }}>
            <TextField
              size="small"
              sx={{ width: '100%' }}
            />
            <TextField
              size="small"
              sx={{ width: '100%', marginTop: '8px' }} // 두 번째 텍스트필드와 간격을 추가
            />
          </Box>
        </StyledFormControl>

        {/* 가맹점 공제 수수료(%) */}
        <StyledFormControl>
          <FieldLabel>가맹점 공제 수수료(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 가맹점 포함 공제 수수료(%) */}
        <StyledFormControl>
          <FieldLabel>가맹점 포함 공제 수수료(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 담당자 검색 드롭다운 섹션 */}
        <Box sx={{ mb: 3 }}>
          <Box 
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ 
              cursor: 'pointer',
              mb: 1
            }}
          >
            <FieldLabel 
              sx={{ 
                display: 'inline-block',
                mb: 0,
                verticalAlign: 'middle'
              }}
            >
              담당자 검색
              <Box component="span" sx={{ display: 'inline-block', verticalAlign: 'middle', ml: 0.5 }}>
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Box>
            </FieldLabel>
          </Box>

          <Collapse in={isExpanded}></Collapse>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              columnGap: 6,
              '& > *': {
                marginBottom: '8px'
              },
              '& > *:last-child': {
                marginBottom: 0
              }
            }}>
              {/* 각 선택 필드와 수수료 입력 필드 */}
              <StyledFormControl>
                <Box sx={{ display: 'flex', columnGap: 6, alignItems: 'center', width: '100%' }}>
                  <StyledSelect 
                    size="small"
                    value={selections.master}
                    onChange={handleChange('master')}
                    displayEmpty
                    sx={{ width: '250px' }}
                  >
                    <MenuItem value="">{fieldLabels.master} 선택</MenuItem>
                    {options.master.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </StyledSelect>
                  <StyledTextField size="small" />
                </Box>
              </StyledFormControl>

              {/* 나머지 선택 필드들도 동일한 패턴으로 구성 */}
              {['branch', 'distributor', 'agency', 'retail', 'sales1', 'sales2', 'sales3'].map((field) => (
                <StyledFormControl key={field}>
                  <Box sx={{ display: 'flex', columnGap: 6, alignItems: 'center', width: '100%' }}>
                    <StyledSelect
                      size="small"
                      value={selections[field]}
                      onChange={handleChange(field)}
                      displayEmpty
                      sx={{ width: '250px' }}
                    >
                      <MenuItem value="">{fieldLabels[field]} 선택</MenuItem>
                      {options[field].map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))}
                    </StyledSelect>
                    <StyledTextField size="small" />
                  </Box>
                </StyledFormControl>
              ))}

              {/* 빈 선택 필드 */}
              <StyledFormControl>
                <Box sx={{ 
                  display: 'flex', 
                  columnGap: 6,
                  alignItems: 'center', 
                  width: '100%' 
                }}>
                  <StyledSelect
                    size="small"
                    value={selections.empty}
                    onChange={handleChange('empty')}
                    displayEmpty
                    sx={{ width: '250px' }}
                  >
                    <MenuItem value=""></MenuItem>
                  </StyledSelect>
                  <StyledTextField
                    size="small"
                  />
                </Box>
              </StyledFormControl>
            </Box>
          
        </Box>

        {/* 가맹점 포인트 공제 수수료(%) */}
        <StyledFormControl>
          <FieldLabel>가맹점 포인트 공제 수수료(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 가맹점 적립금 공제 수수료(%) */}
        <StyledFormControl>
          <FieldLabel>가맹점 적립금 공제 수수료(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 은행명 */}
        <StyledFormControl>
          <FieldLabel>은행명</FieldLabel>
          <Select
            size="small"
            defaultValue="select"
            sx={{ width: '250px' }}
          >
            <MenuItem value="select">선택</MenuItem>
            {/* 은행 옵션들 */}
          </Select>
        </StyledFormControl>

        {/* 예금주 */}
        <StyledFormControl>
          <FieldLabel>예금주</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 계좌번호 */}
        <StyledFormControl>
          <FieldLabel>계좌번호</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 1, 
            alignItems: 'center',
            width: '380px'
          }}>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
            <StatusButton
              variant="contained"
              color="error"
            >
              미검증
            </StatusButton>
            <VerifyButton
              variant="contained"
              color="primary"
            >
              검증
            </VerifyButton>
          </Box>
        </StyledFormControl>

        {/* 정산일 */}
        <StyledFormControl>
          <FieldLabel>정산일</FieldLabel>
          <Select
            size="small"
            defaultValue="select"
            sx={{ width: '250px' }}
          >
            <MenuItem value="select">선택</MenuItem>
            {/* 정산일 옵션들 */}
          </Select>
        </StyledFormControl>

        {/* 출금가능시간 */}
        <StyledFormControl>
          <FieldLabel>출금가능시간</FieldLabel>
          <Box sx={{ display: 'flex', gap: 6, width: '250px' }}>
            <TextField
              type="time"
              size="small"
              sx={{ flex: 1 }}
            />
            <TextField
              type="time"
              size="small"
              sx={{ flex: 1 }}
            />
          </Box>
        </StyledFormControl>

        {/* 월렛 출금 미사용 */}
        <StyledFormControl>
          <FieldLabel>월렛 출금 미사용</FieldLabel>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="미사용"
          />
        </StyledFormControl>

        {/* 머천트 계좌 */}
        <StyledFormControl>
          <FieldLabel>머천트 계좌</FieldLabel>
          <Select
            size="small"
            defaultValue="select"
            sx={{ width: '250px' }}
          >
            <MenuItem value="select">선택</MenuItem>
            {/* 머천트 계좌 옵션들 */}
          </Select>
        </StyledFormControl>

        {/* 가상계좌 */}
        <StyledFormControl>
          <FieldLabel>가상계좌</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 1, 
            width: '380px'
          }}>
            <VirtualAccountButton
              variant="contained"
              color="primary"
            >
              보안해제
            </VirtualAccountButton>
            <VirtualAccountButton
              variant="contained"
              color="success"
            >
              생성
            </VirtualAccountButton>
            <VirtualAccountButton
              variant="contained"
              color="error"
            >
              반려
            </VirtualAccountButton>
          </Box>
        </StyledFormControl>

        {/* 비회원에게 송금 정액 */}
        <StyledFormControl>
          <FieldLabel>비회원에게 송금 정액</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 비회원에게 송금 정률(%) */}
        <StyledFormControl>
          <FieldLabel>비회원에게 송금 정률(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 회원에게 송금 정액 */}
        <StyledFormControl>
          <FieldLabel>회원에게 송금 정액</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 회원에게 송금 정률(%) */}
        <StyledFormControl>
          <FieldLabel>회원에게 송금 정률(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 나의계좌 출금 공제 수수료 정액 */}
        <StyledFormControl>
          <FieldLabel>나의계좌 출금 공제 수수료 정액</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 나의계좌 출금 공제 수수료 정률(%) */}
        <StyledFormControl>
          <FieldLabel>나의계좌 출금 공제 수수료 정률(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 어드민 입금 공제 수수료 정액 */}
        <StyledFormControl>
          <FieldLabel>어드민 입금 공제 수수료 정액</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 어드민 입금 공제 수수료 정률(%) */}
        <StyledFormControl>
          <FieldLabel>어드민 입금 공제 수수료 정률(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 마스터 입금 공제 수수료 정액 */}
        <StyledFormControl>
          <FieldLabel>마스터 입금 공제 수수료 정액</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 마스터 입금 공제 수수료 정률(%) */}
        <StyledFormControl>
          <FieldLabel>마스터 입금 공제 수수료 정률(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 회원간 이체공제 수수료 적용 */}
        <StyledFormControl>
          <FieldLabel>회원간 이체공제 수수료 적용</FieldLabel>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="적용"
          />
        </StyledFormControl>

        {/* 출금제한(%) */}
        <StyledFormControl>
          <FieldLabel>출금제한(%)</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '250px' }}
          />
        </StyledFormControl>

        {/* 요일제한 */}
        <StyledFormControl>
          <FieldLabel>요일제한</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 0, 
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '300px'
          }}>
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="월"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="화"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="수"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="목"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="금"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="토"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="일"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
            <FormControlLabel 
              control={<Checkbox size="small" />} 
              label="공휴일"
              sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
            />
          </Box>
        </StyledFormControl>

        {/* 출금 딜레이(초) */}
        <StyledFormControl>
          <FieldLabel>출금 딜레이(초)</FieldLabel>
          <TextField
            size="small"
            type="number"
            sx={{ width: '300px' }}
          />
        </StyledFormControl>

        {/* 정산금지 */}
        <StyledFormControl>
          <FieldLabel>정산금지</FieldLabel>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="금지"
          />
        </StyledFormControl>

        {/* 출금정지 */}
        <StyledFormControl>
          <FieldLabel>출금정지</FieldLabel>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="정지"
          />
        </StyledFormControl>

        {/* 하단 버튼 그룹 */}
        {/* <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 3 }}>
          <Button 
            variant="contained" 
            color="primary"
            sx={{ height: '40px', padding: '0 24px' }}
          >
            저장
          </Button>
          <Button 
            variant="outlined"
            sx={{ height: '40px', padding: '0 24px' }}
          >
            취소
          </Button>
          <Button 
            variant="contained" 
            color="secondary"
            sx={{ height: '40px', padding: '0 24px' }}
          >
            임시저장
          </Button>
        </Box> */}
      </Box>
    </StyledPaper>
  );
};

export default Jeongsan;

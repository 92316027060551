import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, TextField, Popover, Alert, Select, MenuItem, Button, Menu } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const DateRangePicker = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {
  const today = dayjs().format('YYYYMMDD');

  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(startDate ? dayjs(startDate) : dayjs());
  const [tempEndDate, setTempEndDate] = useState(endDate ? dayjs(endDate) : dayjs());
  const [error, setError] = useState('');
  const [startMonth, setStartMonth] = useState(dayjs().month() + 1);
  const [endMonth, setEndMonth] = useState(dayjs().month() + 1);

  useEffect(() => {
    if (!startDate && !endDate) {
      const displayToday = dayjs().format('YYYY-MM-DD');
      onStartDateChange(displayToday);
      onEndDateChange(displayToday);
      
      console.log('DateRangePicker 초기값 설정');
      console.log('시작일:', displayToday);
      console.log('종료일:', displayToday);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setError('');  // 팝업 열 때 에러 메시지 초기화
  };

  const handleClose = () => {
    setAnchorEl(null);
    setError('');  // 팝업 닫을 때 에러 메시지 초기화
  };

  const formatDateRange = () => {
    const formatDate = (date) => {
      if (!date) return '';
      return dayjs(date).format('YYYY-MM-DD');
    };

    if (!startDate && !endDate) return '';
    if (!endDate) return `${formatDate(startDate)} ~`;
    if (!startDate) return `~ ${formatDate(endDate)}`;
    return `${formatDate(startDate)} ~ ${formatDate(endDate)}`;
  };

  const validateDateRange = (start, end) => {
    if (!start || !end) return true;
    return start.isBefore(end) || start.isSame(end);
  };

  const handleStartDateChange = (newDate) => {
    if (tempEndDate && !validateDateRange(newDate, tempEndDate)) {
      setError('시작일은 종료일보다 이후일 수 없습니다.');
      return;
    }
    setError('');
    setTempStartDate(newDate);
    const formattedDate = newDate.format('YYYY-MM-DD');
    onStartDateChange(formattedDate);
    
    // 검색 파라미터 콘솔 출력
    console.log('Search Parameters:', {
      startDate: formattedDate,
      endDate: endDate || '',
      type: 'startDateChange'
    });
  };

  const handleEndDateChange = (newDate) => {
    if (tempStartDate && !validateDateRange(tempStartDate, newDate)) {
      setError('종료일은 시작일보다 이전일 수 없습니다.');
      return;
    }
    setError('');
    setTempEndDate(newDate);
    const formattedDate = newDate.format('YYYY-MM-DD');
    onEndDateChange(formattedDate);
    
    // 검색 파라미터 콘솔 출력
    console.log('Search Parameters:', {
      startDate: startDate || '',
      endDate: formattedDate,
      type: 'endDateChange'
    });
  };

  const handleStartMonthChange = (event) => {
    const newMonth = event.target.value;
    setStartMonth(newMonth);
    if (tempStartDate) {
      const newDate = tempStartDate.month(newMonth - 1);
      setTempStartDate(newDate);
      onStartDateChange(newDate.format('YYYY-MM-DD'));
    }
  };

  const handleEndMonthChange = (event) => {
    const newMonth = event.target.value;
    setEndMonth(newMonth);
    if (tempEndDate) {
      const newDate = tempEndDate.month(newMonth - 1);
      setTempEndDate(newDate);
      onEndDateChange(newDate.format('YYYY-MM-DD'));
    }
  };

  const handleYearClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleYearClose = () => {
    setAnchorEl(null);
  };

  const handleYearSelect = (year) => {
    if (tempEndDate) {
      const newDate = tempEndDate.year(year);
      setTempEndDate(newDate);
      onEndDateChange(newDate.format('YYYY-MM-DD'));
    }
  };

  const handleConfirm = () => {
    if (!validateDateRange(tempStartDate, tempEndDate)) {
      setError('시작일은 종료일보다 이후일 수 없습니다.');
      return;
    }

    // YYYYMMDD 형식으로 변환
    const formattedStartDate = tempStartDate.format('YYYYMMDD');
    const formattedEndDate = tempEndDate.format('YYYYMMDD');

    console.log('DateRangePicker 날짜 선택 확인');
    console.log('시작일:', formattedStartDate);
    console.log('종료일:', formattedEndDate);

    // 상위 컴포넌트로 전달
    onStartDateChange(formattedStartDate);
    onEndDateChange(formattedEndDate);
    
    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <Box>
        <TextField
          size="small"
          value={formatDateRange()}
          onClick={handleClick}
          placeholder="날짜 범위 선택"
          InputProps={{
            readOnly: true,
          }}
          sx={{
            width: '260px',
            cursor: 'pointer',
            '& .MuiOutlinedInput-root': {
              height: '36px',
              fontSize: '0.875rem',
              backgroundColor: '#fff',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f8f9fa'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e0e0e0'
              },
              '& .MuiOutlinedInput-input': {
                cursor: 'pointer'
              }
            }
          }}
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2, 
            p: 2, 
            bgcolor: 'background.paper'
          }}>
            {error && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error}
              </Alert>
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box>
                <Box sx={{ mb: 1, fontWeight: 500, color: '#666' }}>시작일</Box>
                <DateCalendar 
                  value={tempStartDate}
                  onChange={handleStartDateChange}
                  views={['year', 'day']}
                  openTo="day"
                  showDaysOutsideCurrentMonth={false}
                  slots={{
                    calendarHeader: () => (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        padding: '8px'
                      }}>
                        <Select
                          value={tempStartDate ? tempStartDate.year() : new Date().getFullYear()}
                          onChange={(event) => {
                            const newYear = event.target.value;
                            const newDate = tempStartDate ? tempStartDate.year(newYear) : dayjs().year(newYear);
                            setTempStartDate(newDate);
                            onStartDateChange(newDate.format('YYYY-MM-DD'));
                          }}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '70px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Select
                          value={startMonth}
                          onChange={handleStartMonthChange}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '45px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: 12 }, (_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )
                  }}
                  sx={{ 
                    width: '280px',
                    '& .MuiPickersArrowSwitcher-root': {
                      display: 'none'
                    },
                    '& .MuiDayCalendar-header': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    },
                    '& .MuiDayCalendar-monthContainer': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }
                  }}
                />
              </Box>
              <Box>
                <Box sx={{ mb: 1, fontWeight: 500, color: '#666' }}>종료일</Box>
                <DateCalendar 
                  value={tempEndDate}
                  onChange={handleEndDateChange}
                  views={['year', 'day']}
                  openTo="day"
                  showDaysOutsideCurrentMonth={false}
                  slots={{
                    calendarHeader: () => (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        padding: '8px'
                      }}>
                        <Select
                          value={tempEndDate ? tempEndDate.year() : new Date().getFullYear()}
                          onChange={(event) => {
                            const newYear = event.target.value;
                            const newDate = tempEndDate ? tempEndDate.year(newYear) : dayjs().year(newYear);
                            setTempEndDate(newDate);
                            onEndDateChange(newDate.format('YYYY-MM-DD'));
                          }}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '70px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Select
                          value={endMonth}
                          onChange={handleEndMonthChange}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '45px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: 12 }, (_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )
                  }}
                  sx={{ 
                    width: '280px',
                    '& .MuiPickersArrowSwitcher-root': {
                      display: 'none'
                    },
                    '& .MuiDayCalendar-header': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    },
                    '& .MuiDayCalendar-monthContainer': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }
                  }}
                />
              </Box>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              mt: 2,
              borderTop: '1px solid #e0e0e0',
              pt: 2
            }}>
              <Button 
                variant="contained"
                onClick={handleConfirm}
                sx={{
                  minWidth: '100px',
                  height: '36px',
                  backgroundColor: '#2196f3',
                  '&:hover': {
                    backgroundColor: '#1976d2'
                  }
                }}
              >
                확인
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  TextField,
  Drawer
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangePicker from '../../Common/DateRangePicker';
import QuickMenu from '../../Common/QuickMenu';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import styled from '@emotion/styled';
// import SearchOptions from '../../Common/SearchOptions';

// 스타일드 컴포넌트 수정
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

const ActionButton = styled(IconButton)({
  width: '40px',
  height: '40px',
  backgroundColor: '#fff',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
});

// ErrorLog에서 가져온 모바일 관련 스타일 추가
const MobileTableRow = styled(TableRow)({
  '& td': {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    whiteSpace: 'nowrap',
    '& > *': {
      marginRight: '8px'
    }
  }
});

const MobileTableCell = styled(TableCell)({
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1976d2'
  }
});

// 모바일 테이블 스타일 수정
const columnStyles = {
  no: { minWidth: '40px', marginRight: '16px' },
  pg: { minWidth: '80px', marginRight: '16px' },
  merchantNo: { minWidth: '120px', marginRight: '16px' },
  mid: { minWidth: '100px', marginRight: '16px' },
  tmnId: { minWidth: '100px', marginRight: '16px' },
  merchantName: { minWidth: '120px', marginRight: '16px' },
  transactionTime: { minWidth: '150px', marginRight: '16px' },
  amount: { minWidth: '100px', marginRight: '16px' },
  cardNo: { minWidth: '120px', marginRight: '16px' },
  approvalNo: { minWidth: '100px', marginRight: '16px' },
  status: { minWidth: '80px', marginRight: '16px' }
};

// 금액 포맷팅 함수 추가
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 카드번호 마스킹 함수 수정
const maskCardNumber = (cardNumber) => {
  // 하이픈 제거 후 마스킹 처리
  const numbers = cardNumber.replace(/-/g, '');
  const maskedNumber = numbers.replace(/(\d{6})(\d{6})(\d{4})/, '$1******$3');
  
  // 4자리마다 하이픈 추가
  return maskedNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
};

function DuplicateCheck() {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 800 && window.innerWidth <= 1025);
  const [isCompact, setIsCompact] = useState(window.innerWidth <= 1025);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      setIsTablet(window.innerWidth >= 800 && window.innerWidth <= 1025);
      setIsCompact(window.innerWidth <= 1025);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 더미 데이터
  const rows = [
    {
      id: 1,
      no: 290,
      pg: 'Galaxia',
      merchantNo: '760827-1168316',
      mid: 'M2140389',
      tmnId: '104695803',
      merchantName: '레드피그',
      transactionTime: '2024-12-06 16:08:31',
      amount: '20000',
      cardNo: '4370-3339-6896-1845',
      approvalNo: '30081967',
      status: '승인'
    },
    // ... 많은 더미 데이터
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '40px' : '40px',  // SearchPanel 기준
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="중복확인" />
        
        <Paper sx={{ p: 3, borderRadius: '12px' }}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              mb: 2,
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                />
                <TextField
                  size="small"
                  placeholder="모든 필드를 검색합니다"
                  sx={{ 
                    minWidth: '250px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      backgroundColor: '#fff'
                    }
                  }}
                />
              </Box>
              
              <Box sx={{ 
                display: 'flex', 
                gap: 1, 
                alignItems: 'center',
                mt: isMobile ? 1 : 0
              }}>
                <Tooltip title="Copy">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <FontAwesomeIcon icon={faCopy} />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Excel">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <DownloadIcon />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Print">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <FontAwesomeIcon icon={faPrint} />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="엑셀 다운로드">
                  <ActionButton sx={{ 
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}>
                    <DownloadIcon />
                  </ActionButton>
                </Tooltip>
              </Box>
            </Box>

            <TableContainer component={StyledTableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="primary-header" align="center">No</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">PG</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">사업자번호</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">MID</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">TMNID</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">가맹점명</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">거래일시</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">금액</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">카드번호</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">승인번호</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">취소여부</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id} hover>
                      <StyledTableCell align="center">{row.no}</StyledTableCell>
                      <StyledTableCell align="center">{row.pg}</StyledTableCell>
                      <StyledTableCell align="center">{row.merchantNo}</StyledTableCell>
                      <StyledTableCell align="center">{row.mid}</StyledTableCell>
                      <StyledTableCell align="center">{row.tmnId}</StyledTableCell>
                      <StyledTableCell align="center">{row.merchantName}</StyledTableCell>
                      <StyledTableCell align="center">{row.transactionTime}</StyledTableCell>
                      <StyledTableCell align="right">{formatNumber(row.amount)}</StyledTableCell>
                      <StyledTableCell align="center">{maskCardNumber(row.cardNo)}</StyledTableCell>
                      <StyledTableCell align="center">{row.approvalNo}</StyledTableCell>
                      <StyledTableCell align="center">{row.status}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>

      <QuickMenu />

      {/* SearchPanel */}
      {/* <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: '280px',
            boxSizing: 'border-box',
            mt: '64px',
            height: 'calc(100% - 64px)',
            border: 'none',
            backgroundColor: 'transparent',
            zIndex: 1100
          }
        }}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer> */}
    </Box>
  );
}

export default DuplicateCheck;

import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  '& .MuiGrid-root': {
    gap: '8px'
  },
  // 모바일에서 전체적으로 크기 축소
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px',
    '& .MuiTextField-root, & .MuiSelect-root': {
      maxWidth: '100%',  // 모바일에서 너비 제한
      minWidth: 'auto'   // 최소 너비 제거
    },
    '& .MuiGrid-root': {
      gap: '4px'
    }
  }
}));

const SectionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#1976d2',
  marginBottom: '16px'
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',

  // 모바일에서는 세로 배치로 변경
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '4px',
    marginBottom: '12px',

    // 입력 필드 너비를 100%로
    '& .MuiTextField-root, & .MuiSelect-root': {
      width: '100%'
    },

    // 체크박스 그룹 조정
    '& .checkbox-group': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',  // 4개씩 배치
      gap: '4px'
    }
  }
}));

const FieldLabel = styled(Typography)({
  minWidth: '240px',
  color: '#34495e',
  fontWeight: '400',
  fontSize: '0.9rem'
});

const ButtonGroup = styled(Box)({
  display: 'flex',
  gap: '8px',
  marginTop: '24px',
  justifyContent: 'flex-end',
  '& .MuiButton-root': {
    minWidth: '120px'
  }
});

const Jeongsan = () => {
  return (
    <StyledPaper>
      <SectionTitle>정산정보</SectionTitle>
      <Box>
        {/* 카드번호 */}
        <StyledFormControl>
          <FieldLabel>카드번호</FieldLabel>
          <TextField
            size="small"
            placeholder="카드번호 16자리"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 계정 적립금 수수료 */}
        <StyledFormControl>
          <FieldLabel>계정 적립금 수수료</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 출금가능시간 */}
        <StyledFormControl>
          <FieldLabel>출금가능시간</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            alignItems: 'center',
            width: '400px'
          }}>
            <Select
              size="small"
              defaultValue="00"
              sx={{ width: '120px' }}
            >
              {[...Array(24)].map((_, i) => (
                <MenuItem key={i} value={i.toString().padStart(2, '0')}>
                  {i.toString().padStart(2, '0')}시
                </MenuItem>
              ))}
            </Select>
            <Typography>~</Typography>
            <Select
              size="small"
              defaultValue="00"
              sx={{ width: '120px' }}
            >
              {[...Array(24)].map((_, i) => (
                <MenuItem key={i} value={i.toString().padStart(2, '0')}>
                  {i.toString().padStart(2, '0')}시
                </MenuItem>
              ))}
            </Select>
          </Box>
        </StyledFormControl>

        {/* 머천트 계좌 */}
        <StyledFormControl>
          <FieldLabel>머천트 계좌</FieldLabel>
          <Select
            size="small"
            defaultValue="740"
            sx={{ width: '400px' }}
          >
            <MenuItem value="740">현금결제전용(740)</MenuItem>
            <MenuItem value="741">계좌이체전용(741)</MenuItem>
          </Select>
        </StyledFormControl>

        {/* 은행명 */}
        <StyledFormControl>
          <FieldLabel>은행명</FieldLabel>
          <Select
            size="small"
            defaultValue="select"
            sx={{ width: '400px' }}
          >
            <MenuItem value="select">선택</MenuItem>
          </Select>
        </StyledFormControl>

        {/* 예금주 */}
        <StyledFormControl>
          <FieldLabel>예금주</FieldLabel>
          <TextField
            size="small"
            
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 계좌번호 */}
        <StyledFormControl>
          <FieldLabel>계좌번호</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 1, 
            alignItems: 'center',
            width: '400px',
          }}>
            <TextField
              size="small"
              placeholder="하이픈 입력"
              sx={{ flex: 1 }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                  bgcolor: '#3b82f6',
                '&:hover': { bgcolor: '#2563eb' },
              }}
              >
                계좌검증
              </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: '#ff6b6b',
                '&:hover': { bgcolor: '#f03e3e' },
              }}
            >
              상태: 미검증
            </Button>
          </Box>
        </StyledFormControl>

        {/* 월렛금급 사용 */}
        <StyledFormControl>
          <FieldLabel>월렛출급 사용</FieldLabel>
          <Box sx={{ width: '400px' }}>
            <FormControlLabel
              control={<Checkbox />}
              label=""
            />
          </Box>
        </StyledFormControl>

        {/* 가상계좌 */}
        <StyledFormControl>
          <FieldLabel>가상계좌</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            width: '400px'
          }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: '#ff8c66',
                '&:hover': { bgcolor: '#ff6b6b' },
              }}
            >
              보안정지 해제
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: '#2ecc71',
                '&:hover': { bgcolor: '#27ae60' },
              }}
            >
              생성
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: '#e74c3c',
                '&:hover': { bgcolor: '#c0392b' },
              }}
            >
              반려
            </Button>
          </Box>
        </StyledFormControl>

        {/* 비회원에게 송금 정액 */}
        <StyledFormControl>
          <FieldLabel>비회원에게 송금 정액</FieldLabel>
          <TextField
            size="small"
            defaultValue="400"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 비회원에게 송금 정률(%) */}
        <StyledFormControl>
          <FieldLabel>비회원에게 송금 정률(%)</FieldLabel>
          <TextField
            size="small"
            defaultValue="0.5"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 회원에게 송금 정액 */}
        <StyledFormControl>
          <FieldLabel>회원에게 송금 정액</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 회원에게 송금 정률(%) */}
        <StyledFormControl>
          <FieldLabel>회원에게 송금 정률(%)</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 나의계좌 출금 수수료 정액 */}
        <StyledFormControl>
          <FieldLabel>나의계좌 출금 수수료 정액</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 나의계좌 출금 수수료 정률(%) */}
        <StyledFormControl>
          <FieldLabel>나의계좌 출금 수수료 정률(%)</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 어드민 입금 수수료 정액 */}
        <StyledFormControl>
          <FieldLabel>어드민 입금 수수료 정액</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 어드민 입금 수수료 정률(%) */}
        <StyledFormControl>
          <FieldLabel>어드민 입금 수수료 정률(%)</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 마스터 입금 수수료 정액 */}
        <StyledFormControl>
          <FieldLabel>마스터 입금 수수료 정액</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 마스터 입금 수수료 정률(%) */}
        <StyledFormControl>
          <FieldLabel>마스터 입금 수수료 정률(%)</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 회원간 이체수수료 적용 */}
        <StyledFormControl>
          <FieldLabel>회원간 이체수수료 적용</FieldLabel>
          <Box sx={{ width: '400px' }}>
            <FormControlLabel
              control={<Checkbox />}
              label=""
            />
          </Box>
        </StyledFormControl>

        {/* 출금제한 */}
        <StyledFormControl>
          <FieldLabel>출금제한(%)</FieldLabel>
          <TextField
            size="small"
            defaultValue="50"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        <StyledFormControl>
          <FieldLabel>이체 한도</FieldLabel>
          <TextField
            size="small"
            defaultValue="0"
            sx={{ width: '400px' }}
          />
        </StyledFormControl>

        {/* 요일제한 */}
        <StyledFormControl>
          <FieldLabel>요일제한</FieldLabel>
          <Box sx={{ 
            display: 'flex', 
            gap: 0, 
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <FormControlLabel control={<Checkbox />} label="월" />
            <FormControlLabel control={<Checkbox />} label="화" />
            <FormControlLabel control={<Checkbox />} label="수" />
            <FormControlLabel control={<Checkbox />} label="목" />
            <FormControlLabel control={<Checkbox />} label="금" />
            <FormControlLabel control={<Checkbox checked />} label="토" />
            <FormControlLabel control={<Checkbox checked />} label="일" />
            <FormControlLabel control={<Checkbox checked />} label="공휴일" />
          </Box>
        </StyledFormControl>

        
        

       

        {/* 출금정지 */}
        <StyledFormControl>
          <FieldLabel>출금정지</FieldLabel>
          <Box sx={{ width: '400px' }}>
            <FormControlLabel
              control={<Checkbox checked />}
              label=""
            />
          </Box>
        </StyledFormControl>

        {/* 버튼 그룹 */}
        <ButtonGroup>
          <Button 
            variant="contained" 
            sx={{ 
              bgcolor: '#3498db',
              '&:hover': { bgcolor: '#2980b9' }
            }}
          >
            저장
          </Button>
          <Button 
            variant="outlined"
            sx={{
              color: '#7f8c8d',
              borderColor: '#7f8c8d',
              '&:hover': {
                borderColor: '#95a5a6',
                bgcolor: 'rgba(127, 140, 141, 0.08)'
              }
            }}
          >
            취소
          </Button>
          <Button 
            variant="contained"
            sx={{ 
              bgcolor: '#e67e22',
              '&:hover': { bgcolor: '#d35400' }
            }}
          >
            임시저장
          </Button>
        </ButtonGroup>
      </Box>
    </StyledPaper>
  );
};

export default Jeongsan; 
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, 
  IconButton, Tooltip, Modal, Paper, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 380px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center', // 기본적으로 모든 셀 중앙정렬

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

const HeaderBox = styled(Box)({
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const AddButton = styled(Button)({
  backgroundColor: '#4a90e2',
  '&:hover': {
    backgroundColor: '#357abd'
  },
  boxShadow: '0 2px 4px rgba(74, 144, 226, 0.3)',
  textTransform: 'none',
  fontWeight: 500
});

const UserNotices = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [notices, setNotices] = useState([
    {
      action: '변경',
      group: '결제사이트',
      fixed: 1,
      public: 1,
      title: '고객센터 업무시간 안내',
      content: '업무시간 09:30~17:30\n(점심 12:00~13:00) 토,일 공휴일 휴무\n1566-9805 로 연락주시기 바랍니다.',
      date: '2022-07-20'
    },
    {
      action: '추가',
      group: '메인사이트',
      fixed: 0,
      public: 1,
      title: '시스템 점검 안내',
      content: '2024년 3월 15일 02:00~06:00 시스템 점검이 진행됩니다. 이용에 참고 부탁드립니다.',
      date: '2024-03-14'
    },
    {
      action: '삭제',
      group: '결제사이트',
      fixed: 1,
      public: 0,
      title: '결제 시스템 업데이트',
      content: '결제 시스템 2.0 버전 업데이트 진행 예정입니다. 자세한 내용은 공지사항을 참고해주세요.',
      date: '2024-03-13'
    },
    {
      action: '변경',
      group: '관리자사이트',
      fixed: 0,
      public: 1,
      title: '신규 서비스 오픈',
      content: '간편결제 서비스가 추가되었습니다. 결제 방식 추가 및 기능 개선이 있었습니다.',
      date: '2024-03-12'
    },
    {
      action: '추가',
      group: '메인사이트',
      fixed: 1,
      public: 1,
      title: '휴면계정 전환 안내',
      content: '1년 이상 미접속 계정은 휴면상태로 전환됩니다. 로그인 시 본인인증이 필요합니다.',
      date: '2024-03-10'
    },
    {
      action: '변경',
      group: '결제사이트',
      fixed: 0,
      public: 1,
      title: '카드결제 서비스 점검',
      content: '카드결제 서비스 점검으로 인해 3월 20일 새벽 2시부터 4시까지 일시 중단됩니다.',
      date: '2024-03-09'
    },
    {
      action: '추가',
      group: '관리자사이트',
      fixed: 1,
      public: 1,
      title: '관리자 페이지 개편',
      content: '관리자 페이지 UI/UX 개선 작업이 완료되었습니다. 새로운 기능이 추가되었습니다.',
      date: '2024-03-08'
    },
    {
      action: '삭제',
      group: '메인사이트',
      fixed: 0,
      public: 0,
      title: '이벤트 종료 안내',
      content: '3월 신규가입 이벤트가 종료되었습니다. 많은 참여 감사드립니다.',
      date: '2024-03-07'
    },
    {
      action: '변경',
      group: '결제사이트',
      fixed: 1,
      public: 1,
      title: '보안 업데이트 안내',
      content: 'SSL 인증서 갱신 및 보안 강화 작업이 진행되었습니다. 더욱 안전한 서비스를 제공하겠습니다.',
      date: '2024-03-06'
    },
    {
      action: '추가',
      group: '메인사이트',
      fixed: 0,
      public: 1,
      title: '모바일 앱 출시',
      content: '신규 모바일 앱이 출시되었습니다. 앱스토어에서 다운로드 가능합니다.',
      date: '2024-03-05'
    }
  ]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNotice, setEditNotice] = useState(null);
  const [editContent, setEditContent] = useState('');

  // 공지 추가 모달 상태 관리
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newNotice, setNewNotice] = useState({
    group: '메인사이트',
    fixed: 0,
    title: '',
    public: 0,
    content: '',
    date: new Date().toISOString().split('T')[0]
  });

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedNotices = [...notices].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setNotices(sortedNotices);
  };

  const handleEditClick = (notice) => {
    setEditNotice(notice);
    setEditContent(notice.content);
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
    setEditNotice(null);
    setEditContent('');
  };

  const handleEditSubmit = () => {
    // 서버 연동 전이므로 콘솔로그만 출력
    console.log('수정된 내용:', {
      ...editNotice,
      content: editContent
    });
    handleEditClose();
  };

  // 모달 핸들러
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewNotice({
      group: '메인사이트',
      fixed: 0,
      title: '',
      public: 0,
      content: '',
      date: new Date().toISOString().split('T')[0]
    });
  };

  const handleAddSubmit = () => {
    // 서버 연동 전이므로 콘솔로그만 출력
    console.log('새로운 공지:', newNotice);
    handleAddClose();
  };

  return (
    <>
      <StyledCard>
        <HeaderBox>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <NotificationsActiveIcon sx={{ color: '#1976d2' }} />
            <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
              유저 공지사항
            </Typography>
          </Box>
          <AddButton 
            variant="contained" 
            startIcon={<AddIcon />} 
            onClick={handleAddClick}
          >
            공지추가
          </AddButton>
        </HeaderBox>
        <StyledTableWrapper>
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>그룹</StyledTableCell>
                  <StyledTableCell>고정여부</StyledTableCell>
                  <StyledTableCell>공개/비공개</StyledTableCell>
                  <StyledTableCell>제목</StyledTableCell>
                  <StyledTableCell>내용</StyledTableCell>
                  <StyledTableCell 
                    onClick={handleSortClick}
                    sx={{ 
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#eaeaea' }
                    }}
                  >
                    등록시간 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                  </StyledTableCell>
                  <StyledTableCell>작업</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notices.map((notice, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{notice.group}</StyledTableCell>
                    <StyledTableCell>{notice.fixed ? '고정' : '일반'}</StyledTableCell>
                    <StyledTableCell>{notice.public ? '공개' : '비공개'}</StyledTableCell>
                    <StyledTableCell>{notice.title}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      maxWidth: '300px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                      {notice.content}
                    </StyledTableCell>
                    <StyledTableCell>{notice.date}</StyledTableCell>
                    <StyledTableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                        <Tooltip title="수정">
                          <IconButton 
                            size="small" 
                            sx={{ color: '#fbc658' }}
                            onClick={() => handleEditClick(notice)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="삭제">
                          <IconButton size="small" sx={{ color: '#ef8157' }}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledTableWrapper>
      </StyledCard>

      {/* 수정 모달 */}
      <Modal
        open={editModalOpen}
        onClose={handleEditClose}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          pt: '15vh'
        }}
      >
        <Paper sx={{ 
          width: '600px',
          p: 3,
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          borderRadius: '4px',
          bgcolor: '#f5f5f5'
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            공지사항 수정
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={editContent}
            onChange={(e) => setEditContent(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 1
          }}>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              sx={{ width: '80px' }}
            >
              확인
            </Button>
            <Button
              onClick={handleEditClose}
              variant="outlined"
              sx={{ width: '80px' }}
            >
              취소
            </Button>
          </Box>
        </Paper>
      </Modal>

      {/* 공지 추가 모달 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'  // 전체 높이 약간 감소
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'  // 타이틀 패딩 감소
        }}>
          공지등록
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 1,  // 상단 여백 감소
          p: 3,
          '& .MuiFormControl-root': {
            minHeight: '65px'  // 입력 필드 영역 높이 감소
          }
        }}>
          <Grid container spacing={2}>  {/* Grid 간격 감소 */}
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1  // 레이블 위치 상단으로 조정
                }}>그룹</InputLabel>
                <Select
                  value={newNotice.group}
                  onChange={(e) => setNewNotice({...newNotice, group: e.target.value})}
                  sx={{ height: '40px' }}  // Select 높이 약간 감소
                >
                  <MenuItem value="메인사이트">메인사이트</MenuItem>
                  <MenuItem value="결제사이트">결제사이트</MenuItem>
                  <MenuItem value="관리자사이트">관리자사이트</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1  // 레이블 위치 상단으로 조정
                }}>고정여부</InputLabel>
                <Select
                  value={newNotice.fixed}
                  onChange={(e) => setNewNotice({...newNotice, fixed: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value={0}>일반</MenuItem>
                  <MenuItem value={1}>고정</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="제목"
                value={newNotice.title}
                onChange={(e) => setNewNotice({...newNotice, title: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1  // 레이블 위치 상단으로 조정
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1  // 레이블 위치 상단으로 조정
                }}>공개여부</InputLabel>
                <Select
                  value={newNotice.public}
                  onChange={(e) => setNewNotice({...newNotice, public: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value={0}>비공개</MenuItem>
                  <MenuItem value={1}>공개</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
                label="내용"
                value={newNotice.content}
                onChange={(e) => setNewNotice({...newNotice, content: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  },
                  '& .MuiInputBase-root': {
                    height: 'auto',  // 자동 높이 조절을 위해 고정 높이 제거
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                type="date"
                label="일자"
                value={newNotice.date}
                onChange={(e) => setNewNotice({...newNotice, date: e.target.value})}
                InputLabelProps={{ 
                  shrink: true,
                  sx: { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1  // 레이블 위치 상단으로 조정
                  }
                }}
                sx={{ '& .MuiInputBase-root': { height: '40px' } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,  // 버튼 영역 패딩 감소
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}  // 버튼 크기 약간 감소
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
          <Button 
            variant="contained" 
            color="error"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserNotices;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Card, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, IconButton, 
  Tooltip, TextField, RadioGroup, FormControlLabel, Radio 
} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';

// Styled Components (동일한 스타일 컴포넌트 사용)
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 430px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const HeaderBox = styled(Box)({
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const SMSSendingHistory = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [records, setRecords] = useState([
    {
      no: 1,
      date: '2024-12-05 14:25:22',
      type: '영업',
      id: 'm20230926',
      name: '1월럭시아 테스트',
      phone: '010-7337-9950'
    },
    {
      no: 2,
      date: '2024-12-05 14:22:52',
      type: '영업',
      id: 't20241108',
      name: '비트페이 테스트',
      phone: '010-4117-9805'
    },
    // ... more records ...
  ]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountType, setAccountType] = useState('전체');

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedRecords = [...records].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setRecords(sortedRecords);
  };

  return (
    <StyledCard>
      <HeaderBox>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SmsIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            문자 발송 내역
          </Typography>
        </Box>
      </HeaderBox>

      <Box sx={{ px: 3, pb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />
        <TextField
          size="small"
          placeholder="로그인 아이디, IP, 휴대번호"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          sx={{ width: '300px' }}
        />
        <RadioGroup
          row
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
        >
          <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
          <FormControlLabel value="영업" control={<Radio />} label="영업" />
        </RadioGroup>
        <Tooltip title="엑셀 다운로드">
          <IconButton
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: '#fff',
              border: '1px solid #2e7d32',
              color: '#2e7d32',
              '&:hover': {
                backgroundColor: '#e8f5e9',
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell 
                onClick={handleSortClick}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eaeaea'
                  }
                }}
              >
                등록일시 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </StyledTableCell>
              <StyledTableCell>가맹점/영업</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>상호명</StyledTableCell>
              <StyledTableCell>휴대번호</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{record.no}</StyledTableCell>
                <StyledTableCell>{record.date}</StyledTableCell>
                <StyledTableCell>{record.type}</StyledTableCell>
                <StyledTableCell>{record.id}</StyledTableCell>
                <StyledTableCell>{record.name}</StyledTableCell>
                <StyledTableCell>{record.phone}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledCard>
  );
};

export default SMSSendingHistory;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c',
  },
}));

const ChugaInput = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const validFiles = files.filter(file => file.type === 'image/jpeg' || file.type === 'image/png');

    const newFiles = validFiles.map((file, index) => ({
      id: uploadedFiles.length + index + 1,
      name: file.name,
      file: URL.createObjectURL(file), // Create a preview URL
    }));

    setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleDelete = (id) => {
    setUploadedFiles(uploadedFiles.filter(file => file.id !== id));
  };

  return (
    <StyledPaper elevation={2} sx={{ mt: 3 }} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
      <SectionTitle>추가 입력</SectionTitle>
      <Box sx={{ width: '100%' }}>
        {/* 파일 드래그 & 드롭 영역 */}
        <Box
          sx={{
            border: '1px dashed #ccc',
            borderRadius: '4px',
            p: 3,
            mb: 3,
            textAlign: 'center',
            backgroundColor: '#fafafa',
          }}
        >
          <Typography variant="body1" sx={{ mb: 1 }}>
            이곳에 파일을
            <br />
            끌어서 놓아주세요.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            파일용량 영문 및 숫자, 5MB 이하
            <br />
            jpg, jpeg, png 확장자만 업로드 가능합니다.
          </Typography>
        </Box>

        {/* 새로 추가될 테이블(파일 업로드) */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>파일명</TableCell>
                <TableCell>삭제</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedFiles.map(file => (
                <TableRow key={file.id}>
                  <TableCell>{file.id}</TableCell>
                  <TableCell>
                    <img src={file.file} alt={file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                    {file.name}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleDelete(file.id)}>삭제</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* 입력 필드들 */}
        <Box sx={{ '& > :not(:last-child)': { mb: 2 } }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>비고</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
            />
          </Box>
          
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>요청란</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
            />
          </Box>
          
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>보완사항</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default ChugaInput;
import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';

// BonNote 스타일 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#1976d2', // 기존 파란색
    color: '#ffffff',
  },

  // 특정 ID에 스타일 추가
  '&#specific-id': {
    color: '#000000', // 텍스트 색상 검정
    backgroundColor: '#1565c0', // 더 진한 파란색
    fontWeight: 'bold', // 텍스트 두껍게
    borderBottom: '2px solid #e9ecef', // 기존 테두리 유지
    borderRight: '1px solid #e9ecef',
    fontSize: '0.813rem', // 기존 텍스트 크기 유지
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 차트 래퍼 스타일 컴포넌트 추가
const ChartWrapper = styled(Box)({
  width: '100%',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function PgData() {
  const [selectedWeek, setSelectedWeek] = React.useState('first');

  // 더미 데이터 정의
  const dummyData = {
    '거래건수': {
      '페이업': 3289,
      '다날': 17,
      '다페이': 51,
      '페이허브': 17,
      'SPC': 290,
      '갤럭시아': 2390,
    },
    '합계': {
      '페이업': 574303110,
      '다날': 725355,
      '다페이': 73030000,
      '페이허브': 6017000,
      'SPC': 67616500,
      '갤럭시아': 168724030,
    },
    '01일': {
      '페이업': { 결제금액: 35353230, 취소금액: -1000000, 실매출액: 34353230 },
      '다날': { 결제금액: 435050, 취소금액: -20000, 실매출액: 415050 },
      '다페이': { 결제금액: 16310000, 취소금액: -500000, 실매출액: 15810000 },
      '페이허브': { 결제금액: 1235000, 취소금액: -35000, 실매출액: 1200000 },
      'SPC': { 결제금액: 4065100, 취소금액: -65100, 실매출액: 4000000 },
      '갤럭시아': { 결제금액: 23151834, 취소금액: -151834, 실매출액: 23000000 }
    },
    '02일': {
      '페이업': { 결제금액: 42156780, 취소금액: -1156780, 실매출액: 41000000 },
      '다날': { 결제금액: 521000, 취소금액: -21000, 실매출액: 500000 },
      '다페이': { 결제금액: 12450000, 취소금액: -450000, 실매출액: 12000000 },
      '페이허브': { 결제금액: 987000, 취소금액: -87000, 실매출액: 900000 },
      'SPC': { 결제금액: 9803600, 취소금액: -803600, 실매출액: 9000000 },
      '갤럭시아': { 결제금액: 19004502, 취소금액: -1004502, 실매출액: 18000000 }
    },
    '03일': {
      '페이업': { 결제금액: 38567100, 취소금액: -987000, 실매출액: 37580100 },
      '다날': { 결제금액: 678000, 취소금액: -28000, 실매출액: 650000 },
      '다페이': { 결제금액: 15670000, 취소금액: -670000, 실매출액: 15000000 },
      '페이허브': { 결제금액: 1456000, 취소금액: -56000, 실매출액: 1400000 },
      'SPC': { 결제금액: 7654300, 취소금액: -654300, 실매출액: 7000000 },
      '갤럭시아': { 결제금액: 21567800, 취소금액: -567800, 실매출액: 21000000 }
    },
    '04일': {
      '페이업': { 결제금액: 45123000, 취소금액: -1234500, 실매출액: 43888500 },
      '다날': { 결제금액: 432000, 취소금액: -32000, 실매출액: 400000 },
      '다페이': { 결제금액: 13450000, 취소금액: -450000, 실매출액: 13000000 },
      '페이허브': { 결제금액: 876000, 취소금액: -76000, 실매출액: 800000 },
      'SPC': { 결제금액: 5678900, 취소금액: -678900, 실매출액: 5000000 },
      '갤럭시아': { 결제금액: 18965400, 취소금액: -965400, 실매출액: 18000000 }
    },
    '05일': {
      '페이업': { 결제금액: 39876500, 취소금액: -876500, 실매출액: 39000000 },
      '다날': { 결제금액: 567000, 취소금액: -67000, 실매출액: 500000 },
      '다페이': { 결제금액: 14567000, 취소금액: -567000, 실매출액: 14000000 },
      '페이허브': { 결제금액: 1234000, 취소금액: -34000, 실매출액: 1200000 },
      'SPC': { 결제금액: 8765400, 취소금액: -765400, 실매출액: 8000000 },
      '갤럭시아': { 결제금액: 20876500, 취소금액: -876500, 실매출액: 20000000 }
    },
    '06일': {
      '페이업': { 결제금액: 41234500, 취소금액: -1234500, 실매출액: 40000000 },
      '다날': { 결제금액: 612000, 취소금액: -12000, 실매출액: 600000 },
      '다페이': { 결제금액: 13240000, 취소금액: -240000, 실매출액: 13000000 },
      '페이허브': { 결제금액: 1345000, 취소금액: -45000, 실매출액: 1300000 },
      'SPC': { 결제금액: 7123400, 취소금액: -123400, 실매출액: 7000000 },
      '갤럭시아': { 결제금액: 19876500, 취소금액: -876500, 실매출액: 19000000 }
    },
    '07일': {
      '페이업': { 결제금액: 37654300, 취소금액: -654300, 실매출액: 37000000 },
      '다날': { 결제금액: 543000, 취소금액: -43000, 실매출액: 500000 },
      '다페이': { 결제금액: 11234000, 취소금액: -234000, 실매출액: 11000000 },
      '페이허브': { 결제금액: 987000, 취소금액: -87000, 실매출액: 900000 },
      'SPC': { 결제금액: 6543200, 취소금액: -543200, 실매출액: 6000000 },
      '갤럭시아': { 결제금액: 17654300, 취소금액: -654300, 실매출액: 17000000 }
    },
    '08일': {
      '페이업': { 결제금액: 43567800, 취소금액: -567800, 실매출액: 43000000 },
      '다날': { 결제금액: 678000, 취소금액: -78000, 실매출액: 600000 },
      '다페이': { 결제금액: 15678000, 취소금액: -678000, 실매출액: 15000000 },
      '페이허브': { 결제금액: 1456000, 취소금액: -56000, 실매출액: 1400000 },
      'SPC': { 결제금액: 8765400, 취소금액: -765400, 실매출액: 8000000 },
      '갤럭시아': { 결제금액: 22567800, 취소금액: -567800, 실매출액: 22000000 }
    },
    '09일': {
      '페이업': { 결제금액: 40987600, 취소금액: -987600, 실매출액: 40000000 },
      '다날': { 결제금액: 534000, 취소금액: -34000, 실매출액: 500000 },
      '다페이': { 결제금액: 12345000, 취소금액: -345000, 실매출액: 12000000 },
      '페이허브': { 결제금액: 1123000, 취소금액: -123000, 실매출액: 1000000 },
      'SPC': { 결제금액: 7654300, 취소금액: -654300, 실매출액: 7000000 },
      '갤럭시아': { 결제금액: 18987600, 취소금액: -987600, 실매출액: 18000000 }
    },
    '10일': {
      '페이업': { 결제금액: 44567800, 취소금액: -567800, 실매출액: 44000000 },
      '다날': { 결제금액: 623000, 취소금액: -23000, 실매출액: 600000 },
      '다페이': { 결제금액: 14567000, 취소금액: -567000, 실매출액: 14000000 },
      '페이허브': { 결제금액: 1234000, 취소금액: -34000, 실매출액: 1200000 },
      'SPC': { 결제금액: 8876500, 취소금액: -876500, 실매출액: 8000000 },
      '갤럭시아': { 결제금액: 21567800, 취소금액: -567800, 실매출액: 21000000 }
    },
    '11일': {
      '페이업': { 결제금액: 38765400, 취소금액: -765400, 실매출액: 38000000 },
      '다날': { 결제금액: 545000, 취소금액: -45000, 실매출액: 500000 },
      '다페이': { 결제금액: 13456000, 취소금액: -456000, 실매출액: 13000000 },
      '페이허브': { 결제금액: 1345000, 취소금액: -45000, 실매출액: 1300000 },
      'SPC': { 결제금액: 6987600, 취소금액: -987600, 실매출액: 6000000 },
      '갤럭시아': { 결제금액: 19765400, 취소금액: -765400, 실매출액: 19000000 }
    },
    '12일': {
      '페이업': { 결제금액: 42345600, 취소금액: -345600, 실매출액: 42000000 },
      '다날': { 결제금액: 612000, 취소금액: -12000, 실매출액: 600000 },
      '다페이': { 결제금액: 15678000, 취소금액: -678000, 실매출액: 15000000 },
      '페이허브': { 결제금액: 987000, 취소금액: -87000, 실매출액: 900000 },
      'SPC': { 결제금액: 7765400, 취소금액: -765400, 실매출액: 7000000 },
      '갤럭시아': { 결제금액: 20345600, 취소금액: -345600, 실매출액: 20000000 }
    },
    '13일': {
      '페이업': { 결제금액: 39876500, 취소금액: -876500, 실매출액: 39000000 },
      '다날': { 결제금액: 567000, 취소금액: -67000, 실매출액: 500000 },
      '다페이': { 결제금액: 12345000, 취소금액: -345000, 실매출액: 12000000 },
      '페이허브': { 결제금액: 1234000, 취소금액: -34000, 실매출액: 1200000 },
      'SPC': { 결제금액: 8654300, 취소금액: -654300, 실매출액: 8000000 },
      '갤럭시아': { 결제금액: 18876500, 취소금액: -876500, 실매출액: 18000000 }
    },
    '14일': {
      '페이업': { 결제금액: 41234500, 취소금액: -1234500, 실매출액: 40000000 },
      '다날': { 결제금액: 623000, 취소금액: -23000, 실매출액: 600000 },
      '다페이': { 결제금액: 14567000, 취소금액: -567000, 실매출액: 14000000 },
      '페이허브': { 결제금액: 1345000, 취소금액: -45000, 실매출액: 1300000 },
      'SPC': { 결제금액: 7234500, 취소금액: -234500, 실매출액: 7000000 },
      '갤럭시아': { 결제금액: 21234500, 취소금액: -1234500, 실매출액: 20000000 }
    }
  };

  const chartOption = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff',
      borderWidth: 1,
      borderColor: '#fff',
      textStyle: {
        color: '#666',
        fontSize: 11
      },
      padding: 10,
      axisPointer: {
        type: 'shadow'
      },
      formatter: function(params) {
        const day = params[0].name;
        let result = `${day}<br/>`;
        
        // PG사별로 데이터 그룹화
        const pgGroups = {};
        params.forEach(param => {
          const [pg, type] = param.seriesName.split(' - ');
          if (!pgGroups[pg]) {
            pgGroups[pg] = {};
          }
          pgGroups[pg][type] = param.value;
        });

        // 각 PG사의 데이터를 순회하며 출력
        Object.entries(pgGroups).forEach(([pg, data]) => {
          if (data['결제금액'] !== undefined) {  // 해당 PG사의 데이터가 있는 경우만 표시
            result += `<br/><b>${pg}</b><br/>` +
              `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#6387f9;"></span>` +
              `결제금액: <span style="font-weight:bold">${data['결제금액'].toLocaleString()}원</span><br/>` +
              `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#ff9933;"></span>` +
              `취소금액: <span style="font-weight:bold">${data['취소금액'].toLocaleString()}원</span><br/>` +
              `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#36c6d3;"></span>` +
              `실매출액: <span style="font-weight:bold">${data['실매출액'].toLocaleString()}원</span>`;
          }
        });
        
        return result;
      }
    },
    legend: {
      show: true,
      data: ['결제금액', '취소금액', '실매출액'],
      bottom: 0,
      icon: 'circle',
      itemGap: 30,
      textStyle: {
        color: '#666666',
        fontSize: 12
      },
      itemStyle: {
        borderWidth: 0
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '60px',
      containLabel: true
    },
    width: window.innerWidth <= 600 ? 1200 : 'auto',
    xAxis: {
      type: 'category',
      data: selectedWeek === 'first' 
        ? ['01일', '02일', '03일', '04일', '05일', '06일', '07일']
        : ['08일', '09일', '10일', '11일', '12일', '13일', '14일'],
      axisLine: {
        lineStyle: { color: '#eaeaea' }
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => {
          const labels = ['0', '2천만', '4천만', '6천만', '8천만', '1억'];
          return labels[value / 20000000] || '';
        },
        fontSize: 11
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#eaeaea'
        }
      }
    },
    series: [
      {
        name: '페이업 - 결제금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first' 
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['페이업'].결제금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['페이업'].결제금액),
        itemStyle: { color: '#6387f9' }
      },
      {
        name: '페이업 - 취소금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['페이업'].취소금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['페이업'].취소금액),
        itemStyle: { color: '#ff9933' }
      },
      {
        name: '페이업 - 실매출액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['페이업'].실매출액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['페이업'].실매출액),
        itemStyle: { color: '#36c6d3' }
      },
      // 다날
      {
        name: '다날 - 결제금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first' 
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['다날'].결제금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['다날'].결제금액),
        itemStyle: { color: '#6387f9' }
      },
      {
        name: '다날 - 취소금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['다날'].취소금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['다날'].취소금액),
        itemStyle: { color: '#ff9933' }
      },
      {
        name: '다날 - 실매출액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['다날'].실매출액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['다날'].실매출액),
        itemStyle: { color: '#36c6d3' }
      },
      // 다페이, 페이허브, SPC, 갤럭시아도 동일한 패턴으로 추가
      //다페이
      {
        name: '다페이 - 결제금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first' 
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['다페이'].결제금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['다페이'].결제금액),
        itemStyle: { color: '#6387f9' }
      },
      {
        name: '다페이 - 취소금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['다페이'].취소금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['다페이'].취소금액),
        itemStyle: { color: '#ff9933' }
      },
      {
        name: '다페이 - 실매출액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['다페이'].실매출액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['다페이'].실매출액),
        itemStyle: { color: '#36c6d3' }
      },
      // 페이허브
      {
        name: '페이허브 - 결제금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first' 
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['페이허브'].결제금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['페이허브'].결제금액),
        itemStyle: { color: '#6387f9' }
      },
      {
        name: '페이허브 - 취소금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['페이허브'].취소금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['페이허브'].취소금액),
        itemStyle: { color: '#ff9933' }
      },
      {
        name: '페이허브 - 실매출액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['페이허브'].실매출액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['페이허브'].실매출액),
        itemStyle: { color: '#36c6d3' }
      },
       // SPC
       {
        name: 'SPC - 결제금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first' 
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['SPC'].결제금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['SPC'].결제금액),
        itemStyle: { color: '#6387f9' }
      },
      {
        name: 'SPC - 취소금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['SPC'].취소금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['SPC'].취소금액),
        itemStyle: { color: '#ff9933' }
      },
      {
        name: 'SPC - 실매출액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['SPC'].실매출액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['SPC'].실매출액),
        itemStyle: { color: '#36c6d3' }
      },
       // 갤럭시아
       {
        name: '갤럭시아 - 결제금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first' 
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['갤럭시아'].결제금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['갤럭시아'].결제금액),
        itemStyle: { color: '#6387f9' }
      },
      {
        name: '갤럭시아 - 취소금액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['갤럭시아'].취소금액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['갤럭시아'].취소금액),
        itemStyle: { color: '#ff9933' }
      },
      {
        name: '갤럭시아 - 실매출액',
        type: 'bar',
        barWidth: 4.5,
        barGap: '100%',
        barCategoryGap: '400%',
        data: selectedWeek === 'first'
          ? ['01', '02', '03', '04', '05', '06', '07'].map(day => dummyData[day + '일']['갤럭시아'].실매출액)
          : ['08', '09', '10', '11', '12', '13', '14'].map(day => dummyData[day + '일']['갤럭시아'].실매출액),
        itemStyle: { color: '#36c6d3' }
      },
      // ... 나머지 PG사들의 series 추가 ...
    ]
  };

  return (
    <Paper sx={{ 
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <Typography variant="h6" sx={{ p: 2, borderBottom: '1px solid #e9ecef' }}>
        PG사별 결제금액
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <StyledTableWrapper>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="sticky-column primary-header">PG</StyledTableCell>
                <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                <StyledTableCell className="primary-header">합계</StyledTableCell>
                {Object.keys(dummyData)
                  .filter(key => key !== '거래건수' && key !== '합계')
                  .map((day) => (
                    <StyledTableCell key={day} className="secondary-header">{day}</StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {Object.keys(dummyData['거래건수']).map((pg) => (
                <StyledTableRow key={pg}>
                  <StyledTableCell className="sticky-column">{pg}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {dummyData['거래건수'][pg].toLocaleString()}
                  </StyledTableCell>
                  {Object.keys(dummyData)
                    .filter(key => key !== '거래건수' && key !== '합계')
                    .map((day) => (
                      <StyledTableCell key={day} sx={{ textAlign: 'right' }}>
                        {dummyData[day][pg].실매출액.toLocaleString()}
                      </StyledTableCell>
                  ))}
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {dummyData['합계'][pg].toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>

        {/* 차트 영역 */}
        <Box sx={{ width: '100%', mt: 3 }}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setSelectedWeek('first')}
              variant={selectedWeek === 'first' ? 'contained' : 'outlined'}
              size="small"
              sx={{ mr: 1 }}
            >
              1~7일
            </Button>
            <Button
              onClick={() => setSelectedWeek('second')}
              variant={selectedWeek === 'second' ? 'contained' : 'outlined'}
              size="small"
            >
              8~14일
            </Button>
          </Box>
          <ChartWrapper>
            <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
              <ReactECharts 
                option={chartOption}
                style={{ height: '300px' }}
                className="echarts-for-react boot-chart"
              />
            </Box>
          </ChartWrapper>
        </Box>
      </Box>
    </Paper>
  );
}

export default PgData;
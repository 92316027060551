import React, { useState, useEffect, useCallback } from 'react';
import { Box, Drawer, IconButton, Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExcelJS from 'exceljs';
import { useNavigate } from 'react-router-dom';

// 공통 컴포넌트 import
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import SearchOptions from '../../Common/ManagementData/MerchantSearch';
import QuickMenu from '../../Common/QuickMenu';
import MerchantManageTables from './MerchantManageTables';

// 스타일 정의
const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden'
  },
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    pl: '50px',
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)',
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '280px',
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      borderLeft: '1px solid #e0e0e0',
      height: 'calc(100% - 64px)',
      top: '64px'
    }
  }
};

function MerchantManagement() {
  const navigate = useNavigate();
  // 검색 패널 상태
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchParams, setSearchParams] = useState({
    startDate: '',
    endDate: '',
    limit: 10
  });

  // 테이블 데이터 상태 추가
  const [tableData, setTableData] = useState([]);
  const [getAllMerchantData, setGetAllMerchantData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearchSubmit = (params) => {
    setSearchParams({
      startDate: params.startDate,
      endDate: params.endDate,
      limit: Number(params.limit)
    });
  };

  // 데이터 업데이트 핸들러
  const handleDataUpdate = useCallback(({ data, getAllData }) => {
    setTableData(data);
    setGetAllMerchantData(() => getAllData);
  }, []);

  // 컬럼 최대 너비 계산 함수 추가
  const getMaxColumnWidth = (data, header, key) => {
    const headerLength = header.length * 2; // 헤더는 더 넓게
    const maxDataLength = Math.max(...data.map(item => {
      const value = item[key];
      return value ? String(value).length : 0;
    }));
    return Math.max(headerLength, maxDataLength, 10); // 최소 너비 10
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      if (!getAllMerchantData) {
        console.warn('데이터를 가져오는 함수가 준비되지 않았습니다.');
        return;
      }

      const allData = typeof getAllMerchantData === 'function' 
        ? getAllMerchantData() 
        : getAllMerchantData;

      if (!allData || allData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('가맹점관리');

      // 헤더 설정
      const headers = [
        'No', 'PG', '본사/일반', '치환계정', '로그인 아이디', '가맹점명', 'MID', 'TID',
        '사업자/개인', '과세/원천', '결제수단', '인증방식', '취소권한', '정산', '월렛',
        '출금딜레이', '정산상태', '실적문자', '수수료율', '보증금율', '포인트', '건한도',
        '할부', '횟수제한', '월렛정액', '월렛정률', '월렛제한', '영업1', '영업2', '영업3',
        '대리점', '리테일', '지사', '총판', '마스터', '등록일', '수정일', '작업자 아이디'
      ];

      // 컬럼 매핑
      const columnMapping = {
        'No': 'id',
        'PG': 'pg',
        '본사/일반': 'businessType',
        '치환계정': 'replacementAccount',
        '로그인 아이디': 'loginId',
        '가맹점명': 'merchantName',
        'MID': 'mid',
        'TID': 'tid',
        '사업자/개인': 'businessStatus',
        '과세/원천': 'taxType',
        '결제수단': 'paymentMethod',
        '인증방식': 'authMethod',
        '취소권한': 'cancelAuth',
        '정산': 'settlement',
        '월렛': 'wallet',
        '출금딜레이': 'withdrawalDelay',
        '정산상태': 'settlementStatus',
        '실적문자': 'performanceSms',
        '수수료율': 'feeRate',
        '보증금율': 'depositRate',
        '포인트': 'point',
        '건한도': 'limitPerCase',
        '할부': 'installment',
        '횟수제한': 'frequencyLimit',
        '월렛정액': 'walletFixed',
        '월렛정률': 'walletRate',
        '월렛제한': 'walletLimit',
        '영업1': 'sales1',
        '영업2': 'sales2',
        '영업3': 'sales3',
        '대리점': 'agency',
        '리테일': 'retail',
        '지사': 'branch',
        '총판': 'distributor',
        '마스터': 'master',
        '등록일': 'registDate',
        '수정일': 'modifyDate',
        '작업자 아이디': 'workerId'
      };

      // 헤더 행 추가
      worksheet.addRow(headers);

      // 데이터 행 추가
      allData.forEach(item => {
        const row = headers.map(header => item[columnMapping[header]] || '');
        worksheet.addRow(row);
      });

      // 컬럼 너비 자동 조정
      headers.forEach((header, index) => {
        const key = columnMapping[header];
        const column = worksheet.getColumn(index + 1);
        const maxWidth = getMaxColumnWidth(allData, header, key);
        column.width = maxWidth + 2; // 여유 공간 추가
      });

      // 스타타일 적용
      worksheet.getRow(1).font = { bold: true };
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1976D2' }
      };
      worksheet.getRow(1).font = { 
        bold: true,
        color: { argb: 'FFFFFFFF' }
      };

      // 모든 셀에 대한 정렬 및 테두리 설정
      worksheet.eachRow((row, rowNumber) => {
        row.eachCell((cell) => {
          cell.alignment = { 
            vertical: 'middle', 
            horizontal: 'center',
            wrapText: false
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // 엑셀 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `가맹점관리_${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: searchPanelOpen && !isMobile ? '280px' : '50px',
    width: '100%',
    transition: 'padding-right 0.3s ease',
    backgroundColor: 'transparent'
  };

  const noticeBoxStyle = {
    ...styles.noticeBox,
    width: 'calc(100% - 0px)',
    transition: 'width 0.3s ease'
  };

  return (
    <Box sx={styles.contentWrapper}>
      <Box component="main" sx={mainBoxStyle}>
        {/* 공지사항 영역 */}
        <Box sx={{ 
          ...noticeBoxStyle,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <Notice />
        </Box>

        {/* 현재 위치 */}
        <Box sx={{ 
          ...noticeBoxStyle,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <CurrentLocation mainTitle="관리" subTitle="가맹점관리" />
        </Box>

        {/* 버튼 그룹과 총 인원수 */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mt: 3,
          mb: 2,
          gap: 2,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <Button
            variant="contained"
            startIcon={<StorefrontIcon />}
            onClick={() => navigate('/management/merchant/register')}
            sx={{
              backgroundColor: '#2196f3',
              '&:hover': {
                backgroundColor: '#1976d2'
              },
              fontSize: '0.95rem',
              py: 1,
              px: 3
            }}
          >
            가맹점 등록하기
          </Button>
          <Button
            variant="contained"
            startIcon={<AssignmentIcon />}
            onClick={() => navigate('/management/merchant-no')}
            sx={{
              backgroundColor: '#ff9800',
              '&:hover': {
                backgroundColor: '#f57c00'
              },
              fontSize: '0.95rem',
              py: 1,
              px: 3
            }}
          >
            가맹점관리 승인전
          </Button>

          <Typography 
            variant="h6" 
            sx={{ 
              fontSize: '1.1rem',
              fontWeight: 500,
              color: '#1976d2'
            }}
          >
            총 618 명
          </Typography>
        </Box>

        {/* 가맹점관리 테이블 */}
        <Box sx={{ 
          mt: 3,
          width: '100%',
          transition: 'width 0.3s ease',
          pr: searchPanelOpen && !isMobile ? '80px' : '0px'
        }}>
          <MerchantManageTables onDataUpdate={handleDataUpdate} />
        </Box>
      </Box>

      {/* 좌측 사이드바 */}
      <QuickMenu />

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            zIndex: 1200,
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearchSubmit}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={handleExcelDownload}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default MerchantManagement;

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 필요한 컴포넌트들 import
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../ResultText'; // 추가
import NonOutputTable from './NonOutputTable';
import QuickMenu from '../../Common/QuickMenu';
import NonwalletTable from './NonwalletTable';
import CurrentLocation from '../../Common/CurrentLocation';

function NonWallet() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);
  const [selectedRows, setSelectedRows] = useState([]);

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="비월렛" />
        <ResultText />
        
        <NonOutputTable
          searchPanelOpen={searchPanelOpen}
          setSearchPanelOpen={setSearchPanelOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />

        <NonwalletTable
          searchPanelOpen={searchPanelOpen}
          setSearchPanelOpen={setSearchPanelOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default NonWallet;

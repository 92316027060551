import React, { useState } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import ButtonWidget from '../../Common/TransferButton';
import ArticleIcon from '@mui/icons-material/Article';
// import TransferDetails from '../../Common/SetModal/TransferDetails';
import WalletModal from '../../Common/SetModal/WalletModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
  },

  '&.amount': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.cancel': {
    color: '#dc3545',
    textAlign: 'right'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'checkbox', label: '일괄 체크', color: '#bbdefb' },
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'status', label: '상태', color: '#bbdefb' },
  { id: 'merchantMID', label: 'MID', color: '#bbdefb' },
  { id: 'merchantTID', label: 'TID', color: '#bbdefb' },
  { id: 'merchantName', label: '가맹점명', color: '#bbdefb' },
  { id: 'settlementCycle', label: '정산주기', color: '#bbdefb' },
  { id: 'transactionDate', label: '결제일시', color: '#2196f3' },
  { id: 'expectedSettlementDate', label: '정산예정일', color: '#2196f3' },
  { id: 'settlementTargetAmount', label: '정산 대상액', color: '#2196f3' },
  { id: 'detail', label: '세부내역', color: '#2196f3' },
  { id: 'offsetAmount', label: '상계금액', color: '#2196f3' },
  { id: 'remittanceAmount', label: '송금예정액', color: '#2196f3' },
  { id: 'approvalCount', label: '승인건수', color: '#2196f3' },
  { id: 'cancelCount', label: '취소건수', color: '#2196f3' },
  { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
  { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
  { id: 'commissionAmount', label: '수수료금액', color: '#c8e6c9' },
  { id: 'guaranteeAmount', label: '보증금액', color: '#c8e6c9' },
  { id: 'accountHolder', label: '예금주', color: '#f0f0f0' },
  { id: 'merchantPhone', label: '가맹점 휴대폰', color: '#f0f0f0' },
  { id: 'bankName', label: '은행명', color: '#f0f0f0' },
  { id: 'accountNumber', label: '계좌번호', color: '#f0f0f0' },
];

const dummyData = [
  {
    no: 1,
    status: '상태1',
    merchantMID: 'MID123',
    merchantTID: 'TID123',
    merchantName: '가맹점명1',
    settlementCycle: '정산주기1',
    transactionDate: '2024-01-01',
    expectedSettlementDate: '2024-01-02',
    settlementTargetAmount: 100000,
    detail: '세부내역1',
    offsetAmount: 10000,
    remittanceAmount: 90000,
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 500000,
    cancelAmount: -10000,
    commissionAmount: 7200,
    guaranteeAmount: 0,
    accountHolder: '예금주1',
    merchantPhone: '010-1234-5678',
    bankName: '은행명1',
    accountNumber: '1234567890',
  },
  {
    no: 2,
    status: '상태2',
    merchantMID: 'MID456',
    merchantTID: 'TID456',
    merchantName: '가맹점명2',
    settlementCycle: '정산주기2',
    transactionDate: '2024-01-02',
    expectedSettlementDate: '2024-01-04',
    settlementTargetAmount: 200000,
    detail: '세부내역2',
    offsetAmount: 20000,
    remittanceAmount: 180000,
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 300000,
    cancelAmount: 0,
    commissionAmount: 6000,
    guaranteeAmount: 0,
    accountHolder: '예금주2',
    merchantPhone: '010-9012-3456',
    bankName: '은행명2',
    accountNumber: '9876543210',
  },
];

function NonwalletTable({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows 
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('하이픈');
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const handleCheckboxChange = (checked, rowId) => {
    if (checked) {
      setSelectedRows(prev => [...prev, rowId]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== rowId));
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDetailClick = (row) => {
    setSelectedDetail(row);
    setDetailModalOpen(true);
  };

  return (
    <>
      <Box mb={2}>
  <Box 
    sx={{ 
      mb: 1, 
      display: 'flex', 
      alignItems: 'center', 
      flexWrap: 'nowrap', 
      whiteSpace: 'nowrap' 
    }}
  >
    {/* 라디오 버튼 1 */}
    <label>
      <input 
        type="radio" 
        value="하이픈" 
        checked={selectedOption === '하이픈'} 
        onChange={handleOptionChange} 
      /> 
      하이픈
    </label>

    {/* 라디오 버튼 2 */}
    <label style={{ marginLeft: '10px' }}>
      <input 
        type="radio" 
        value="웰컴" 
        checked={selectedOption === '웰컴'} 
        onChange={handleOptionChange} 
      /> 
      웰컴
    </label>

    {/* 버튼 위젯 */}
    <Box sx={{ ml: 2 }}>
      <ButtonWidget showFirst={false} showSecond={true} />
    </Box>
  </Box>
        
        <TableContainer component={StyledTableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={header.id}
                    align="center"
                    style={{ backgroundColor: header.color }}
                  >
                    {header.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((row) => (
                <StyledTableRow key={row.no}>
                  {tableHeaders.map((header) => (
                    <StyledTableCell 
                      key={`${row.no}-${header.id}`}
                      align={
                        ['approvalAmount', 'cancelAmount', 'commissionAmount', 'guaranteeAmount', 'settlementTargetAmount', 'offsetAmount', 'remittanceAmount'].includes(header.id)
                          ? 'right'
                          : ['checkbox', 'no', 'status', 'merchantMID', 'merchantTID', 'merchantName', 'settlementCycle', 'transactionDate', 'expectedSettlementDate', 'accountHolder', 'merchantPhone', 'bankName', 'accountNumber'].includes(header.id)
                            ? 'center'
                            : 'left'
                      }
                      className={
                        header.id === 'cancelAmount' 
                          ? 'cancel' 
                          : ['approvalAmount', 'settlementTargetAmount', 'offsetAmount', 'remittanceAmount'].includes(header.id)
                            ? 'amount'
                            : ''
                      }
                    >
                      {header.id === 'checkbox' ? (
                        <Checkbox 
                          checked={selectedRows.includes(row.no)}
                          onChange={(e) => handleCheckboxChange(e.target.checked, row.no)}
                        />
                      ) : header.id === 'detail' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                          <Tooltip title="세부내역 보기">
                            <IconButton 
                              size="small" 
                              onClick={() => handleDetailClick(row)}
                              sx={{
                                padding: '4px',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                              }}
                            >
                              <ArticleIcon sx={{ fontSize: 20, color: '#6435c9' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        row[header.id]
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인금액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>

      <WalletModal 
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
      />
    </>
  );
}

export default NonwalletTable;

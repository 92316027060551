import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { paymentData } from './PayHisSummaryTable';

const ResultText = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { basicStats = {} } = paymentData || {};

  const formatNumber = (number) => {
    return (number || 0).toLocaleString();
  };

  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      mb: 2,
      p: 2,
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #e3f2fd',
      justifyContent: { xs: 'flex-start', sm: 'center' },
      alignItems: 'center',
    },
    textGroup: {
      display: 'flex',
      alignItems: 'center',
      minWidth: { xs: '45%', sm: 'auto' },
      '&:not(:last-child)::after': {
        content: isMobile ? '""' : '"|"',
        margin: '0 8px',
        color: '#e0e0e0',
      }
    },
    label: {
      color: '#1976d2',
      fontWeight: 600,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
    },
    labelBlack: {
      color: '#333',
      fontWeight: 600,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
    },
    value: {
      color: '#333',
      fontWeight: 500,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
      ml: 1,
    },
    redValue: {
      color: '#ff3d00',
      fontWeight: 500,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
      ml: 1,
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>승인금액</Typography>
        <Typography sx={styles.value}>{formatNumber(basicStats.approvalAmount)}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>취소금액</Typography>
        <Typography sx={styles.redValue}>{formatNumber(basicStats.cancelAmount)}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.labelBlack}>합계</Typography>
        <Typography sx={styles.value}>{formatNumber(basicStats.totalAmount)}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>수수료 합계(VAT포함)</Typography>
        <Typography sx={styles.value}>{formatNumber(basicStats.totalFee)}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>보증금 합계액(VAT포함)</Typography>
        <Typography sx={styles.value}>{formatNumber(basicStats.totalDeposit)}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.labelBlack}>정산액</Typography>
        <Typography sx={styles.value}>{formatNumber(basicStats.totalSettlement)}원</Typography>
      </Box>
    </Box>
  );
};

export default ResultText;

import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableContainer,
  Paper,
  Box,
  LinearProgress
} from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import axios from 'axios';

// 스타일 컴포넌트들 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#1976d2',
    zIndex: 3
  }
});

// 더미 데이터 수정
const dummyData = [
  {
    no: 1,
    loginId: 'test123',
    merchantName: '오성상사',
    master: '마스터A',
    branch: '오성',
    rmCount: 2,
    rmAmount: 500000,
    approvalCount: 5,
    cancelCount: 0,
    actualSalesAmount: 29760000,
    settlementAmount: 28319616,
    supplyValue: 27060000,
    vatAmount: 2700000,
    merchantFeeRate: 4.84,
    feeWithVat: 1440384,
    supplyPrice: 1309440,
    vat: 130944,
    depositRate: 10,
    depositAmount: 2976000,
    paymentDiff: 0,
    totalPayment: 25343616,
    sales1: '홍길동',
    sales2: '김철수',
    sales3: '이영희',
    agency: '에이전시A',
    retail: '리테일A',
    branchOffice: '지사A',
    distributor: '총판A',
    seniorMaster: '시니어A',
    master: '마스터A',
    riskRatio: 2.5
  },
  {
    no: 2,
    loginId: 'gangnam01',
    merchantName: '강남상사',
    master: '마스터B',
    branch: '강남',
    rmCount: 1,
    rmAmount: 300000,
    approvalCount: 12,
    cancelCount: 2,
    actualSalesAmount: 13000000,
    settlementAmount: 12642500,
    supplyValue: 11818182,
    vatAmount: 1181818,
    merchantFeeRate: 2.75,
    feeWithVat: 357500,
    supplyPrice: 325000,
    vat: 32500,
    depositRate: 5,
    depositAmount: 650000,
    paymentDiff: 0,
    totalPayment: 11992500,
    sales1: '박영수',
    sales2: '최민지',
    sales3: '정대현',
    agency: '에이전시B',
    retail: '리테일B',
    branchOffice: '지사B',
    distributor: '총판B',
    seniorMaster: '시니어B',
    master: '마스터B',
    riskRatio: 1.8
  }
];

function BunseokGamengTables({ searchParams, searchPanelOpen, onDataUpdate }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1000);
  const rowsPerPage = searchParams?.limit || 10;

  // API 호출 함수
  const getStatisticsData = async (skip = 0) => {
    try {
      const instance = axios.create({
        baseURL: 'https://api-fsai-bnine.211.45.182.26.nip.io',
        headers: {
          'accept': 'application/json'
        }
      });

      const params = {
        skip: skip,
        limit: rowsPerPage,
        start_date: searchParams?.startDate?.replace(/-/g, ''),
        end_date: searchParams?.endDate?.replace(/-/g, '')
      };

      const response = await instance.get('/statistics/merchant/', { params });

      if (Array.isArray(response.data)) {
        setData(response.data);
        setTotalCount(1000); // 임시로 1000으로 설정
      } else {
        setError('데이터 형식이 올바르지 않습니다.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
    getStatisticsData(0);
  }, [searchParams]);

  return (
    <Paper sx={{ 
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <StyledTableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell className="primary-header">No</StyledTableCell>
              <StyledTableCell className="primary-header">로그인아이디</StyledTableCell>
              <StyledTableCell className="primary-header">상호</StyledTableCell>
              <StyledTableCell className="primary-header">마스터</StyledTableCell>
              <StyledTableCell className="primary-header">지사</StyledTableCell>
              <StyledTableCell className="primary-header">RM건수</StyledTableCell>
              <StyledTableCell className="primary-header">RM금액</StyledTableCell>
              <StyledTableCell className="primary-header">승인건수</StyledTableCell>
              <StyledTableCell className="primary-header">취소건수</StyledTableCell>
              <StyledTableCell className="primary-header">실매출금액</StyledTableCell>
              <StyledTableCell className="primary-header">정산금</StyledTableCell>
              <StyledTableCell className="primary-header">공급가액</StyledTableCell>
              <StyledTableCell className="primary-header">부가세액</StyledTableCell>
              <StyledTableCell className="primary-header">가맹점수수료율</StyledTableCell>
              <StyledTableCell className="primary-header">수수료(VAT포함)</StyledTableCell>
              <StyledTableCell className="primary-header">공급가</StyledTableCell>
              <StyledTableCell className="primary-header">부가세</StyledTableCell>
              <StyledTableCell className="primary-header">보증금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">보증금액</StyledTableCell>
              <StyledTableCell className="primary-header">지급차액</StyledTableCell>
              <StyledTableCell className="primary-header">지급총액</StyledTableCell>
              <StyledTableCell className="primary-header">영업1</StyledTableCell>
              <StyledTableCell className="primary-header">영업2</StyledTableCell>
              <StyledTableCell className="primary-header">영업3</StyledTableCell>
              <StyledTableCell className="primary-header">대리점</StyledTableCell>
              <StyledTableCell className="primary-header">리테일</StyledTableCell>
              <StyledTableCell className="primary-header">지사</StyledTableCell>
              <StyledTableCell className="primary-header">총판</StyledTableCell>
              <StyledTableCell className="primary-header">시니어마스터</StyledTableCell>
              <StyledTableCell className="primary-header">마스터</StyledTableCell>
              <StyledTableCell className="primary-header">그래프</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.no}</StyledTableCell>
                <StyledTableCell>{row.loginId}</StyledTableCell>
                <StyledTableCell>{row.merchantName}</StyledTableCell>
                <StyledTableCell>{row.master}</StyledTableCell>
                <StyledTableCell>{row.branch}</StyledTableCell>
                <StyledTableCell align="right">{row.rmCount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.rmAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.approvalCount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right" style={{ color: row.cancelCount > 0 ? '#f44336' : 'inherit' }}>
                  {row.cancelCount.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="right">{row.actualSalesAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.settlementAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.supplyValue.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.vatAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.merchantFeeRate}%</StyledTableCell>
                <StyledTableCell align="right">{row.feeWithVat.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.supplyPrice.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.vat.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.depositRate}%</StyledTableCell>
                <StyledTableCell align="right">{row.depositAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.paymentDiff.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.totalPayment.toLocaleString()}</StyledTableCell>
                <StyledTableCell>{row.sales1}</StyledTableCell>
                <StyledTableCell>{row.sales2}</StyledTableCell>
                <StyledTableCell>{row.sales3}</StyledTableCell>
                <StyledTableCell>{row.agency}</StyledTableCell>
                <StyledTableCell>{row.retail}</StyledTableCell>
                <StyledTableCell>{row.branchOffice}</StyledTableCell>
                <StyledTableCell>{row.distributor}</StyledTableCell>
                <StyledTableCell>{row.seniorMaster}</StyledTableCell>
                <StyledTableCell>{row.master}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box sx={{ width: '100%', minWidth: 100 }}>
                    <LinearProgress
                      variant="determinate"
                      value={parseFloat(row.riskRatio)}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: '#e3f2fd',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#1976d2',
                          borderRadius: 5,
                        }
                      }}
                    />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>
    </Paper>
  );
}

BunseokGamengTables.propTypes = {
  searchParams: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    limit: PropTypes.number
  }),
  searchPanelOpen: PropTypes.bool,
  onDataUpdate: PropTypes.func
};

export default BunseokGamengTables; 
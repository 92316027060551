import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Box,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '800px',
    minHeight: '600px',
    maxHeight: '80vh',
    borderRadius: '8px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

const StyledTableCell = styled(TableCell)({
  padding: '6px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  height: '32px',
  whiteSpace: 'nowrap',
  '&.no-cell': {
    width: '45px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 8px'
  },
  '&.label-cell': {
    width: '180px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 12px'
  },
  '&.value-cell': {
    width: '250px',
    color: '#495057',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    padding: '6px 12px'
  }
});

const formatValue = (value) => {
  if (typeof value === 'string' && value.includes('\n')) {
    return value.split('\n').join(' ');
  }
  return value;
};

const MaechulGamangOne = ({ open, onClose, businessName }) => {
  const tableData = [
    { no: '1', label: 'GID', value: 'G230912345' },
    { no: '2', label: '로그인 아이디', value: 'user123' },
    { no: '3', label: 'MID', value: 'M230912345' },
    { no: '4', label: 'TID', value: 'T230912345' },
    { no: '5', label: '가맹점명', value: businessName },
    { no: '6', label: '대표자명', value: '홍길동' },
    { no: '7', label: '사업자/개인', value: '사업자' },
    { no: '8', label: '세금/원천', value: '세금계산서' },
    { no: '9', label: '사업자번호/주민번호', value: '123-45-67890' },
    { no: '10', label: '은행명', value: '신한은행' },
    { no: '11', label: '예금주', value: '홍길동' },
    { no: '12', label: '계좌번호', value: '110-123-456789' },
    { no: '13', label: '업종', value: '소매' },
    { no: '14', label: '품명', value: '전자기기' },
    { no: '15', label: '연락처', value: '02-1234-5678' },
    { no: '16', label: '휴대번호', value: '010-1234-5678' },
    { no: '17', label: '이메일', value: 'example@email.com' },
    { no: '18', label: '주소', value: '서울시 강남구 테헤란로 123' },
    { no: '19', label: '시리얼넘버', value: 'SN230912345' },
    { no: '20', label: '정산금지', value: '해당없음' },
    { no: '21', label: '출금저지', value: '해당없음' }
  ];

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <StyledDialogTitle>
        계정 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.no}>
                  <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                  <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                  <StyledTableCell className="value-cell">
                    {formatValue(row.value)}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default MaechulGamangOne;

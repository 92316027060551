import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  Typography, 
  TextField,
  Button,
  Grid
} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';

const MerchantSMSManagement = () => {
  // 첫 번째 섹션 상태 (잔액 미달 기준)
  const [balanceThresholds, setBalanceThresholds] = useState({
    threshold1: '10000000',
    threshold2: '8000000',
    threshold3: '5000000',
    threshold4: '3000000',
    threshold5: '1500000'
  });

  // 두 번째 섹션 상태 (SMS 수신번호들)
  const [phoneNumbers, setPhoneNumbers] = useState({
    phone1: '',
    phone2: '',
    phone3: '',
    phone4: '',
    phone5: ''
  });

  // 머천트 잔액기준 KEY 상태 추가
  const [merchantKey, setMerchantKey] = useState('');

  return (
    <Card sx={{ mt: 4, borderRadius: '12px', boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)' }}>
      <Box sx={{ p: 3 }}>
        {/* 제목 */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <SmsIcon sx={{ color: '#9c27b0' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            머천트 SMS관리
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* 머천트 잔액기준 KEY 섹션 추가 */}
          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>머천트 잔액기준 KEY</Typography>
              <TextField
                fullWidth
                size="small"
                value={merchantKey}
                onChange={(e) => setMerchantKey(e.target.value)}
                placeholder="머천트 잔액기준 KEY를 입력하세요"
                sx={{ 
                  bgcolor: '#ffffff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#9c27b0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#9c27b0',
                    },
                  }
                }}
              />
            </Box>
          </Grid>

          {/* 첫 번째 섹션 - 잔액 미달 기준 */}
          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>잔액 미달 기준</Typography>
              <Box sx={{ 
                display: 'flex', 
                gap: 2,
                flexWrap: 'wrap'
              }}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <TextField
                    key={num}
                    size="small"
                    label={`잔액 미달기준 금액 ${num}`}
                    value={balanceThresholds[`threshold${num}`]}
                    onChange={(e) => setBalanceThresholds({
                      ...balanceThresholds,
                      [`threshold${num}`]: e.target.value
                    })}
                    sx={{ flexGrow: 1, minWidth: '200px' }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          {/* 두 번째 섹션 - SMS 수신번호 */}
          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>SMS 수신번호</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <TextField
                    key={num}
                    size="small"
                    label={`SMS 수신번호 ${num}`}
                    value={phoneNumbers[`phone${num}`]}
                    onChange={(e) => setPhoneNumbers({...phoneNumbers, [`phone${num}`]: e.target.value})}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* 적용 버튼 */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button 
            variant="contained"
            sx={{ 
              bgcolor: '#51cbce',
              '&:hover': {
                bgcolor: '#45b0b3'
              }
            }}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default MerchantSMSManagement;

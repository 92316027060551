import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, 
  IconButton, Tooltip, Paper, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '@mui/icons-material/Download';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 380px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

function PGProviderSettings() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newSetting, setNewSetting] = useState({
    mid: '',
    pg: '',
    pay_flag: '',
    admin_fee: '',
    senior_fee: '',
    key: '',
    inv: ''
  });
  const [dummyData, setDummyData] = useState([
    { 
      No: 1, 
      PG: 'payhub', 
      PAY_FLAG: '구인증', 
      MID: 'test_mid1', 
      ADMIN_FEE: '3.5', 
      SENIOR_FEE: '2.8', 
      KEY: 'key1', 
      INV: 'inv1',
      ADMIN: 'admin1',
      SENIOR: 'senior1',
      INSERTDATE: '2024-03-15'
    },
    { 
      No: 2, 
      PG: 'Galaxia', 
      PAY_FLAG: '신인증', 
      MID: 'test_mid2', 
      ADMIN_FEE: '3.2', 
      SENIOR_FEE: '2.5', 
      KEY: 'key2', 
      INV: 'inv2',
      ADMIN: 'admin2',
      SENIOR: 'senior2',
      INSERTDATE: '2024-03-14'
    },
    { 
      No: 3, 
      PG: 'dapay', 
      PAY_FLAG: '구인증', 
      MID: 'test_mid3', 
      ADMIN_FEE: '3.8', 
      SENIOR_FEE: '3.0', 
      KEY: 'key3', 
      INV: 'inv3',
      ADMIN: 'admin3',
      SENIOR: 'senior3',
      INSERTDATE: '2024-03-13'
    },
    { 
      No: 4, 
      PG: 'payhub', 
      PAY_FLAG: '신인증', 
      MID: 'test_mid4', 
      ADMIN_FEE: '3.3', 
      SENIOR_FEE: '2.6', 
      KEY: 'key4', 
      INV: 'inv4',
      ADMIN: 'admin4',
      SENIOR: 'senior4',
      INSERTDATE: '2024-03-12'
    },
    { 
      No: 5, 
      PG: 'Galaxia', 
      PAY_FLAG: '구인증', 
      MID: 'test_mid5', 
      ADMIN_FEE: '3.6', 
      SENIOR_FEE: '2.9', 
      KEY: 'key5', 
      INV: 'inv5',
      ADMIN: 'admin5',
      SENIOR: 'senior5',
      INSERTDATE: '2024-03-11'
    },
    { 
      No: 6, 
      PG: 'dapay', 
      PAY_FLAG: '신인증', 
      MID: 'test_mid6', 
      ADMIN_FEE: '3.4', 
      SENIOR_FEE: '2.7', 
      KEY: 'key6', 
      INV: 'inv6',
      ADMIN: 'admin6',
      SENIOR: 'senior6',
      INSERTDATE: '2024-03-10'
    },
    { 
      No: 7, 
      PG: 'payhub', 
      PAY_FLAG: '구인증', 
      MID: 'test_mid7', 
      ADMIN_FEE: '3.7', 
      SENIOR_FEE: '3.1', 
      KEY: 'key7', 
      INV: 'inv7',
      ADMIN: 'admin7',
      SENIOR: 'senior7',
      INSERTDATE: '2024-03-09'
    },
    { 
      No: 8, 
      PG: 'Galaxia', 
      PAY_FLAG: '신인증', 
      MID: 'test_mid8', 
      ADMIN_FEE: '3.1', 
      SENIOR_FEE: '2.4', 
      KEY: 'key8', 
      INV: 'inv8',
      ADMIN: 'admin8',
      SENIOR: 'senior8',
      INSERTDATE: '2024-03-08'
    }
  ]);

  const columns = [
    { id: 'No', label: 'No', sortable: true },
    { id: 'PG', label: 'PG', sortable: true },
    { id: 'PAY_FLAG', label: 'PAY_FLAG', sortable: true },
    { id: 'MID', label: 'MID', sortable: true },
    { id: 'ADMIN_FEE', label: 'ADMIN_FEE', sortable: true },
    { id: 'SENIOR_FEE', label: 'SENIOR_FEE', sortable: true },
    { id: 'KEY', label: 'KEY', sortable: true },
    { id: 'INV', label: 'INV', sortable: true },
    { id: 'ADMIN', label: 'ADMIN', sortable: true },
    // { id: 'SENIOR', label: 'SENIOR', sortable: true },
    { id: 'INSERTDATE', label: 'INSERTDATE', sortable: true },
    { id: 'delete', label: '삭제', sortable: false }
  ];

  const [selectedPG, setSelectedPG] = useState('');
  const [filteredData, setFilteredData] = useState(dummyData);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      console.log("파일이 선택되었습니다:", file.name);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    fileInputRef.current.value = '';
  };

  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  const handlePGSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedPG(selectedValue);
    
    if (selectedValue === '') {
      setFilteredData(dummyData);
    } else {
      const filtered = dummyData.filter(row => row.PG === selectedValue);
      setFilteredData(filtered);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...filteredData].sort((a, b) => {
      if (['No', 'ADMIN_FEE', 'SENIOR_FEE'].includes(property)) {
        return isAsc 
          ? parseFloat(b[property]) - parseFloat(a[property])
          : parseFloat(a[property]) - parseFloat(b[property]);
      }
      return isAsc
        ? b[property].localeCompare(a[property])
        : a[property].localeCompare(b[property]);
    });

    setFilteredData(sortedData);
  };

  // 모달 핸들러
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewSetting({
      mid: '',
      pg: '',
      pay_flag: '',
      admin_fee: '',
      senior_fee: '',
      key: '',
      inv: ''
    });
  };

  const handleAddSubmit = () => {
    console.log('새로운 PG 세팅:', newSetting);
    handleAddClose();
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Paper elevation={3}>
          <Box sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #e0e0e0'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccountBalanceIcon sx={{ color: '#ed6c02' }} />
              <Typography variant="h6">PG사별 세팅</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                accept="image/*"
              />
              {selectedFile && (
                <Box sx={{ 
                  position: 'relative',
                  width: '40px',
                  height: '40px'
                }}>
                  <img 
                    src={previewUrl}
                    alt="preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '4px'
                    }}
                  />
                  <IconButton
                    onClick={handleCancelFile}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#fff',
                      border: '1px solid #d32f2f',
                      color: '#d32f2f',
                      padding: '2px',
                      '&:hover': {
                        backgroundColor: '#d32f2f10',
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size="xs" />
                  </IconButton>
                </Box>
              )}
              <Tooltip title="파일 선택">
                <IconButton
                  onClick={handleFileButtonClick}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #1976d2',
                    color: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1976d210',
                    }
                  }}
                >
                  <UploadFileIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="엑셀 양식 다운로드">
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}
                >
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleAddClick}
              >
                등록하기
              </Button>
            </Box>
          </Box>

          <Box sx={{ p: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              gap: 1,
              mb: 2 
            }}>
              {buttons.map((button, index) => (
                <Tooltip key={index} title={button.label}>
                  <IconButton
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: `1px solid ${button.color}`,
                      color: button.color,
                      '&:hover': {
                        backgroundColor: `${button.color}10`,
                      }
                    }}
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell 
                        key={column.id}
                        onClick={() => column.sortable && handleRequestSort(column.id)}
                        sx={{
                          cursor: column.sortable ? 'pointer' : 'default',
                          '&:hover': column.sortable ? {
                            backgroundColor: '#eaeaea'
                          } : {}
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {column.id === 'PG' ? (
                            <Select
                              displayEmpty
                              value={selectedPG}
                              onChange={handlePGSelect}
                              size="small"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <MenuItem value="">전체</MenuItem>
                              <MenuItem value="payhub">payhub</MenuItem>
                              <MenuItem value="Galaxia">Galaxia</MenuItem>
                              <MenuItem value="dapay">dapay</MenuItem>
                            </Select>
                          ) : (
                            <>
                              {column.label}
                              {column.sortable && (
                                <Box component="span" sx={{ ml: 0.5 }}>
                                  {orderBy === column.id ? (
                                    order === 'asc' ? '▲' : '▼'
                                  ) : '↕'}
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row) => (
                    <StyledTableRow key={row.No}>
                      {columns.map((column) => (
                        <StyledTableCell key={column.id}>
                          {column.id === 'delete' ? (
                            <Button 
                              variant="contained" 
                              color="error" 
                              size="small"
                              onClick={() => console.log(`Delete row ${row.No}`)}
                            >
                              삭제
                            </Button>
                          ) : (
                            row[column.id]
                          )}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>

      {/* PG사 세팅 추가 모달 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          세트값 관리
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 3,  // 상단 여백 증가
          p: 3,
          '& .MuiFormControl-root': {
            minHeight: '75px'  // 필드 간격 증가
          }
        }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <br/>
              <TextField
                fullWidth
                size="small"
                label="MID"
                value={newSetting.mid}
                onChange={(e) => setNewSetting({...newSetting, mid: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <br/>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ backgroundColor: 'white', px: 1, mt: -0.5 }}>PG</InputLabel>
                <Select
                  value={newSetting.pg}
                  label="PG"
                  onChange={(e) => setNewSetting({...newSetting, pg: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="SPC">SPC</MenuItem>
                  {/* Add other PG options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ backgroundColor: 'white', px: 1, mt: -0.5 }}>PAY_FLAG</InputLabel>
                <Select
                  value={newSetting.pay_flag}
                  label="PAY_FLAG"
                  onChange={(e) => setNewSetting({...newSetting, pay_flag: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="구인증">구인증</MenuItem>
                  {/* Add other PAY_FLAG options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="ADMIN_FEE"
                value={newSetting.admin_fee}
                onChange={(e) => setNewSetting({...newSetting, admin_fee: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="SENIOR_FEE"
                value={newSetting.senior_fee}
                onChange={(e) => setNewSetting({...newSetting, senior_fee: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="KEY"
                value={newSetting.key}
                onChange={(e) => setNewSetting({...newSetting, key: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="INV"
                value={newSetting.inv}
                onChange={(e) => setNewSetting({...newSetting, inv: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PGProviderSettings;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '12px',
    paddingBottom: '8px'
  }
}));

const StyledButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  padding: '6px 16px',
  '&:hover': {
    boxShadow: '0 2px 8px rgba(52, 152, 219, 0.3)'
  }
});

const Auth = () => {
  const [checkboxes, setCheckboxes] = useState({
    mainpay: true,
    platbill: true,
    bitopay: true,
    cash2check: true,
    cashbill: false,
    ondapay: false,
    imcrew: false,
    // 인증 및 권한 체크박스
    loginAccess: false,
    documentAccess: false,
    loginAuth: false,
    qrPayment: false,
    linkPayment: false,
    bankPayment: false
  });

  // 전체 체크 상태 추가
  const [allChecked, setAllChecked] = useState(false);

  // 인증 및 권한 섹션 토글 상태 추가
  const [isAuthExpanded, setIsAuthExpanded] = useState(true);

  // 전체 체크/해제 핸들러
  const handleAllCheck = (event) => {
    const isChecked = event.target.checked;
    setAllChecked(isChecked);
    
    // 로그인 허용 사이트 관련 체크박스만 업데이트
    setCheckboxes(prev => ({
      ...prev,
      mainpay: isChecked,
      platbill: isChecked,
      bitopay: isChecked,
      cash2check: isChecked,
      cashbill: isChecked,
      ondapay: isChecked,
      imcrew: isChecked
    }));
  };

  const handleCheckboxChange = (name) => (event) => {
    setCheckboxes(prev => ({
      ...prev,
      [name]: event.target.checked
    }));
  };

  // 토글 핸들러 추가
  const handleAuthToggle = () => {
    setIsAuthExpanded(!isAuthExpanded);
  };

  return (
    <StyledPaper>
      <SectionTitle>인증 설정</SectionTitle>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12}>
          {/* 로그인 허용사이트 섹션 */}
          <Box sx={{ 
            mb: { xs: 2, md: 4 }
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              mb: { xs: 1, md: 2 }
            }}>
              <Typography sx={{ 
                fontSize: { xs: '0.8rem', md: '0.9rem' },
                color: '#666',
                mr: { xs: 1, md: 2 }
              }}>
                로그인 허용 사이트
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={allChecked}
                    onChange={handleAllCheck}
                  />
                }
                label="로그인 허용 전체 체크"
                sx={{ 
                  '& .MuiFormControlLabel-label': { 
                    fontSize: { xs: '0.7rem', md: '0.75rem' }
                  }
                }}
              />
            </Box>
            
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: { xs: 0.5, md: 1 },
              mb: { xs: 1, md: 2 }
            }}>
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.mainpay}
                    onChange={handleCheckboxChange('mainpay')}
                  />
                }
                label="mainpay.kr (결제원칙)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.platbill}
                    onChange={handleCheckboxChange('platbill')}
                  />
                }
                label="platbill.kr (결제원칙)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.bitopay}
                    onChange={handleCheckboxChange('bitopay')}
                  />
                }
                label="bitopay.kr (결제원칙)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.cash2check}
                    onChange={handleCheckboxChange('cash2check')}
                  />
                }
                label="cash2check.kr (조회전산)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.cashbill}
                    onChange={handleCheckboxChange('cashbill')}
                  />
                }
                label="cashbill.kr (조회전산)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.ondapay}
                    onChange={handleCheckboxChange('ondapay')}
                  />
                }
                label="ondapay.kr (서브전산)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    size="small"
                    checked={checkboxes.imcrew}
                    onChange={handleCheckboxChange('imcrew')}
                  />
                }
                label="imcrew.kr (서브전산)"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
              />
            </Box>
          </Box>

          {/* 인증 및 권한 섹션 */}
          <Box sx={{ mb: 3 }}>
            <Typography 
              onClick={handleAuthToggle}
              sx={{ 
                fontSize: '0.9rem', 
                color: '#666', 
                mb: 2,
                cursor: 'pointer',
                userSelect: 'none',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  color: '#333'
                }
              }}
            >
              인증 및 권한 {isAuthExpanded ? '▼' : '▲'}
            </Typography>

            <Collapse in={isAuthExpanded}>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 1
              }}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.loginAccess}
                      onChange={handleCheckboxChange('loginAccess')}
                    />
                  }
                  label="로그인 접속 여부"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.documentAccess}
                      onChange={handleCheckboxChange('documentAccess')}
                    />
                  }
                  label="문서 접속 여부"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.loginAuth}
                      onChange={handleCheckboxChange('loginAuth')}
                    />
                  }
                  label="로그인 인증 여부"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.qrPayment}
                      onChange={handleCheckboxChange('qrPayment')}
                    />
                  }
                  label="QR결제 허용"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.linkPayment}
                      onChange={handleCheckboxChange('linkPayment')}
                    />
                  }
                  label="링크결제 허용"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.bankPayment}
                      onChange={handleCheckboxChange('bankPayment')}
                    />
                  }
                  label="계좌이체 허용"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.loginAccess}
                      onChange={handleCheckboxChange('loginAccess')}
                    />
                  }
                  label="로그인 접속 여부"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.loginAccess}
                      onChange={handleCheckboxChange('loginAccess')}
                    />
                  }
                  label="로그인 접속 여부"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.loginAccess}
                      onChange={handleCheckboxChange('loginAccess')}
                    />
                  }
                  label="로그인 접속 여부"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
                />
              </Box>
            </Collapse>
          </Box>

          {/* 버튼 그룹 */}
          <Box sx={{
            position:  'right',
            display: 'flex', 
            justifyContent: 'center',
            gap: 1 
          }}>
            <StyledButton
              variant="contained"
              sx={{
                bgcolor: '#3498db',
                '&:hover': { bgcolor: '#2980b9' }
              }}
            >
              저장
            </StyledButton>
            <StyledButton
              variant="contained"
              sx={{
                bgcolor: '#95a5a6',
                '&:hover': { bgcolor: '#7f8c8d' }
              }}
            >
              취소
            </StyledButton>
            <StyledButton
              variant="contained"
              sx={{
                bgcolor: '#e74c3c',
                '&:hover': { bgcolor: '#c0392b' }
              }}
            >
              임시저장
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default Auth;

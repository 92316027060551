import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  Button
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchOptions from '../../Common/SearchOptions'; // 검색 패널 컴포넌트
import Notice from '../../Notice/Notice'; // 공지사항 컴포넌트
import QuickMenu from '../../Common/QuickMenu'; // 좌측 퀵메뉴 컴포넌트
import ScheduledOffsetTable from './ScheduledOffsetTable'; // ScheduledOffsetTable 컴포넌트
import ResultText from '../../Common/SettlementResultData/SetResultText';
import ResultTable from '../../Common/SettlementResultData/SetResultTables';
import DefaultTable from './DefaultTables';//기본 산출 테이블
import OffsetAmount from '../../Common/SettlementResultData/OffsetAmount';
import SetSummaryTables from '../../Common/SettlementResultData/SetSummaryTables';
import RestoreTables from './RestoreTables';
import { openCancelPopup } from './CancelPopup';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CurrentLocation from '../../Common/CurrentLocation';
import CancelIcon from '@mui/icons-material/Cancel';

function ScheduledSettlementDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSearchPanelOpen(window.innerWidth >= 960);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="정산예정 내역" />
        <OffsetAmount />
        <ResultText />
        <ResultTable />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            variant="contained"
            startIcon={<CancelIcon />}
            onClick={() => openCancelPopup()}
            sx={{
              backgroundColor: '#9e9e9e',
              '&:hover': {
                backgroundColor: '#757575'
              },
              color: 'white',
              fontSize: '0.875rem',
              padding: '6px 16px',
              textTransform: 'none'
            }}
          >
            기간내 취소 팝업
          </Button>
        </Box>

        <RestoreTables />
        <ScheduledOffsetTable />
        <DefaultTable />
        <SetSummaryTables />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default ScheduledSettlementDetails;

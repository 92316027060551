import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import HistoryIcon from '@mui/icons-material/History';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ListAltIcon from '@mui/icons-material/ListAlt';
import styled from '@emotion/styled';

// 버튼 그룹 래퍼 스타일 컴포넌트
const ButtonGroupWrapper = styled('div')({
  display: 'flex',
  gap: '8px',
  margin: '15px 0',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

const ButtonWidget = ({ showFirst = true, showSecond = false }) => {
    const firstButtonGroup = [
        { label: '정산초기화', icon: <RestartAltIcon /> },
        { label: '갱신', icon: <RefreshIcon /> },
        { label: '송금 실패내역', icon: <HistoryIcon /> },
    ];

    const secondButtonGroup = [
        { label: '송금하기', icon: <SendIcon /> },
        { label: '예약송금', icon: <ScheduleIcon /> },
        { label: '예약목록', icon: <ListAltIcon /> },
    ];

    return (
        <div>
            {showFirst && (
                <ButtonGroupWrapper>
                    {firstButtonGroup.map((button, index) => (
                        <button 
                            key={index} 
                            className="styled-button"
                            style={{
                                flexShrink: 0,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {button.icon} {button.label}
                        </button>
                    ))}
                </ButtonGroupWrapper>
            )}
            {showSecond && (
                <ButtonGroupWrapper>
                    {secondButtonGroup.map((button, index) => (
                        <button 
                            key={index} 
                            className="styled-button"
                            style={{
                                flexShrink: 0,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {button.icon} {button.label}
                        </button>
                    ))}
                </ButtonGroupWrapper>
            )}

            <style jsx>{`
                .styled-button {
                    display: flex;
                    align-items: center;
                    padding: 6px 10px;
                    border: none;
                    border-radius: 4px;
                    background-color: #007BFF;
                    color: white;
                    cursor: pointer;
                    transition: background-color 0.3s;
                    font-size: 13px;
                }

                .styled-button:hover {
                    background-color: #0056b3;
                }

                .styled-button svg {
                    margin-right: 4px;
                    font-size: 14px;
                }
            `}</style>
        </div>
    );
};

export default ButtonWidget;

import React, { useState, useEffect } from 'react';
import { 
  Box,
  IconButton,
  Tooltip,
  Grid
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 공통 컴포넌트 import
import SearchOptions from '../../Common/SearchOptions';
import QuickMenu from '../../Common/QuickMenu';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';

// 섹션 컴포넌트 import
import CardJipgye from './bunseokCardSection/CardJipgye';
import CardHalbu from './bunseokCardSection/CardHalbu';
import GeoraegmBiyul from './bunseokCardSection/GeoraegmBiyul';
import IlsiHalbuBiyul from './bunseokCardSection/IlsiHalbuBiyul';
import WolbyeolGyesan from './bunseokCardSection/WolbyeolGyesan';
import InjeungGyesan from './bunseokCardSection/InjeungGyesan';
import PgGyesan from './bunseokCardSection/PgGyesan';
import GamaengjeomTop from './bunseokCardSection/GamaengjeomTop';
import PGsaData from './bunseokCardSection/PGsaData';

function BunseokCard() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchParams, setSearchParams] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (params) => {
    setSearchParams(params);
    console.log('Search params:', params);
  };

  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '10px'
    },
    ml: {
      xs: '10px',
      md: '50px'
    },
    '@media (max-width: 960px)': {
      ml: '10px',
      pr: '10px'
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 공지사항 */}
      <Notice />

      {/* 현재 위치 */}
      <CurrentLocation mainTitle="통계" subTitle="카드분석" />
      
      <Box sx={{ mb: 3 }} />
      
      {/* 메인 컨텐츠 영역 */}
      <Grid container spacing={3}>
        {/* 카드집계 - 전체 너비 */}
        <Grid item xs={12}>
          <CardJipgye searchParams={searchParams} />
        </Grid>

        {/* 월별 데이터 (전체 너비) */}
        <Grid item xs={12}>
          <WolbyeolGyesan searchParams={searchParams} />
        </Grid>
        <Grid item xs={12}>
          <InjeungGyesan searchParams={searchParams} />
        </Grid>
        <Grid item xs={12}>
          <PgGyesan searchParams={searchParams} />
        </Grid>

        <Grid item xs={12}>
          <PGsaData searchParams={searchParams} />
        </Grid>

        {/* 2열 그리드 레이아웃 */}
        <Grid item xs={12} md={6}>
          <GeoraegmBiyul searchParams={searchParams} />
        </Grid>
        <Grid item xs={12} md={6}>
          <IlsiHalbuBiyul searchParams={searchParams} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardHalbu searchParams={searchParams} />
        </Grid>
        <Grid item xs={12} md={6}>
          
        </Grid>

        {/* 가맹점 TOP - 전체 너비 */}
        <Grid item xs={12}>
          <GamaengjeomTop searchParams={searchParams} />
        </Grid>
      </Grid>

      <Box sx={{ mb: 3 }} />

      {/* 퀵 메뉴 */}
      <QuickMenu />

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <Tooltip title="검색 패널 열기" placement="left">
          <IconButton
            onClick={() => setSearchPanelOpen(true)}
            sx={{
              position: 'fixed',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#fff',
              borderRadius: '12px 0 0 12px',
              boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              },
              zIndex: 900
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* 검색 패널 */}
      {searchPanelOpen && (
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
        />
      )}
    </Box>
  );
}

export default BunseokCard; 
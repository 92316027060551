import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  styled
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    backgroundColor: '#1976d2',
    color: '#ffffff'
  }
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#90a4ae',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#607d8b',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '4px 8px',
    fontSize: '0.813rem',
  },
});

const InputValidationTable = () => {
  // 더미 데이터
  const rows = [
    {
      date: '2024-12-11',
      no: '1',
      pg: '페이업',
      count: '102',
      sales: '19,142,160',
      inputFee: '',
      calculatedValue: '결과 표시',
      amountInput: '',
      difference: '결과 표시',
      differenceRate: '결과 표시'
    },
    {
      date: '2024-12-11',
      no: '2',
      pg: '갤럭시아',
      count: '140',
      sales: '10,198,132',
      inputFee: '',
      calculatedValue: '결과 표시',
      amountInput: '',
      difference: '결과 표시',
      differenceRate: '결과 표시'
    },
    {
      date: '2024-12-11',
      no: '3',
      pg: 'SPC',
      count: '13',
      sales: '5,874,080',
      inputFee: '',
      calculatedValue: '결과 표시',
      amountInput: '',
      difference: '결과 표시',
      differenceRate: '결과 표시'
    },
    {
      date: '2024-12-11',
      no: '4',
      pg: '다페이',
      count: '6',
      sales: '4,630,000',
      inputFee: '',
      calculatedValue: '결과 표시',
      amountInput: '',
      difference: '결과 표시',
      differenceRate: '결과 표시'
    },
    {
      date: '2024-12-11',
      no: '5',
      pg: '다날',
      count: '1',
      sales: '256,300',
      inputFee: '',
      calculatedValue: '결과 표시',
      amountInput: '',
      difference: '결과 표시',
      differenceRate: '결과 표시'
    }
  ];

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '4px',
        },
      }}
    >
      <Table size="small" sx={{ minWidth: 1000 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>날짜</StyledTableCell>
            <StyledTableCell>NO</StyledTableCell>
            <StyledTableCell>PG구분</StyledTableCell>
            <StyledTableCell align="right">건수합계</StyledTableCell>
            <StyledTableCell align="right">매출합계</StyledTableCell>
            <StyledTableCell>입력수수료</StyledTableCell>
            <StyledTableCell>산출값</StyledTableCell>
            <StyledTableCell>금액입력</StyledTableCell>
            <StyledTableCell>차액</StyledTableCell>
            <StyledTableCell>차액율</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow 
              key={index}
              sx={{ 
                '&:nth-of-type(odd)': { 
                  backgroundColor: '#ffffff',
                },
                '&:nth-of-type(even)': { 
                  backgroundColor: '#e9ecef',
                },
                '&:hover': {
                  backgroundColor: '#dfe3e6',
                },
                transition: 'background-color 0.2s ease',
              }}
            >
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell>{row.no}</StyledTableCell>
              <StyledTableCell>{row.pg}</StyledTableCell>
              <StyledTableCell align="right">{row.count}</StyledTableCell>
              <StyledTableCell align="right">{row.sales}</StyledTableCell>
              <StyledTableCell>
                <StyledTextField
                  size="small"
                  placeholder="값 입력"
                  fullWidth
                  variant="outlined"
                />
              </StyledTableCell>
              <StyledTableCell>{row.calculatedValue}</StyledTableCell>
              <StyledTableCell>
                <StyledTextField
                  size="small"
                  placeholder="값 입력"
                  fullWidth
                  variant="outlined"
                />
              </StyledTableCell>
              <StyledTableCell>{row.difference}</StyledTableCell>
              <StyledTableCell>{row.differenceRate}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InputValidationTable;

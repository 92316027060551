import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';

const AccountNoButton = ({ onOptionClick, selectedItems = [] }) => {
  const handleOptionClick = (optionType) => {
    console.log(`선택된 항목 수: ${selectedItems.length}, 클릭한 버튼: ${optionType}`);
    
    if (selectedItems.length === 0) {
      alert('항목을 선택해주세요.');
      return;
    }
    
    onOptionClick?.(optionType, selectedItems);
  };

  const buttons = [
    { 
      label: '신청', 
      icon: <CheckCircleIcon />,
      color: '#4caf50',  // 초록색
      onClick: () => handleOptionClick('신청')
    },
    { 
      label: '반려', 
      icon: <CancelIcon />,
      color: '#f44336',  // 빨간색
      onClick: () => handleOptionClick('반려')
    },
    { 
      label: '삭제요청', 
      icon: <DeleteForeverIcon />,
      color: '#ff9800',  // 주황색
      onClick: () => handleOptionClick('삭제요청')
    },
    { 
      label: '임시저장', 
      icon: <SaveIcon />,
      color: '#2196f3',  // 파란색
      onClick: () => handleOptionClick('임시저장')
    }
  ];

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontSize: '0.875rem', 
          fontWeight: 600,
          color: '#495057',
          mb: 1.5,
          pl: '4px'
        }}
      >
        계정 상태 옵션
      </Typography>
      <Box sx={{ 
        display: 'flex',
        gap: 1
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={button.onClick}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.75rem',
              height: '32px',
              padding: '4px 12px',
              minWidth: 'auto',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: button.color,
                color: '#fff',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
              },
              '& .MuiButton-startIcon': {
                marginRight: '4px',
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default AccountNoButton;

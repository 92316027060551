import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Button,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일 컴포넌트
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '16px',
    paddingBottom: '8px'
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  width: 'auto',
  '& .MuiFormLabel-root': {
    minWidth: '120px',
    color: '#34495e',
    fontWeight: '500',
    fontSize: '0.9rem',
    transform: 'none',
    position: 'static',
    '&.Mui-focused': {
      color: '#3498db'
    }
  },
  '& .MuiInputBase-root': {
    width: '300px',
    transition: 'all 0.3s ease',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#f8f9fa'
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& fieldset': {
        borderWidth: '2px',
        borderColor: '#3498db'
      }
    }
  },
  
  // 모바일에서만 적용되는 스타일
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    marginBottom: '8px',
    
    '& .MuiFormLabel-root': {
      minWidth: 'auto',
      fontSize: '0.85rem',
      marginBottom: '2px'
    },
    
    '& .MuiInputBase-root': {
      width: '100%'
    },
    
    '& .MuiSelect-root': {
      width: '100%'
    }
  }
}));

// MenuItem 스타일 개선을 위한 추가 컴포넌트
const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.875rem',
  padding: '8px 16px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: '#f1f5f9'
  },
  '&.Mui-selected': {
    backgroundColor: '#e8f4fd',
    '&:hover': {
      backgroundColor: '#dbeafe'
    }
  }
});

// 버튼 스타일 추가
const StyledButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 2px 8px rgba(52, 152, 219, 0.3)'
  }
});

// 주소 입력을 위한 특별한 FormControl
const AddressFormControl = styled(StyledFormControl)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: 'auto'
  },
  
  [theme.breakpoints.down('sm')]: {
    '& .MuiBox-root': {
      width: '100%',
      display: 'flex',
      gap: '8px',
      
      '& .MuiTextField-root': {
        flex: 1
      },
      
      '& .MuiButton-root': {
        minWidth: '80px'
      }
    }
  }
}));

const Gibon = () => {
  useEffect(() => {
    // 카카오 우편번호 스크립트 로드
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // 우편번호 검색 함수
  const handlePostcode = () => {
    new window.daum.Postcode({
      oncomplete: function(data) {
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분
        document.querySelector('input[name="postcode"]').value = data.zonecode;
        document.querySelector('input[name="address"]').value = data.address;
        document.querySelector('input[name="detailAddress"]').focus();
      }
    }).open();
  };

  return (
    <StyledPaper>
      <SectionTitle>기본정보</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledFormControl>
            <InputLabel>사용자 유형*</InputLabel>
            <Select
              size="small"
              defaultValue="레벨선택"
            >
              <StyledMenuItem value="레벨선택">레벨 선택</StyledMenuItem>
              <StyledMenuItem value="사업자">사업자</StyledMenuItem>
              <StyledMenuItem value="과세">과세</StyledMenuItem>
            </Select>
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>사업자/개인*</InputLabel>
            <Select
              size="small"
              defaultValue="사업자"
            >
              <MenuItem value="사업자">사업자</MenuItem>
            </Select>
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>과세*</InputLabel>
            <Select
              size="small"
              defaultValue="과세"
            >
              <MenuItem value="과세">과세</MenuItem>
            </Select>
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>원천*</InputLabel>
            <Select
              size="small"
              defaultValue="비원천"
            >
              <MenuItem value="비원천">비원천</MenuItem>
            </Select>
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>발행품목</InputLabel>
            <Select
              size="small"
              defaultValue="솔루션이용료"
            >
              <MenuItem value="슬립영수증">솔루션이용료</MenuItem>
            </Select>
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>가맹점명*</InputLabel>
            <TextField
              size="small"
              placeholder="가맹점 입력시 12자이내로 권장 드립니다."
              sx={{
                '& .MuiInputBase-input': {  // 입력 텍스트 크기
                  fontSize: '0.875rem',      // 14px
                },
                '& .MuiInputBase-input::placeholder': {  // placeholder 텍스트 크기
                  fontSize: '0.75rem',       // 12px
                  opacity: 0.8
                }
              }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>대표자명*</InputLabel>
            <TextField
              size="small"
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>사업자등록번호*</InputLabel>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>주민번호</InputLabel>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>업종*</InputLabel>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>품목*</InputLabel>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>연락처</InputLabel>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>핸드폰번호</InputLabel>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel>이메일*</InputLabel>
            <TextField
              size="small"
              placeholder="example@naver.com"
              sx={{ flex: 1 }}
            />
          </StyledFormControl>

          <AddressFormControl>
            <InputLabel>주소</InputLabel>
            <Box sx={{ 
              display: 'flex', 
              gap: 1, 
              width: '300px'
            }}>
              <TextField 
                size="small"
                name="postcode"
                sx={{ width: '180px' }}
                placeholder="우편번호"
                inputProps={{ readOnly: true }}
              />
              <StyledButton 
                variant="contained" 
                size="small"
                onClick={handlePostcode}
                sx={{
                  width: '110px',
                  backgroundColor: '#3498db',
                  '&:hover': {
                    backgroundColor: '#2980b9'
                  }
                }}
              >
                우편번호
              </StyledButton>
            </Box>
          </AddressFormControl>

          <StyledFormControl>
            <InputLabel></InputLabel>
            <TextField
              size="small"
              name="address"
              placeholder="주소"
              inputProps={{ readOnly: true }}
            />
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel></InputLabel>
            <TextField
              size="small"
              name="detailAddress"
              placeholder="상세주소"
            />
          </StyledFormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default Gibon;

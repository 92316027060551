import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// PG사별 데이터 컴포넌트
function PGsaData() {
  // 예시 데이터
  const pgData = [
    { pgName: '페이넘', count: 217, amount: 65324590, cancelAmount: -19004, totalAmount: 65343594 },
    { pgName: '다날', count: 8, amount: 18695000, cancelAmount: 0, totalAmount: 18695000 },
    { pgName: '페이히어', count: 2, amount: 0, cancelAmount: -100000, totalAmount: 100000 },
    { pgName: 'SPC', count: 38, amount: 6110012, cancelAmount: -551700, totalAmount: 6661712 },
    { pgName: '갤럭시아', count: 87, amount: 9573700, cancelAmount: -110000, totalAmount: 9683700 }
  ];

  return (
    <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, fontSize: '1rem', fontWeight: 600 }}>
        ● PG사별 결제금액
      </Typography>
      
      <StyledTableWrapper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell className="primary-header sticky-column">PG사</StyledTableCell>
                <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                <StyledTableCell className="primary-header">실매출금액</StyledTableCell>
                <StyledTableCell className="primary-header">취소금액</StyledTableCell>
                <StyledTableCell className="primary-header">결제금액</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pgData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="sticky-column">{row.pgName}</StyledTableCell>
                  <StyledTableCell align="center">{row.count}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.amount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ color: row.cancelAmount < 0 ? '#ff0000' : 'inherit' }}>
                    {row.cancelAmount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.totalAmount.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default PGsaData; 
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Paper,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Drawer,
  TableSortLabel,

  
  FormLabel
} from '@mui/material';
import { Search as SearchIcon, Download as DownloadIcon, Today as TodayIcon, Event as EventIcon, DateRange as DateRangeIcon, CalendarMonth as CalendarMonthIcon, CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from '../../Sidebar';
import Notice from '../../Notice/Notice';
import FailHistoryTable from './FailHistoryTable';
import DateRangePicker from '../../Common/DateRangePicker';
import Breadcrumb from '../../Common/Breadcrumb';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';
import styled from '@emotion/styled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dayjs from 'dayjs';
// import SearchOptions from '../../Common/SearchOptions';

const dummyData = [
  {
    no: 1,
    pg: 'KSNET',
    mid: 'KS0001234',
    loginId: 'test_user1',
    company: '테스트상점1',
    transactionDate: '2024-03-19 14:30:25',
    amount: 50000,
    resultMessage: '카드번호 오류',
    buyerName: '홍길동',
    cardCompany: '삼성카드',
    cardNumber: '5412-****-****-1234',
    expiryDate: '12/25',
    installment: 0,
    paymentMethod: '신용카드',
    authType: '인증',
    trackId: 'TRK2024031900001'
  },
  {
    no: 2,
    pg: 'NICE',
    mid: 'NC0005678',
    loginId: 'test_user2',
    company: '테스트상점2',
    transactionDate: '2024-03-19 14:28:15',
    amount: 30000,
    resultMessage: '한도초과',
    buyerName: '김철수',
    cardCompany: '현대카드',
    cardNumber: '4921-****-****-5678',
    expiryDate: '09/26',
    installment: 3,
    paymentMethod: '신용카드',
    authType: '비인증',
    trackId: 'TRK2024031900002'
  },
  {
    no: 3,
    loginId: "user789",
    company: "테스트상점3",
    tid: "T3456789012",
    mid: "M765432109",
    authType: "인증",
    cardCompany: '삼성카드',
    paymentType: "정기결제",
    pg: 'KSNET',
    transactionDate: "2024-01-15 16:20:45",
    amount: "500,000",
    transactionStatus: "승인",
    cardNumber: "234567******5678",
    expiryDate: "08/27",
    installment: "일시불",
    paymentMethod: "신용카드",
    buyerName: "이영희",
    resultMessage: "정상처리되었습니다",
    trackId: "TR345678901"
  },
  {
    no: 4,
    loginId: "user101",
    company: "테스트상점4",
    tid: "T4567890123",
    mid: "M654321098",
    authType: "비인증",
    paymentType: "수기결제",
    cardCompany: '삼성카드',
    transactionDate: "2024-01-15 17:10:15",
    amount: "1,500,000",
    transactionStatus: "승인",
    pg: 'KSNET',
    cardNumber: "345678******6789",
    expiryDate: "03/26",
    installment: "6개월",
    paymentMethod: "신용카드",
    buyerName: "박민수",
    resultMessage: "정상처리되었습니다",
    trackId: "TR456789012"
  },
  {
    no: 5,
    loginId: "user202",
    company: "테스트상점5",
    tid: "T5678901234",
    pg: 'KSNET',
    mid: "M543210987",
    cardCompany: '삼성카드',
    authType: "인증",
    paymentType: "온라인결제",
    transactionDate: "2024-01-15 18:05:33",
    amount: "2,000,000",
    transactionStatus: "승인",
    cardNumber: "456789******7890",
    expiryDate: "07/25",
    installment: "12개월",
    paymentMethod: "신용카드",
    buyerName: "최지은",
    resultMessage: "정상처리되었습니다",
    trackId: "TR567890123"
  },
  {
    no: 6,
    loginId: "user303",
    company: "테스트상점6",
    tid: "T6789012345",
    cardCompany: '삼성카드',
    mid: "M432109876",
    pg: 'KSNET',
    authType: "비인증",
    paymentType: "정기결제",
    transactionDate: "2024-01-15 19:25:50",
    amount: "800,000",
    transactionStatus: "승인",
    cardNumber: "567890******8901",
    expiryDate: "10/26",
    installment: "일시불",
    paymentMethod: "신용카드",
    buyerName: "강동원",
    resultMessage: "정상처리되었습니다",
    trackId: "TR678901234"
  }
];

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    ml: '40px',  // QuickMenu 기준
    mr: '40px',  // SearchPanel 없음
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    '@media (max-width: 960px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: '100%', 
    mb: 2 
  }
};

// BonNote 스타일 규칙 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.amount': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.negative': {
    color: '#dc3545',
    textAlign: 'right'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

// 검색 섹션 래퍼 스타일 추가
const StyledSearchWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  '@media (max-width: 600px)': {
    flexDirection: 'row',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: '8px 0',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 테이블 래퍼 스타일 수정
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  },
  '@media (max-width: 600px)': {
    maxHeight: 'none',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
});

// 테이블 헤더 정의
const headers = [
  // 파란색 그룹 (primary-header)
  { id: 'no', label: 'No.', color: 'primary' },
  { id: 'pg', label: 'PG', color: 'primary' },
  { id: 'mid', label: 'MID', color: 'primary' },
  { id: 'loginId', label: 'LOGIN ID', color: 'primary' },
  { id: 'company', label: '상호명', color: 'primary' },
  { id: 'transactionDate', label: '거래일시', color: 'primary' },
  { id: 'amount', label: '금액', color: 'primary' },
  { id: 'resultMessage', label: '결과메시지', color: 'primary' },
  { id: 'buyerName', label: '구매자명', color: 'primary' },
  
  // 하늘색 그룹 (secondary-header)
  { id: 'cardCompany', label: '카드사', color: 'secondary' },
  { id: 'cardNumber', label: '카드번호', color: 'secondary' },
  { id: 'expiryDate', label: '유효기간', color: 'secondary' },
  { id: 'installment', label: '할부개월수', color: 'secondary' },
  
  // 흰색 그룹
  { id: 'paymentMethod', label: '결제수단', color: 'default' },
  { id: 'authType', label: '인증방식', color: 'default' },
  { id: 'trackId', label: 'Track ID', color: 'default' }
];

// PG사 목록
const pgList = ['KSNET', 'NICE', 'KCP', 'KICC', 'DAOU'];

// 카드사 목록
const cardCompanyList = ['삼성카드', '현대카드', '신한카드', '비씨카드', '롯데카드', '하나카드', '국민카드'];

function FailHistory() {
  const [sortColumn, setSortColumn] = useState('transactionDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState('2024-11-28');
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [cardCompany, setCardCompany] = useState('');
  const [installment, setInstallment] = useState('');
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 800 && window.innerWidth <= 1025);
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth < 500);
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [searchText1, setSearchText1] = useState('');
  const [searchText2, setSearchText2] = useState('');
  const [authType, setAuthType] = useState('인증');

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 800 && window.innerWidth <= 1025);
      setIsMobile(window.innerWidth < 960);
      setIsSmallMobile(window.innerWidth < 500);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedData = React.useMemo(() => {
    if (!sortColumn) return dummyData;

    return [...dummyData].sort((a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      // 숫자 필드 처리
      if (['amount', 'installment'].includes(sortColumn)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }
      
      // 날짜 필드 처리
      if (sortColumn === 'transactionDate') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [dummyData, sortColumn, sortDirection]);

  const handleRowClick = (row) => {
    if (isSmallScreen) {
      setSelectedRow(row);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const mainBoxStyle = {
    ...styles.mainBox,
    mx: { xs: '20px', md: '20px' },
    pl: { xs: '40px', md: '40px' },
    pr: { xs: '40px', md: '40px' },
    width: { xs: 'calc(100% - 40px)', md: 'calc(100% - 40px)' },
    transition: 'margin 0.3s ease',
    '@media (max-width: 960px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  };

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    // 실제 검색 로직 구현
  };

  // 날짜 아이콘 클릭 핸들러
  const handleDateIconClick = (type) => {
    const today = dayjs();
    
    switch(type) {
      case 'today':
        setStartDate(today.format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'yesterday':
        const yesterday = today.subtract(1, 'day');
        setStartDate(yesterday.format('YYYY-MM-DD'));
        setEndDate(yesterday.format('YYYY-MM-DD'));
        break;
      case 'last7days':
        setStartDate(today.subtract(6, 'day').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'lastMonth':
        const lastMonth = today.subtract(1, 'month');
        setStartDate(lastMonth.startOf('month').format('YYYY-MM-DD'));
        setEndDate(lastMonth.endOf('month').format('YYYY-MM-DD'));
        break;
      case 'thisMonth':
        setStartDate(today.startOf('month').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
    }
  };

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="실패내역" />
        {/* 검색 섹션 */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          mb: 3,
          flexWrap: 'wrap',
          alignItems: 'center'
        }}>
          {/* DateRangePicker */}
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />

          {/* 날짜 아이콘 그룹 */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            gap: 2
          }}>
            <Tooltip title="오늘"><TodayIcon sx={{ cursor: 'pointer' }} onClick={() => handleDateIconClick('today')} /></Tooltip>
            <Tooltip title="어제"><EventIcon sx={{ cursor: 'pointer' }} onClick={() => handleDateIconClick('yesterday')} /></Tooltip>
            <Tooltip title="지난 7일"><DateRangeIcon sx={{ cursor: 'pointer' }} onClick={() => handleDateIconClick('last7days')} /></Tooltip>
            <Tooltip title="지난달"><CalendarMonthIcon sx={{ cursor: 'pointer' }} onClick={() => handleDateIconClick('lastMonth')} /></Tooltip>
            <Tooltip title="이번달"><CalendarTodayIcon sx={{ cursor: 'pointer' }} onClick={() => handleDateIconClick('thisMonth')} /></Tooltip>
          </Box>

          {/* 검색 필드 */}
          <TextField
            placeholder="MID, TID, 가맹점명, 상품명, 승인번호, 예금주"
            size="small"
            value={searchText1}
            onChange={(e) => setSearchText1(e.target.value)}
            sx={{ flexGrow: 1 }}
          />

          <TextField
            placeholder="결과메세지 검색"
            size="small"
            value={searchText2}
            onChange={(e) => setSearchText2(e.target.value)}
            sx={{ width: '200px' }}
          />

          {/* 인증/비인증 라디오 그룹 */}
          <FormControl>
            <RadioGroup
              row
              value={authType}
              onChange={(e) => setAuthType(e.target.value)}
            >
              <FormControlLabel 
                value="인증" 
                control={<Radio size="small" />} 
                label="인증"
                sx={{ mr: 1 }}
              />
              <FormControlLabel 
                value="비인증" 
                control={<Radio size="small" />} 
                label="비인증"
              />
            </RadioGroup>
          </FormControl>

          {/* 검색 버튼 */}
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={() => {/* 검색 로직 구현 */}}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>
        </Box>
        <Box sx={{ mb: 2 }} />
        <TableContainer component={StyledTableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <StyledTableCell
                    key={header.id}
                    className={`MuiTableCell-head ${
                      header.color === 'primary' ? 'primary-header' :
                      header.color === 'secondary' ? 'secondary-header' : ''
                    }`}
                    onClick={() => handleSort(header.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {header.label}
                    {sortColumn === header.id && (
                      <Box component="span" sx={{ ml: 0.5 }}>
                        {sortDirection === 'asc' ? '▲' : '▼'}
                      </Box>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <StyledTableRow 
                  key={index}
                  onClick={() => handleRowClick(row)}
                  hover
                >
                  {headers.map((header) => (
                    <StyledTableCell
                      key={`${index}-${header.id}`}
                      align={
                        ['amount'].includes(header.id) 
                          ? 'right'
                          : ['no', 'transactionDate', 'installment', 'cardCompany', 'authType'].includes(header.id)
                            ? 'center'
                            : 'left'
                      }
                      className={
                        ['amount'].includes(header.id)
                          ? 'amount'
                          : ''
                      }
                    >
                      {header.id === 'amount' 
                        ? row[header.id].toLocaleString() + '원'
                        : header.id === 'installment'
                          ? row[header.id] === 0 
                            ? '일시불' 
                            : row[header.id] + '개월'
                          : row[header.id]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default FailHistory;

import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popover,
  Chip,
  MenuItem,
  IconButton,
  Tooltip
} from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DownloadIcon from '@mui/icons-material/Download';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const CashdamdangSearch = ({ onSearch, onClose, onExcelDownload, isMobile }) => {
  // 기본 검색 필드
  const [searchFields, setSearchFields] = useState({
    companyName: '',    // 상호명
    ownerName: '',     // 대표자명
    businessNumber: '', // 사업자번호
    phoneNumber: '',    // 전화번호
    email: '',         // 이메일
  });

  // 필요한 라디오 버튼 상태만 유지
  const [taxType, setTaxType] = useState('');         // 과세/비과세
  const [sourceType, setSourceType] = useState('');   // 원천/비원천
  const [loginPermission, setLoginPermission] = useState(''); // 로그인 허용
  const [dataCount, setDataCount] = useState('50');

  // 팝오버 상태
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedToggleOptions, setSelectedToggleOptions] = useState([]);
  const [toggleSearchText, setToggleSearchText] = useState('');
  const [filteredToggleOptions, setFilteredToggleOptions] = useState([]);

  // 검색 옵션 데이터 수정
  const searchOptions = [
    { value: 'master', label: '마스터검색' },
    { value: 'branch', label: '지사검색' },
    { value: 'agency', label: '대리점검색' },
    { value: 'userType', label: '사용자 유형' },
    { value: 'settlementDate', label: '정산일' },
    { value: 'status', label: '상태여부' }
  ];

  // 토글 옵션 데이터 수정
  const toggleOptions = {
    userType: [
      { value: 'admin', label: '관리자' },
      { value: 'user', label: '일반사용자' },
      { value: 'guest', label: '게스트' }
    ],
    settlementDate: [
      { value: 'instant', label: '즉시' },
      { value: 'd1', label: 'D+1' },
      { value: 'd2', label: 'D+2' },
      { value: 'd3', label: 'D+3' }
    ],
    status: [
      { value: 'active', label: '활성' },
      { value: 'inactive', label: '비활성' },
      { value: 'pending', label: '대기' }
    ]
  };

  // 스타일 정의
  const styles = {
    radioGroupsContainer: {
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      p: 1,
      '& > div': {
        backgroundColor: 'transparent',
        mb: 0.5,
        '&:last-child': {
          mb: 0
        }
      }
    },
    radioGroupLabel: {
      fontSize: '0.8rem',
      color: '#495057',
      mb: 0.2,
      ml: 0.5
    },
    radioGroup: {
      ml: 0.5,
      '& .MuiFormControlLabel-root': {
        marginRight: 1.5,
        marginY: 0,
        '& .MuiTypography-root': {
          fontSize: '0.8rem'
        }
      },
      '& .MuiRadio-root': {
        padding: '4px'
      }
    }
  };

  // 검색 필드 변경 핸들러
  const handleSearchFieldChange = (field) => (event) => {
    setSearchFields(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  // 검색 제출 핸들러
  const handleSubmit = () => {
    const searchData = {
      ...searchFields,
      taxType,
      sourceType,
      loginPermission,
      dataCount
    };
    onSearch(searchData);
  };

  // 검색옵션 내부 검색 핸들러
  const handleToggleSearch = (e) => {
    const searchText = e.target.value;
    setToggleSearchText(searchText);

    if (selectedOption && toggleOptions[selectedOption]) {
      const filtered = toggleOptions[selectedOption].filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredToggleOptions(filtered);
    }
  };

  // 검색 옵션 클릭 핸들러
  const handleSearchOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // 검색 옵션 닫기 핸들러
  const handleSearchOptionClose = () => {
    setAnchorEl(null);
    setSelectedOption('');
    setToggleSearchText('');
  };

  // 옵션 선택 핸들러
  const handleOptionSelect = (option) => {
    setSelectedOption(option.value);
    setFilteredToggleOptions(toggleOptions[option.value] || []);
    setToggleSearchText('');
  };

  // 패널이 닫힐 때 포커스 해제
  const handleClose = () => {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    onClose();
  };

  // 필터 제거 핸들러 추가
  const removeFilter = (filterToRemove) => {
    const [category] = filterToRemove.split(': ');
    
    setActiveFilters(prev => 
      prev.filter(filter => filter !== filterToRemove)
    );
    
    // 라디오 버튼 상태 초기화
    switch(category) {
      case '과세구분':
        setTaxType('');
        break;
      case '원천구분':
        setSourceType('');
        break;
      case '로그인':
        setLoginPermission('');
        break;
      default:
        break;
    }
  };

  // 라디오 버튼 변경 핸들러 추가
  const handleRadioChange = (type, value) => {
    let filterLabel = '';
    let currentValue = '';
    let newState = {};
    
    switch(type) {
      case 'tax':
        currentValue = taxType;
        newState = { taxType: value === currentValue ? '' : value };
        setTaxType(newState.taxType);
        filterLabel = `과세구분: ${value === 'taxable' ? '과세' : '비과세'}`;
        break;
      case 'source':
        currentValue = sourceType;
        newState = { sourceType: value === currentValue ? '' : value };
        setSourceType(newState.sourceType);
        filterLabel = `원천구분: ${value === 'withholding' ? '원천' : '비원천'}`;
        break;
      case 'loginPermission':
        currentValue = loginPermission;
        newState = { loginPermission: value === currentValue ? '' : value };
        setLoginPermission(newState.loginPermission);
        filterLabel = `로그인: ${value === 'allowed' ? '허용' : '차단'}`;
        break;
      default:
        return;
    }

    // 필터 업데이트
    if (newState[Object.keys(newState)[0]]) {
      setActiveFilters(prev => {
        const category = filterLabel.split(': ')[0];
        const filtered = prev.filter(filter => !filter.startsWith(category));
        return [...filtered, filterLabel];
      });
    } else {
      setActiveFilters(prev => 
        prev.filter(filter => !filter.startsWith(filterLabel.split(': ')[0]))
      );
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ 
        position: 'fixed',
        right: 0,
        top: '80px',
        width: '300px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px 0 0 12px',
        boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
        pl: 2,
        pr: '5px',
        pt: 2,
        pb: 2,
        height: 'calc(100vh - 100px)',
        zIndex: 900,
        '@media (max-width: 600px)': {
          overflowY: 'auto',
          overflowX: 'visible',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}>
        {/* 닫기 버튼 위치 수정 */}
        <IconButton 
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: '-20px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            zIndex: 1001,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            '@media (max-width: 600px)': {
              zIndex: 1001,
              left: '15px',
              position: 'fixed'
            }
          }}
        >
          <ChevronRight />
        </IconButton>

        {/* 검색 패널 컨텐츠 */}
        <Box sx={{ p: 1.5 }}>
          <Typography variant="subtitle2" sx={{ mb: 1.5, fontSize: '0.875rem' }}>검색 조건</Typography>
          
          {/* 텍스트 필드들 */}
          <TextField
            fullWidth
            size="small"
            label="상호명, 대표자명, 아이디"
            value={searchFields.companyName}
            onChange={(e) => setSearchFields(prev => ({ ...prev, companyName: e.target.value }))}
            sx={{ mb: 1.2 }}
          />
          <TextField
            fullWidth
            size="small"
            label="예금주, 계좌번호"
            value={searchFields.ownerName}
            onChange={(e) => setSearchFields(prev => ({ ...prev, ownerName: e.target.value }))}
            sx={{ mb: 1.2 }}
          />

          {/* 검색 옵션 선택 버튼 */}
          <Button
            variant="outlined"
            fullWidth
            onClick={handleSearchOptionClick}
            sx={{ mb: 2 }}
          >
            검색옵션 선택
          </Button>

          {/* 라디오 버튼 그룹들 */}
          <Box sx={styles.radioGroupsContainer}>
            <Box>
              <Typography sx={styles.radioGroupLabel}>과세/비과세</Typography>
              <RadioGroup 
                row 
                value={taxType}
                onChange={(e) => handleRadioChange('tax', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="taxable" control={<Radio size="small" />} label="과세" />
                <FormControlLabel value="non-taxable" control={<Radio size="small" />} label="비과세" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>원천/비원천</Typography>
              <RadioGroup 
                row 
                value={sourceType}
                onChange={(e) => handleRadioChange('source', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="withholding" control={<Radio size="small" />} label="원천" />
                <FormControlLabel value="non-withholding" control={<Radio size="small" />} label="비원천" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography sx={styles.radioGroupLabel}>로그인 허용</Typography>
              <RadioGroup 
                row 
                value={loginPermission}
                onChange={(e) => handleRadioChange('loginPermission', e.target.value)}
                sx={styles.radioGroup}
              >
                <FormControlLabel value="allowed" control={<Radio size="small" />} label="허용" />
                <FormControlLabel value="denied" control={<Radio size="small" />} label="차단" />
              </RadioGroup>
            </Box>
          </Box>

          {/* 데이터 개수 선택 */}
          <TextField
            select
            fullWidth
            size="small"
            label="데이터 개수"
            value={dataCount}
            onChange={(e) => setDataCount(e.target.value)}
            sx={{ 
              mt: 1.2, 
              mb: 1.2,
              '& .MuiInputLabel-root': {
                fontSize: '0.825rem'
              },
              '& .MuiSelect-select': {
                fontSize: '0.825rem',
                padding: '6px 14px'
              }
            }}
          >
            {[10, 20, 50, 100].map(num => (
              <MenuItem key={num} value={num} sx={{ fontSize: '0.825rem' }}>{num}개</MenuItem>
            ))}
          </TextField>

          {/* 버튼 그룹 */}
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 1, 
            mt: 0.5
          }}>
            {/* 검색 버튼 */}
            <Button 
              variant="contained" 
              onClick={handleSubmit}
              sx={{ 
                fontSize: '0.825rem', 
                py: 0.8,
                width: '100%'
              }}
            >
              검색
            </Button>

            {/* 다운로드 버튼 그룹 */}
            <Box sx={{ 
              display: 'flex', 
              gap: 0.5,
              justifyContent: 'flex-end'
            }}>
              <Tooltip title="엑셀 다운로드">
                <IconButton
                  onClick={onExcelDownload}
                  sx={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="계정 종합 표">
                <IconButton
                  onClick={onExcelDownload}
                  sx={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: '#fff',
                    border: '1px solid #1976d2',
                    color: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1976d210',
                    }
                  }}
                >
                  <SummarizeIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* 검색 옵션 팝오버 */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleSearchOptionClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 2, width: 250 }}>
              {selectedOption ? (
                <>
                  <TextField
                    size="small"
                    placeholder="검색..."
                    value={toggleSearchText}
                    onChange={handleToggleSearch}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                    {filteredToggleOptions.map((option) => (
                      <Button
                        key={option.value}
                        fullWidth
                        onClick={() => {
                          setActiveFilters(prev => [...prev, `${searchOptions.find(so => so.value === selectedOption).label}: ${option.label}`]);
                          handleSearchOptionClose();
                        }}
                        sx={{ justifyContent: 'flex-start', mb: 1 }}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </Box>
                </>
              ) : (
                searchOptions.map((option) => (
                  <Button
                    key={option.value}
                    fullWidth
                    onClick={() => handleOptionSelect(option)}
                    sx={{ justifyContent: 'flex-start', mb: 1 }}
                  >
                    {option.label}
                  </Button>
                ))
              )}
            </Box>
          </Popover>

          {/* 선택된 필터 표시 */}
          {activeFilters.length > 0 && (
            <Box sx={{ mt: 1.2, mb: 1.2 }}>
              <Typography variant="subtitle2" sx={{ mb: 0.8, fontSize: '0.825rem' }}>
                적용된 필터
              </Typography>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 0.8
              }}>
                {activeFilters.map((filter, index) => (
                  <Chip
                    key={index}
                    label={filter}
                    onDelete={() => {
                      setActiveFilters(prev => prev.filter((_, i) => i !== index));
                    }}
                    size="small"
                    sx={{ 
                      width: '100%',
                      height: '24px',
                      '& .MuiChip-label': {
                        fontSize: '0.825rem',
                        px: 1.2
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CashdamdangSearch; 
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 430px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const UserAccessHistory = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [records, setRecords] = useState([
    {
      no: 1,
      date: '2024-12-05 18:24:58',
      domain: 'mainpay.me',
      id: 'm21644470',
      name: '(주)농부꼬물',
      ip: '211.234.206.123',
      loginStatus: '성공'
    },
    {
      no: 2,
      date: '2024-12-05 18:24:17',
      domain: 'mainpay.me',
      id: 'm21644470',
      name: '(주)농부꼬물',
      ip: '211.234.206.123',
      loginStatus: '실패'
    },
    {
      no: 3,
      date: '2024-12-05 18:20:33',
      domain: 'platbill.kr',
      id: 'p20231205',
      name: '테크노마트',
      ip: '123.456.789.012',
      loginStatus: '성공'
    },
    {
      no: 4,
      date: '2024-12-05 18:15:22',
      domain: 'brinecrew.com',
      id: 'admin123',
      name: '관리자',
      ip: '98.765.432.101',
      loginStatus: '성공'
    },
    {
      no: 5,
      date: '2024-12-05 18:10:45',
      domain: 'mainpay.me',
      id: 'm21644471',
      name: '디지털플러스',
      ip: '211.234.206.124',
      loginStatus: '실패'
    },
    {
      no: 6,
      date: '2024-12-05 18:05:12',
      domain: 'platbill.kr',
      id: 'p20231206',
      name: '스마트스토어',
      ip: '123.456.789.013',
      loginStatus: '성공'
    },
    {
      no: 7,
      date: '2024-12-05 18:00:59',
      domain: 'brinecrew.com',
      id: 'user456',
      name: '사용자1',
      ip: '98.765.432.102',
      loginStatus: '실패'
    },
    {
      no: 8,
      date: '2024-12-05 17:55:30',
      domain: 'mainpay.me',
      id: 'm21644472',
      name: '온라인마트',
      ip: '211.234.206.125',
      loginStatus: '성공'
    },
    {
      no: 9,
      date: '2024-12-05 17:50:18',
      domain: 'platbill.kr',
      id: 'p20231207',
      name: '이커머스',
      ip: '123.456.789.014',
      loginStatus: '성공'
    },
    {
      no: 10,
      date: '2024-12-05 17:45:42',
      domain: 'brinecrew.com',
      id: 'user789',
      name: '사용자2',
      ip: '98.765.432.103',
      loginStatus: '성공'
    },
    {
      no: 11,
      date: '2024-12-05 17:40:15',
      domain: 'mainpay.me',
      id: 'm21644473',
      name: '스마트페이',
      ip: '211.234.206.126',
      loginStatus: '실패'
    },
    {
      no: 12,
      date: '2024-12-05 17:35:33',
      domain: 'platbill.kr',
      id: 'p20231208',
      name: '디지털마트',
      ip: '123.456.789.015',
      loginStatus: '성공'
    },
    {
      no: 13,
      date: '2024-12-05 17:30:27',
      domain: 'brinecrew.com',
      id: 'admin789',
      name: '관리자2',
      ip: '98.765.432.104',
      loginStatus: '성공'
    },
    {
      no: 14,
      date: '2024-12-05 17:25:55',
      domain: 'mainpay.me',
      id: 'm21644474',
      name: '페이플러스',
      ip: '211.234.206.127',
      loginStatus: '성공'
    },
    {
      no: 15,
      date: '2024-12-05 17:20:41',
      domain: 'platbill.kr',
      id: 'p20231209',
      name: '스마트뱅크',
      ip: '123.456.789.016',
      loginStatus: '실패'
    },
    {
      no: 16,
      date: '2024-12-05 17:15:38',
      domain: 'brinecrew.com',
      id: 'user101112',
      name: '사용자3',
      ip: '98.765.432.105',
      loginStatus: '성공'
    },
    {
      no: 17,
      date: '2024-12-05 17:10:22',
      domain: 'mainpay.me',
      id: 'm21644475',
      name: '디지털뱅크',
      ip: '211.234.206.128',
      loginStatus: '성공'
    },
    {
      no: 18,
      date: '2024-12-05 17:05:19',
      domain: 'platbill.kr',
      id: 'p20231210',
      name: '온라인뱅크',
      ip: '123.456.789.017',
      loginStatus: '실패'
    },
    {
      no: 19,
      date: '2024-12-05 17:00:44',
      domain: 'brinecrew.com',
      id: 'admin1314',
      name: '관리자3',
      ip: '98.765.432.106',
      loginStatus: '성공'
    },
    {
      no: 20,
      date: '2024-12-05 16:55:30',
      domain: 'mainpay.me',
      id: 'm21644476',
      name: '페이뱅크',
      ip: '211.234.206.129',
      loginStatus: '성공'
    }
  ]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountType, setAccountType] = useState('전체');

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedRecords = [...records].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setRecords(sortedRecords);
  };

  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <PeopleAltIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            통합유저 접속내역
          </Typography>
        </Box>

        <Box sx={{ px: 3, pb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          <TextField
            size="small"
            placeholder="로그인 아이디, IP"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            sx={{ width: '300px' }}
          />
          <RadioGroup
            row
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
            <FormControlLabel value="영업" control={<Radio />} label="영업" />
          </RadioGroup>
          <Tooltip title="엑셀 다운로드">
            <IconButton
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: '1px solid #2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#e8f5e9',
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell 
                  onClick={handleSortClick}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eaeaea'
                    }
                  }}
                >
                  접속일시 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                </StyledTableCell>
                <StyledTableCell>접속 도메인</StyledTableCell>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>상호명</StyledTableCell>
                <StyledTableCell>IP</StyledTableCell>
                <StyledTableCell>로그인여부</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((record, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{record.no}</StyledTableCell>
                  <StyledTableCell>{record.date}</StyledTableCell>
                  <StyledTableCell>{record.domain}</StyledTableCell>
                  <StyledTableCell>{record.id}</StyledTableCell>
                  <StyledTableCell>{record.name}</StyledTableCell>
                  <StyledTableCell>{record.ip}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    color: record.loginStatus === '성공' ? '#2e7d32' : '#d32f2f',
                    fontWeight: 600
                  }}>
                    {record.loginStatus}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </StyledCard>
  );
};

export default UserAccessHistory;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Chip,
  Radio,
  RadioGroup,
  Button,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangePicker from './DateRangePicker';
import DownloadButtons from './DownloadButtons';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dayjs from 'dayjs';

const styles = {
  searchPaper: {
    p: 2,
    mb: 2,
    borderRadius: '12px',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)',
    border: '1px solid #edf2f9',
    width: '100%',
    maxWidth: '1700px'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  topRow: {
    display: 'flex',
    gap: 2,
    pb: 1.5,
    pt: 0.5,
    borderBottom: '1px solid #edf2f9',
    alignItems: 'center'
  },
  searchFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    minWidth: 'max-content'
  },
  searchFieldsTop: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    height: '36px',
    mb: 0.5
  },
  searchFieldsBottom: {
    display: 'flex',
    alignItems: 'center',
    height: '34px',
    marginTop: '4px'
  },
  radioContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.35,
    rowGap: 1,
    alignContent: 'flex-start',
    minHeight: '70px',
    paddingTop: '4px',
    paddingLeft: '8px',
    paddingBottom: '4px',
    flex: 1,
    overflow: 'visible'
  },
  radioGroupBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  radioGroupLabel: {
    fontSize: '0.875rem',
    color: '#475569',
    fontWeight: 600,
    minWidth: '85px',
  },
  radioGroup: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
  },
  radioOption: {
    margin: '0 8px 0 0',
    '& .MuiFormControlLabel-label': { 
      fontSize: '0.9rem'
    },
    '& .MuiRadio-root': { 
      marginRight: '2px',
      '& .MuiSvgIcon-root': { 
        fontSize: 18 
      },
      padding: '4px'
    }
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    borderTop: '1px solid #edf2f9',
    pt: 2
  },
  dateField: {
    '& .MuiInputBase-root': {
      height: '34px'
    }
  },
  searchField: {
    '& .MuiInputBase-root': {
      height: '34px'
    }
  },
  filterChips: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    p: 2,
    backgroundColor: '#f8f9fa',
    borderRadius: '8px'
  },
  lastRadioGroupBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.75,
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    py: 0.75,
    px: 1.5,
    border: '1px solid #edf2f9',
    height: '32px',
    flex: '1 0 auto'
  },
  dataCountBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: '100%',
    mt: 2,
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
      fontWeight: 600,
      whiteSpace: 'nowrap'
    }
  },
  dataCountSelect: {
    height: '28px',
    '& .MuiSelect-select': {
      padding: '4px 8px',
      fontSize: '0.85rem'
    }
  },
  excelButton: {
    height: '32px',
    minWidth: '100px',
    textAlign: 'center',
    px: 2,
    '& .MuiButton-startIcon': {
      marginRight: '4px'
    }
  },
  dateIconGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    marginLeft: 2
  },
  dateLabel: {
    fontSize: '0.8rem',
    color: '#000',
    fontWeight: '600'
  },
  dateIcon: {
    width: '32px',
    height: '32px',
    padding: '6px',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#2196f3',
    border: '1px solid #e0e0e0',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#e3f2fd',
      color: '#1976d2',
      border: '1px solid #2196f3',
      transform: 'scale(1.05)'
    }
  }
};

// 새로운 컴포넌트로 분리
const ToggleOptionItem = ({ option, selectedToggleOptions, selectedOption, handleToggleOptionChange }) => {
  const textRef = React.useRef(null);
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);

  React.useEffect(() => {
    if (textRef.current) {
      setIsTextTruncated(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [option.label]);

  const LabelContent = (
    <span
      ref={textRef}
      style={{
        display: 'inline-block',
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
        fontSize: '0.85rem'
      }}
    >
      {option.label}
    </span>
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={selectedToggleOptions.some(
            item => item.value === option.value && item.parentOption === selectedOption
          )}
          onChange={() => handleToggleOptionChange(option)}
          size="small"
        />
      }
      label={
        isTextTruncated ? (
          <Tooltip 
            title={option.label}
            placement="top"
            sx={{
              tooltip: {
                fontSize: '0.813rem',
                padding: '4px 8px',
                maxWidth: '300px'
              }
            }}
          >
            {LabelContent}
          </Tooltip>
        ) : (
          LabelContent
        )
      }
      sx={{
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
        display: 'flex',
        '& .MuiFormControlLabel-label': {
          fontSize: '0.85rem'
        }
      }}
    />
  );
};

function SearchOptions({ onSearch, onClose, onExcelDownload }) {
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [excludeOption, setExcludeOption] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [sourceType, setSourceType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [taxType, setTaxType] = useState('');
  const [searchOptionFilter, setSearchOptionFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([
    { value: 'master', label: '마스터' },
    { value: 'branch', label: '지사' },
    { value: 'distributor', label: '총판' },
    { value: 'agency', label: '대리점' },
    { value: 'retail', label: '리테일' },
    { value: 'sales1', label: '영업1' },
    { value: 'sales2', label: '영업2' },
    { value: 'sales3', label: '영업3' },
    { value: 'paymentType', label: '결제형식' },
    { value: 'authType', label: '인증방식' },
    { value: 'installment', label: '일시불/할부' },
    { value: 'cancelStatus', label: '취소여부' },
    { value: 'merchant', label: '가맹점 검색' },
    { value: 'settlementCycle', label: '정산주기' },
    { value: 'merchantMID', label: '상점MID' },
    { value: 'pgCompany', label: 'PG사별' },
  ]);
  const [toggleOptions, setToggleOptions] = useState({
    master: [
      { value: 'kim1', label: '김지사ㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏㅏ 1' },
      { value: 'lee1', label: '이지사 1' },
      { value: 'park1', label: '박지사 1' }
    ],
    branch: [
      { value: 'park2', label: '박지사 2' },
      { value: 'kim2', label: '김지사 2' }
    ],
    distributor: [
      { value: 'dist1', label: '총판 1' },
      { value: 'dist2', label: '총판 2' }
    ],
    paymentType: [
      { value: 'manual', label: '수기' },
      { value: 'terminal', label: '단말기' },
      { value: 'online', label: '온라인' }
    ],
    authType: [
      { value: 'old', label: '구인증' },
      { value: 'non', label: '비인증' },
      { value: 'auth', label: '인증' }
    ],
    installment: [
      { value: 'single', label: '일시불' },
      { value: '1', label: '1개월' },
      { value: '2', label: '2개월' },
      { value: '3', label: '3개월' },
      { value: '4', label: '4개월' },
      { value: '5', label: '5개월' },
      { value: '6', label: '6개월' },
      { value: '7', label: '7개월' },
      { value: '8', label: '8개월' },
      { value: '9', label: '9개월' },
      { value: '10', label: '10개월' },
      { value: '11', label: '11개월' },
      { value: '12', label: '12개월' }
    ],
    cancelStatus: [
      { value: 'cancel', label: '취소' },
      { value: 'approve', label: '승인' },
      { value: 'excludePeriodCancel', label: '기간내 취소 제외' },
      { value: 'includePeriodCancel', label: '기간내 취소' }
    ],
    merchant: [
      { value: 'merchant1', label: '가맹점 1' },
      { value: 'merchant2', label: '가맹점 2' }
    ],
    settlementCycle: [
      { value: 'instant', label: '즉결' },
      { value: 'd1', label: 'D+1' },
      { value: 'd2', label: 'D+2' },
      { value: 'd3', label: 'D+3' },
      { value: 'd4', label: 'D+4' },
      { value: 'd5', label: 'D+5' },
      { value: 'd6', label: 'D+6' },
      { value: 'd7', label: 'D+7' },
      { value: 'monthly1', label: '월1회' },
      { value: 'monthly2', label: '월2회' }
    ],
    merchantMID: [
      { value: 'spc107836', label: 'SPC_107836' },
      { value: 'galaxiaM2140389', label: 'Galaxia_M2140389' },
      { value: 'galaxiaM2248907', label: 'Galaxia_M2248907' }
    ],
    pgCompany: [
      { value: 'aynil', label: 'AYNIL' },
      { value: 'spc', label: 'SPC' },
      { value: 'first', label: 'First' },
      { value: 'infini', label: 'Infini' },
      { value: 'etou', label: 'EtoU' },
      { value: 'mobil', label: 'Mobil' },
      { value: 'galaxia', label: 'Galaxia' },
      { value: 'kopay', label: 'Kopay' },
      { value: 'omir', label: 'Omir' },
      { value: 'coam', label: 'Coam' },
      { value: 'pluspay', label: 'Pluspay' },
      { value: 'payster', label: 'payster' },
      { value: 'winpay', label: 'winpay' },
      { value: 'payup', label: 'payup' },
      { value: 'winglobal', label: 'winglobal' },
      { value: 'hectokorea', label: 'HECTOKorea' },
      { value: 'danal', label: 'Danal' },
      { value: 'dapay', label: 'Dapay' },
      { value: 'payhub', label: 'Payhub' }
    ],
  });
  const [toggleSearchText, setToggleSearchText] = useState('');
  const [selectedToggleOptions, setSelectedToggleOptions] = useState([]);
  const [depositType, setDepositType] = useState('');
  const [recurringType, setRecurringType] = useState('');
  const [duplicateType, setDuplicateType] = useState('');
  const [amountSortType, setAmountSortType] = useState('');
  const [walletType, setWalletType] = useState('');
  const [filteredToggleOptions, setFilteredToggleOptions] = useState([]);
  const [tempDataCount, setTempDataCount] = useState('50');

  const FILTER_TYPES = {
    SOURCE: 'sourceType',
    BUSINESS: 'businessType',
    TAX: 'taxType',
    DEPOSIT: 'depositType',
    DUPLICATE: 'duplicateType',
    RECURRING: 'recurringType',
    AMOUNT_SORT: 'amountSortType',
    WALLET: 'walletType'
  };

  const searchOptions = [
    { value: 'master', label: '마스터' },
    { value: 'branch', label: '지사' },
    { value: 'distributor', label: '총판' },
    { value: 'agency', label: '대리점' },
    { value: 'retail', label: '리테일' },
    { value: 'sales1', label: '영업1' },
    { value: 'sales2', label: '영업2' },
    { value: 'sales3', label: '영업3' },
    { value: 'paymentType', label: '결제형식' },
    { value: 'authType', label: '인증방식' },
    { value: 'installment', label: '일시불/할부' },
    { value: 'cancelStatus', label: '취소여부' },
    { value: 'merchant', label: '가맹점 검색' },
    { value: 'settlementCycle', label: '정산주기' },
    { value: 'merchantMID', label: '상점MID' },
    { value: 'pgCompany', label: 'PG사별' },
  ];

  const handleSearchOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchOptionClose = () => {
    setAnchorEl(null);
    setSelectedOption('');
    setExcludeOption(false);
  };

  const addFilter = () => {
    if (!selectedOption) return;
    const filterText = `${searchOptions.find(opt => opt.value === selectedOption).label}${excludeOption ? ' (제외)' : ''}`;
    if (!activeFilters.includes(filterText)) {
      setActiveFilters([...activeFilters, filterText]);
    }
    handleSearchOptionClose();
  };

  const removeFilter = (filterToRemove) => {
    const [category, value] = filterToRemove.split(': ');
    const cleanValue = value?.replace(' (제외)', ''); // 제외 텍스트 제거
    
    // 특정 필터만 제거
    setActiveFilters(prev => 
      prev.filter(filter => filter !== filterToRemove)
    );
    
    // 해당 필터의 토글 옵션만 제거
    setSelectedToggleOptions(prev => 
      prev.filter(item => {
        const itemLabel = `${searchOptions.find(opt => opt.value === item.parentOption)?.label}: ${item.label}${excludeOption ? ' (제외)' : ''}`;
        return itemLabel !== filterToRemove;
      })
    );
    
    // 라디오 버튼인 경우에만 상태 초기화
    switch(true) {
      case filterToRemove.startsWith('원천구분:'):
        setSourceType('');
        break;
      case filterToRemove.startsWith('사업자구분:'):
        setBusinessType('');
        break;
      case filterToRemove.startsWith('보증금:'):
        setDepositType('');
        break;
      case filterToRemove.startsWith('중복확인:'):
        setDuplicateType('');
        break;
      case filterToRemove.startsWith('정기결제:'):
        setRecurringType('');
        break;
      case filterToRemove.startsWith('금액정렬:'):
        setAmountSortType('');
        break;
      case filterToRemove.startsWith('월렛:'):
        setWalletType('');
        break;
    }
    
    console.log('필터 제거:', filterToRemove);
  };

  const handleRadioChange = (type, value) => {
    let filterLabel = '';
    let currentValue = '';
    let newState = {};
    
    switch(type) {
      case FILTER_TYPES.SOURCE:
        currentValue = sourceType;
        newState = { sourceType: value === currentValue ? '' : value };
        setSourceType(newState.sourceType);
        filterLabel = `원천구분: ${value}`;
        break;
      case FILTER_TYPES.BUSINESS:
        currentValue = businessType;
        newState = { businessType: value === currentValue ? '' : value };
        setBusinessType(newState.businessType);
        filterLabel = `사업자구분: ${value}`;
        break;
      case FILTER_TYPES.DEPOSIT:
        currentValue = depositType;
        newState = { depositType: value === currentValue ? '' : value };
        setDepositType(newState.depositType);
        filterLabel = `보증금: ${value}`;
        break;
      case FILTER_TYPES.DUPLICATE:
        currentValue = duplicateType;
        newState = { duplicateType: value === currentValue ? '' : value };
        setDuplicateType(newState.duplicateType);
        filterLabel = `중복확인: ${value}`;
        break;
      case FILTER_TYPES.RECURRING:
        currentValue = recurringType;
        newState = { recurringType: value === currentValue ? '' : value };
        setRecurringType(newState.recurringType);
        filterLabel = `정기결제: ${value}`;
        break;
      case FILTER_TYPES.AMOUNT_SORT:
        currentValue = amountSortType;
        newState = { amountSortType: value === currentValue ? '' : value };
        setAmountSortType(newState.amountSortType);
        filterLabel = `금액정렬: ${value === 'asc' ? '오름차순' : '내림차순'}`;
        break;
      case FILTER_TYPES.WALLET:
        currentValue = walletType;
        newState = { walletType: value === currentValue ? '' : value };
        setWalletType(newState.walletType);
        filterLabel = `월렛: ${value}`;
        break;
      case FILTER_TYPES.TAX:
        currentValue = taxType;
        newState = { taxType: value === currentValue ? '' : value };
        setTaxType(newState.taxType);
        filterLabel = `과세구분: ${value === 'taxable' ? '과세' : '비과세'}`;
        break;
      default:
        return;
    }

    // 필터 업데이트 로직
    setActiveFilters(prev => {
      let newFilters;
      const isRemoving = value === currentValue;
      
      if (isRemoving) {
        newFilters = prev.filter(filter => !filter.startsWith(filterLabel.split(':')[0]));
      } else {
        const filtered = prev.filter(filter => !filter.startsWith(filterLabel.split(':')[0]));
        newFilters = [...filtered, filterLabel];
      }
      
      // 상태 변경 시 한 번만 로깅
      requestAnimationFrame(() => {
        console.log(isRemoving ? '필터 제거:' : '필터 추가:', filterLabel);
        console.log('현재 적용된 필터:', newFilters);
        console.log('현재 필터 상태:', {
          sourceType,
          businessType,
          depositType,
          duplicateType,
          recurringType,
          amountSortType,
          walletType,
          taxType,
          ...newState
        });
      });
      
      return newFilters;
    });
  };

  const handleOptionFilter = (e) => {
    const value = e.target.value;
    setSearchOptionFilter(value);
    setFilteredOptions(
      searchOptions.filter(option => 
        option.label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleToggleSearch = (e) => {
    const searchText = e.target.value;
    setToggleSearchText(searchText);
    
    // 검색어에 따라 filteredToggleOptions 업데이트
    if (selectedOption) {
      const options = toggleOptions[selectedOption] || [];
      setFilteredToggleOptions(
        options.filter(option => 
          option.label.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  const handleToggleOptionChange = (option) => {
    // 부모 옵션 라벨 찾기
    const parentLabel = searchOptions.find(opt => opt.value === selectedOption)?.label;
    
    // 토글 옵션 상태 업데이트
    setSelectedToggleOptions(prev => {
      const isCurrentlySelected = prev.some(item => 
        item.value === option.value && 
        item.parentOption === selectedOption
      );
      
      let newSelected;
      if (isCurrentlySelected) {
        newSelected = prev.filter(item => 
          !(item.value === option.value && item.parentOption === selectedOption)
        );
      } else {
        newSelected = [...prev, { ...option, parentOption: selectedOption }];
      }

      // 필터 상태 업데이트
      setActiveFilters(currentFilters => {
        // 현재 카테고리의 모든 선택된 옵션들을 필터에 반영
        const otherFilters = currentFilters.filter(filter => 
          !filter.startsWith(parentLabel)
        );
        
        const newFilters = newSelected
          .filter(item => item.parentOption === selectedOption)
          .map(item => {
            const filterText = `${parentLabel}: ${item.label}${excludeOption ? ' (제외)' : ''}`;
            // 필터 추가/제거 로직
            if (!currentFilters.includes(filterText)) {
              console.log('필터 추가:', filterText);
            }
            return filterText;
          });
        
        const resultFilters = [...otherFilters, ...newFilters];
        
        requestAnimationFrame(() => {
          console.log(`${parentLabel} 선택된 옵션들:`, 
            newSelected
              .filter(item => item.parentOption === selectedOption)
              .map(item => `${item.label}${excludeOption ? ' (제외)' : ''}`)
          );
          console.log('현재 적용된 필터:', resultFilters);
        });

        return resultFilters;
      });

      return newSelected;
    });
  };

  // 검색 옵션 변경 핸들러 추가
  const handleSearchOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    setToggleSearchText('');  // 토글 색어 초기화
    
    // 옵션이 선택되면 해당 옵션의 토글 옵션을 필링
    if (newValue) {
      const selectedOptionData = searchOptions.find(opt => opt.value === newValue);
      if (selectedOptionData) {
        setFilteredToggleOptions(toggleOptions[newValue] || []);
      }
    }
  };

  // 제외 옵션 변경 핸들러 추가
  const handleExcludeOptionChange = (event) => {
    const newExcludeOption = event.target.checked;
    setExcludeOption(newExcludeOption);
    
    // 현재 선택된 옵션들의 필터를 업데이트
    setActiveFilters(prev => {
      const parentLabel = searchOptions.find(opt => opt.value === selectedOption)?.label;
      const currentFilters = prev.filter(filter => !filter.startsWith(parentLabel));
      
      const updatedFilters = selectedToggleOptions
        .filter(item => item.parentOption === selectedOption)
        .map(item => {
          const filterText = `${parentLabel}: ${item.label}${newExcludeOption ? ' (제외)' : ''}`;
          console.log(newExcludeOption ? '제외 옵션 추가:' : '제외 옵션 제거:', filterText);
          return filterText;
        });
      
      const resultFilters = [...currentFilters, ...updatedFilters];
      console.log('현재 적용된 필터:', resultFilters);
      return resultFilters;
    });
  };

  const handleDateIconClick = (dateType) => {
    const today = dayjs();  // 현재 시간 (로컬 타임)

    switch (dateType) {
      case 'today':
        // 오늘 00:00:00 ~ 오늘 23:59:59
        setStartDate(today.format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;

      case 'yesterday':
        // 어제 00:00:00 ~ 어제 23:59:59
        const yesterday = today.subtract(1, 'day');
        setStartDate(yesterday.format('YYYY-MM-DD'));
        setEndDate(yesterday.format('YYYY-MM-DD'));
        break;

      case 'last7days':
        // 7일 전부터 어제까지
        setStartDate(today.subtract(7, 'day').format('YYYY-MM-DD'));
        setEndDate(today.subtract(1, 'day').format('YYYY-MM-DD'));
        break;

      case 'lastMonth':
        // 지난달 1일 ~ 지난달 말일
        const lastMonth = today.subtract(1, 'month');
        setStartDate(lastMonth.startOf('month').format('YYYY-MM-DD'));
        setEndDate(lastMonth.endOf('month').format('YYYY-MM-DD'));
        break;

      case 'thisMonth':
        // 이번달 1일 ~ 오늘
        setStartDate(today.startOf('month').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;

      default:
        return;
    }
  };

  // 패널이 닫힐 때 포커스 해제
  const handleClose = () => {
    // 현재 포커스된 요소에서 포커스 해제
    if (document.activeElement) {
      document.activeElement.blur();
    }
    // 패널 닫기
    onClose();
  };

  // Select 토글 변경 시 임시값만 변경
  const handleDataCountChange = (e) => {
    setTempDataCount(e.target.value);  // 임시값만 업데이트
  };

  // 확인 버튼 클릭 시 모든 검색 조건 적용
  const handleSearchClick = () => {
    // 모든 검색 조건을 한번에 전달
    const searchParams = {
      startDate,
      endDate,
      limit: Number(tempDataCount)  // 숫자로 변환하여 전달
    };

    console.log('검색 조건 적용:', searchParams);
    onSearch(searchParams);  // 상위 컴포넌트로 전달
  };

  // selectedOption이 변경될 때도 필터링된 옵션 업데이트
  useEffect(() => {
    if (selectedOption) {
      const options = toggleOptions[selectedOption] || [];
      setFilteredToggleOptions(
        options.filter(option => 
          option.label.toLowerCase().includes(toggleSearchText.toLowerCase())
        )
      );
    }
  }, [selectedOption, toggleOptions, toggleSearchText]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ 
        position: 'fixed',
        right: 0,
        top: '80px',
        width: '300px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px 0 0 12px',
        boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
        pl: 2,
        pr: '5px',
        pt: 2,
        pb: 2,
        height: 'calc(100vh - 100px)',
        zIndex: 900,
        '@media (max-width: 600px)': {
          overflowY: 'auto',
          overflowX: 'visible',  // 가로 방향 overflow 허용
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}>
        <IconButton 
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: '-20px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            zIndex: 1001,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            '@media (max-width: 600px)': {
              zIndex: 1001,
              left: '15px',  // 5px에서 15px 수정여 패널에 더 가깝게 배치
              position: 'fixed'
            }
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {/* 검색어 입력 */}
        <TextField
          size="small"
          placeholder="MID, TID, 가맹점명, 상품명, 승인번호"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ width: '260px', mb: 3 }}
        />

        {/* 검색 옵션 선택 버튼 */}
        <Button
          variant="outlined"
          onClick={handleSearchOptionClick}
          fullWidth
          sx={{ width: '260px', mb: 3 }}
        >
          검색 옵션 선택
        </Button>

        {/* 검색 옵션 팝오버 */}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleSearchOptionClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2, width: '300px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <FormControl size="small" sx={{ flex: 1, minWidth: 0 }}>
                <InputLabel>검색 옵션</InputLabel>
                <Select
                  value={selectedOption}
                  onChange={handleSearchOptionChange}
                  label="검색 옵션"
                >
                  {filteredOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={excludeOption}
                    onChange={handleExcludeOptionChange}
                    size="small"
                  />
                }
                label="제외"
                sx={{
                  margin: 0,
                  '& .MuiFormControlLabel-label': {
                    fontSize: '0.85rem'
                  }
                }}
              />
            </Box>

            {selectedOption && (
              <>
                <TextField
                  size="small"
                  placeholder="토글 옵션 검색"
                  value={toggleSearchText}
                  onChange={handleToggleSearch}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {filteredToggleOptions.map((option) => (
                    <ToggleOptionItem
                      key={option.value}
                      option={option}
                      selectedToggleOptions={selectedToggleOptions}
                      selectedOption={selectedOption}
                      handleToggleOptionChange={handleToggleOptionChange}
                    />
                  ))}
                </Box>
                
              </>
            )}
          </Box>
        </Popover>

        

        {/* 날짜 범위 선택 */}
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          sx={{ width: '100%', mb: 2 }}
        />

        {/* 날짜 아이콘 그룹 */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          mt: 2,
          mb: 2,
          width: '260px',  // 전체 너 설정
        }}>
          <Tooltip title="오늘"><TodayIcon onClick={() => handleDateIconClick('today')} /></Tooltip>
          <Tooltip title="어제"><EventIcon onClick={() => handleDateIconClick('yesterday')} /></Tooltip>
          <Tooltip title="지난 7일"><DateRangeIcon onClick={() => handleDateIconClick('last7days')} /></Tooltip>
          <Tooltip title="지난달"><CalendarMonthIcon onClick={() => handleDateIconClick('lastMonth')} /></Tooltip>
          <Tooltip title="이번달"><CalendarTodayIcon onClick={() => handleDateIconClick('thisMonth')} /></Tooltip>
        </Box>

        {/* 다운로드 버튼 */}
        <Box sx={{ mb: 2 }}>
          <DownloadButtons onExcelDownload={onExcelDownload} />
        </Box>

        {/* 라디오 튼 그룹들 */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>원천구분:</Typography>
            <RadioGroup 
              row 
              value={sourceType} 
              onChange={(e) => handleRadioChange(FILTER_TYPES.SOURCE, e.target.value)} 
              sx={styles.radioGroup}
            >
              <FormControlLabel 
                value="원천" 
                control={<Radio size="small" />} 
                label="원천"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="비원천" 
                control={<Radio size="small" />} 
                label="비원천"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>사업자/개인:</Typography>
            <RadioGroup row value={businessType} onChange={(e) => handleRadioChange(FILTER_TYPES.BUSINESS, e.target.value)} sx={styles.radioGroup}>
              <FormControlLabel 
                value="사업자" 
                control={<Radio size="small" />} 
                label="사업자"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="개인" 
                control={<Radio size="small" />} 
                label="개인"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>보증금:</Typography>
            <RadioGroup row value={depositType} onChange={(e) => handleRadioChange(FILTER_TYPES.DEPOSIT, e.target.value)} sx={styles.radioGroup}>
              <FormControlLabel 
                value="있음" 
                control={<Radio size="small" />} 
                label="있음"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="없음" 
                control={<Radio size="small" />} 
                label="없음"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>중복확인:</Typography>
            <RadioGroup row value={duplicateType} onChange={(e) => handleRadioChange(FILTER_TYPES.DUPLICATE, e.target.value)} sx={styles.radioGroup}>
              <FormControlLabel 
                value="있음" 
                control={<Radio size="small" />} 
                label="있음"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="없음" 
                control={<Radio size="small" />} 
                label="없음"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>정기결제:</Typography>
            <RadioGroup row value={recurringType} onChange={(e) => handleRadioChange(FILTER_TYPES.RECURRING, e.target.value)} sx={styles.radioGroup}>
              <FormControlLabel 
                value="정기" 
                control={<Radio size="small" />} 
                label="사용"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="일반" 
                control={<Radio size="small" />} 
                label="미사용"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>월렛:</Typography>
            <RadioGroup row value={walletType} onChange={(e) => handleRadioChange(FILTER_TYPES.WALLET, e.target.value)} sx={styles.radioGroup}>
              <FormControlLabel 
                value="사용" 
                control={<Radio size="small" />} 
                label="사용"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="비미사용" 
                control={<Radio size="small" />} 
                label="미사용"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.radioGroupBox}>
            <Typography sx={styles.radioGroupLabel}>과세구분:</Typography>
            <RadioGroup
              row
              value={taxType}
              onChange={(e) => handleRadioChange(FILTER_TYPES.TAX, e.target.value)}
              sx={styles.radioGroup}
            >
              <FormControlLabel 
                value="taxable" 
                control={<Radio size="small" />} 
                label="과세"
                sx={styles.radioOption}
              />
              <FormControlLabel 
                value="taxFree" 
                control={<Radio size="small" />} 
                label="비과세"
                sx={styles.radioOption}
              />
            </RadioGroup>
          </Box>

          <Box sx={styles.dataCountBox}>
            <Typography>선택 데이터 개수</Typography>
            <Select
              value={tempDataCount}
              onChange={handleDataCountChange}
              size="small"
              sx={{ 
                width: 'auto',
                minWidth: '100px',
                height: '32px',
                '.MuiOutlinedInput-input': {
                  padding: '4px 14px'
                }
              }}
            >
              <MenuItem value="10">10개</MenuItem>
              <MenuItem value="20">20개</MenuItem>
              <MenuItem value="50">50개</MenuItem>
              <MenuItem value="100">100개</MenuItem>
            </Select>
          </Box>
          {/* 검색 버튼 추가 */}
        <Button
          variant="contained"
          onClick={handleSearchClick}
          sx={{
            width: '260px',
            mt: -1,
            mb: 2,
            backgroundColor: '#2196f3',
            '&:hover': {
              backgroundColor: '#1976d2'
            }
          }}
        >
          확인
        </Button>

          

          {/* 활성화된 필터 표시 */}
        {activeFilters.length > 0 && (
          <Box sx={{ mt: -3.5 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>적용된 필터</Typography>
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)', // 2열 그리드
              gap: 1,
              width: '100%'
            }}>
              {activeFilters.map((filter, index) => (
                <Chip
                  key={index}
                  label={filter}
                  onDelete={() => removeFilter(filter)}
                  size="small"
                  sx={{ 
                    width: '100%',
                    '.MuiChip-label': {
                      whiteSpace: 'normal',  // 텍스트 줄바꿈 허용
                      display: '-webkit-box',
                      WebkitLineClamp: 2,    // 최대 2
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden'
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
        </Box>

        
      </Box>
    </Box>
  );
}

export default SearchOptions;
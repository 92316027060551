import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, IconButton, 
  Tooltip, TextField, RadioGroup, FormControlLabel, Radio 
} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 430px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const HeaderBox = styled(Box)({
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

// 검색 섹션 스타일 컴포넌트 추가
const SearchSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '0 24px 16px 24px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  '&::-webkit-scrollbar': {
    height: '6px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '3px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
    '&:hover': {
      background: '#666'
    }
  }
});

const SMSVerificationHistory = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [records, setRecords] = useState([
    {
      no: 1,
      date: '2024-12-05 17:13:20',
      type: '가맹점',
      id: 'm90745089',
      name: '안경선',
      ip: '106.101.196.221',
      registeredPhone: '010-9074-5089',
      loginPhone: '010-9074-5089'
    },
    {
      no: 2,
      date: '2024-12-05 16:12:17',
      type: '가맹점',
      id: 'm48780064',
      name: '오성상사',
      ip: '211.235.97.144',
      registeredPhone: '010-4878-0051',
      loginPhone: '010-3766-0071'
    },
    {
      no: 3,
      date: '2024-12-05 15:45:33',
      type: '영업',
      id: 's77123456',
      name: '김영업',
      ip: '192.168.1.100',
      registeredPhone: '010-1234-5678',
      loginPhone: '010-1234-5678'
    },
    {
      no: 4,
      date: '2024-12-05 14:30:22',
      type: '가맹점',
      id: 'm55667788',
      name: '대한상사',
      ip: '172.16.0.100',
      registeredPhone: '010-5566-7788',
      loginPhone: '010-9988-7766'
    },
    {
      no: 5,
      date: '2024-12-05 13:15:45',
      type: '영업',
      id: 's88990011',
      name: '이영업',
      ip: '10.0.0.50',
      registeredPhone: '010-8899-0011',
      loginPhone: '010-7788-9900'
    }
  ]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountType, setAccountType] = useState('전체');

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedRecords = [...records].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setRecords(sortedRecords);
  };

  return (
    <StyledCard>
      <HeaderBox>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SmsIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            문자 인증내역
          </Typography>
        </Box>
      </HeaderBox>

      <SearchSection>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />
        <TextField
          size="small"
          placeholder="로그인 아이디, IP, 휴대번호"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          sx={{ 
            width: '300px',
            flexShrink: 0
          }}
        />
        <RadioGroup
          row
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
          sx={{ flexShrink: 0 }}
        >
          <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
          <FormControlLabel value="영업" control={<Radio />} label="영업" />
        </RadioGroup>
        <Tooltip title="엑셀 다운로드">
          <IconButton
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: '#fff',
              border: '1px solid #2e7d32',
              color: '#2e7d32',
              flexShrink: 0,
              '&:hover': {
                backgroundColor: '#e8f5e9',
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </SearchSection>

      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell 
                onClick={handleSortClick}
                sx={{ cursor: 'pointer' }}
              >
                등록일시 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </StyledTableCell>
              <StyledTableCell>가맹점/영업</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>상호명</StyledTableCell>
              <StyledTableCell>IP</StyledTableCell>
              <StyledTableCell>등록 휴대번호</StyledTableCell>
              <StyledTableCell>로그인 휴대번호</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{record.no}</StyledTableCell>
                <StyledTableCell>{record.date}</StyledTableCell>
                <StyledTableCell>{record.type}</StyledTableCell>
                <StyledTableCell>{record.id}</StyledTableCell>
                <StyledTableCell>{record.name}</StyledTableCell>
                <StyledTableCell>{record.ip}</StyledTableCell>
                <StyledTableCell>{record.registeredPhone}</StyledTableCell>
                <StyledTableCell sx={{ 
                  color: record.registeredPhone !== record.loginPhone ? '#d32f2f' : 'inherit',
                  fontWeight: record.registeredPhone !== record.loginPhone ? 600 : 400
                }}>
                  {record.loginPhone}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledCard>
  );
};

export default SMSVerificationHistory;

import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일 컴포넌트
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '16px',
    paddingBottom: '8px'
  }
}));

// 파일 업로드 영역을 위한 스타일 컴포넌트 추가
const FileUploadBox = styled(Box)(({ theme }) => ({
  display: 'flex', 
  gap: 2, 
  alignItems: 'center',
  
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 1,
    
    '& .MuiTypography-root': {
      minWidth: 'auto',
      marginBottom: '4px'
    },
    
    '& .file-select-group': {
      display: 'flex',
      gap: 1,
      width: '100%',
      
      '& .MuiButton-root': {
        minWidth: '80px'
      },
      
      '& .MuiTypography-caption': {
        flex: 1,
        alignSelf: 'center'
      }
    }
  }
}));

// StyledFormControl 추가
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  width: 'auto',
  '& .MuiFormLabel-root': {
    minWidth: '120px',
    color: '#34495e',
    fontWeight: '500',
    fontSize: '0.9rem',
    transform: 'none',
    position: 'static',
    '&.Mui-focused': {
      color: '#3498db'
    }
  }
}));

const Homep = () => {
  return (
    <StyledPaper elevation={2} sx={{ mt: 3 }}>
      <SectionTitle>홈페이지 설정</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* 도메인 */}
          <StyledFormControl>
            <InputLabel>도메인 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="링크"
              />
            </Box>
          </StyledFormControl>

          {/* 메인 컬러 */}
          <StyledFormControl>
            <InputLabel>메인 컬러 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="RGB 색상 입력 ( ex. #1262e6"
              />
            </Box>
          </StyledFormControl>

          {/* 서브 컬러 */}
          <StyledFormControl>
            <InputLabel>서브 컬러 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="RGB 색상 입력 ( ex. #0dcaf0"
              />
            </Box>
          </StyledFormControl>

          {/* 로고 */}
          <StyledFormControl>
            <InputLabel>로고 :</InputLabel>
            <Box className="file-select-group" sx={{ display: 'flex', gap: 1, width: '300px' }}>
              <Button
                variant="contained"
                size="small"
                sx={{ minWidth: '80px' }}
              >
                파일 선택
              </Button>
              <Typography variant="caption" sx={{ color: '#666', alignSelf: 'center' }}>
                선택된 파일 없음
              </Typography>
            </Box>
          </StyledFormControl>

          {/* 영수증 로고 */}
          <StyledFormControl>
            <InputLabel>영수증 로고 :</InputLabel>
            <Box className="file-select-group" sx={{ display: 'flex', gap: 1, width: '300px' }}>
              <Button
                variant="contained"
                size="small"
                sx={{ minWidth: '80px' }}
              >
                파일 선택
              </Button>
              <Typography variant="caption" sx={{ color: '#666', alignSelf: 'center' }}>
                선택된 파일 없음
              </Typography>
            </Box>
          </StyledFormControl>

          {/* title 로고 */}
          <StyledFormControl>
            <InputLabel>title 로고 :</InputLabel>
            <Box className="file-select-group" sx={{ display: 'flex', gap: 1, width: '300px' }}>
              <Button
                variant="contained"
                size="small"
                sx={{ minWidth: '80px' }}
              >
                파일 선택
              </Button>
              <Typography variant="caption" sx={{ color: '#666', alignSelf: 'center' }}>
                선택된 파일 없음
              </Typography>
            </Box>
          </StyledFormControl>

          {/* 서브전산 PG */}
          <StyledFormControl>
            <InputLabel>서브전산 PG :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                select
                size="small"
                variant="outlined"
                defaultValue=""
              >
                <MenuItem value="">
                  <em>PG사 선택</em>
                </MenuItem>
              </TextField>
            </Box>
          </StyledFormControl>

          {/* 업체명 */}
          <StyledFormControl>
            <InputLabel>업체명 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Title CompanyName"
              />
            </Box>
          </StyledFormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default Homep;

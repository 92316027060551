import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Modal,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import UserInfoOne from '../../Common/PayModal/UserInfoOne';
import UserInfoTwo from '../../Common/PayModal/UserInfoTwo';
import MerchantIcons from '../../Common/MerchantIcons';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';

// 표 스타일 통일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px',
      
      '&[data-field="amount"]': {
        height: '40px',
        lineHeight: '40px'
      }
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  }
});

const formatNumber = (value, isAmount = false) => {
  if (!value) return '0';
  
  const num = typeof value === 'string' ? parseFloat(value) : value;
  const formattedNum = Math.abs(num).toLocaleString();
  
  if (num < 0) {
    return `-${formattedNum}`;
  }
  return formattedNum;
};

const formatFee = (amount, rate) => {
  if (!amount || !rate) return '0';
  const feeAmount = Math.floor(amount * (parseFloat(rate) / 100));
  return `+${feeAmount.toLocaleString()}/${rate}`;
};

const formatAmountWithCount = (amount, count) => {
  if (!amount) return '0 (0건)';
  return `${formatNumber(amount, true)} (${count}건)`;
};

// Modal 스타일 추가
const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const ModalContent = styled(Box)({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  minWidth: '300px',
  maxWidth: '500px'
});

// IconButtonStyle 정의 추가
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

function PaymentAggregationTable({ data = [], searchPanelOpen }) {
  // 모달 상태 관리
  const [agencyModalOpen, setAgencyModalOpen] = useState(false);
  const [merchantModalOpen, setMerchantModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [userInfoOneOpen, setUserInfoOneOpen] = useState(false);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');

  // 지사명 클릭 핸들러
  const handleAgencyClick = (agencyName) => {
    setSelectedData(agencyName);
    setAgencyModalOpen(true);
  };

  // 가맹점 클릭 핸들러
  const handleMerchantClick = (merchantName) => {
    setSelectedData(merchantName);
    setMerchantModalOpen(true);
  };

  const handleCardDetailClick = (row) => {
    setUserInfoTwoOpen(true);
    setSelectedMerchantName(row.merchantName);
  };

  const handleUserDetailClick = (row) => {
    setSelectedRowData({
      amount: row.amount,
      approvalNo: row.approvalNo,
      transactionDate: row.transactionDate,
      cardNumber: row.cardNumber,
      installment: row.installment,
      productName: row.productName
    });
    setUserInfoOneOpen(true);
  };

  console.log('PaymentAggregationTable - Search Panel State:', searchPanelOpen);
  
  // 더미 데이터 추가
  const dummyData = [
    {
      no: 1,
      merchantName: '스타벅스',
      merchantStatus: ['sms', 'recurring'],
      approvalAmount: 2500000,
      cancelAmount: -500000,
      totalSales: "2,000,000 (862건)",
      transactionCount: '850/12',
      averageAmount: 235000,
      feeRate: '125,000/5.0',
      settlementCycle: 'D+1',
      settlementRate: '1,875,000',
      riskCount: 0,
      branchName: '강남지사',
      paymentRate: '25,000/1.25',
      reserveRate: '15,000/0.75',
      deductionRate: '8,000/0.4',
      refundRate: '5,000/0.25',
      unpaidRate: '10,000/0.5',
      depositRate: '20,000/1.0',
      pgName: '인증/단말/KG이니시스',
      mid: 'ST12345678',
      tid: 'T9876543',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      no: 2,
      merchantName: '메가커피',
      merchantStatus: ['block', 'sms'],
      approvalAmount: 1800000,
      cancelAmount: -800000,
      totalSales: "-1,000,000 (675건)",
      transactionCount: '650/25',
      averageAmount: -153846,
      feeRate: '-55,000/5.5',
      settlementCycle: 'D+2',
      settlementRate: '-945,000',
      riskCount: 1,
      branchName: '서초지사',
      paymentRate: '12,500/1.25',
      reserveRate: '7,500/0.75',
      deductionRate: '4,000/0.4',
      refundRate: '2,500/0.25',
      unpaidRate: '5,000/0.5',
      depositRate: '10,000/1.0',
      pgName: '비인증/수기/나이스',
      mid: 'MG98765432',
      tid: 'T1234567',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      no: 3,
      merchantName: '올리브영',
      merchantStatus: ['recurring'],
      approvalAmount: 3500000,
      cancelAmount: -200000,
      totalSales: "3,300,000 (445건)",
      transactionCount: '440/5',
      averageAmount: 458333,
      feeRate: '198,000/6.0',
      settlementCycle: 'D+1',
      settlementRate: '3,102,000',
      riskCount: 0,
      branchName: '종로지사',
      paymentRate: '33,000/1.0',
      reserveRate: '16,500/0.5',
      deductionRate: '9,900/0.3',
      refundRate: '6,600/0.2',
      unpaidRate: '0/0',
      depositRate: '33,000/1.0',
      pgName: '인증/단말/토스',
      mid: 'OY87654321',
      tid: 'T5432198',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      no: 4,
      merchantName: '버거킹',
      merchantStatus: ['sms'],
      approvalAmount: 4200000,
      cancelAmount: -400000,
      totalSales: "3,800,000 (923건)",
      transactionCount: '900/23',
      averageAmount: 411957,
      feeRate: '209,000/5.5',
      settlementCycle: 'D+1',
      settlementRate: '3,591,000',
      riskCount: 2,
      branchName: '신촌지사',
      paymentRate: '38,000/1.0',
      reserveRate: '19,000/0.5',
      deductionRate: '11,400/0.3',
      refundRate: '7,600/0.2',
      unpaidRate: '15,200/0.4',
      depositRate: '38,000/1.0',
      pgName: '인증/단말/페이코',
      mid: 'BK24681357',
      tid: 'T7654321',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      no: 5,
      merchantName: 'GS25',
      merchantStatus: ['block', 'recurring'],
      approvalAmount: 1200000,
      cancelAmount: -1500000,
      totalSales: "-300,000 (234건)",
      transactionCount: '220/14',
      averageAmount: -128205,
      feeRate: '-18,000/6.0',
      settlementCycle: 'D+2',
      settlementRate: '-282,000',
      riskCount: 3,
      branchName: '마포지사',
      paymentRate: '3,000/1.0',
      reserveRate: '1,500/0.5',
      deductionRate: '900/0.3',
      refundRate: '600/0.2',
      unpaidRate: '1,200/0.4',
      depositRate: '3,000/1.0',
      pgName: '인증/단말/KG이니시스',
      mid: 'GS13579246',
      tid: 'T2468135',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    }
  ];

  // data prop이 비어있으면 더미데이터를 사용
  const displayData = data.length > 0 ? data : dummyData;

  const handleIconClick = (value) => {
    console.log('Icon clicked:', value);
    // 아이콘 클릭 처리 로직 추가
  };

  return (
    <Paper sx={{ 
      width: '100%',
      overflow: 'hidden',
      transition: 'all 0.3s',
      marginRight: searchPanelOpen ? '280px' : '0'
    }}>
      {/* MerchantIcons 추가 */}
      <Box sx={{ 
        p: 2,
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: '#fff'
      }}>
        <MerchantIcons onIconClick={handleIconClick} />
      </Box>

      <StyledTableWrapper>
        <Table stickyHeader className="sum-jungsan-list">
          <TableHead>
            <TableRow>
              <StyledTableCell className="primary-header sticky-header">No</StyledTableCell>
              <StyledTableCell className="primary-header">가맹점명</StyledTableCell>
              <StyledTableCell className="primary-header">승인금액</StyledTableCell>
              <StyledTableCell className="primary-header">취소금액</StyledTableCell>
              <StyledTableCell className="primary-header">매출합계</StyledTableCell>
              <StyledTableCell className="primary-header">승인/취소건수</StyledTableCell>
              <StyledTableCell className="primary-header">평균단가</StyledTableCell>
              <StyledTableCell className="primary-header">수수료 (%)</StyledTableCell>
              <StyledTableCell className="primary-header">정산금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">RISK건수</StyledTableCell>
              <StyledTableCell className="primary-header">지사명</StyledTableCell>
              <StyledTableCell className="primary-header">지급액(%)</StyledTableCell>
              <StyledTableCell className="primary-header">유보액(%)</StyledTableCell>
              <StyledTableCell className="primary-header">원차감(%)</StyledTableCell>
              <StyledTableCell className="primary-header">환입금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">미수금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">보증금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">PG</StyledTableCell>
              <StyledTableCell className="primary-header">MID</StyledTableCell>
              <StyledTableCell className="primary-header">TID</StyledTableCell>
              <StyledTableCell className="primary-header">상세</StyledTableCell>
              <StyledTableCell className="primary-header">계정</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((row, index) => (
              <StyledTableRow 
                key={index}
                className={Number(row.totalSales.replace(/[^0-9-]/g, '')) < 0 ? 'negative-amount' : ''}
              >
                <StyledTableCell className="sticky-column" align="right">{row.no}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.merchantName}
                  {' '}
                  <Box component="span" sx={{ display: 'inline-flex', gap: 0.5, alignItems: 'center', ml: 1 }}>
                    {row.merchantStatus.map((status, idx) => {
                      switch(status) {
                        case 'block':
                          return <BlockIcon key={idx} sx={{ fontSize: '1rem', color: '#dc3545' }} />;
                        case 'sms':
                          return <TextsmsIcon key={idx} sx={{ fontSize: '1rem', color: '#4caf50' }} />;
                        case 'recurring':
                          return <AutorenewIcon key={idx} sx={{ fontSize: '1rem', color: '#2196f3' }} />;
                        default:
                          return null;
                      }
                    })}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.approvalAmount)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.cancelAmount)}</StyledTableCell>
                <StyledTableCell align="right">{row.totalSales}</StyledTableCell>
                <StyledTableCell align="center">{row.transactionCount}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.averageAmount)}</StyledTableCell>
                <StyledTableCell align="center">{row.feeRate}</StyledTableCell>
                <StyledTableCell align="right">{row.settlementRate}</StyledTableCell>
                <StyledTableCell align="right">{row.riskCount}</StyledTableCell>
                <StyledTableCell align="center">{row.branchName}</StyledTableCell>
                <StyledTableCell align="right">{row.paymentRate}</StyledTableCell>
                <StyledTableCell align="right">{row.reserveRate}</StyledTableCell>
                <StyledTableCell align="right">{row.deductionRate}</StyledTableCell>
                <StyledTableCell align="right">{row.refundRate}</StyledTableCell>
                <StyledTableCell align="right">{row.unpaidRate}</StyledTableCell>
                <StyledTableCell align="right">{row.depositRate}</StyledTableCell>
                <StyledTableCell align="left">{row.pgName}</StyledTableCell>
                <StyledTableCell align="center">{row.mid}</StyledTableCell>
                <StyledTableCell align="center">{row.tid}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="결제 상세보기">
                    <IconButton 
                      size="small" 
                      onClick={() => handleCardDetailClick(row)}
                      sx={IconButtonStyle}
                    >
                      <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="사용자 정보">
                    <IconButton 
                      size="small" 
                      onClick={() => handleUserDetailClick(row)}
                      sx={IconButtonStyle}
                    >
                      <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>

      {/* 지사 데이터 모달 */}
      <StyledModal
        open={agencyModalOpen}
        onClose={() => setAgencyModalOpen(false)}
      >
        <ModalContent>
          <Typography variant="h6" component="h2" gutterBottom>
            해당 지사 데이터
          </Typography>
          <Typography>
            지사명: {selectedData}
          </Typography>
          {/* 추가 지사 데이터 표시 */}
        </ModalContent>
      </StyledModal>

      {/* 가맹점 데이터 모달 */}
      <StyledModal
        open={merchantModalOpen}
        onClose={() => setMerchantModalOpen(false)}
      >
        <ModalContent>
          <Typography variant="h6" component="h2" gutterBottom>
            해당 가맹점 데이터
          </Typography>
          <Typography>
            가맹점명: {selectedData}
          </Typography>
          {/* 추가 가맹점 데이터 표시 */}
        </ModalContent>
      </StyledModal>

      {/* 모달 컴포넌트 추가 */}
      <UserInfoOne
        open={userInfoOneOpen}
        onClose={() => setUserInfoOneOpen(false)}
        data={selectedRowData}
      />
      
      {userInfoTwoOpen && (
        <UserInfoTwo
          open={userInfoTwoOpen}
          onClose={() => setUserInfoTwoOpen(false)}
          businessName={selectedMerchantName}
        />
      )}

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        p: 2,
        backgroundColor: '#fff'
      }}>
        <Pagination count={10} variant="outlined" shape="rounded" />
      </Box>
    </Paper>
  );
}

export default PaymentAggregationTable;

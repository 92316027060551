import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Card,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Grid,
  Divider,
  Input
} from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import * as XLSX from 'xlsx';

const ExcelManagement = () => {
  const [tableType, setTableType] = useState('-');
  const [selectedFields, setSelectedFields] = useState([]);

  // 각 테이블 타입별 필드 정의
  const tableFields = {
    '가맹점': [
      { id: 'merchant_id', label: '가맹점 ID' },
      { id: 'merchant_name', label: '상호명' },
      { id: 'business_number', label: '사업자번호' },
      { id: 'representative', label: '대표자명' },
      { id: 'phone', label: '연락처' },
      { id: 'address', label: '주소' },
      { id: 'bank_info', label: '계좌정보' },
      { id: 'balance', label: '잔액' },
      { id: 'status', label: '상태' }
    ],
    '영업계정': [
      { id: 'sales_id', label: '영업사원 ID' },
      { id: 'sales_name', label: '이름' },
      { id: 'department', label: '부서' },
      { id: 'position', label: '직급' },
      { id: 'phone', label: '연락처' },
      { id: 'email', label: '이메일' },
      { id: 'assigned_merchants', label: '담당 가맹점' },
      { id: 'join_date', label: '입사일' }
    ],
    '입출금내역': [
      { id: 'transaction_date', label: '거래일시' },
      { id: 'transaction_type', label: '거래구분' },
      { id: 'amount', label: '금액' },
      { id: 'merchant_info', label: '가맹점 정보' },
      { id: 'bank_account', label: '계좌정보' },
      { id: 'status', label: '처리상태' },
      { id: 'description', label: '비고' }
    ]
  };

  // 체크박스 변경 핸들러
  const handleFieldChange = (fieldId) => {
    setSelectedFields(prev => {
      if (prev.includes(fieldId)) {
        return prev.filter(id => id !== fieldId);
      } else {
        return [...prev, fieldId];
      }
    });
  };

  // 엑셀 다운로드 핸들러
  const handleDownload = () => {
    // 선택된 필드에 해당하는 데이터만 추출
    const selectedData = tableFields[tableType]
      .filter(field => selectedFields.includes(field.id))
      .map(field => ({
        [field.label]: '' // 빈 데이터로 템플릿 생성
      }));

    // 워크북 생성
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(selectedData);
    XLSX.utils.book_append_sheet(wb, ws, tableType);

    // 파일 다운로드
    XLSX.writeFile(wb, `${tableType}_template.xlsx`);
  };

  // 엑셀 업로드 핸들러
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        
        // 첫 번째 시트의 데이터 처리
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);
        
        console.log('Uploaded Excel Data:', jsonData);
        // 여기에 업로드된 데이터 처리 로직 추가
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Card sx={{ p: 3, borderRadius: '12px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <ListAltIcon sx={{ mr: 1, color: '#1976d2' }} />
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          엑셀 관리
        </Typography>
      </Box>

      {/* 테이블 선택 섹션 */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
          테이블 선택
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={tableType}
            onChange={(e) => {
              setTableType(e.target.value);
              setSelectedFields([]);
            }}
          >
            <FormControlLabel value="-" control={<Radio />} label="-" />
            <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
            <FormControlLabel value="영업계정" control={<Radio />} label="영업계정" />
            <FormControlLabel value="입출금내역" control={<Radio />} label="입출금내역" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {tableType !== '-' && (
        <>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {tableFields[tableType]?.map((field) => (
              <Grid item xs={12} sm={6} md={4} key={field.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFields.includes(field.id)}
                      onChange={() => handleFieldChange(field.id)}
                    />
                  }
                  label={field.label}
                />
              </Grid>
            ))}
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<FileDownloadIcon />}
              onClick={handleDownload}
              disabled={selectedFields.length === 0}
              sx={{
                bgcolor: '#2e7d32',
                '&:hover': {
                  bgcolor: '#1b5e20'
                }
              }}
            >
              엑셀 다운로드
            </Button>
            
            <Button
              variant="contained"
              component="label"
              startIcon={<FileUploadIcon />}
              sx={{
                bgcolor: '#1976d2',
                '&:hover': {
                  bgcolor: '#1565c0'
                }
              }}
            >
              양식 업로드
              <Input
                type="file"
                sx={{ display: 'none' }}
                onChange={handleUpload}
                accept=".xlsx, .xls"
              />
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
};

export default ExcelManagement;

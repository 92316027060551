import React, { useState } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper, 
  Box,
  TextField,
  Button,
  Typography, 
  ToggleButton, 
  ToggleButtonGroup,
} from '@mui/material';
import styled from '@emotion/styled';
import DateRangePicker from '../../Common/DateRangePicker';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.bg-green': {
    backgroundColor: '#e8f5e9'
  },
  '&.bg-blue': {
    backgroundColor: '#e3f2fd'
  },
  '&.bg-orange': {
    backgroundColor: '#fff3e0'
  },

  '&.status-cell': {
    textAlign: 'center',
    '& .active': { color: '#2185d0' },
    '& .inactive': { color: '#f44336' }
  },

  '&:last-child': {
    borderRight: 'none'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f9ff'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  }
});

const StatusChip = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  
  &.active {
    background-color: #64b5f6;  // 연한 파란색
    color: #ffffff;
  }
  
  &.inactive {
    background-color: #ef5350;  // 연한 빨간색
    color: #ffffff;
  }

  &.sms-unavailable {
    background-color: #ef5350;  // 연한 붉은색
    color: #ffffff;
  }

  &.sms-available {
    background-color: #fb8c00;  // 연한 주황색
    color: #ffffff;
  }

  &.delete {
    background-color: #ef5350;  // 연한 빨간색
    color: #ffffff;
  }
`;

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    border: '1px solid #e0e0e0',
    
    '&.Mui-selected': {
      '&.active': {
        backgroundColor: '#64b5f6',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#42a5f5',
        }
      },
      '&.inactive': {
        backgroundColor: '#ef5350',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#e53935',
        }
      },
      '&.delete': {
        backgroundColor: '#ef5350',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#e53935',
        }
      },
      '&.sms-unavailable': {
        backgroundColor: '#ef5350',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#e53935',
        }
      },
      '&.sms-available': {
        backgroundColor: '#fb8c00',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#f57c00',
        }
      }
    }
  }
}));

// 더미데이터 수정 및 확장
const dummyData = [
  {
    no: 1,
    registerDate: '2024-01-01 14:00:00',
    nextPaymentDate: '2024-02-01 14:00:00',
    stopDate: '-',
    status: '사용중',  // "사용중" 또는 "삭제완료"만 사용
    deleteStatus: '삭제',
    authType: 'SMS',
    pgName: 'PG1',
    merchantId: 'MID00000001',
    merchantName: '가맹점1',
    registerName: '등록자1',
    amount: '10,000원',
    cardNumber: '4321-****-****-0001',
    validThru: '01/25',
    customerName: '고객1',
    password: '******',
    phone: '010-1234-5678',
    email: 'user1@example.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 2,
    registerDate: '2024-01-02 14:10:00',
    nextPaymentDate: '2024-02-02 14:10:00',
    stopDate: '2024-03-02',
    status: '삭제완료',  // "사용중" 또는 "삭제완료"만 사용
    deleteStatus: '-',
    authType: 'CARD',
    pgName: 'PG2',
    merchantId: 'MID00000002',
    merchantName: '가맹점2',
    registerName: '등록자2',
    amount: '20,000원',
    cardNumber: '4321-****-****-0002',
    validThru: '02/25',
    customerName: '고객2',
    password: '******',
    phone: '010-1235-5679',
    email: 'user2@example.com',
    smsStatus: '발송불가',
    result: '성공'
  },
  {
    no: 3,
    registerDate: '2024-01-03 14:20:00',
    nextPaymentDate: '2024-02-03 14:20:00',
    stopDate: '-',
    status: '사용중',  // "사용중" 또는 "삭제완료"만 사용
    deleteStatus: '-',
    authType: 'BIO',
    pgName: 'PG3',
    merchantId: 'MID00000003',
    merchantName: '가맹점3',
    registerName: '등록자3',
    amount: '30,000원',
    cardNumber: '4321-****-****-0003',
    validThru: '03/25',
    customerName: '고객3',
    password: '******',
    phone: '010-1236-5680',
    email: 'user3@example.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 4,
    registerDate: '2024-01-04 14:30:00',
    nextPaymentDate: '2024-02-04 14:30:00',
    stopDate: '2024-03-04',
    status: '삭제완료',  // "사용중" 또는 "삭제완료"만 사용
    deleteStatus: '-',
    authType: 'SMS',
    pgName: 'PG4',
    merchantId: 'MID00000004',
    merchantName: '가맹점4',
    registerName: '등록자4',
    amount: '40,000원',
    cardNumber: '4321-****-****-0004',
    validThru: '04/25',
    customerName: '고객4',
    password: '******',
    phone: '010-1237-5681',
    email: 'user4@example.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 5,
    registerDate: '2024-01-05 14:40:00',
    nextPaymentDate: '2024-02-05 14:40:00',
    stopDate: '-',
    status: '사용중',  // "사용중" 또는 "삭제완료"만 사용
    deleteStatus: '-',
    authType: 'CARD',
    pgName: 'PG5',
    merchantId: 'MID00000005',
    merchantName: '가맹점5',
    registerName: '등록자5',
    amount: '50,000원',
    cardNumber: '4321-****-****-0005',
    validThru: '05/25',
    customerName: '고객5',
    password: '******',
    phone: '010-1238-5682',
    email: 'user5@example.com',
    smsStatus: '발송하기',
    result: '성공'
  }
];

const FilterGroup = ({ title, children }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 1 
  }}>
    <Typography 
      sx={{ 
        fontSize: '0.875rem', 
        fontWeight: 600,
        color: '#333',
        minWidth: 'max-content'
      }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

function RegularPaymentTable({ filters }) {
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });

  const [searchText, setSearchText] = useState('');
  const [localFilters, setLocalFilters] = useState({
    usage: null,
    deletion: null,
    sms: null
  });

  const handleFilterChange = (type, value) => {
    setLocalFilters(prev => ({
      ...prev,
      [type]: prev[type] === value ? null : value
    }));
  };

  const handleReset = () => {
    setLocalFilters({
      usage: null,
      deletion: null,
      sms: null
    });
  };

  return (
    <>
      {/* 검색 옵션 박스 */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          alignItems: 'center', 
          flexWrap: 'wrap'
        }}>
          <DateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onStartDateChange={(date) => setDateRange(prev => ({ ...prev, startDate: date }))}
            onEndDateChange={(date) => setDateRange(prev => ({ ...prev, endDate: date }))}
          />
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ minWidth: '200px' }}
          />
          
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <FilterGroup title="사용구분">
              <ToggleButtonGroup
                value={localFilters.usage}
                exclusive
                onChange={(e, value) => handleFilterChange('usage', value)}
                size="small"
              >
                <StyledToggleButton value="사용중" className="active">사용중</StyledToggleButton>
                <StyledToggleButton value="삭제완료" className="inactive">삭제완료</StyledToggleButton>
              </ToggleButtonGroup>
            </FilterGroup>

            <FilterGroup title="삭제여부">
              <ToggleButtonGroup
                value={localFilters.deletion}
                exclusive
                onChange={(e, value) => handleFilterChange('deletion', value)}
                size="small"
              >
                <StyledToggleButton value="삭제" className="delete">삭제</StyledToggleButton>
              </ToggleButtonGroup>
            </FilterGroup>

            <FilterGroup title="문자발송여부">
              <ToggleButtonGroup
                value={localFilters.sms}
                exclusive
                onChange={(e, value) => handleFilterChange('sms', value)}
                size="small"
              >
                <StyledToggleButton value="발송하기" className="sms-available">발송하기</StyledToggleButton>
                <StyledToggleButton value="발송불가" className="sms-unavailable">발송불가</StyledToggleButton>
              </ToggleButtonGroup>
            </FilterGroup>

            <Button 
              variant="outlined" 
              size="small" 
              onClick={handleReset}
              sx={{ height: '31px' }}
            >
              초기화
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* 테이블 */}
      <StyledTableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              {/* 기본 정보 */}
              <StyledTableCell className="primary-header">No</StyledTableCell>
              <StyledTableCell className="primary-header">등록일시</StyledTableCell>
              <StyledTableCell className="primary-header">다음결제일</StyledTableCell>
              <StyledTableCell className="primary-header">중지일시</StyledTableCell>
              <StyledTableCell className="primary-header">사용구분</StyledTableCell>
              <StyledTableCell className="primary-header">삭제여부</StyledTableCell>
              
              {/* 상태 정보 */}
              <StyledTableCell className="primary-header bg-green">문자발송여부</StyledTableCell>
              <StyledTableCell className="primary-header bg-green">응답결과</StyledTableCell>
              
              {/* 인증/가맹점 정보 */}
              <StyledTableCell className="primary-header bg-blue">인증타입</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">PG</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">ID</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">상호명</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">등록명</StyledTableCell>
              
              {/* 결제 정보 */}
              <StyledTableCell className="primary-header bg-orange">결제금액</StyledTableCell>
              <StyledTableCell className="primary-header bg-orange">카드번호</StyledTableCell>
              <StyledTableCell className="primary-header bg-orange">유효기간</StyledTableCell>
              
              {/* 고객 정보 */}
              <StyledTableCell className="primary-header">고객명</StyledTableCell>
              <StyledTableCell className="primary-header">비밀번호</StyledTableCell>
              <StyledTableCell className="primary-header">휴대폰번호</StyledTableCell>
              <StyledTableCell className="primary-header">이메일</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.no}>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell align="center">{row.registerDate}</StyledTableCell>
                <StyledTableCell align="center">{row.nextPaymentDate}</StyledTableCell>
                <StyledTableCell align="center">{row.stopDate}</StyledTableCell>
                <StyledTableCell align="center">
                  <StatusChip className={row.status === '사용중' ? 'active' : 'inactive'}>
                    {row.status}
                  </StatusChip>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.deleteStatus === '삭제' ? (
                    <StatusChip className="delete">{row.deleteStatus}</StatusChip>
                  ) : row.deleteStatus}
                </StyledTableCell>
                <StyledTableCell className="bg-green" align="center">
                  <StatusChip className={row.smsStatus === '발송하기' ? 'sms-available' : 'sms-unavailable'}>
                    {row.smsStatus}
                  </StatusChip>
                </StyledTableCell>
                <StyledTableCell className="bg-green" align="center">{row.result}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.authType}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.pgName}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.merchantId}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="left">{row.merchantName}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.registerName}</StyledTableCell>
                <StyledTableCell className="bg-orange" align="right">{row.amount}</StyledTableCell>
                <StyledTableCell className="bg-orange" align="center">{row.cardNumber}</StyledTableCell>
                <StyledTableCell className="bg-orange" align="center">{row.validThru}</StyledTableCell>
                <StyledTableCell align="center">{row.customerName}</StyledTableCell>
                <StyledTableCell align="center">{row.password}</StyledTableCell>
                <StyledTableCell align="center">{row.phone}</StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>
    </>
  );
}

export default RegularPaymentTable;

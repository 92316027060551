import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

// BonNote PC버전 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

function InjeungGyesan() {
  // 더미 데이터는 그대로 유지
  const injeungData = [
    { 
      type: '비인증',
      count: 156,
      jan: 25678000,
      feb: 28900000,
      mar: 32456000,
      apr: 35678000,
      may: 38765000,
      jun: 42456000,
      total: 203933000
    },
    { 
      type: '인증',
      count: 296,
      jan: 100000000,
      feb: 116990000,
      mar: 134778000,
      apr: 153889000,
      may: 160000000,
      jun: 170000000,
      total: 835657000
    },
    { 
      type: '구인증',
      count: 156,
      jan: 2228000,
      feb: 53200000,
      mar: 32456000,
      apr: 35678000,
      may: 38765000,
      jun: 42456000,
      total: 203933000
    }
  ];

  return (
    <Paper elevation={1} sx={{ p: 3, backgroundColor: '#ffffff', borderRadius: '8px' }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#424242' }}>
        월별 인증 결제금액
      </Typography>
      
      <StyledTableWrapper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="sticky-column">구분</StyledTableCell>
                <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                <StyledTableCell className="primary-header">합계</StyledTableCell>
                <StyledTableCell className="secondary-header">1월</StyledTableCell>
                <StyledTableCell className="secondary-header">2월</StyledTableCell>
                <StyledTableCell className="secondary-header">3월</StyledTableCell>
                <StyledTableCell className="secondary-header">4월</StyledTableCell>
                <StyledTableCell className="secondary-header">5월</StyledTableCell>
                <StyledTableCell className="secondary-header">6월</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {injeungData.map((row) => (
                <StyledTableRow key={row.type}>
                  <StyledTableCell className="sticky-column">{row.type}</StyledTableCell>
                  <StyledTableCell align="right" sx={{ backgroundColor: '#f8f9fa', fontWeight: 600 }}>
                    {row.count.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ backgroundColor: '#f8f9fa', fontWeight: 600 }}>
                    {row.total.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.jan.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.feb.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.mar.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.apr.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.may.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.jun.toLocaleString()}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default InjeungGyesan; 
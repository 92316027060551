import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  styled
} from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '400px',
    borderRadius: '8px',
    padding: '20px'
  }
}));

const MessageText = styled(Typography)({
  fontSize: '0.775rem',
  color: '#495057',
  textAlign: 'center',
  whiteSpace: 'pre-line',  // 줄바꿈 허용
  lineHeight: 1.6
});

const DepositConfirmModal = ({ open, onClose }) => {
  const message = `*** 반드시 월렛에 등록된 동일한 계좌에서 입금해주시기 바랍니다 ***

입금전용계좌 : 저축은행 066-401584-01-043
이지플랫전자결제

상기 계좌로 입력한 '요청금액'을 입금후
입금 확인 요청 하여 주시기 바랍니다.

입금 후에 입금확인 요청을 클릭하시면
잔액에 반영 됩니다.

*** 카카오페이, 토스는 입금이 불가능합니다. ***

자동으로 입금계좌가 복사됩니다.`;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <MessageText>
            {message}
          </MessageText>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            bgcolor: '#1976d2',
            color: '#fff',
            '&:hover': {
              bgcolor: '#1565c0'
            },
            width: '80px'
          }}
        >
          OK
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DepositConfirmModal; 
import React from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper
} from '@mui/material';
import styled from '@emotion/styled';

// 스타일드 컴포넌트 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
  },

  '&.amount': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.negative': {
    color: '#dc3545',
    textAlign: 'right'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

const tableHeaders = [
  // 파란색 그룹
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'date', label: '날짜', color: '#bbdefb' },
  { id: 'approvalNo', label: '승인번호', color: '#bbdefb' },
  
  // 하늘색 그룹
  { id: 'paymentAmount', label: '결제액', color: '#e3f2fd' },
  { id: 'commission', label: '수수료', color: '#e3f2fd' },
  { id: 'inOutAmount', label: '입출금액', color: '#e3f2fd' },
  { id: 'chargeAmount', label: '충전액', color: '#e3f2fd' },
  { id: 'accountBalance', label: '계정잔액', color: '#e3f2fd' },
  { id: 'fixedRate', label: '정액정률', color: '#e3f2fd' },
  { id: 'tid', label: 'TID', color: '#e3f2fd' },
  
  // 초록색 그룹
  { id: 'companyName', label: '상호', color: '#c8e6c9' },
  { id: 'userId', label: '아이디', color: '#c8e6c9' },
  { id: 'bankName', label: '은행명', color: '#c8e6c9' },
  
  // 연두색 그룹
  { id: 'accountHolder', label: '예금주', color: '#f1f8e9' },
  { id: 'accountNumber', label: '계좌번호', color: '#f1f8e9' },
  { id: 'paymentMethod', label: '결제방식', color: '#f1f8e9' },
  { id: 'withdrawalLimit', label: '출금제한', color: '#f1f8e9' },
  { id: 'status', label: '상태(지연시간)', color: '#f1f8e9' },
  { id: 'success', label: '성공실패', color: '#f1f8e9' },
];

const dummyData = [
  {
    no: 1,
    date: '2024-03-19 14:30:25',
    approvalNo: 'AP123456',
    paymentAmount: 100000,
    commission: 1000,
    inOutAmount: 99000,
    chargeAmount: 0,
    accountBalance: 500000,
    fixedRate: '3.5%',
    tid: 'TID123456',
    companyName: '테스트상회',
    userId: 'test123',
    bankName: '신한은행',
    accountHolder: '홍길동',
    accountNumber: '123-456-789',
    paymentMethod: '계좌이체',
    withdrawalLimit: '없음',
    status: '정상(0s)',
    success: '성공',
  },
  {
    no: 2,
    date: '2024-03-19 14:28:15',
    approvalNo: 'AP123457',
    paymentAmount: 250000,
    commission: 2500,
    inOutAmount: 247500,
    chargeAmount: 0,
    accountBalance: 747500,
    fixedRate: '1.0%',
    tid: 'TID123457',
    companyName: '행복마트',
    userId: 'happy777',
    bankName: '국민은행',
    accountHolder: '김행복',
    accountNumber: '456-789-123',
    paymentMethod: '가상계좌',
    withdrawalLimit: '있음',
    status: '정상(1s)',
    success: '성공',
  },
  {
    no: 3,
    date: '2024-03-19 14:25:30',
    approvalNo: 'AP123458',
    paymentAmount: 50000,
    commission: 500,
    inOutAmount: 49500,
    chargeAmount: 10000,
    accountBalance: 59500,
    fixedRate: '1.0%',
    tid: 'TID123458',
    companyName: '스마일샵',
    userId: 'smile123',
    bankName: '우리은행',
    accountHolder: '이스마일',
    accountNumber: '789-123-456',
    paymentMethod: '계좌이체',
    withdrawalLimit: '없음',
    status: '지연(5s)',
    success: '대기',
  },
  {
    no: 4,
    date: '2024-03-19 14:20:45',
    approvalNo: 'AP123459',
    paymentAmount: 150000,
    commission: 1500,
    inOutAmount: 148500,
    chargeAmount: 0,
    accountBalance: 208000,
    fixedRate: '1.0%',
    tid: 'TID123459',
    companyName: '드림스토어',
    userId: 'dream999',
    bankName: '하나은행',
    accountHolder: '박드림',
    accountNumber: '321-654-987',
    paymentMethod: '가상계좌',
    withdrawalLimit: '없음',
    status: '정상(0s)',
    success: '성공',
  },
  {
    no: 5,
    date: '2024-03-19 14:15:10',
    approvalNo: 'AP123460',
    paymentAmount: 75000,
    commission: 750,
    inOutAmount: 74250,
    chargeAmount: 5000,
    accountBalance: 79250,
    fixedRate: '1.0%',
    tid: 'TID123460',
    companyName: '굿모닝마트',
    userId: 'good777',
    bankName: '농협은행',
    accountHolder: '최굿모닝',
    accountNumber: '147-258-369',
    paymentMethod: '계좌이체',
    withdrawalLimit: '있음',
    status: '오류(10s)',
    success: '실패',
  }
];

function WalletInOutTable() {
  return (
    <Box mb={2}>
      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  style={{
                    backgroundColor: header.color,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.no}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.no}-${header.id}`}
                    align={
                      ['paymentAmount', 'commission', 'inOutAmount', 'chargeAmount', 'accountBalance'].includes(header.id)
                        ? 'right'
                        : ['no', 'date', 'approvalNo', 'tid', 'userId', 'status', 'success'].includes(header.id)
                          ? 'center'
                          : 'left'
                    }
                    className={
                      ['paymentAmount', 'inOutAmount', 'chargeAmount', 'accountBalance'].includes(header.id)
                        ? 'amount'
                        : header.id === 'commission'
                          ? 'negative'
                          : ''
                    }
                  >
                    {typeof row[header.id] === 'number' 
                      ? row[header.id].toLocaleString() 
                      : row[header.id]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default WalletInOutTable;

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, ToggleButton, ToggleButtonGroup, Drawer, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Notice from '../../Notice/Notice';
import QuickMenu from '../../Common/QuickMenu';
import RegularPaymentTable from './RegularPaymentTable';
import CurrentLocation from '../../Common/CurrentLocation';
// import SearchOptions from '../../Common/SearchOptions';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '50px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  },
  filterBox: {
    mb: 2,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 3,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  filterGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  filterLabel: {
    minWidth: '100px',
    fontWeight: 'bold'
  }
};

function RegularPayment() {
  const [filters, setFilters] = useState({
    usage: null,
    deletion: null,
    sms: null
  });
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  const handleFilterChange = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type]: prev[type] === value ? null : value
    }));
  };

  const handleReset = () => {
    setFilters({
      usage: null,
      deletion: null,
      sms: null
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '40px' : '40px',  // SearchPanel 기준
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="정기결제 내역" />
        <RegularPaymentTable filters={filters} />
      </Box>

      <QuickMenu />

      {/* SearchPanel */}
      {/* <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: '280px',
            boxSizing: 'border-box',
            mt: '64px',
            height: 'calc(100% - 64px)',
            border: 'none',
            backgroundColor: 'transparent',
            zIndex: 1100
          }
        }}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer> */}
    </Box>
  );
}

export default RegularPayment;

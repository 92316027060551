import React, { useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  Paper, 
  Typography,
  Box,
  Divider,
  IconButton,
  Link,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';


const ReceiptPaper = styled(Paper)(({ theme }) => ({
  width: '500px',
  padding: '20px 40px',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  position: 'relative',
  fontSize: '0.85rem',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  maxHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '10px',
    left: '10px',
    right: '10px',
    bottom: '10px',
    background: `
      radial-gradient(
        circle at center,
        #333 9px,
        transparent 7px
      )
    `,
    backgroundSize: '52px 50px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'repeat',
    pointerEvents: 'none',
    zIndex: 1
  }
}));

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    margin: '16px',
    maxWidth: 'none'
  }
});

const ReceiptHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  height: 'auto',
  padding: '20px 25px 20px',
  backgroundColor: '#fff',
  zIndex: 3,
  margin: '-20px -40px 0 -40px',
  paddingLeft: '40px',
  paddingRight: '40px',
  paddingTop: '20px',
  paddingBottom: '20px',
  width: 'calc(100% + 80px)',
  borderBottom: '1px solid #eee'
});

const LogoImage = styled('img')({
  height: '44px',
  width: 'auto',
  objectFit: 'contain',
  position: 'absolute',
  left: '30px',
  top: '20px',
  zIndex: 3
});

const ReceiptTitle = styled(Typography)({
  fontSize: '1.2rem',
  fontWeight: 700,
  color: '#1976d2',
  letterSpacing: '0.5px',
  marginTop: '5px',
  position: 'relative',
  zIndex: 3,
  fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important",
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-6px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '3px',
    background: '#1976d2',
    borderRadius: '2px'
  }
});

const CloseButton = styled(CloseIcon)({
  position: 'absolute',
  right: '30px',
  top: '22px',
  color: '#666',
  zIndex: 3,
  fontSize: '34px',
  cursor: 'pointer',
  '&:hover': {
    color: '#1976d2'
  }
});

const ContentWrapper = styled(Box)({
  position: 'relative',
  backgroundColor: '#fff',
  zIndex: 2,
  margin: '0 30px',
  padding: '30px',
  marginTop: '0',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  '& > :first-child': {
    marginTop: 0
  },
  
  msOverflowStyle: 'none',  // IE and Edge
  scrollbarWidth: 'none',   // Firefox
  '&::-webkit-scrollbar': { // Chrome, Safari, Opera
    display: 'none'
  }
});

const ReceiptSection = styled(Box)({
  marginBottom: '12px',
  backgroundColor: '#fff',
  borderRadius: '6px',
  padding: '2px',
  position: 'relative',
  zIndex: 2,
  width: '100%',
  boxSizing: 'border-box'
});

const ReceiptSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  marginBottom: '12px',
  padding: '8px 12px',
  borderRadius: '4px',
  color: '#1976d2',
  
  '&:not(.store-info)': {
    backgroundColor: '#e3f2fd', // 구매정보, 결제정보는 연한 배경색 유지
    color: '#42a5f5'
  },

  '&.store-info': {
    backgroundColor: '#bbdefb', // 상점정보는 더 진한 배경색으로 변경
    color: '#1976d2'
  }
}));

const ReceiptRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '12px 5px',
  width: 'auto',
  boxSizing: 'border-box',
  gap: '15px',
  '& .label': {
    color: '#2c3e50',
    fontSize: '0.85rem',
    flex: '0 0 80px',
    fontWeight: 500,
    wordBreak: 'keep-all',
    fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
  },
  '& .value': {
    color: '#000',
    fontSize: '0.8rem',
    textAlign: 'right',
    flex: '0 0 auto',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
  }
}));

const ReceiptFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  height: 'auto',
  backgroundColor: '#fff',
  zIndex: 3,
  margin: '0 -40px -20px -40px',
  padding: '20px 40px',
  width: 'calc(100% + 80px)',
  borderTop: '1px solid #eee',
  boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)',
  
  '& .notice': {
    width: '100%',
    backgroundColor: '#e7f5ff',
    color: '#0d6efd',
    padding: '12px',
    textAlign: 'center',
    borderRadius: '4px',
    marginBottom: '15px',
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.3px',
    fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
  },
  
  '& .legal': {
    fontSize: '0.65rem',
    color: '#666',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
    lineHeight: 1.5,
    width: '100%',
    fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
  }
});

// 모바일 버전을 위한 스타일 컴포넌트들
const MobileReceiptPaper = styled(Paper)(({ theme }) => ({
  width: '320px',
  padding: '15px 20px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  position: 'relative',
  fontSize: '0.7rem',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '12px',
    background: 'repeating-linear-gradient(45deg, #1976d2, #1976d2 5px, transparent 5px, transparent 12px)'
  }
}));

const MobileReceiptHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '25px',
  position: 'relative',
  height: 'auto',
  padding: '20px 25px 0',
});

const MobileLogoImage = styled('img')({
  height: '24px',
  width: 'auto',
  objectFit: 'contain',
  position: 'absolute',
  left: '20px',
  top: '20px',
});

const MobileReceiptTitle = styled(Typography)({
  fontSize: '1.1rem',
  fontWeight: 700,
  color: '#1976d2',
  letterSpacing: '0.5px',
  marginTop: '5px',
  position: 'relative',
  fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important",
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-6px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '3px',
    background: '#1976d2',
    borderRadius: '2px'
  }
});

const MobileReceiptSection = styled(Box)({
  marginBottom: '12px',
  backgroundColor: '#f8f9fa',
  borderRadius: '6px',
  padding: '2px',
});

const MobileReceiptSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  fontWeight: 600,
  marginBottom: '12px',
  padding: '8px 12px',
  borderRadius: '4px',
  color: '#1976d2',
  
  '&:not(.store-info)': {
    backgroundColor: '#e3f2fd', // 구매정보, 결제정보는 연한 배경색 유지
    color: '#42a5f5'
  },

  '&.store-info': {
    backgroundColor: '#bbdefb', // 상점정보는 더 진한 배경색으로 변경
    color: '#1976d2'
  }
}));

const MobileReceiptRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '6px 10px',
  '& .label': {
    color: '#2c3e50',
    fontSize: '0.7rem',
    flex: '0 0 85px',
    fontWeight: 500,
    fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
  },
  '& .value': {
    color: '#000',
    fontSize: '0.7rem',
    textAlign: 'right',
    flex: 1,
    paddingLeft: '15px',
    fontWeight: 500,
    fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
  },
  '&.highlight': {
    '& .label': {
      color: '#0d6efd',
      fontWeight: 600
    },
    '& .value': {
      color: '#000',
      fontWeight: 600
    }
  }
});

const MobileReceiptFooter = styled(Typography)({
  fontSize: '0.65rem',
  color: '#666',
  textAlign: 'left',
  marginTop: '15px',
  whiteSpace: 'pre-wrap',
  wordBreak: 'keep-all',
  lineHeight: 1.5,
  fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
});

const ReceiptModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const data = {
    paymentInfo: {
      paymentType: '신용카드',
      cardType: '비씨카드',
      cardNumber: '1321-1213-10312',
      installment: '일시불',
      transactionType: '승인',
      approvalNumber: '132131231',
      taxAmount: '68,776',
      vatAmount: '37,000',
      totalAmount: '100,000'
    },
    purchaseInfo: {
      transactionDate: '2024. 12. 12 오후 5:00:14',
      productName: '신속개발강의',
      customerName: '이민자',
      orderNumber: '1232321'
    },
    storeInfo: {
      storeName: '초보탈출교습소',
      ownerName: '일론머스크',
      businessNumber: '124-87-24823',
      address: '경기도 개발시 죽도로 999',
      contactNumber: '1669-2255'
    }
  };

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;600;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  if (isMobile) {
    return (
      <Dialog 
        className="receipt-modal"
        open={open} 
        onClose={onClose}
        PaperProps={{
          style: { backgroundColor: 'transparent', boxShadow: 'none' }
        }}
      >
        <MobileReceiptPaper>
          <MobileReceiptHeader>
            {/* <MobileLogoImage src={receiptLogo} alt="Receipt Logo" /> */}
            <MobileReceiptTitle>온라인 매출전표</MobileReceiptTitle>
            <CloseIcon 
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: '20px',
                top: '22px',
                fontSize: '20px',
                color: '#666',
                cursor: 'pointer',
                '&:hover': {
                  color: '#1976d2'
                }
              }}
            />
          </MobileReceiptHeader>

          <MobileReceiptSection>
            <MobileReceiptSectionTitle>구매정보</MobileReceiptSectionTitle>
            {Object.entries(data.purchaseInfo).map(([key, value]) => (
              <MobileReceiptRow key={key}>
                <Typography className="label">{getLabel(key)}</Typography>
                <Typography className="value">{value}</Typography>
              </MobileReceiptRow>
            ))}
          </MobileReceiptSection>

          <MobileReceiptSection>
            <MobileReceiptSectionTitle>결제정보</MobileReceiptSectionTitle>
            {Object.entries(data.paymentInfo).map(([key, value]) => (
              <MobileReceiptRow key={key} className={key.includes('Amount') ? 'highlight' : ''}>
                <Typography className="label">{getLabel(key)}</Typography>
                <Typography className="value">
                  {value}{key.includes('Amount') ? '원' : ''}
                </Typography>
              </MobileReceiptRow>
            ))}
          </MobileReceiptSection>

          <MobileReceiptSection>
            <MobileReceiptSectionTitle className="store-info">상점정보</MobileReceiptSectionTitle>
            {Object.entries(data.storeInfo).map(([key, value]) => (
              <MobileReceiptRow key={key}>
                <Typography className="label">{getLabel(key)}</Typography>
                <Typography className="value">{value}</Typography>
              </MobileReceiptRow>
            ))}
          </MobileReceiptSection>

          <Box sx={{
            width: '100%',
            backgroundColor: '#e7f5ff',
            color: '#0d6efd',
            padding: '12px',
            textAlign: 'center',
            borderRadius: '4px',
            mt: 2,
            mb: 2,
            fontSize: '0.7rem',
            fontWeight: 500,
            letterSpacing: '0.3px',
            fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
          }}>
            신용카드 청구서에는 
            <Box component="span" sx={{ fontWeight: 600, mx: 0.5 }}>
              이지플랫전자결제(주)
            </Box>
            로 표시됩니다.
            <Typography sx={{ fontSize: '0.65rem', color: '#4dabf7', mt: 0.5 }}>
              This Transaction will be billed by EGFP Inc.
            </Typography>
          </Box>

          <MobileReceiptFooter>
            * 신용카드 매출전표는 부가가치세법 제32조 2제3항에 의하여 발행되었으며, 
              부가가치세법 제46조에 따라 신용카드매출전표 등을 발급받은 경우에는 
              매입세액 공제가 가능합니다.
          </MobileReceiptFooter>
        </MobileReceiptPaper>
      </Dialog>
    );
  }

  // 웹 버전 return (기존 코드 그대로 유지)
  return (
    <StyledDialog 
      className="receipt-modal"
      open={open} 
      onClose={onClose}
    >
      <ReceiptPaper>
        <ReceiptHeader>
          {/* <LogoImage src={receiptLogo} alt="Receipt Logo" /> */}
          <ReceiptTitle>온라인 매출전표</ReceiptTitle>
          <CloseButton onClick={onClose} />
        </ReceiptHeader>

        <ContentWrapper>
          <ReceiptSection>
            <ReceiptSectionTitle>구매정보</ReceiptSectionTitle>
            {Object.entries(data.purchaseInfo).map(([key, value]) => (
              <ReceiptRow key={key}>
                <Typography className="label">{getLabel(key)}</Typography>
                <Typography className="value">{value}</Typography>
              </ReceiptRow>
            ))}
          </ReceiptSection>

          <ReceiptSection>
            <ReceiptSectionTitle>결제정보</ReceiptSectionTitle>
            {Object.entries(data.paymentInfo).map(([key, value]) => (
              <ReceiptRow key={key} className={key.includes('Amount') ? 'highlight' : ''}>
                <Typography className="label">{getLabel(key)}</Typography>
                <Typography className="value">
                  {value}{key.includes('Amount') ? '원' : ''}
                </Typography>
              </ReceiptRow>
            ))}
          </ReceiptSection>

          <ReceiptSection>
            <ReceiptSectionTitle className="store-info">상점정보</ReceiptSectionTitle>
            {Object.entries(data.storeInfo).map(([key, value]) => (
              <ReceiptRow key={key}>
                <Typography className="label">{getLabel(key)}</Typography>
                <Typography className="value">{value}</Typography>
              </ReceiptRow>
            ))}
          </ReceiptSection>

          <Box sx={{
            width: '100%',
            backgroundColor: '#e7f5ff',
            color: '#0d6efd',
            padding: '12px',
            textAlign: 'center',
            borderRadius: '4px',
            mt: 2,
            mb: 2,
            fontSize: '0.75rem',
            fontWeight: 500,
            letterSpacing: '0.3px',
            fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
          }}>
            신용카드 청구서에는 
            <Box component="span" sx={{ fontWeight: 600, mx: 0.5 }}>
              이지플랫전자결제(주)
            </Box>
            로 표시됩니다.
            <Typography sx={{ fontSize: '0.7rem', color: '#4dabf7', mt: 0.5 }}>
              This Transaction will be billed by EGFP Inc.
            </Typography>
          </Box>
        </ContentWrapper>
      </ReceiptPaper>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: 'auto',
        padding: '20px 30px',
        backgroundColor: '#fff',
        zIndex: 3,
        width: '500px',
        marginTop: '-30px',
        borderTop: '1px solid #eee',
        boxSizing: 'border-box'
      }}>
        <Typography sx={{
          fontSize: '0.65rem',
          color: '#666',
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
          wordBreak: 'keep-all',
          lineHeight: 1.5,
          width: '100%',
          fontFamily: "'Cafe24Ssurround', 'Noto Sans KR', sans-serif !important"
        }}>
          * 신용카드 매출전표는 부가가치세법 제32조 2제3항에 의하여 발행되었으며, 
            부가가치세법 제46조에 따라 신용카드매출전표 등을 발급받은 경우에는 
            매입세액 공제가 가능합니다.
        </Typography>
      </Box>
    </StyledDialog>
  );
};

const getLabel = (key) => {
  const labels = {
    paymentType: '결제방법',
    cardType: '카드종류',
    cardNumber: '카드번호',
    installment: '할부개월',
    transactionType: '거래유형',
    approvalNumber: '승인번호',
    taxAmount: '결제금액',
    vatAmount: '공급가',
    totalAmount: '부가세',
    transactionDate: '거래일시',
    productName: '상품명',
    customerName: '구매자명',
    orderNumber: '주문번호',
    storeName: '상점명',
    ownerName: '대표자명',
    businessNumber: '사업자등록번호',
    address: '사업장주소',
    contactNumber: '상점이용문의'
  };
  return labels[key] || key;
};

export default ReceiptModal;

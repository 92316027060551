import React, { useState } from "react";
import { Box } from "@mui/material";
import CurrentLocation from "../../Common/CurrentLocation";
import Notice from "../../Notice/Notice";
import QuickMenu from "../../Common/QuickMenu";
import moment from "moment";
import "moment/locale/ko";
import styled from "@emotion/styled";

// 색상 상수
const COLORS = {
  primary: '#51cbce',
  success: '#6bd098',
  info: '#51bcda',
  warning: '#fbc658',
  danger: '#ef8157'
};

// 범례 아이템 정의
const LEGEND_ITEMS = [
  { label: '정산예정', color: '#4B77BE' },
  { label: '정산완료', color: '#59B75C' },
  { label: '정산보류', color: '#F3B760' },
  { label: '상계', color: '#FF9800' },
  { label: '결제액', color: '#6C757D' },
  { label: '정산금지', color: '#FF6B6B' }
];

// 색상 상수 정의
const ITEM_COLORS = {
  결제: '#4B77BE',
  금지: '#FF6B6B',
  보류: '#59B75C',
  상계: '#F3B760',
  결제액: '#6C757D'
};

// 상수로 mockData 정의
const mockData = {
  '2024-01-01': {
    예정: 31840620,
    완료: 25355300,
    보류: 15000000,
    상계: 5000000,
    결제: 111479467,
    금지: 86211603
  },
  '2024-01-02': {
    예정: 28750000,
    완료: 19876543,
    보류: 8765432,
    상계: 3456789,
    결제: 98765432,
    금지: 45678901
  },
  '2024-01-03': {
    예정: 42567890,
    완료: 31234567,
    보류: 12345678,
    상계: 6789012,
    결제: 87654321,
    금지: 34567890
  },
  '2024-01-04': {
    예정: 35678901,
    완료: 28901234,
    보류: 9876543,
    상계: 4567890,
    결제: 76543210,
    금지: 23456789
  },
  '2024-01-05': {
    예정: 39012345,
    완료: 27654321,
    보류: 11234567,
    상계: 5678901,
    결제: 65432109,
    금지: 32109876
  },
  '2024-01-06': {
    예정: 41234567,
    완료: 30987654,
    보류: 13456789,
    상계: 7890123,
    결제: 54321098,
    금지: 21098765
  },
  '2024-01-07': {
    예정: 33456789,
    완료: 24567890,
    보류: 10987654,
    상계: 6543210,
    결제: 43210987,
    금지: 10987654
  },
  '2024-01-08': {
    예정: 37890123,
    완료: 26789012,
    보류: 14567890,
    상계: 8901234,
    결제: 32109876,
    금지: 9876543
  },
  '2024-01-09': {
    예정: 45678901,
    완료: 32109876,
    보류: 16789012,
    상계: 9012345,
    결제: 21098765,
    금지: 8765432
  },
  '2024-01-10': {
    예정: 40123456,
    완료: 29012345,
    보류: 15678901,
    상계: 7654321,
    결제: 10987654,
    금지: 7654321
  }
};

// 각 항목별 스타일 정의
const itemStyles = {
  예정: { backgroundColor: '#4B77BE', color: 'white' },
  완료: { backgroundColor: '#59B75C', color: 'white' },
  보류: { backgroundColor: '#F3B760', color: 'white' },
  상계: { backgroundColor: '#FF9800', color: 'white' },
  결제: { backgroundColor: '#6C757D', color: 'white' },
  금지: { backgroundColor: '#FF6B6B', color: 'white' }
};

// 달력 래퍼 스타일 컴포넌트 추가
const StyledCalendarWrapper = styled(Box)({
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    height: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.2)',
    borderRadius: '3px'
  }
});

// 범례 래퍼 스타일 컴포넌트 추가
const StyledLegendWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  '@media (max-width: 600px)': {
    flexDirection: 'row',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function SettlementCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const weekDays = ['일', '월', '화', '수', '목', '금', '토'];

  // 금액 포맷팅 함수
  const formatAmount = (amount) => {
    return amount?.toLocaleString() || '0';
  };

  // 달력 데이터 생성 함수
  const getCalendarDays = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    // 이전 달의 마지막 날짜들
    const prevMonthDays = [];
    const firstDayOfWeek = firstDay.getDay();
    if (firstDayOfWeek > 0) {
      const prevMonth = new Date(year, month, 0);
      for (let i = firstDayOfWeek - 1; i >= 0; i--) {
        prevMonthDays.unshift(new Date(year, month - 1, prevMonth.getDate() - i));
      }
    }

    // 현재 달의 날짜들
    const currentMonthDays = [];
    for (let i = 1; i <= lastDay.getDate(); i++) {
      currentMonthDays.push(new Date(year, month, i));
    }

    // 다음 달의 시작 날짜들
    const nextMonthDays = [];
    const lastDayOfWeek = lastDay.getDay();
    if (lastDayOfWeek < 6) {
      for (let i = 1; i <= 6 - lastDayOfWeek; i++) {
        nextMonthDays.push(new Date(year, month + 1, i));
      }
    }

    return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
  };

  // 테스트용 날짜 출력
  console.log('Calendar Days:', getCalendarDays(currentDate).map(date => ({
    date: date.getDate(),
    month: date.getMonth() + 1,
    isCurrentMonth: date.getMonth() === currentDate.getMonth()
  })));

  // 월 이동 함수
  const moveMonth = (direction) => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1));
  };

  // 오늘 날짜로 이동
  const goToToday = () => {
    setCurrentDate(new Date());
  };

  // 현재 날짜가 오늘인지 확인
  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const calendarDays = getCalendarDays(currentDate);

  return (
    <Box>
      <Box sx={{ 
        maxWidth: '1200px',
        margin: '0 auto',
        px: { xs: 1, md: 8 }, 
        mb: 2, 
        mt: 3 
      }}>
        <Notice />
        <Box sx={{ mt: 2 }}>
          <CurrentLocation mainTitle="정산" subTitle="정산달력" />
        </Box>
        
        <Box sx={{ 
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 2, sm: 0 },
          my: 2
        }}>
          {/* 범례 부분 */}
          <StyledLegendWrapper sx={{ 
            flex: 1,
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(3, auto)', md: 'repeat(3, 1fr)' },
            gap: '8px 16px'
          }}>
            {LEGEND_ITEMS.map((item, index) => (
              <div key={index} style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                fontSize: '12px',
                flexShrink: 0
              }}>
                <div style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: item.color,
                  borderRadius: '50%'
                }} />
                <span style={{ whiteSpace: 'nowrap' }}>{item.label}</span>
              </div>
            ))}
          </StyledLegendWrapper>

          {/* 년월 표시 */}
          <Box sx={{ 
            flex: 1,
            textAlign: 'center',
            whiteSpace: 'nowrap'
          }}>
            <span style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#333'
            }}>
              {currentDate.getFullYear()}년 {currentDate.getMonth() + 1}월
            </span>
          </Box>
          
          {/* 버튼 그룹 */}
          <Box sx={{ 
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            flexShrink: 0
          }}>
            <button
              onClick={() => moveMonth(-1)}
              style={{
                padding: '8px 16px',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                backgroundColor: 'white',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#666'
              }}
            >
              이전달
            </button>
            <button
              onClick={goToToday}
              style={{
                padding: '8px 16px',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                backgroundColor: 'white',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#666'
              }}
            >
              오늘
            </button>
            <button
              onClick={() => moveMonth(1)}
              style={{
                padding: '8px 16px',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                backgroundColor: 'white',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#666'
              }}
            >
              다음달
            </button>
          </Box>
        </Box>
      </Box>

      <StyledCalendarWrapper sx={{ 
        maxWidth: '1200px',
        margin: '0 auto',
        px: { xs: 1, md: 8 }
      }}>
        <table style={{ 
          width: '100%',
          minWidth: '800px',
          borderCollapse: 'collapse',
          backgroundColor: 'white',
          border: '1px solid #eaeaea',
          tableLayout: 'fixed'
        }}>
          <thead>
            <tr>
              {weekDays.map((day, index) => (
                <th key={index} style={{
                  width: `${100/7}%`,
                  padding: '4px',
                  textAlign: 'center',
                  borderBottom: '1px solid #eaeaea',
                  borderRight: '1px solid #eaeaea',
                  color: index === 0 || index === 6 ? '#FF0000' : '#333',
                  fontSize: '12px',
                  height: '30px',
                  backgroundColor: '#f8f9fa'
                }}>
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: Math.ceil(calendarDays.length / 7) }).map((_, weekIndex) => (
              <tr key={weekIndex} style={{ height: '150px' }}>
                {calendarDays.slice(weekIndex * 7, (weekIndex + 1) * 7).map((date, dayIndex) => {
                  const isWeekend = dayIndex === 0 || dayIndex === 6;
                  const isCurrentMonth = date.getMonth() === currentDate.getMonth();
                  
                  return (
                    <td key={dayIndex} style={{
                      border: '1px solid #eaeaea',
                      backgroundColor: 'white',
                      opacity: isCurrentMonth ? 1 : 0.5,
                      padding: 0,
                      verticalAlign: 'top',
                      width: `${100/7}%`,
                      maxWidth: `${100/7}%`
                    }}>
                      <div style={{ 
                        textAlign: 'right',
                        backgroundColor: isWeekend ? '#FFF1F0' : 'transparent',
                        color: isWeekend ? '#FF0000' : 'inherit',
                        fontSize: '12px',
                        padding: '4px',
                        width: '100%',
                        borderBottom: '1px solid #eaeaea'
                      }}>
                        {date.getDate()}일
                      </div>
                      {isCurrentMonth && (
                        <div style={{ 
                          fontSize: '12px',
                          padding: 0,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 0
                        }}>
                          {/* 예정 */}
                          <div style={{ 
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eaeaea',
                            height: '24px'
                          }}>
                            <span style={{ 
                              padding: '2px 6px',
                              backgroundColor: '#4B77BE',
                              color: 'white',
                              minWidth: '40px',
                              textAlign: 'center',
                              fontSize: '11px'
                            }}>
                              예정
                            </span>
                            <span style={{ 
                              flex: 1,
                              textAlign: 'right',
                              paddingRight: '6px',
                              color: '#333'
                            }}>
                              31,840,620
                            </span>
                          </div>

                          {/* 완료 */}
                          <div style={{ 
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eaeaea',
                            height: '24px'
                          }}>
                            <span style={{ 
                              padding: '2px 6px',
                              backgroundColor: '#59B75C',
                              color: 'white',
                              minWidth: '40px',
                              textAlign: 'center',
                              fontSize: '11px'
                            }}>
                              완료
                            </span>
                            <span style={{ 
                              flex: 1,
                              textAlign: 'right',
                              paddingRight: '6px',
                              color: '#333'
                            }}>
                              25,355,300
                            </span>
                          </div>

                          {/* 보류 */}
                          <div style={{ 
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eaeaea',
                            height: '24px'
                          }}>
                            <span style={{ 
                              padding: '2px 6px',
                              backgroundColor: '#F3B760',
                              color: 'white',
                              minWidth: '40px',
                              textAlign: 'center',
                              fontSize: '11px'
                            }}>
                              보류
                            </span>
                            <span style={{ 
                              flex: 1,
                              textAlign: 'right',
                              paddingRight: '6px',
                              color: '#333'
                            }}>
                              15,000,000
                            </span>
                          </div>

                          {/* 상계 */}
                          <div style={{ 
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eaeaea',
                            height: '24px'
                          }}>
                            <span style={{ 
                              padding: '2px 6px',
                              backgroundColor: '#FF9800',
                              color: 'white',
                              minWidth: '40px',
                              textAlign: 'center',
                              fontSize: '11px'
                            }}>
                              상계
                            </span>
                            <span style={{ 
                              flex: 1,
                              textAlign: 'right',
                              paddingRight: '6px',
                              color: '#333'
                            }}>
                              5,000,000
                            </span>
                          </div>

                          {/* 결제 */}
                          <div style={{ 
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eaeaea',
                            height: '24px'
                          }}>
                            <span style={{ 
                              padding: '2px 6px',
                              backgroundColor: '#6C757D',
                              color: 'white',
                              minWidth: '40px',
                              textAlign: 'center',
                              fontSize: '11px'
                            }}>
                              결제
                            </span>
                            <span style={{ 
                              flex: 1,
                              textAlign: 'right',
                              paddingRight: '6px',
                              color: '#333'
                            }}>
                              111,479,467
                            </span>
                          </div>

                          {/* 금지 */}
                          <div style={{ 
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eaeaea',
                            height: '24px'
                          }}>
                            <span style={{ 
                              padding: '2px 6px',
                              backgroundColor: '#FF6B6B',
                              color: 'white',
                              minWidth: '40px',
                              textAlign: 'center',
                              fontSize: '11px'
                            }}>
                              금지
                            </span>
                            <span style={{ 
                              flex: 1,
                              textAlign: 'right',
                              paddingRight: '6px',
                              color: '#333'
                            }}>
                              86,211,603
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </StyledCalendarWrapper>
    </Box>
  );
}

export default SettlementCalendar;
import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, GlobalStyles } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 6px',
  fontSize: '0.813rem',
  fontFamily: 'inherit',
  textAlign: 'center',
  border: '1px solid #e3f2fd',
  backgroundColor: '#f8fbff',
  color: '#1a1a1a',
  whiteSpace: 'nowrap',
  minWidth: 'auto',
  '&.header': {
    backgroundColor: '#e3f2fd',
    color: '#1a1a1a',
    fontWeight: 'bold'
  },
  '&.subheader': {
    backgroundColor: '#bbdefb',
    color: '#1a1a1a',
    fontWeight: 'bold'
  },
  '&.negative': {
    color: '#d32f2f'
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid #ddd'
  }
});

const StyledTable = styled(Table)({
  borderCollapse: 'collapse',
  width: '100%',
  tableLayout: 'fixed',
  backgroundColor: '#f8fbff',
  '& th, & td': {
    border: '1px solid #e3f2fd',
    whiteSpace: 'nowrap',
    overflow: 'visible'
  }
});

// 더든 컴포넌트에서 사용할 데이터를 한 곳에서 관리하고 초기값 설정
export const paymentData = {
  basicStats: {
    dealerCount: 150,
    transactionCount: 1000,
    approvalCount: 900,
    cancelCount: 100,
    netCount: 800,
    approvalAmount: 10000000,
    cancelAmount: 1000000,
    totalAmount: 9000000,
    totalFee: 500000,
    totalDeposit: 1000000,
    totalSettlement: 8500000,
    periodCancelCount: 50,
    returnCount: 20,
    netCancelCount: 30,
    successRate: 90,
    avgAmount: 100000,
    totalCount: 1000,
    // AYNIL 관련 데이터
    aynilApprovalTotal: 8827000,
    aynilCancelTotal: 621700,
    aynilDeductionTotal: 0,
    aynilDepositTotal: 8205300,
    aynilTotal: 10189250
  },
  accountPayments: {
    total: 10000000,
    master: 2000000,
    company: 1500000,
    distributor: 1000000,
    agency: 800000,
    retail: 700000,
    sales1: 500000,
    sales2: 300000,
    sales3: 200000
  },
  accountPoints: {
    total: 100000,
    master: 20000,
    company: 15000,
    distributor: 10000,
    agency: 8000,
    retail: 7000,
    sales1: 5000,
    sales2: 3000,
    sales3: 2000
  },
  settlementDetails: {
    totalFee: 500000,
    supplyPrice: 9500000,
    vat: 950000,
    totalDeposit: 1000000,
    totalDeduction: 300000,
    difference: 200000,
    totalAccountSettlement: 8500000,
    totalMasterPayment: 2000000,
    totalSeniorPayment: -100000,
    merchantSettlement: 8000000,
    walletWithdrawal: 2000000,
    totalSettlement: 10000000,
    retention: 1500000,
    totalOriginalDeduction: 500000,
    retentionPayment: -200000,
    totalDeposited: 9800000,
    merchantPoints: 100000,
    pointRate: 1.5,
    totalAdminPayment: 300000
  }
};

const SummaryTable = ({ searchPanelOpen }) => {
  // 컴포넌트에서 데이터 사용
  const { basicStats, accountPayments, accountPoints, settlementDetails } = paymentData || {};

  // 데이터가 없을 경우를 대비한 기본값 설정
  const safeBasicStats = basicStats || {};
  const safeAccountPayments = accountPayments || {};
  const safeAccountPoints = accountPoints || {};
  const safeSettlementDetails = settlementDetails || {};

  // 안전한 toLocaleString 함수
  const formatNumber = (number) => {
    return (number || 0).toLocaleString();
  };

  const tableContainerStyle = {
    mb: 2, 
    backgroundColor: '#ffffff', 
    boxShadow: 'none',
    width: '100%',
    overflowX: 'auto',
    '& table': {
      minWidth: {
        xs: '1000px',
        sm: '1200px',
        md: '100%'
      }
    },
    '&::-webkit-scrollbar': {
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px'
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          '@import': "url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css')"
        }}
      />
      <Box sx={{ 
        fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
        letterSpacing: '-0.03em',
        mt: 6
      }}>
        <Typography 
          variant="h6" 
          className="summary-header"
          sx={{ 
            mb: 3,
            fontWeight: '600',
            fontSize: '1.3rem',
            fontFamily: 'inherit',
            color: '#1a1a1a'
          }}
        >
          하단 합계 표
        </Typography>
        
        {/* 첫 번째 그룹: 기간별 통계 */}
        <Box sx={{ mb: 4 }}>
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">기간별 거래처</StyledTableCell>
                  <StyledTableCell className="header">기간별 거래건수</StyledTableCell>
                  <StyledTableCell className="header">승인건수</StyledTableCell>
                  <StyledTableCell className="header">취소건수</StyledTableCell>
                  <StyledTableCell className="header">승인-취소 건수</StyledTableCell>
                  <StyledTableCell className="header">정산후취소건수</StyledTableCell>
                  <StyledTableCell className="header">환입건수</StyledTableCell>
                  <StyledTableCell className="header">기간내취소 - 환입건수</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell>{formatNumber(safeBasicStats.dealerCount)}개</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.transactionCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.approvalCount)}건</StyledTableCell>
                  <StyledTableCell className="negative">{formatNumber(safeBasicStats.cancelCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.netCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.periodCancelCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.returnCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.netCancelCount)}건</StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>

          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">기간별 승인액</StyledTableCell>
                  <StyledTableCell className="header">기간별 취소액</StyledTableCell>
                  <StyledTableCell className="header">간별 합계</StyledTableCell>
                  <StyledTableCell className="header">기간내취소</StyledTableCell>
                  <StyledTableCell className="header">미수금액</StyledTableCell>
                  <StyledTableCell className="header">환입금액</StyledTableCell>
                  <StyledTableCell className="header">기간내취소액 - 환입금액</StyledTableCell>
                  <StyledTableCell className="header">RM건수</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell>{formatNumber(safeBasicStats.approvalAmount)}원</StyledTableCell>
                  <StyledTableCell className="negative">{formatNumber(safeBasicStats.cancelAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.periodCancelAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.outstandingAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.returnAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.netCancelAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.rmCount)}건</StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>

          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">총 가맹점 수수료</StyledTableCell>
                  <StyledTableCell className="header">총 보증금액</StyledTableCell>
                  <StyledTableCell className="header">총 가맹점 정산액</StyledTableCell>
                  <StyledTableCell className="header">총 월렛 출금액</StyledTableCell>
                  <StyledTableCell className="header">총 계정 정산액</StyledTableCell>
                  <StyledTableCell className="header">총 마스터지급액</StyledTableCell>
                  <StyledTableCell className="header">총 시니어지급액(6)</StyledTableCell>
                  <StyledTableCell className="header">총 원차감</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalFee)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalDeposit)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalSettlement)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalWalletWithdrawal)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalAccountSettlement)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalMasterPayment)}원</StyledTableCell>
                  <StyledTableCell className="negative">{formatNumber(safeBasicStats.totalSeniorPayment)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeBasicStats.totalDeduction)}원</StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        {/* 두 번째 그룹: 계정 관련 */}
        <Box sx={{ mb: 4 }}>
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="subheader">계정지급액(3)</StyledTableCell>
                  <StyledTableCell className="subheader">마스터 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">자사 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">총판 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">대리점 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">리테일 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">영업1 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">영업2 지급액</StyledTableCell>
                  <StyledTableCell className="subheader">��업3 지급액</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>{formatNumber(safeAccountPayments.total)} 원</StyledTableCell>
                  <StyledTableCell className="negative">{formatNumber(safeAccountPayments.master)} 원 (3%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.company)} 원 (32%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.distributor)} 원 (46%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.agency)} 원 (19%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.retail)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.sales1)} 원 (0%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.sales2)} 원 (0%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPayments.sales3)} 원 (0%)</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>

          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="subheader">계정인트(4)</StyledTableCell>
                  <StyledTableCell className="subheader">마스터 포인트</StyledTableCell>
                  <StyledTableCell className="subheader">자사 포인트</StyledTableCell>
                  <StyledTableCell className="subheader">총판 포인트</StyledTableCell>
                  <StyledTableCell className="subheader">대리점 포인트</StyledTableCell>
                  <StyledTableCell className="subheader">리테일 포트</StyledTableCell>
                  <StyledTableCell className="subheader">영업1 포인트</StyledTableCell>
                  <StyledTableCell className="subheader">영업2 포인트</StyledTableCell>
                  <StyledTableCell className="subheader">영업3 포인트</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>{formatNumber(safeAccountPoints.total)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.master)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.company)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.distributor)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.agency)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.retail)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.sales1)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.sales2)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeAccountPoints.sales3)} 원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>

          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="subheader">계정적립금(5)</StyledTableCell>
                  <StyledTableCell className="subheader">마스터 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">자사 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">총판 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">대리점 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">리테일 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">영업1 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">영업2 적립금</StyledTableCell>
                  <StyledTableCell className="subheader">영업3 적립금</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                  <StyledTableCell>0 원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        {/* 세 번째 그룹: 총계 관련 */}
        <Box sx={{ mb: 4 }}>
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="subheader">총 가맹점수수료(A)</StyledTableCell>
                  <StyledTableCell className="subheader">공급가</StyledTableCell>
                  <StyledTableCell className="subheader">부가세</StyledTableCell>
                  <StyledTableCell className="subheader">총 보증금액(B)</StyledTableCell>
                  <StyledTableCell className="subheader">제액(A)+(B)</StyledTableCell>
                  <StyledTableCell className="subheader">차인(A)+(B)-(3)-(4)-(5)</StyledTableCell>
                  <StyledTableCell className="subheader">총 계정정산액</StyledTableCell>
                  <StyledTableCell className="subheader">총 마스터지급액</StyledTableCell>
                  <StyledTableCell className="subheader">총 시니어지급액(6)</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalFee)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.supplyPrice)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.vat)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalDeposit)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalDeduction)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.difference)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalAccountSettlement)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalMasterPayment)} 원</StyledTableCell>
                  <StyledTableCell className="negative">{formatNumber(safeSettlementDetails.totalSeniorPayment)} 원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>

          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="subheader">총 가맹점정산액(1)</StyledTableCell>
                  <StyledTableCell className="subheader">총 월렛출금액(2)</StyledTableCell>
                  <StyledTableCell className="subheader">총정산 (1)+(2)</StyledTableCell>
                  <StyledTableCell className="subheader">유보 (A)-(3)-(4)-(5)-(E)</StyledTableCell>
                  <StyledTableCell className="subheader">총 원차감(E)</StyledTableCell>
                  <StyledTableCell className="subheader">유보 지급(6)+(7)</StyledTableCell>
                  <StyledTableCell className="subheader">총 입금액</StyledTableCell>
                  <StyledTableCell className="subheader">머천트 적립금(적립률)</StyledTableCell>
                  <StyledTableCell className="subheader">총 어민지급액(7)</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.merchantSettlement)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.walletWithdrawal)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalSettlement)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.retention)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalOriginalDeduction)} 원</StyledTableCell>
                  <StyledTableCell className="negative">{formatNumber(safeSettlementDetails.retentionPayment)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalDeposited)} 원</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.merchantPoints)} 원({formatNumber(safeSettlementDetails.pointRate)}%)</StyledTableCell>
                  <StyledTableCell>{formatNumber(safeSettlementDetails.totalAdminPayment)} 원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        {/* PG사 테이블들 */}
        <Box sx={{ mb: 4 }}>
          {/* AYNIL */}
          <Typography 
            variant="h6"
            className="pg-data-header"
            sx={{ 
              mb: 3,
              fontWeight: '600',
              fontSize: '1.3rem',
              fontFamily: 'inherit',
              color: '#1a1a1a'
            }}
          >
            
          </Typography>
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: '#f5f5f5' }}>
                  <StyledTableCell sx={{ color: '#1976d2', fontWeight: 'bold' }}>AYNIL</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>승인액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>취소액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>차감액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>입금액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>합계</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000' }}>소계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>{(safeBasicStats?.aynilApprovalTotal || 0).toLocaleString()}원</StyledTableCell>
                  <StyledTableCell sx={{ color: 'red', fontWeight: 'bold' }}>-{(safeBasicStats?.aynilCancelTotal || 0).toLocaleString()}원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>{(safeBasicStats?.aynilDeductionTotal || 0).toLocaleString()}원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>{(safeBasicStats?.aynilDepositTotal || 0).toLocaleString()}원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>{(safeBasicStats?.aynilTotal || 0).toLocaleString()}원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        <Box sx={{ mb: 4 }}>
          {/* SPC */}
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: '#f5f5f5' }}>
                  <StyledTableCell sx={{ color: '#1976d2', fontWeight: 'bold' }}>SPC</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>107836</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>113900</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>소계</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>승인액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>8,827,000원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>1,983,950원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>10,810,950원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>취소액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: 'red', fontWeight: 'bold' }}>-621,700원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: 'red', fontWeight: 'bold' }}>-621,700원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>차감액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>입금액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>8,205,300원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>1,983,950원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>10,189,250원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>합계</StyledTableCell>
                  <StyledTableCell colSpan={3} sx={{ color: '#666666', fontWeight: 'bold' }}>10,189,250원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        <Box sx={{ mb: 4 }}>
          {/* EtoU */}
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: '#424242' }}>
                  <StyledTableCell sx={{ color: '#1976d2', fontWeight: 'bold' }}>EtoU</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>승인액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>취소액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>차감액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>입금액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>합계</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>소계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>8,827,000원</StyledTableCell>
                  <StyledTableCell sx={{ color: 'red', fontWeight: 'bold' }}>-621,700원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>1,983,950원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>8,205,300원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>10,189,250원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        <Box sx={{ mb: 4 }}>
          {/* Galaxia */}
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: '#424242' }}>
                  <StyledTableCell sx={{ color: '#1976d2', fontWeight: 'bold' }}>Galaxia</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>M2140389</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>M2248907</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>M2140381</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>소계</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>승인액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>6,599,200원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>990원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>5,000,000원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>11,600,190원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>취소액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: 'red', fontWeight: 'bold' }}>-1,000</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: 'red', fontWeight: 'bold' }}>-1,000원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>차감액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>111,870원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>22,000원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>133,870원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>입금액의 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>6,486,330원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>990원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>4,978,000원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>11,465,320원</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>합계</StyledTableCell>
                  <StyledTableCell colSpan={4} sx={{ color: '#666666', fontWeight: 'bold' }}>11,599,190원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>

        <Box sx={{ mb: 4 }}>
          {/* Kopay */}
          <TableContainer component={Paper} sx={tableContainerStyle}>
            <StyledTable sx={{
              '& .MuiTableCell-root': {
                borderColor: '#e0e0e0',
                minWidth: 'auto',
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: '#424242' }}>
                  <StyledTableCell sx={{ color: '#1976d2', fontWeight: 'bold' }}>Kopay</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>승인액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>취소액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>차감액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>입금액 총합계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>합계</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell sx={{ color: '#000000', fontWeight: 'bold' }}>소계</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                  <StyledTableCell sx={{ color: '#666666', fontWeight: 'bold' }}>0원</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default SummaryTable;
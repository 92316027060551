import React, { useState, useEffect, useCallback } from 'react';
import { Box, Drawer, IconButton, Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useNavigate } from 'react-router-dom';

import ExcelJS from 'exceljs';

// 공통 컴포넌트 import
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import SearchOptions from './CashUserSearch';
import QuickMenu from '../../Common/QuickMenu';
import CashUserTables from './CashUserTables';
import CashResultTables from '../../CashResultTables';

// 스타일 정의
const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden'
  },
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    pl: '50px',
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)',
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '280px',
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      borderLeft: '1px solid #e0e0e0',
      height: 'calc(100% - 64px)',
      top: '64px'
    }
  }
};

function CashUser() {
  const navigate = useNavigate();

  // 검색 패널 상태
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchParams, setSearchParams] = useState({
    startDate: '',
    endDate: '',
    limit: 10
  });
  
  // 테이블 데이터 상태
  const [tableData, setTableData] = useState([]);
  const [getAllMerchantData, setGetAllMerchantData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearchSubmit = (params) => {
    setSearchParams({
      startDate: params.startDate,
      endDate: params.endDate,
      limit: Number(params.limit)
    });
  };

  // 데이터 업데이트 핸들러
  const handleDataUpdate = useCallback(({ data, getAllData }) => {
    setTableData(data);
    setGetAllMerchantData(() => getAllData);
  }, []);

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      // 데이터 존재 여부 체크
      if (!getAllMerchantData || getAllMerchantData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('캐시빌 회원 관리');

      // 헤더 정의
      const headers = [
        'No', '선택', '충전수수료', '충전액', '충전금', '회원아이디', '이름', 
        '휴대폰번호', '정산', '월렛', '출금딜레이', '정산상태', '출금상태', 
        '관리', '등록일', '수정일', '작업자 아이디'
      ];

      // 컬럼 정렬 방식 정의
      const columnAlignments = {
        'No': 'center',
        '선택': 'center',
        '충전수수료': 'right',
        '충전액': 'right',
        '충전금': 'right',
        '회원아이디': 'left',
        '이름': 'left',
        '휴대폰번호': 'left',
        '정산': 'center',
        '월렛': 'center',
        '출금딜레이': 'center',
        '정산상태': 'center',
        '출금상태': 'center',
        '관리': 'center',
        '등록일': 'center',
        '수정일': 'center',
        '작업자 아이디': 'left'
      };

      // 컬럼 설정
      worksheet.columns = headers.map(header => ({
        header,
        width: 15,
        style: {
          alignment: { 
            horizontal: columnAlignments[header], 
            vertical: 'middle' 
          }
        }
      }));

      // 데이터 행 추가
      const rows = getAllMerchantData.map((merchant, index) => [
        index + 1,
        merchant.property?.value || '',
        merchant.deductionRate || '',
        merchant.chargeAmount || '',
        merchant.chargeMoney || '',
        merchant.memberId || '',
        merchant.name || '',
        merchant.phoneNumber || '',
        merchant.settlement || '',
        merchant.wallet || '',
        merchant.withdrawalDelay || '',
        merchant.settlementStatus || '',
        merchant.withdrawalStatus || '',
        merchant.manage || '',
        merchant.registDate || '',
        merchant.modifyDate || '',
        merchant.workerId || ''
      ]);

      worksheet.addRows(rows);

      // 스타일 적용
      worksheet.eachRow((row, rowNumber) => {
        row.height = 18;
        row.eachCell((cell, colNumber) => {
          // 기본 폰트 설정
          cell.font = { 
            size: 9,
            name: '맑은 고딕'
          };

          // 테두리 스타일
          cell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };

          // 헤더 행 스타일
          if (rowNumber === 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF1976D2' }
            };
            cell.font = {
              size: 9,
              color: { argb: 'FFFFFFFF' },
              bold: true,
              name: '맑은 고딕'
            };
          }

          // 정렬 적용
          const header = headers[colNumber - 1];
          cell.alignment = {
            horizontal: columnAlignments[header],
            vertical: 'middle'
          };
        });
      });

      // 컬럼 너비 자동 조정
      worksheet.columns.forEach(column => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
          const cellValue = cell.value !== null && cell.value !== undefined ? cell.value.toString() : '';
          const length = [...cellValue].reduce((acc, char) => {
            return acc + (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(char) ? 1.8 : 1);
          }, 0);
          maxLength = Math.max(maxLength, length);
        });
        column.width = Math.max(6, maxLength + 1);
      });

      // 엑셀 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `캐시빌 회원관리_${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading excel:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: searchPanelOpen && !isMobile ? '280px' : '50px',
    width: '100%',
    transition: 'padding-right 0.3s ease',
    backgroundColor: 'transparent'
  };

  const noticeBoxStyle = {
    ...styles.noticeBox,
    width: 'calc(100% - 0px)',
    transition: 'width 0.3s ease'
  };

  // 담당 등록하기 핸들러 추가
  const handleRegister = () => {
    navigate('/management/cashuser/register');
  };

  return (
    <Box sx={styles.contentWrapper}>
      <Box component="main" sx={mainBoxStyle}>
        {/* 공지사항 영역 */}
        <Box sx={{ 
          ...noticeBoxStyle,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <Notice />
        </Box>

        {/* 현재 위치 */}
        <Box sx={{ 
          ...noticeBoxStyle,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <CurrentLocation mainTitle="관리" subTitle="캐시빌회원관리" />
        </Box>

        {/* 버튼 그룹 추가 */}
<Box sx={{ 
  display: 'flex', 
  alignItems: 'center',
  mt: 3,
  mb: 2,
  gap: 2,
  pr: searchPanelOpen && !isMobile ? '80px' : '0px',
  transition: 'padding-right 0.3s ease',
  flexWrap: 'wrap'  // 모바일에서는 줄바꿈 허용
}}>
  <CashResultTables />
  <Button
    variant="contained"
    startIcon={<PersonAddIcon />}
    onClick={handleRegister}
    sx={{
      backgroundColor: '#2196f3',
      '&:hover': {
        backgroundColor: '#1976d2'
      },
      fontSize: '0.95rem',
      py: 1,
      px: 3,
      height: 'fit-content',
      alignSelf: 'center'
    }}
  >
    회원 등록하기
  </Button>
</Box>

        {/* 캐시빌담당관리 테이블 */}
        <Box sx={{ 
          mt: 3,
          width: '100%',
          transition: 'width 0.3s ease',
          pr: searchPanelOpen && !isMobile ? '80px' : '0px'
        }}>
          <CashUserTables onDataUpdate={handleDataUpdate} />
        </Box>
      </Box>

      {/* 좌측 사이드바 */}
      <QuickMenu />

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            zIndex: 1200,
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearchSubmit}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={handleExcelDownload}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default CashUser; 
import React, { useState } from 'react';
import { 
  Box, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import CancelOptions from '../../Common/CancelOptions';
import MerchantIcons from '../../Common/MerchantIcons';
import RMquestion from '../../Common/RMquestion';
import SetOff from '../../Common/SetOff';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px'
  }
});

function ComplaintDetailsTables({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows 
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [isSetOffModalOpen, setIsSetOffModalOpen] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);

  // dummyData를 먼저 선언
  const dummyData = [
    {
      id: 1,
      no: '1',
      complaintType: '결제취소요청',
      completionStatus: '처리중',
      responseDeadline: '2024-03-25',
      turnCount: '1',
      pgType: 'KG이니시스',
      paymentMethod: '신용카드',
      userId: 'USER001',
      merchantTid: 'TID123456789',
      merchantName: '테스트가맹점1',
      merchantStatus: ['block', 'sms'],
      branchName: '서울지사',
      complaintContent: '취소 요청드립니다.',
      responseContent: '확인 후 처리하겠습니다.',
      registerTime: '2024-03-21 15:30:00',
      registrant: '홍길동',
      userInfo: {
        customerName: '김고객',
        phoneNumber: '010-1234-5678',
        approvalAmount: '50,000원',
        approvalNo: 'APPR12345678',
        paymentDate: '2024-03-20 14:30:00',
        cancelDate: '',
        hasAttachments: true
      }
    },
    {
      id: 2,
      no: '2',
      complaintType: '환불요청',
      completionStatus: '완료',
      responseDeadline: '2024-03-26',
      turnCount: '2',
      pgType: '나이스페이',
      paymentMethod: '계좌이체',
      userId: 'USER002',
      merchantTid: 'TID987654321',
      merchantName: '테스트가맹점2',
      merchantStatus: ['sms'],
      branchName: '부산지사',
      complaintContent: '환불 부탁드립니다.',
      responseContent: '환불 처리 완료했습니다.',
      registerTime: '2024-03-22 10:15:00',
      registrant: '김철수',
      userInfo: {
        customerName: '이고객',
        phoneNumber: '010-9876-5432',
        approvalAmount: '30,000원',
        approvalNo: 'APPR98765432',
        paymentDate: '2024-03-21 09:30:00',
        cancelDate: '2024-03-22 10:20:00',
        hasAttachments: false
      }
    },
    {
      id: 3,
      no: '3',
      complaintType: '오류신고',
      completionStatus: '처리중',
      responseDeadline: '2024-03-27',
      turnCount: '1',
      pgType: '토스페이먼츠',
      paymentMethod: '간편결제',
      userId: 'USER003',
      merchantTid: 'TID456789123',
      merchantName: '테스트가맹점3',
      merchantStatus: ['block'],
      branchName: '대구지사',
      complaintContent: '결제 오류가 발생했습니다.',
      responseContent: '확인 중입니다.',
      registerTime: '2024-03-23 14:45:00',
      registrant: '박영희',
      userInfo: {
        customerName: '박고객',
        phoneNumber: '010-4567-8901',
        approvalAmount: '75,000원',
        approvalNo: 'APPR45678901',
        paymentDate: '2024-03-23 14:40:00',
        cancelDate: '',
        hasAttachments: true
      }
    }
  ];

  // 그 다음에 tableHeaders 정의
  const tableHeaders = [
    { id: 'checkbox', label: <Checkbox 
      size="small"
      onChange={(e) => {
        const allRowIds = dummyData.map(row => row.id);
        if (e.target.checked) {
          setSelectedRows(allRowIds);
        } else {
          setSelectedRows([]);
        }
      }}
      checked={dummyData.length > 0 && selectedRows.length === dummyData.length}
      indeterminate={selectedRows.length > 0 && selectedRows.length < dummyData.length}
    />, color: '#bbdefb', width: '60px' },
    { id: 'no', label: 'No', color: '#42a5f5', width: '50px' },
    // { id: 'detailInfo', label: '상세정보', color: '#42a5f5', width: '80px' },
    { id: 'offsetRefund', label: '상계환입', color: '#42a5f5', width: '80px' },
    { id: 'complaintType', label: '민원종류', color: '#42a5f5', width: '100px' },
    { id: 'completionStatus', label: '완료여부', color: '#42a5f5', width: '80px' },
    { id: 'responseDeadline', label: '회신기한', color: '#64b5f6', width: '100px' },
    { id: 'turnCount', label: '회차수', color: '#64b5f6', width: '60px' },
    { id: 'pgType', label: 'PG구분', color: '#64b5f6', width: '100px' },
    { id: 'paymentMethod', label: '결제수단', color: '#64b5f6', width: '80px' },
    { id: 'userId', label: '로그인ID', color: '#a5d6a7', width: '100px' },
    { id: 'merchantTid', label: '가맹점TID', color: '#a5d6a7', width: '120px' },
    { id: 'merchantName', label: '가맹점명', color: '#a5d6a7', width: '150px' },
    { id: 'branchName', label: '지사명', color: '#a5d6a7', width: '100px' },
    { id: 'complaintContent', label: '민원내용', color: '#ef9a9a', width: '200px' },
    { id: 'responseContent', label: '답변내용', color: '#ef9a9a', width: '200px' },
    { id: 'registerTime', label: '등록시간', color: '#ef9a9a', width: '150px' },
    { id: 'registrant', label: '등록자', color: '#ef9a9a', width: '80px' },
    { id: 'userInfo', label: '결제 정보', color: '#ef9a9a', width: '80px' }
  ];

  const handleCheckboxChange = (checked, rowId) => {
    console.log('Checkbox clicked:', { checked, rowId });
    console.log('Current selectedRows:', selectedRows);
    
    if (checked) {
      setSelectedRows(prev => {
        const newSelection = [...prev, rowId];
        console.log('New selectedRows after adding:', newSelection);
        return newSelection;
      });
    } else {
      setSelectedRows(prev => {
        const newSelection = prev.filter(id => id !== rowId);
        console.log('New selectedRows after removing:', newSelection);
        return newSelection;
      });
    }
  };

  const handleUserIconClick = (userData) => {
    setSelectedUserData(userData);
    setModalOpen(true);
  };

  const handleRMClick = () => {
    if (selectedRows.length > 1) {
      alert('RM 문의는 한 건만 선택 가능합니다.');
      return;
    }
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    
    const selectedRow = dummyData.find(row => row.id === selectedRows[0]);
    setSelectedMerchantData(selectedRow);
    setIsRMModalOpen(true);
  };

  const handleSetOffClick = () => {
    if (selectedRows.length > 1) {
      alert('상계 처리를 위해 한 건만 선택해주세요.');
      return;
    }
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    
    setIsSetOffModalOpen(true);
  };

  // 텍스트 길이를 제한하는 유틸리티 함수
  const truncateText = (text, maxLength = 20) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <>
      <Box 
        className="cancel-options-section"
        sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '20px',
          borderRadius: '8px',
          mb: 3,
          width: '100%',
         
          '@media (max-width: 600px)': {
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '3px'
            }
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          '@media (max-width: 600px)': {
            minWidth: 'max-content'
          }
        }}>
          <CancelOptions 
            selectedItems={selectedRows}
            onCancelOption={(optionType) => {
              switch(optionType) {
                case 'RM':
                  handleRMClick();
                  break;
                case '상계':
                  handleSetOffClick();
                  break;
                case '강제취소':
                  console.log('취소 클릭');
                  break;
              }
            }}
          />
          <Box sx={{ borderLeft: '1px solid #ddd' }} />
          <MerchantIcons />
        </Box>
      </Box>

      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align="center"
                  className="primary-header"
                  style={{ 
                    backgroundColor: header.color,
                    width: header.width
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.id}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.id}-${header.id}`}
                    align={
                      header.id === 'checkbox' || 
                      header.id === 'offsetRefund' ? 'center' : 
                      ['amount', 'refundAmount'].includes(header.id) ? 'right' : 
                      'center'  // BonNote 규칙 2: 텍스트는 중앙정렬
                    }
                  >
                    {header.id === 'checkbox' ? (
                      <Checkbox 
                        checked={selectedRows.includes(row.id)}
                        onChange={(e) => handleCheckboxChange(e.target.checked, row.id)}
                      />
                    ) : header.id === 'offsetRefund' ? (
                      <Checkbox 
                        size="small"
                        onChange={(e) => {
                          console.log('상계환입 체크박스 클릭:', row.id);
                          // 여기에 상계환입 관련 로직 추가
                        }}
                      />
                    ) : header.id === 'complaintContent' || header.id === 'responseContent' ? (
                      <Tooltip 
                        title={
                          <Typography sx={{ 
                            p: 1, 
                            maxWidth: '500px',
                            whiteSpace: 'pre-wrap',
                            fontSize: '0.875rem'
                          }}>
                            {row[header.id]}
                          </Typography>
                        }
                        placement="top-start"
                        arrow
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                            border: '1px solid #e0e0e0'
                          }
                        }}
                      >
                        <Box sx={{ 
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' }
                        }}>
                          {truncateText(row[header.id])}
                        </Box>
                      </Tooltip>
                    ) : header.id === 'merchantName' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {row[header.id]}
                        <MerchantStatusIcons statuses={row.merchantStatus} />
                      </Box>
                    ) : header.id === 'userInfo' ? (
                      <IconButton 
                        size="small"
                        onClick={() => handleUserIconClick(row.userInfo)}
                      >
                        <PersonIcon />
                      </IconButton>
                    ) : (
                      row[header.id]
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />

      <Modal
        open={isSetOffModalOpen}
        onClose={() => setIsSetOffModalOpen(false)}
        aria-labelledby="setoff-modal-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiModal-backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <Box sx={{ 
          width: '95%',
          maxWidth: '1300px',
          minWidth: '1000px',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          height: '90vh',
          overflow: 'hidden'
        }}>
          <SetOff onClose={() => setIsSetOffModalOpen(false)} />
        </Box>
      </Modal>
    </>
  );
}

export default ComplaintDetailsTables;

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Button, Typography, Divider, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBell, 
  faUser, 
  faCircleUser,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';
import UserInfoPanel from './UserInfoPanel';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import AlertMessage from './Common/AlertMessage';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// 스타일 추가
const styles = {
  '@media (max-width: 767px)': {  // 모바일 (iPad Mini 제외)
    '.header-toolbar': {
      padding: '0 8px',
      overflowX: 'auto',
    },
    '.menu-container': {
      gap: '8px',
    },
    '.menu-button': {
      fontSize: '14px',
      padding: '6px 8px',
    },
    '.user-info': {
      display: 'none',
    },
    '.logo-text': {
      display: 'none',
    }
  },
  '@media (min-width: 768px) and (max-width: 1024px)': {  // 태블릿 (iPad Mini 포함)
    '.header-toolbar': {
      padding: '0 16px',
    },
    '.menu-container': {
      gap: '12px',
    },
    '.menu-button': {
      fontSize: '15px',
      padding: '8px 12px',
    },
    '.user-info': {
      display: 'flex',
    },
    '.logo-text': {
      display: 'block',
      fontSize: '1.1rem',
    }
  },
  '@media (min-width: 1025px)': {  // 데스크톱
    '.header-toolbar': {
      padding: '0 24px',
    },
    '.menu-container': {
      gap: '16px',
    },
    '.menu-button': {
      fontSize: '16px',
      padding: '8px 16px',
    },
    '.user-info': {
      display: 'flex',
    },
    '.logo-text': {
      display: 'block',
      fontSize: '1.2rem',
    }
  }
};

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentMenu, setCurrentMenu] = useState('');
  const [userPanelOpen, setUserPanelOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:800px)');
  const [alerts, setAlerts] = useState([]);
  const [alertsShown, setAlertsShown] = useState(false);
  const [notifications, setNotifications] = useState([]); // 알림 목록 저장
  const [anchorEl, setAnchorEl] = useState(null); // 알림 메뉴 앵커

  // 모바일/태블릿 감지 로직 수정
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm')); // 모바일 감지
  const isTabletDevice = useMediaQuery(theme.breakpoints.between('sm', 'lg')); // 태블릿 감지
  const isMobileOrTablet = isMobileDevice || isTabletDevice; // 모바일 또는 태블릿 여부

  // 전체 영역 클릭 핸들러
  React.useEffect(() => {
    const handleClickOutside = () => {
      if (currentMenu && isMobileOrTablet) {
        setCurrentMenu('');
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [currentMenu, isMobileOrTablet]);

  // 메뉴 클릭 핸들러 수정
  const handleMenuClick = (e, key) => {
    e.stopPropagation();
    if (isMobileOrTablet) {  // 모바일이나 태블릿에서 동작
      setCurrentMenu(prevMenu => prevMenu === key ? '' : key);
    }
  };

  // 로고 클릭 핸들러 수정
  const handleLogoClick = (e) => {
    e.stopPropagation();
    setCurrentMenu('');
    navigate('/'); // Dashboard로 이동
  };

  // 서브메뉴 클릭 핸들러 수정
  const handleSubMenuClick = (e) => {
    e.preventDefault();  // 기본 이벤트 방지
    e.stopPropagation();
  };

  // 메뉴 아이템 클릭 핸들러 수정
  const handleMenuItemClick = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    if (isMobileOrTablet) {
      setCurrentMenu(''); // 메뉴 닫기
      setTimeout(() => {
        navigate(path); // 페이지 이동
      }, 10);
    } else {
      navigate(path);
    }
  };

  // 날짜 포맷 수정 - 점 앞에 공백 추가
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year} . ${month} . ${day}`;
  };

  const currentDate = formatDate(new Date());

  const menus = {
    payment: {
      label: '결제',
      subMenus: {
        payment: {
          label: '결제',
          items: [
            { label: '실패내역', path: '/fail-list' },
            { label: '알람내역', path: '/alarm-history' },
            { label: '결제내역', path: '/payment-history' },
            { label: '결제내역집계', path: '/payment-aggregation' },
            { label: '정기결제내역', path: '/auto-payment' }
          ]
        },
        management: {
          label: '결제관리',
          items: [
            { label: '오류내역', path: '/error-log' },
            { label: '중복확인', path: '/duplicate-check' },
            { label: '미실적 가맹점관리', path: '/payment/non-merchant' }
          ]
        },
        cancel: {
          label: '취소관리',
          items: [
            { label: '취소요청 내역', path: '/cancel-requests' },
            { label: '취소완료 내역', path: '/cancel-completed' },
            { label: '임의취소 환입등록', path: '/cancel-refund' }
          ]
        }
      }
    },
    settlement: {
      label: '정산',
      subMenus: {
        main: {
          label: '정산',
          items: [
            { label: '거래민원 내역', path: '/settlement/complaint' },
            { label: '정산달력', path: '/settlement/calendar' }
          ]
        },
        management: {
          label: '정산관리',
          items: [
            { label: '정산예정 내역', path: '/settlement/scheduled' }, 
            { label: '정산보류 내역', path: '/settlement/hold' },
            { label: '정산집계 내역', path: '/settlement/summary' },
            { label: '입금내역', path: '/settlement/deposit' }
          ]
        },
        transfer: {
          label: '송금하기',
          items: [
            { label: '비월렛', path: '/settlement/transfer/non-wallet' },
            { label: '월렛', path: '/settlement/transfer/wallet' }
          ]
        },
        additional: {
          label: '부가서비스',
          items: [
            { label: '월렛입출금 내역', path: '/settlement/wallet-history' },
            { label: '입력검증표', path: '/settlement/input-validation' },
            { label: '세금계 전산매체', path: '/settlement/tax-it' }
          ]
        }
      }
    },
    statistics: {
      label: '통계',
      subMenus: {
        sales: {
          label: '매출통계',
          items: [
            { label: '기간별 통계', path: '/statistics/sales/period' },
            { label: '월별통계', path: '/statistics/sales/monthly' },
            { label: '가맹별 통계', path: '/statistics/sales/merchant' }
          ]
        },
        payment: {
          label: '지급통계',
          items: [
            { label: '가맹별', path: '/statistics/payment/merchant' },
            { label: '영업별', path: '/statistics/payment/sales' }
          ]
        },
        analysis: {
          label: '통계분석',
          items: [
            { label: '기간별', path: '/statistics/analysis/period' },
            { label: '월별', path: '/statistics/analysis/monthly' },
            { label: '가맹별', path: '/statistics/analysis/merchant' },
            { label: '카드사별', path: '/statistics/analysis/card' }
          ]
        }
      }
    },
    management: {
      label: '관리',
      subMenus: {
        account: {
          label: '계정',
          items: [
            { label: '계정관리', path: '/management/account' }
          ]
        },
        merchant: {
          label: '가맹',
          items: [
            { label: '가맹관리', path: '/management/merchant' }
          ]
        },
        cashbill: {
          label: '캐시빌',
          items: [
            { label: '캐시빌 담당관리', path: '/management/cashbill/manager' },
            { label: '캐시빌 회원관리', path: '/management/cashuser/manager' }
          ]
        }
      }
    }
    // ,
    // test: {  // 새로운 테스트 메뉴 추가
    //   label: '테스트 페이지',
    //   subMenus: {
    //     test: {
    //       label: '테스트',
    //       items: [
    //         { label: '테스트 페이지', path: '/test' }
    //       ]
    //     }
    //   }
    // }
  };

  // 상태 표시 색상 스타일
  const statusColors = [
    { color: '#ff0000', count: 4 }, // 빨강
    { color: '#ff9900', count: 2 }, // 주황
    { color: '#ffff00', count: 1 }, // 노랑
    { color: '#0000ff', count: 3 }, // 파랑
    { color: '#ffffff', count: 0 }  // 흰색
  ];

  // 현재 경로에 따른 활성 뉴 확인 함수
  const isActiveMenuItem = (path) => {
    return location.pathname === path;
  };

  // 현재 경로가 특정 메뉴의 하위 항목인지 확인하는 함수
  const isActiveParentMenu = (subMenus) => {
    return Object.values(subMenus).some(subMenu => 
      subMenu.items.some(item => location.pathname === item.path)
    );
  };

  // 메뉴 레이블을 가져오는 함수 추가
  const getMenuLabel = (key, menu) => {
    return menu.label;  // 항상 메인 메뉴 라벨만 반환
  };

  // 알림 제거 함수 수정
  const removeAlert = (id) => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
    // 마지막 알림이 제거될 때 alertsShown을 true로 유지
    // 이렇게 하면 한 번 모두 닫은 후에는 다시 표시되지 않음
  };

  // useEffect 수정
  useEffect(() => {
    const redStatus = statusColors.find(status => status.color === '#ff0000');
    if (!isMobile && redStatus && redStatus.count > 0 && !alertsShown) {
      setAlerts([
        {
          id: Date.now(),
          message: '[긴급실패] 1046930753번 진행확인! ERROR_CODE: B012',
          type: 'error'
        },
        {
          id: Date.now() + 1,
          message: '[긴급실패] 1046930754번 진행확인! ERROR_CODE: B013',
          type: 'error'
        }
      ]);
      setAlertsShown(true);
    }
  }, [isMobile, alertsShown]);

  // 알림 메뉴 열기/닫기 핸들러
  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  // 알림 추가 함수 수정
  const addNotification = (notification) => {
    setNotifications(prev => [notification, ...prev]);
    
    setAlerts(prev => [...prev, {
      id: Date.now(),
      message: notification.message,
      type: notification.type,
      style: {
        backgroundColor: notification.style.backgroundColor.replace(' !important', ''),
        color: notification.style.color.replace(' !important', ''),
        fontWeight: notification.style.fontWeight
      }
    }]);
  };

  // 초기 알림 데이터
  const initialNotifications = [
    {
      id: 1,
      message: "[긴급실패] 1046930753번 진행확인! ERROR_CODE: B012",
      type: "error",
      time: new Date().toISOString(),
      style: {
        backgroundColor: '#ffebee',  // 연한 빨간색 배경
        color: '#d32f2f'  // 진한 빨간색 텍스트
      }
    },
    {
      id: 2,
      message: "[정보] 시스템 점검 완료",
      type: "info",
      time: new Date().toISOString(),
      style: {
        backgroundColor: '#e3f2fd',  // 연한 파란색 배경
        color: '#1976d2'  // 진한 파란색 텍스트
      }
    }
  ];

  // 컴포넌트 마운트 시 초기 알림 설정
  useEffect(() => {
    setNotifications(initialNotifications);
  }, []);

  // 10초마다 새로운 알림 추가
  useEffect(() => {
    const timer = setTimeout(() => {
      // 에러 알림 추가
      addNotification({
        id: Date.now(),
        message: "[긴급] 머천트 전액부족 8,000,000 기준금액 미달",
        type: "error",
        time: new Date().toISOString(),
        style: {
          backgroundColor: '#ff4444 !important',  
          color: '#ffffff !important'  
        }
      });
    }, 10000);

    const timer2 = setTimeout(() => {
      // 정보 알림 추가
      addNotification({
        id: Date.now() + 1,
        message: "[알림] 정산 처리가 완료되었습니다",
        type: "info",
        time: new Date().toISOString(),
        style: {
          backgroundColor: '#2196f3 !important',  
          color: '#000000 !important',
          fontWeight: 'bold'
        }
      });
    }, 15000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  // 알림 클릭 핸들러 수정
  const handleNotificationItemClick = (notification) => {
    handleNotificationClose();
    navigate('/alarm-history');  // AlarmHistory 페이지로 이동
  };

  return (
    <>
      {/* 헤더 */}
      <AppBar position="fixed" sx={{ 
        backgroundColor: '#f0f0f0',
        fontFamily: "'Font Awesome 6 Free', 'Noto Sans KR', sans-serif",
        zIndex: 1300,
        ...styles
      }} elevation={1}>
        <Toolbar className="header-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', py: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobileOrTablet ? 0.5 : 2 }}>
            <Box className="menu-container" sx={{ display: 'flex', gap: isMobileOrTablet ? 1 : 2 }}>
              {/* P&IMS 로고 - 모바일에서만 클릭 가능 */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: isMobileOrTablet ? 1 : 2,
                  cursor: 'pointer'  // 모든 디바이스에서 커 포인터
                }}
                onClick={handleLogoClick}  // 모든 디바이스에서 클릭 가능
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: '#1976d2',
                    fontSize: '24px',
                    fontFamily: 'Arial',
                    letterSpacing: '0.5px',
                    background: 'linear-gradient(45deg, #1976d2, #2196f3)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      width: '32px',
                      height: '32px',
                      borderRadius: '8px',
                      background: 'linear-gradient(45deg, #1976d2, #2196f3)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 900,
                      fontSize: '25px',
                      boxShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                    }}
                  >
                    <span style={{ 
                      background: 'linear-gradient(45deg, #ffffff, #e3f2fd)',  // 흰색-하늘색 그라데이션
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      // 또는 단순 흰색으로 하려면:
                      // color: '#ffffff',
                      // WebkitTextFillColor: '#ffffff'
                    }}>
                      P
                    </span>
                  </Box>
                  <span className="logo-text" style={{ 
                    fontSize: '30px',  // P와 동일한 크기로 설정
                    display: 'flex',
                    alignItems: 'center',  // 수직 정렬
                    height: '32px',  // P 박스와 동일한 높이
                  }}>
                    &IMS
                  </span>
                </Typography>
              </Box>

              {/* 메뉴 부 수정 */}
              {Object.entries(menus).map(([key, menu]) => (
                <Box
                  key={key}
                  onMouseEnter={() => !isMobileOrTablet && setCurrentMenu(key)}
                  onMouseLeave={() => !isMobileOrTablet && setCurrentMenu('')}
                  sx={{ 
                    position: 'relative',
                    '&:hover > .MuiBox-root': {
                      display: !isMobileOrTablet ? 'block' : 'none'
                    },
                    zIndex: 1500
                  }}
                >
                  <Button
                    className="menu-button"
                    onClick={(e) => handleMenuClick(e, key)}
                    sx={{ 
                      color: isActiveParentMenu(menu.subMenus) ? 'primary.main' : '#000000',
                      textTransform: 'none',
                      fontSize: isMobileOrTablet ? '16px' : '18px',
                      fontWeight: 700,
                      borderBottom: isActiveParentMenu(menu.subMenus) ? 2 : 0,
                      borderColor: 'primary.main',
                      borderRadius: 0,
                      px: isMobileOrTablet ? 1 : 2,
                      py: 1
                    }}
                  >
                    {menu.label}
                  </Button>
                  
                  {/* 서브 메뉴 표시 */}
                  {(!isMobileOrTablet && currentMenu === key) && menu.subMenus && (
                    <Box
                      className="MuiBox-root"
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        backgroundColor: 'white',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        borderRadius: 1,
                        minWidth: '200px',
                        zIndex: 1400,
                        p: 1
                      }}
                    >
                      {Object.entries(menu.subMenus).map(([subKey, subMenu]) => (
                        <Box key={subKey}>
                          <Typography
                            sx={{
                              px: 2,
                              py: 1,
                              fontWeight: 600,
                              color: '#1976d2',
                              fontSize: '1.1rem'
                            }}
                          >
                            {subMenu.label}
                          </Typography>
                          {subMenu.items?.map((item) => (
                            <Button
                              key={item.path}
                              onClick={(e) => handleMenuItemClick(e, item.path)}
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                                textTransform: 'none',
                                py: 0.5,
                                px: 2,
                                color: isActiveMenuItem(item.path) ? 'primary.main' : '#000000',
                                backgroundColor: isActiveMenuItem(item.path) ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                                fontWeight: 700,
                                '&:hover': {
                                  backgroundColor: isActiveMenuItem(item.path) 
                                    ? 'rgba(25, 118, 210, 0.12)' 
                                    : '#f5f5f5'
                                }
                              }}
                            >
                              {item.label}
                            </Button>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2
          }}>
            {!isMobileOrTablet && (
              <>
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                  <Badge badgeContent={notifications.length} color="error">
                    <NotificationsActiveIcon 
                      onClick={handleNotificationClick}
                      sx={{ 
                        color: notifications.length > 0 ? '#ff0000' : '#757575',
                        fontSize: '28px',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.8
                        }
                      }} 
                    />
                  </Badge>
                  
                  {/* 알림 드롭다운 메뉴 */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleNotificationClose}
                    sx={{ 
                      mt: 2,
                      '& .MuiPaper-root': { 
                        width: 320,
                        maxHeight: 400
                      }
                    }}
                  >
                    {notifications.length === 0 ? (
                      <MenuItem disabled>새로운 알림이 없습니다</MenuItem>
                    ) : (
                      notifications.map((notification) => (
                        <MenuItem 
                          key={notification.id}
                          onClick={() => handleNotificationItemClick(notification)}
                          sx={{
                            whiteSpace: 'normal',
                            py: 1,
                            borderBottom: '1px solid #eee',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 0.5,
                            ...notification.style
                          }}
                        >
                          <Typography variant="body2">
                            {notification.message}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: '#000000',  // 날짜 텍스트 색상을 검은색으로 변경
                              fontWeight: 500    // 약간의 굵기 추가
                            }}
                          >
                            {new Date(notification.time).toLocaleString()}
                          </Typography>
                        </MenuItem>
                      ))
                    )}
                  </Menu>
                </Box>
                
                {/* 상태 표시 dots - 숫자 추가 */}
                <Box sx={{ display: 'flex', gap: 0.5, ml: 1 }}>
                  {statusColors.map((status, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: 24,  // 크기 더 증가
                        height: 24, // 크기 더 증가
                        borderRadius: '50%',
                        backgroundColor: status.color,
                        border: status.color === '#ffffff' ? '1px solid #ddd' : 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: status.color === '#ffff00' ? '#000' : '#fff', // 노란색 배경일 때는 검은색 텍스트
                        position: 'relative'
                      }}
                    >
                      {status.count > 0 && status.count}
                    </Box>
                  ))}
                </Box>

                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: '#000000',
                    fontFamily: "'Font Awesome 6 Free', 'Noto Sans KR', sans-serif"
                  }}
                >
                  {currentDate}
                </Typography>

                <Box 
                  className="user-info"
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 }
                  }}
                  // onClick={() => setUserPanelOpen(true)}
                >
                  {/* <FontAwesomeIcon icon={faUser} style={{ color: '#000000' }} /> */}
                  <Typography
                    onClick={() => setUserPanelOpen(true)}
                    sx={{
                      
                      color: '#000000',
                      fontSize: '0.9rem',
                      fontWeight: 'bold',
                      fontFamily: "'Font Awesome 6 Free', 'Noto Sans KR', sans-serif",
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    <span>&nbsp;&nbsp;&nbsp;IP주소: 127.0.0.1</span>&nbsp;<span>환영합니다 bnine19306님</span>
                  </Typography>
                </Box>
              </>
            )}
            {/* <IconButton 
              sx={{ color: '#000000' }}
              onClick={() => setUserPanelOpen(true)}
            >
              <FontAwesomeIcon icon={faUser} />
            </IconButton> */}
          </Box>

          
        </Toolbar>
      </AppBar>

      {/* 알람 메시지 영역 - top 값 증가 */}
      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            top: '80px',
            left: '20px',
            zIndex: 1200,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'  // 알림 이 간격
          }}
        >
          {alerts.map(alert => (
            <AlertMessage
              key={alert.id}
              message={alert.message}
              type={alert.type}
              style={alert.style}  // 스타일 직접 전달
              duration={3000}
              onClose={() => removeAlert(alert.id)}
            />
          ))}
        </Box>
      )}

      {/* 메인 컨텐츠 */}
      <Box sx={{ width: '100%' }}>
        {/* 모바일/태블릿용 사이드 메뉴 */}
        {isMobileOrTablet && (
          <Box
            sx={{
              position: 'fixed',
              top: '64px',
              left: 0,
              width: '280px',
              height: 'calc(100vh - 64px)',
              backgroundColor: '#ffffff',
              boxShadow: '2px 0 8px rgba(0,0,0,0.15)',
              transition: 'all 0.3s ease-in-out',
              transform: currentMenu ? 'translateX(0)' : 'translateX(-100%)',
              opacity: currentMenu ? 1 : 0,
              visibility: currentMenu ? 'visible' : 'hidden',
              zIndex: 1200,
              overflowY: 'auto'
            }}
          >
            {/* 모바일 메뉴 헤더 */}
            <Box sx={{ 
              p: 2, 
              borderBottom: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {currentMenu && menus[currentMenu]?.label}
              </Typography>
              <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentMenu('');
                }}
                size="small"
              >
                ✕
              </IconButton>
            </Box>

            {/* 모바일 서브메뉴 내용 */}
            {currentMenu && menus[currentMenu]?.subMenus && (
              Object.entries(menus[currentMenu].subMenus).map(([subKey, subMenu]) => (
                <Box key={subKey}>
                  <Typography
                    sx={{
                      px: 2,
                      py: 1,
                      fontWeight: 600,
                      color: '#1976d2',
                      fontSize: '1.1rem'
                    }}
                  >
                    {subMenu.label}
                  </Typography>
                  {subMenu.items?.map((item) => (
                    <Button
                      key={item.path}
                      onClick={(e) => handleMenuItemClick(e, item.path)}
                      sx={{
                        width: '100%',
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        py: 0.5,
                        px: 2,
                        color: isActiveMenuItem(item.path) ? 'primary.main' : '#000000',
                        backgroundColor: isActiveMenuItem(item.path) ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                        fontWeight: 700,
                        '&:hover': {
                          backgroundColor: isActiveMenuItem(item.path) 
                            ? 'rgba(25, 118, 210, 0.12)' 
                            : '#f5f5f5'
                        }
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Box>
              ))
            )}
          </Box>
        )}

        {/* 모바일/태블릿 메뉴 열려있을 때의 오버레이 */}
        {isMobileOrTablet && currentMenu && (
          <Box
            onClick={() => setCurrentMenu('')}
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1100
            }}
          />
        )}

        <UserInfoPanel 
          open={userPanelOpen}
          onClose={() => setUserPanelOpen(false)}
        />
      </Box>
    </>
  );
}

export default Header;

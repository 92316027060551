import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90vw',
    maxWidth: '600px',
    minHeight: '200px',
    maxHeight: '80vh',
    borderRadius: '8px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

const StyledTableCell = styled(TableCell)({
  padding: '8px 12px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  borderRight: '1px solid #edf2f9',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  '&.header': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    height: '42px'
  },
  '&.body': {
    height: '36px'
  },
  '&:last-child': {
    borderRight: 'none'
  }
});

const DetailPopup = ({ open, onClose, data }) => {
  // 더미 데이터 (실제 데이터로 교체 필요)
  const dummyData = {
    branch: 'rsm',
    agency: '대리점',
    loginId: 'r95215900',
    representative: '최명환',
    businessNumber: '780927-1552012',
    companyName: '신한은행',
    accountHolder: '최명환',
    accountNumber: '858-13-008501',
    contact: '010-9521-5900',
    mobile: '010-9521-5900',
    address: '광주 동구 선교로 38-30 103동 1801호',
    cardNumber: '5181-85**-****-****'
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <StyledDialogTitle>
        계정정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell className="header">No</StyledTableCell>
                <StyledTableCell className="header">항목</StyledTableCell>
                <StyledTableCell className="header">데이터</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell className="body">1</StyledTableCell>
                <StyledTableCell className="body">지사</StyledTableCell>
                <StyledTableCell className="body">{data?.branch || dummyData.branch}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">2</StyledTableCell>
                <StyledTableCell className="body">대리점</StyledTableCell>
                <StyledTableCell className="body">{data?.agency || dummyData.agency}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">3</StyledTableCell>
                <StyledTableCell className="body">로그인 아이디</StyledTableCell>
                <StyledTableCell className="body">{data?.loginId || dummyData.loginId}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">4</StyledTableCell>
                <StyledTableCell className="body">대표자명</StyledTableCell>
                <StyledTableCell className="body">{data?.representative || dummyData.representative}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">5</StyledTableCell>
                <StyledTableCell className="body">사업자번호/주민번호</StyledTableCell>
                <StyledTableCell className="body">{data?.businessNumber || dummyData.businessNumber}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">6</StyledTableCell>
                <StyledTableCell className="body">은행명</StyledTableCell>
                <StyledTableCell className="body">{data?.companyName || dummyData.companyName}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">7</StyledTableCell>
                <StyledTableCell className="body">예금주</StyledTableCell>
                <StyledTableCell className="body">{data?.accountHolder || dummyData.accountHolder}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">8</StyledTableCell>
                <StyledTableCell className="body">계좌번호</StyledTableCell>
                <StyledTableCell className="body">{data?.accountNumber || dummyData.accountNumber}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">9</StyledTableCell>
                <StyledTableCell className="body">연락처</StyledTableCell>
                <StyledTableCell className="body">{data?.contact || dummyData.contact}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">10</StyledTableCell>
                <StyledTableCell className="body">휴대번호</StyledTableCell>
                <StyledTableCell className="body">{data?.mobile || dummyData.mobile}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">11</StyledTableCell>
                <StyledTableCell className="body">주소</StyledTableCell>
                <StyledTableCell className="body">{data?.address || dummyData.address}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="body">12</StyledTableCell>
                <StyledTableCell className="body">카드번호</StyledTableCell>
                <StyledTableCell className="body">{data?.cardNumber || dummyData.cardNumber}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default DetailPopup; 
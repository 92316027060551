import React, { useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

const AlertMessage = ({ message, type = 'error', duration = 10000, onClose, style }) => {
  const getAlertStyle = (type) => {
    const styles = {
      error: {
        backgroundColor: 'rgba(235, 87, 87, 0.85)',
        borderLeft: '4px solid #ff0000',
      },
      warning: {
        backgroundColor: 'rgba(255, 152, 0, 0.85)',
        borderLeft: '4px solid #ff9800',
      }
    };
    return styles[type];
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <Box
      sx={{
        color: 'white',
        padding: '12px 45px 12px 16px',
        borderRadius: '4px',
        marginBottom: '8px',
        position: 'relative',
        minWidth: '300px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        animation: 'slideIn 0.5s ease-out',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        zIndex: 1200,
        '@keyframes slideIn': {
          from: {
            transform: 'translateX(-100%)',
            opacity: 0
          },
          to: {
            transform: 'translateX(0)',
            opacity: 1
          }
        },
        ...(style || getAlertStyle(type))
      }}
    >
      {type === 'error' ? (
        <ErrorIcon sx={{ fontSize: '20px' }} />
      ) : (
        <WarningIcon sx={{ fontSize: '20px' }} />
      )}
      {message}
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'inherit',
          padding: '4px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          }
        }}
      >
        ✕
      </IconButton>
    </Box>
  );
};

export default AlertMessage; 
import React from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Divider,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)({
  padding: '6px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  '&.MuiTableCell-head': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    textAlign: 'center'
  }
});

const SummaryBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  padding: '12px 0',
  borderRadius: '4px',
  backgroundColor: '#f8f9fa',
  marginBottom: '16px'
});

// 더미 데이터 생성
const generateDummyData = () => {
  return Array(10).fill(null).map((_, index) => ({
    보류: false,
    상계: false,
    No: index + 1,
    가맹점MID: `MID${String(index + 1).padStart(8, '0')}`,
    가맹점TID: `TID${String(index + 1).padStart(8, '0')}`,
    가맹점명: `테스트가맹점${index + 1}`,
    거래일시: `2024-03-${String(20 + index).padStart(2, '0')} 14:30:00`,
    취소여부: ['승인', '취소'][Math.floor(Math.random() * 2)],
    승인번호: Math.floor(Math.random() * 100000000).toString(),
    발급사: ['신한카드', '삼성카드', '현대카드', 'KB국민'][Math.floor(Math.random() * 4)],
    승인금액: Math.floor(Math.random() * 1000000) + 500000,
    취소금액: Math.floor(Math.random() * 100000),
    실매출금액: function() { return this.승인금액 - this.취소금액; },
    가맹점수수료율: (Math.random() * 3 + 1).toFixed(2),
    수수료VAT포함: function() { return Math.floor(this.실매출금액() * (parseFloat(this.가맹점수수료율) / 100) * 1.1); },
    보증금율: '0.5%',
    보증금액: function() { return Math.floor(this.실매출금액() * 0.005); },
    입금액: function() { return this.실매출금액() - this.수수료VAT포함() - this.보증금액(); }
  }));
};

const DetailInfo = ({ open, onClose }) => {
  const dummyData = generateDummyData();
  
  // 합계 계산
  const totalApproved = dummyData.reduce((sum, item) => sum + item.승인금액, 0);
  const totalCanceled = dummyData.reduce((sum, item) => sum + item.취소금액, 0);
  const total = totalApproved - totalCanceled;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="detail-info-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '98%',
        maxWidth: '1800px',
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 2
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2 
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            세부내역
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <SummaryBox>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">승인금액</Typography>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
              {totalApproved.toLocaleString()}원
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">취소금액</Typography>
            <Typography variant="h6" color="error" sx={{ fontWeight: 600 }}>
              {totalCanceled.toLocaleString()}원
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">합계</Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {total.toLocaleString()}원
            </Typography>
          </Box>
        </SummaryBox>

        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 250px)' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>보류</StyledTableCell>
                <StyledTableCell>상계</StyledTableCell>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>가맹점MID</StyledTableCell>
                <StyledTableCell>가맹점TID</StyledTableCell>
                <StyledTableCell>가맹점명</StyledTableCell>
                <StyledTableCell>거래일시</StyledTableCell>
                <StyledTableCell>취소여부</StyledTableCell>
                <StyledTableCell>승인번호</StyledTableCell>
                <StyledTableCell>발급사</StyledTableCell>
                <StyledTableCell align="right">승인금액</StyledTableCell>
                <StyledTableCell align="right">취소금액</StyledTableCell>
                <StyledTableCell align="right">실매출금액</StyledTableCell>
                <StyledTableCell align="right">가맹점수수료율</StyledTableCell>
                <StyledTableCell align="right">수수료(VAT포함)</StyledTableCell>
                <StyledTableCell align="right">보증금율</StyledTableCell>
                <StyledTableCell align="right">보증금액</StyledTableCell>
                <StyledTableCell align="right">입금액</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell><Checkbox size="small" /></StyledTableCell>
                  <StyledTableCell><Checkbox size="small" /></StyledTableCell>
                  <StyledTableCell>{row.No}</StyledTableCell>
                  <StyledTableCell>{row.가맹점MID}</StyledTableCell>
                  <StyledTableCell>{row.가맹점TID}</StyledTableCell>
                  <StyledTableCell>{row.가맹점명}</StyledTableCell>
                  <StyledTableCell>{row.거래일시}</StyledTableCell>
                  <StyledTableCell>{row.취소여부}</StyledTableCell>
                  <StyledTableCell>{row.승인번호}</StyledTableCell>
                  <StyledTableCell>{row.발급사}</StyledTableCell>
                  <StyledTableCell align="right">{row.승인금액.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.취소금액.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.실매출금액().toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.가맹점수수료율}%</StyledTableCell>
                  <StyledTableCell align="right">{row.수수료VAT포함().toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.보증금율}</StyledTableCell>
                  <StyledTableCell align="right">{row.보증금액().toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.입금액().toLocaleString()}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default DetailInfo;

import React, { useState } from 'react';
import { 
  Box, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  Select, 
  MenuItem, 
  TextField,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DateRangePicker from './DateRangePicker';

const StyledBox = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '16px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px'
});

const GroupBox = styled(Box)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  minWidth: '100px',
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: '#fff',
  padding: '0 4px',
  '& .MuiFormControlLabel-root': {
    marginRight: '0',
    marginLeft: '0',
    '& .MuiRadio-root': {
      padding: '2px'
    },
    '& .MuiTypography-root': {
      fontSize: '0.75rem'
    }
  },
  '& .MuiRadioGroup-root': {
    marginLeft: '2px'
  },
  '&::before': {
    content: 'attr(data-title)',
    position: 'absolute',
    top: '-8px',
    left: '8px',
    padding: '0 4px',
    backgroundColor: '#fff',
    fontSize: '0.7rem',
    color: '#666',
    whiteSpace: 'nowrap'
  }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    padding: '4px 8px',
    fontSize: '0.75rem',
    height: '42px',
    border: '1px solid #e0e0e0',
    '&.Mui-selected': {
      backgroundColor: '#1976d2',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1565c0',
        color: '#fff'
      }
    }
  }
}));

const LabelWrapper = styled(Box)({
  position: 'relative',
  '& .label': {
    position: 'absolute',
    top: '-24px',
    left: '0',
    fontSize: '0.85rem',
    color: '#666',
    backgroundColor: 'transparent'
  }
});

const SearchArea = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountMatch, setAccountMatch] = useState('미검증');
  const [withdrawalLimit, setWithdrawalLimit] = useState('출금가능');
  const [businessAccount, setBusinessAccount] = useState('지사');
  const [historyType, setHistoryType] = useState('머천트 내역');
  const [merchantAccount, setMerchantAccount] = useState('가맹');
  const [merchantType, setMerchantType] = useState('카드결제');
  const [pageSize, setPageSize] = useState('100');
  const [searchText, setSearchText] = useState('');

  const handleAccountMatchChange = (event, newValue) => {
    if (newValue === null) {
      setAccountMatch(null);
    } else {
      setAccountMatch(newValue === accountMatch ? null : newValue);
    }
  };

  const handleSearch = () => {
    console.log('검색 파라미터:', {
      dateRange: { startDate, endDate },
      accountMatch,
      withdrawalLimit,
      businessAccount,
      historyType,
      merchantAccount,
      merchantType,
      pageSize,
      searchText
    });
  };

  return (
    <StyledBox>
      {/* 날짜 선택 */}
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />

      {/* 라디오 버튼 그룹들 먼저 배치 */}
      {/* 출금제한 */}
      <GroupBox data-title="출금제한">
        <RadioGroup row value={withdrawalLimit} onChange={(e) => setWithdrawalLimit(e.target.value)}>
          <FormControlLabel value="출금가능" control={<Radio size="small" />} label="출금가능" />
          <FormControlLabel value="출금불가" control={<Radio size="small" />} label="출금불가" />
        </RadioGroup>
      </GroupBox>

      {/* 영업계정 */}
      <GroupBox data-title="영업계정">
        <RadioGroup row value={businessAccount} onChange={(e) => setBusinessAccount(e.target.value)}>
          <FormControlLabel value="지사" control={<Radio size="small" />} label="지사" />
          <FormControlLabel value="대리점" control={<Radio size="small" />} label="대리점" />
        </RadioGroup>
      </GroupBox>

      {/* 가맹/계정 */}
      <GroupBox data-title="가맹/계정">
        <FormControl>
          <RadioGroup
            row
            value={merchantAccount}
            onChange={(e) => setMerchantAccount(e.target.value)}
          >
            <FormControlLabel value="가맹" control={<Radio size="small" />} label="가맹" />
            <FormControlLabel value="계정" control={<Radio size="small" />} label="계정" />
          </RadioGroup>
        </FormControl>
      </GroupBox>

      {/* 셀렉트 박스와 토글 */}
      {/* 내역조회 */}
      <FormControl size="medium" sx={{ minWidth: '140px' }}>
        <Select
          value={historyType}
          onChange={(e) => setHistoryType(e.target.value)}
        >
          <MenuItem value="머천트 내역">머천트 내역</MenuItem>
          <MenuItem value="계정입금내역">계정입금내역</MenuItem>
          <MenuItem value="계정 출금내역">계정 출금내역</MenuItem>
          <MenuItem value="미출금 내역">미출금 내역</MenuItem>
          <MenuItem value="수기증감 내역">수기증감 내역</MenuItem>
        </Select>
      </FormControl>

      {/* 계좌 일치여부 */}
      <LabelWrapper>
        <span className="label">계좌 일치여부</span>
        <StyledToggleButtonGroup
          value={accountMatch}
          exclusive
          onChange={handleAccountMatchChange}
          size="medium"
        >
          <ToggleButton value="미검증">미검증</ToggleButton>
          <ToggleButton value="계좌일치">계좌일치</ToggleButton>
          <ToggleButton value="계좌불일치">계좌불일치</ToggleButton>
        </StyledToggleButtonGroup>
      </LabelWrapper>

      {/* 머천트 타입 */}
      <FormControl size="medium" sx={{ minWidth: '140px' }}>
        <Select
          value={merchantType}
          onChange={(e) => setMerchantType(e.target.value)}
        >
          <MenuItem value="카드결제">카드결제</MenuItem>
          <MenuItem value="현금결제">현금결제</MenuItem>
          <MenuItem value="예비1">예비1</MenuItem>
          <MenuItem value="상품권">상품권</MenuItem>
        </Select>
      </FormControl>

      {/* 페이지 크기 */}
      <FormControl size="medium" sx={{ minWidth: '80px' }}>
        <Select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
        >
          <MenuItem value="30">30</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
          <MenuItem value="200">200</MenuItem>
        </Select>
      </FormControl>

      {/* 검색 필드와 버튼 */}
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <TextField
          size="medium"
          placeholder="머천트 아이디, 계좌번호"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ width: '220px' }}
        />
        <IconButton 
          onClick={handleSearch}
          sx={{ 
            backgroundColor: '#1976d2', 
            color: 'white',
            padding: '12px',
            '&:hover': {
              backgroundColor: '#1565c0'
            }
          }}
        >
          <SearchIcon sx={{ fontSize: '1.2rem' }} />
        </IconButton>
      </Box>
    </StyledBox>
  );
};

export default SearchArea;
import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Grow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SummarizeIcon from '@mui/icons-material/Summarize';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

function DownloadButtons({ onExcelDownload }) {
  const [showOptions, setShowOptions] = useState(false);
  const isMobile = window.innerWidth <= 768;

  // 현재 경로 확인
  const currentPath = window.location.pathname;

  // 비활성화할 경로 목록
  const disabledPaths = [
    '/statistics/analysis/merchant',
    '/statistics/analysis/card'
  ];

  const handleButtonClick = (type) => {
    switch(type) {
      case 'excel':
        onExcelDownload?.();
        break;
      // ... 다른 다운로드 타입들에 대한 처리
    }
  };

  const buttons = [
    { 
      label: '엑셀 다운로드', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: () => handleButtonClick('excel')
    },
    { 
      label: '개별정산', 
      icon: <AccountBalanceWalletIcon />,
      color: '#1976d2'
    },
    { 
      label: '정산합계', 
      icon: <SummarizeIcon />,
      color: '#1976d2'
    },
    { 
      label: '개별영업', 
      icon: <StorefrontIcon />,
      color: '#ed6c02'
    },
    { 
      label: '영업합계', 
      icon: <ReceiptLongIcon />,
      color: '#ed6c02'
    },
    { 
      label: '유보합계', 
      icon: <SaveAltIcon />,
      color: '#9c27b0'
    }
  ];

  // 비활성화된 경로에 따라 버튼을 필터링
  const filteredButtons = disabledPaths.includes(currentPath) ? [] : buttons;

  return (
    <Box 
      sx={{ 
        width: isMobile ? 'auto' : '260px',
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flexWrap: 'wrap'
      }}
      onMouseEnter={() => !isMobile && setShowOptions(true)}
      onMouseLeave={() => !isMobile && setShowOptions(false)}
    >
      {isMobile ? (
        filteredButtons.map((button, index) => (
          <Tooltip key={index} title={button.label}>
            <IconButton
              onClick={button.onClick}
              sx={{
                width: index === 0 ? '40px' : '36px',
                height: index === 0 ? '40px' : '36px',
                backgroundColor: '#fff',
                border: `1px solid ${button.color}`,
                color: button.color,
                '&:hover': {
                  backgroundColor: `${button.color}10`,
                }
              }}
            >
              {button.icon}
            </IconButton>
          </Tooltip>
        ))
      ) : (
        <>
          {filteredButtons.length > 0 && (
            <Tooltip title={filteredButtons[0].label}>
              <IconButton
                onClick={filteredButtons[0].onClick}
                sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: `1px solid ${filteredButtons[0].color}`,
                  color: filteredButtons[0].color,
                  '&:hover': {
                    backgroundColor: `${filteredButtons[0].color}10`,
                  }
                }}
              >
                {filteredButtons[0].icon}
              </IconButton>
            </Tooltip>
          )}
          
          {showOptions && filteredButtons.slice(1).map((button, index) => (
            <Grow key={index} in={showOptions}>
              <Tooltip title={button.label}>
                <IconButton
                  onClick={button.onClick}
                  sx={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            </Grow>
          ))}
        </>
      )}
    </Box>
  );
}

export default DownloadButtons;
import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote PC버전 스타일 컴포넌트 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px',
      
      '&[data-field="amount"]': {
        height: '40px',
        lineHeight: '40px'
      }
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  }
});

// 차트 래퍼 스타일 컴포넌트 추가
const ChartWrapper = styled(Box)({
  width: '100%',
  mt: 3,
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function IlbyeolData() {
  // 더미 데이터 생성
  const dummyData = {
    거래건수: [6536, 110, 6426],
    '합계': [957183810, -20769505, 978053315],
    '01일': [26281590, -106000, 26387590],
    '02일': [118284582, -810400, 119094982],
    '03일': [70471332, -2026104, 72497436],
    '04일': [36718478, -745400, 37463878],
    '05일': [17424000, -83000, 17507000],
    '06일': [83003810, -1952718, 84956328],
    '07일': [66956422, -50047, 67006469],
    '08일': [82486116, -773726, 83259842],
    '09일': [66056860, -1383916, 67440776],
    '10일': [119224270, -6982210, 126206480],
    '11일': [39207900, -86154, 39294054],
    '12일': [36150530, -61500, 36212030],
    '13일': [48562140, -156240, 48718380],
    '14일': [92456780, -452890, 92909670],
  };

  // 차트 옵션
  const chartOption = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff',
      borderWidth: 1,
      borderColor: '#fff',
      textStyle: {
        color: '#000',
        fontSize: 11
      },
      padding: 10,
      formatter: function(params) {
        return `${params[0].name}<br/>` +
          params.map(param => {
            return `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>` +
            `${param.seriesName}: <span style="font-weight:bold">${param.value.toLocaleString()}원</span>`
          }).join('<br/>');
      }
    },
    legend: {
      data: ['결제금액', '취소금액', '실 매출금액'],
      bottom: 0
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '60px',
      containLabel: true
    },
    width: window.innerWidth <= 600 ? 1200 : 'auto',
    xAxis: {
      type: 'category',
      data: [...Array(14)].map((_, i) => `${String(i + 1).padStart(2, '0')}일`),
      axisLine: {
        lineStyle: {
          color: '#eaeaea'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        fontWeight: 'bold',
        fontSize: 13,
        color: '#000000',
        interval: 0
      }
    },
    yAxis: {
      type: 'value',
      max: 150000000,
      interval: 20000000,
      axisLabel: {
        formatter: (value) => {
          const labels = [
            '0',
            '2천만',
            '4천만',
            '6천만',
            '8천만',
            '1억',
            '1억2천만',
            '1억4천만'
          ];
          return labels[value / 20000000] || '';
        },
        fontSize: 12,
        margin: 8
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#eaeaea'
        }
      }
    },
    series: [
      {
        name: '결제금액',
        type: 'line',
        data: [...Array(14)].map((_, i) => dummyData[`${String(i + 1).padStart(2, '0')}일`][2]),
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#6387f9'
        },
        lineStyle: {
          width: 2
        }
      },
      {
        name: '취소금액',
        type: 'line',
        data: [...Array(14)].map((_, i) => Math.abs(dummyData[`${String(i + 1).padStart(2, '0')}일`][1])),
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#ff9933'
        },
        lineStyle: {
          width: 2
        }
      },
      {
        name: '실 매출금액',
        type: 'line',
        data: [...Array(14)].map((_, i) => dummyData[`${String(i + 1).padStart(2, '0')}일`][0]),
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#36c6d3'
        },
        lineStyle: {
          width: 2
        }
      }
    ]
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>일별 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="primary-header"></StyledTableCell>
                  <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell className="primary-header">합계</StyledTableCell>
                  {[...Array(14)].map((_, index) => (
                    <StyledTableCell key={index} className="secondary-header">
                      {String(index + 1).padStart(2, '0')}일
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">실 매출금액</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['거래건수'][0].toLocaleString()}</StyledTableCell>
                  {Object.entries(dummyData).slice(1).map(([key, values]) => (
                    <StyledTableCell key={key} sx={{ textAlign: 'right' }}>
                      {values[0].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
                <StyledTableRow className="negative-amount">
                  <StyledTableCell className="sticky-column">취소금액</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['거래건수'][1].toLocaleString()}</StyledTableCell>
                  {Object.entries(dummyData).slice(1).map(([key, values]) => (
                    <StyledTableCell key={key} sx={{ textAlign: 'right' }}>
                      {values[1].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column" sx={{ fontWeight: 'bold' }}>결제금액</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>{dummyData['거래건수'][2].toLocaleString()}</StyledTableCell>
                  {Object.entries(dummyData).slice(1).map(([key, values]) => (
                    <StyledTableCell key={key} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {values[2].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        {/* 차트 영역 수정 */}
        <ChartWrapper>
          <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
            <ReactECharts 
              option={chartOption}
              style={{ height: '300px' }}
              className="echarts-for-react boot-chart"
            />
          </Box>
        </ChartWrapper>
      </Box>
    </Paper>
  );
}

export default IlbyeolData;

import React from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableContainer, 
  TableCell,
  Box,
  Paper 
} from '@mui/material';
import styled from '@emotion/styled';

// 스타일드 컴포넌트 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 날짜 포맷팅 함수
const formatDateTime = (index) => {
  const hours = String(Math.floor(Math.random() * 24)).padStart(2, '0');
  const minutes = String(Math.floor(Math.random() * 60)).padStart(2, '0');
  const seconds = String(Math.floor(Math.random() * 60)).padStart(2, '0');
  return `2024-01-${String(index + 1).padStart(2, '0')} ${hours}:${minutes}:${seconds}`;
};

function NonMerchantTable() {
  return (
    <Paper sx={{ width: 'calc(100% - 20px)', margin: '0 auto', overflow: 'hidden' }}>
      <StyledTableWrapper>
        <TableContainer sx={{ 
          maxHeight: '65vh',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px'
          }
        }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell className="primary-header">번호</StyledTableCell>
                <StyledTableCell className="primary-header">등록일</StyledTableCell>
                <StyledTableCell className="primary-header">수정일</StyledTableCell>
                <StyledTableCell className="primary-header">경과일</StyledTableCell>
                <StyledTableCell className="primary-header">가맹점명</StyledTableCell>
                <StyledTableCell className="primary-header">아이디</StyledTableCell>
                <StyledTableCell className="primary-header">결제수단</StyledTableCell>
                <StyledTableCell className="primary-header">인증방식</StyledTableCell>
                <StyledTableCell className="primary-header">영업</StyledTableCell>
                <StyledTableCell className="primary-header">정산상태</StyledTableCell>
                <StyledTableCell className="primary-header">월렛상태</StyledTableCell>
                <StyledTableCell className="primary-header">PG</StyledTableCell>
                <StyledTableCell className="primary-header">MID</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(10)].map((_, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">{formatDateTime(index)}</StyledTableCell>
                  <StyledTableCell align="center">{formatDateTime(index)}</StyledTableCell>
                  <StyledTableCell align="center">{index + 1}일</StyledTableCell>
                  <StyledTableCell align="center">가맹점_{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">user_{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">신용카드</StyledTableCell>
                  <StyledTableCell align="center">일반</StyledTableCell>
                  <StyledTableCell align="center">{`영업${index + 1}`}</StyledTableCell>
                  <StyledTableCell align="center">{['가능', '중지'][index % 2]}</StyledTableCell>
                  <StyledTableCell align="center">{['사용', '미사용'][index % 2]}</StyledTableCell>
                  <StyledTableCell align="center">{['신한', '국민', '우리'][index % 3]}</StyledTableCell>
                  <StyledTableCell align="center">{`MID${String(index).padStart(5, '0')}`}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default NonMerchantTable;
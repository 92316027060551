import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControl,
  Button,
  InputLabel,
  FormControlLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일 컴포넌트 (Gibon.js와 동일)
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '16px',
    paddingBottom: '8px'
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  width: 'auto',
  '& .MuiFormLabel-root': {
    minWidth: '120px',
    color: '#34495e',
    fontWeight: '500',
    fontSize: '0.9rem',
    transform: 'none',
    position: 'static',
    '&.Mui-focused': {
      color: '#3498db'
    }
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    marginBottom: '8px',
    
    '& .MuiFormLabel-root': {
      minWidth: 'auto',
      fontSize: '0.85rem',
      marginBottom: '2px'
    },
    
    '& .MuiBox-root': {
      width: '100%',
      
      '& .MuiTextField-root': {
        width: '100%'
      }
    }
  }
}));

const StyledButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 2px 8px rgba(52, 152, 219, 0.3)'
  }
});

const Service = () => {
  // 체크박스 상태 관리
  const [checkboxes, setCheckboxes] = useState({
    bninecrew: false,
    cash2check: false,
    cashbill: false,
    ondapay: false,
    bitopay: false,
    mainpay: false,
    platbill: false,
    imcrew: false
  });

  // 전체 체크 상태
  const [allChecked, setAllChecked] = useState(false);

  // 인증 및 권한 섹션 토글 상태
  const [isAuthSectionOpen, setIsAuthSectionOpen] = useState(true);

  // 전체 체크/해제 핸들러
  const handleAllCheck = (event) => {
    const isChecked = event.target.checked;
    setAllChecked(isChecked);
    
    // 모든 체크박스 상태 업데이트
    const updatedCheckboxes = Object.keys(checkboxes).reduce((acc, key) => {
      acc[key] = isChecked;
      return acc;
    }, {});
    setCheckboxes(updatedCheckboxes);
  };

  // 개별 체크박스 핸들러
  const handleSingleCheck = (name) => (event) => {
    const isChecked = event.target.checked;
    setCheckboxes(prev => ({
      ...prev,
      [name]: isChecked
    }));

    // 모든 체크박스가 체크되었는지 확인
    const updatedCheckboxes = {
      ...checkboxes,
      [name]: isChecked
    };
    const areAllChecked = Object.values(updatedCheckboxes).every(value => value);
    setAllChecked(areAllChecked);
  };

  // 토글 핸들러
  const handleAuthSectionToggle = () => {
    setIsAuthSectionOpen(!isAuthSectionOpen);
  };

  return (
    <StyledPaper>
      <SectionTitle>서비스 설정</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* 로그인 아이디 */}
          <StyledFormControl>
            <InputLabel>로그인 아이디*</InputLabel>
            <Box sx={{ 
              display: 'flex', 
              gap: 1,
              width: '300px'
            }}>
              <TextField
                size="small"
                sx={{ flex: 1 }}
              />
              <StyledButton
                variant="contained"
                size="small"
                sx={{
                  minWidth: '80px',
                  bgcolor: '#3498db',
                  '&:hover': {
                    bgcolor: '#2980b9'
                  }
                }}
              >
                중복 확인
              </StyledButton>
            </Box>
          </StyledFormControl>

          {/* 비밀번호 */}
          <StyledFormControl>
          <InputLabel>비밀번호*</InputLabel>
          <Box sx={{
            display: 'flex',
            gap: 1,
            width: '300px',
          }}>
            <TextField
            size="small"
            sx={{ flex:1}}
            />
          </Box>
          </StyledFormControl>

          <StyledFormControl>
          <InputLabel>비밀번호 확인*</InputLabel>
          <Box sx={{
            display: 'flex',
            gap: 1,
            width: '300px',
          }}>
            <TextField
            size="small"
            sx={{ flex:1}}
            />
          </Box>
          </StyledFormControl>

          <StyledFormControl>
          <InputLabel>정산 연동계정*</InputLabel>
          <Box sx={{
            display: 'flex',
            gap: 1,
            width: '300px',
          }}>
            <TextField
            size="small"
            sx={{ flex:1}}
            />
          </Box>
          </StyledFormControl>
          
          {/* 로그인 허용 사이트 */}
          <StyledFormControl>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: 1
            }}>
              {/* 전체 체크 영역 */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1,
                mb: 1
              }}>
                <InputLabel>로그인 허용 사이트 :</InputLabel>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: '0.75rem',
                    color: 'text.secondary'
                  }}
                >
                  로그인 허용 전체체크
                </Typography>
                <Checkbox 
                  size="small"
                  checked={allChecked}
                  onChange={handleAllCheck}
                />
              </Box>

              {/* 체크박스 그룹 - 3-3-2 배치 */}
              <Box sx={(theme) => ({ 
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 1,
                pl: 17,
                [theme.breakpoints.down('sm')]: {
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '8px',
                  pl: 0,
                  width: '100%'
                }
              })}>
                {/* 모든 FormControlLabel에 동일한 스타일 적용 */}
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.bninecrew}
                      onChange={handleSingleCheck('bninecrew')}
                    />
                  }
                  label="bninecrew (본전산)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.cash2check}
                      onChange={handleSingleCheck('cash2check')}
                    />
                  }
                  label="cash2check (조회전산)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.cashbill}
                      onChange={handleSingleCheck('cashbill')}
                    />
                  }
                  label="cashbill.kr (조회전산)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                {/* 두 번째 줄 3개 */}
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.ondapay}
                      onChange={handleSingleCheck('ondapay')}
                    />
                  }
                  label="ondapay.kr (서브전산)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.bitopay}
                      onChange={handleSingleCheck('bitopay')}
                    />
                  }
                  label="bitopay.kr (결제월렛)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.mainpay}
                      onChange={handleSingleCheck('mainpay')}
                    />
                  }
                  label="mainpay (결제월렛)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                {/* 마지막 줄 2개 */}
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.platbill}
                      onChange={handleSingleCheck('platbill')}
                    />
                  }
                  label="platbill.kr (결제월렛)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      size="small"
                      checked={checkboxes.imcrew}
                      onChange={handleSingleCheck('imcrew')}
                    />
                  }
                  label="imcrew.kr (서브전산)"
                  sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: '0.75rem' },
                    margin: 0
                  }}
                />
              </Box>
            </Box>
          </StyledFormControl>
        </Grid>
      </Grid>

      {/* 인증 및 권한 섹션 */}
      <Box sx={{ mt: 3 }}>
        <Typography 
          onClick={handleAuthSectionToggle}
          sx={{ 
            fontSize: '0.85rem',
            fontWeight: 600,
            color: '#333',
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            cursor: 'pointer', // 마우스 호버 시 포인터 커서
            userSelect: 'none', // 텍스트 선택 방지
            '&:hover': {
              color: '#1976d2' // 호버 시 색상 변경
            }
          }}
        >
          인증 및 권한 {isAuthSectionOpen ? '▼' : '▲'}
        </Typography>

        {/* 토글되는 컨텐츠 영역 */}
        <Box sx={{ 
          display: isAuthSectionOpen ? 'grid' : 'none',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 1,
          pl: 2
        }}>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="로그인 접속 여부"
            sx={{ 
              '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
              }
            }}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="로그인 인증 여부"
            sx={{ 
              '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
              }
            }}
          />
          <FormControlLabel
            control={<Checkbox size="small" checked />}
            label="본사 인증 여부"
            sx={{ 
              '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
              }
            }}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="당일 취소권한"
            sx={{ 
              '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
              }
            }}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="이전 취소권한"
            sx={{ 
              '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
              }
            }}
          />
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default Service;

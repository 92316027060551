import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  minWidth: 'max-content',
  
  '&.total-column': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function CardHalbu({ searchParams }) {
  // 더미 데이터 유지
  const halbuData = [
    { card: '비씨', amount: 95622880, ilsiAmount: 75622880, ilsiRatio: 79.1, halbuAmount: 20000000, halbuRatio: 20.9 },
    { card: '농협', amount: 31745870, ilsiAmount: 25745870, ilsiRatio: 81.1, halbuAmount: 6000000, halbuRatio: 18.9 },
    { card: '수협', amount: 19366790, ilsiAmount: 15366790, ilsiRatio: 79.3, halbuAmount: 4000000, halbuRatio: 20.7 },
    { card: '국민', amount: 53367870, ilsiAmount: 43367870, ilsiRatio: 81.3, halbuAmount: 10000000, halbuRatio: 18.7 },
    { card: '삼성', amount: 62988150, ilsiAmount: 52988150, ilsiRatio: 84.1, halbuAmount: 10000000, halbuRatio: 15.9 }
  ];

  return (
    <Paper 
      elevation={1} 
      sx={{ 
        p: 3, 
        height: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '8px'
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 2, 
          fontWeight: 600, 
          color: '#424242',
          fontSize: '1rem'
        }}
      >
        카드사별 할부
      </Typography>
      
      <StyledTableWrapper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>카드사</StyledTableCell>
                <StyledTableCell align="right" className="primary-header">총 거래금액</StyledTableCell>
                <StyledTableCell align="right" className="secondary-header">일시불금액</StyledTableCell>
                <StyledTableCell align="right" className="secondary-header">일시불비율</StyledTableCell>
                <StyledTableCell align="right" className="secondary-header">할부금액</StyledTableCell>
                <StyledTableCell align="right" className="secondary-header">할부비율</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {halbuData.map((row) => (
                <StyledTableRow key={row.card}>
                  <StyledTableCell>{row.card}</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {row.amount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.ilsiAmount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.ilsiRatio}%
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.halbuAmount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.halbuRatio}%
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default CardHalbu; 
import React from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// 스타일드 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 16px',
  fontSize: '0.875rem',
  fontFamily: 'inherit',
  borderBottom: '1px solid #e3f2fd',
  '&.header': {
    backgroundColor: '#e3f2fd',
    color: '#0d47a1',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:last-child td': {
    borderBottom: 0,
  },
  '&:hover': {
    backgroundColor: '#f8f9fa',
  }
});

function PGModal({ open, onClose, pgData }) {
  // 더미 데이터
  const dummyData = [
    { no: 1, pg: 'SPC', amount: '5,373,410', ratio: '7.56%' },
    { no: 2, pg: '갤럭시아', amount: '19,185,490', ratio: '31.98%' },
    { no: 3, pg: '페이업', amount: '24,450,930', ratio: '57.56%' }
  ];

  // 모달 컴포넌트
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="pg-modal-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={{
        position: 'relative',
        width: '100%',
        maxWidth: '600px',
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24,
        p: 3,
      }}>
        {/* 헤더 */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <Typography
            id="pg-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 600,
              color: '#1976d2'
            }}
          >
            PG 정보
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: '#666',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* 테이블 */}
        <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #e0e0e0' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell className="header">No</StyledTableCell>
                <StyledTableCell className="header">PG</StyledTableCell>
                <StyledTableCell className="header" align="right">금액</StyledTableCell>
                <StyledTableCell className="header" align="right">비율</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((row) => (
                <StyledTableRow key={row.no}>
                  <StyledTableCell>{row.no}</StyledTableCell>
                  <StyledTableCell>{row.pg}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                  <StyledTableCell align="right">{row.ratio}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

export default PGModal;

import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90vw',
    maxWidth: '1400px',
    minHeight: '300px',
    maxHeight: '80vh',
    borderRadius: '8px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

const StyledTableCell = styled(TableCell)({
  padding: '8px 12px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  whiteSpace: 'nowrap',
  '&.header': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    height: '42px'
  },
  '&.body': {
    height: '36px'
  }
});

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#f8f9fa'
  }
});

const MaechulGamangTwo = ({ open, onClose, businessName }) => {
  const mockData = [
    {
      type: '지사',
      businessName: businessName,
      feeRate: 3.3,
      feeAmount: 150000,
      paymentRate: 3.0,
      paymentAmount: 1596480,
      withholding: '0%',
      pointFee: 0,
      bankName: '신한은행',
      accountNumber: '110-353-123456'
    },
    // ... 추가 데이터
  ];

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <StyledDialogTitle>
        상세 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="header">구분</StyledTableCell>
                <StyledTableCell className="header">영업자명</StyledTableCell>
                <StyledTableCell className="header">수수료율(VAT포함)</StyledTableCell>
                <StyledTableCell className="header">지급금액(VAT포함)</StyledTableCell>
                <StyledTableCell className="header">수수료율(VAT별도)</StyledTableCell>
                <StyledTableCell className="header">지급금액(VAT별도)</StyledTableCell>
                <StyledTableCell className="header">원천공제</StyledTableCell>
                <StyledTableCell className="header">포인트</StyledTableCell>
                <StyledTableCell className="header">은행명</StyledTableCell>
                <StyledTableCell className="header">계좌번호</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {mockData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="body">{row.type}</StyledTableCell>
                  <StyledTableCell className="body">{row.businessName}</StyledTableCell>
                  <StyledTableCell className="body">{row.feeRate}%</StyledTableCell>
                  <StyledTableCell className="body">{row.feeAmount?.toLocaleString()}원</StyledTableCell>
                  <StyledTableCell className="body">{row.paymentRate}%</StyledTableCell>
                  <StyledTableCell className="body">{row.paymentAmount?.toLocaleString()}원</StyledTableCell>
                  <StyledTableCell className="body">{row.withholding}</StyledTableCell>
                  <StyledTableCell className="body">{row.point || '-'}</StyledTableCell>
                  <StyledTableCell className="body">{row.bankName}</StyledTableCell>
                  <StyledTableCell className="body">{row.accountNumber}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default MaechulGamangTwo;

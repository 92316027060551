import React, { useState } from 'react';
import { 
  Box, 
  Paper,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Notice from '../../Notice/Notice';
import NonMerchantTable from './NonMerchantTable';
import Breadcrumb from '../../Common/Breadcrumb';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';

function NonMerchantManagement() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchKeyword, setSearchKeyword] = useState('');
  const [accountStatus, setAccountStatus] = useState('all');

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: '40px',  // SearchPanel 없음
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    searchBox: {
      width: '100%',
      mb: 2
    }
  };

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="미실적 가맹점관리" />
        
        <Box sx={styles.searchBox}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  placeholder="로그인아이디, MID,가맹점명"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={accountStatus}
                    onChange={(e) => setAccountStatus(e.target.value)}
                  >
                    <FormControlLabel value="all" control={<Radio />} label="전체" sx={{ mr: 4 }}/>
                    <FormControlLabel value="active" control={<Radio />} label="사용중" sx={{ mr: 4 }}/>
                    <FormControlLabel value="view" control={<Radio />} label="조회용" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <NonMerchantTable />
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default NonMerchantManagement;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  TablePagination,
  TextField,
  Typography,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import OptionButton from '../../Common/ManagementData/OptionButton';
import DetailPopup from './CashInput/Section/DetailPopup';

// 스타일 컴포넌트 정의
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 350px)',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  }
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#1976d2',
    color: '#ffffff',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    padding: '4px 8px'
  }
}));

// 체크박스 스타일 추가
const StyledCheckbox = styled(Checkbox)({
  color: '#1976d2',
  '&.Mui-checked': {
    color: '#1976d2',
  },
  padding: '0px'
});

// 테이블 헤더 정의
const columns = [
  // { id: 'no', label: 'No', width: 50 },
  // { id: 'detail', label: '상세', width: 70 },
  // { id: 'select', label: '선택', width: 100 },
  { id: 'deductionRate', label: '차감율', width: 70 },
  { id: 'chargeAmount', label: '충전액', width: 100 },
  { id: 'chargeMoney', label: '충전금', width: 100 },
  { id: 'points', label: '적립금', width: 100 },
  { id: 'managerId', label: '담당 아이디', width: 120 },
  { id: 'loginId', label: '로그인 아이디', width: 120 },
  { id: 'name', label: '이름', width: 100 },
  { id: 'phoneNumber', label: '휴대폰 번호', width: 120 },
  { id: 'withdrawalStatus', label: '출금상태', width: 80 },
  { id: 'manage', label: '관리', width: 60 },
  { id: 'registDate', label: '등록일', width: 100 },
  { id: 'modifyDate', label: '수정일', width: 100 }
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

function CashdamdangTables({ onDataUpdate }) {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  // 더미데이터 수정
  useEffect(() => {
    const dummyData = [
      {
        id: 1,
        property: {
          checkbox: false,
          value: ''  // 초기값 빈 문자열
        },
        deductionRate: {
          amount: 500,    // 차감금액
          percentage: 3   // 차감율
        },
        chargeAmount: '0', // 초기 충전액 0
        chargeMoney: '2,000,000',
        points: '75,000',
        managerId: 'manager01',
        loginId: 'user123',
        name: '김철수',
        phoneNumber: '010-1234-5678',
        withdrawalStatus: '가능',
        registDate: '2024-10-15 19:50:33',
        modifyDate: '2024-10-15 19:50:33'
      },
      {
        id: 2,
        property: {
          checkbox: false,
          value: ''
        },
        deductionRate: {
          amount: 1000,
          percentage: 5
        },
        chargeAmount: '0',
        chargeMoney: '1,500,000',
        points: '50,000',
        managerId: 'manager02',
        loginId: 'user456',
        name: '이영희',
        phoneNumber: '010-2345-6789',
        withdrawalStatus: '불가',
        registDate: '2024-10-15 19:50:33',
        modifyDate: '2024-10-15 19:50:33'
      }
    ];
    
    setMerchants(dummyData);
    setSelectedMerchants([]); // 선택 초기화
    onDataUpdate({ 
      data: dummyData,
      getAllData: dummyData
    });
  }, [onDataUpdate]);

  // 옵션 클릭 핸들러
  const handleOptionClick = useCallback((option) => {
    try {
      console.log('선택된 옵션:', option);
      console.log('선택된 항목들:', selectedMerchants);
      // TODO: 선택된 옵션에 따른 처리 구현
    } catch (error) {
      console.error('옵션 처리 중 오류:', error);
    }
  }, [selectedMerchants]);

  // 전체 선택 핸들러 단순화
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    
    // 모든 merchant의 체크박스 상태 변경
    setMerchants(prev => {
      return prev.map(merchant => ({
        ...merchant,
        property: {
          ...merchant.property,
          checkbox: isChecked  // 모두 선택 또는 모두 해제
        }
      }));
    });

    // selectedMerchants 상태도 함께 업데이트
    if (isChecked) {
      setSelectedMerchants(merchants.map(merchant => merchant.id));
    } else {
      setSelectedMerchants([]);
    }
  };

  // 개별 선택 핸들러
  const handleSelect = useCallback((id) => {
    setSelectedMerchants(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  // 차감율 체크박스 핸들러
  const handleDeductionCheckbox = useCallback((id) => {
    setMerchants(prev => {
      const updatedMerchants = prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            deductionRate: {
              ...merchant.deductionRate,
              checkbox: !merchant.deductionRate.checkbox
            }
          };
        }
        return merchant;
      });
      return updatedMerchants;
    });
  }, []);

  // 차감율 값 변경 핸들러
  const handleDeductionValueChange = useCallback((id, value) => {
    setMerchants(prev => {
      const updatedMerchants = prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            deductionRate: {
              ...merchant.deductionRate,
              value: value
            }
          };
        }
        return merchant;
      });
      return updatedMerchants;
    });
  }, []);

  // 충전액 변경 핸들러
  const handleChargeAmountChange = useCallback((id, value) => {
    setMerchants(prev => {
      const updatedMerchants = prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            chargeAmount: value
          };
        }
        return merchant;
      });
      return updatedMerchants;
    });
  }, []);

  // 충전금 분배 핸들러 추가
  const handleChargeDistribution = () => {
    if (selectedMerchants.length === 0) {
      alert('항목을 선택해주세요.');
      return;
    }
    // 충전금 분배 로직 구현
  };

  // 속성 체크박스 핸들러 수정
  const handlePropertyCheckbox = (id) => {
    setMerchants(prev => {
      return prev.map(merchant => {
        if (merchant.id === id) {
          return {
            ...merchant,
            property: {
              ...merchant.property,
              checkbox: !merchant.property?.checkbox
            }
          };
        }
        return merchant;
      });
    });

    setSelectedMerchants(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // 속성 값 변경 핸들러 수정
  const handlePropertyValueChange = (id, value) => {
    // 숫자와 음수 기호만 허용하고 소수점은 제거
    const sanitizedValue = value.replace(/[^0-9-]/g, '');
    
    // 음수 기호가 중간에 있으면 제거
    const finalValue = sanitizedValue.startsWith('-') ? 
      '-' + sanitizedValue.replace(/-/g, '') : 
      sanitizedValue.replace(/-/g, '');

    setMerchants(prev => {
      return prev.map(merchant => {
        if (!merchant || merchant.id !== id) return merchant;

        try {
          // 입력값이 없거나 0인 경우 처리
          if (!finalValue || finalValue === '0') {
            return {
              ...merchant,
              property: {
                ...merchant.property,
                value: finalValue
              },
              chargeAmount: '0'
            };
          }

          // 계산 로직
          const inputValue = parseInt(finalValue, 10);
          const deductionAmount = merchant.deductionRate?.amount || 0;
          const deductionPercentage = merchant.deductionRate?.percentage || 0;
          
          const percentageDeduction = Math.abs(inputValue) * (deductionPercentage / 100);
          // 올림 처리 추가
          const chargeAmount = Math.ceil(inputValue - percentageDeduction - deductionAmount);

          return {
            ...merchant,
            property: {
              ...merchant.property,
              value: finalValue
            },
            chargeAmount: chargeAmount < 0 ? 
              `-${Math.abs(chargeAmount).toLocaleString()}` : 
              chargeAmount.toLocaleString()
          };
        } catch (error) {
          console.error('Calculation error:', error);
          return merchant;
        }
      });
    });
  };

  // 상세 클릭 핸들러
  const handleDetailClick = (merchant) => {
    setSelectedDetail(merchant);
    setDetailOpen(true);
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '24px',
          borderRadius: '8px',
          mb: 3,
          width: '100%'
        }}>
          <Box sx={{ 
            display: 'flex',
            gap: 3,
            alignItems: 'flex-start'
          }}>
            <OptionButton 
              onOptionClick={handleOptionClick}
              selectedItems={selectedMerchants}
            />

            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontSize: '0.875rem', 
                  fontWeight: 600,
                  color: '#495057',
                  mb: 1.5,
                  pl: '4px'
                }}
              >
                충전금 관리
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<AccountBalanceWalletIcon />}
                  onClick={() => handleChargeDistribution()}
                  sx={{
                    bgcolor: '#fff',
                    color: '#1976d2',
                    border: '1px solid #1976d2',
                    fontSize: '0.75rem',
                    height: '32px',
                    padding: '4px 12px',
                    minWidth: 'auto',
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#1976d2',
                      color: '#fff',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    },
                    '& .MuiButton-startIcon': {
                      marginRight: '4px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1rem'
                      }
                    }
                  }}
                >
                  충전금 분배
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <StyledTableWrapper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No</StyledTableCell>
                <StyledTableCell align="center">상세</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StyledCheckbox
                      checked={merchants.some(m => m.property?.checkbox)}  // 단순히 하나라도 체크되어 있으면 체크 표시
                      onChange={handleSelectAll}
                      sx={{
                        color: '#ffffff',
                        '&.Mui-checked': {
                          color: '#ffffff'
                        }
                      }}
                    />
                    <Typography variant="subtitle2" sx={{ fontSize: '0.813rem' }}>선택</Typography>
                  </Box>
                </StyledTableCell>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    style={{ minWidth: column.width }}
                    align="center"
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants.map((merchant, index) => (
                <StyledTableRow 
                  key={merchant.id}
                  className={merchant?.chargeAmount?.startsWith('-') ? 'negative-amount' : ''}
                >
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton 
                      size="small" 
                      onClick={() => handleDetailClick(merchant)}
                      sx={{ color: '#3498db' }}
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StyledCheckbox
                        checked={selectedMerchants.includes(merchant.id)}
                        onChange={() => handlePropertyCheckbox(merchant.id)}
                      />
                      <TextField
                        placeholder="값입력"
                        size="small"
                        value={merchant.property?.value || ''}
                        onChange={(e) => handlePropertyValueChange(merchant.id, e.target.value)}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9-]*',  // 숫자와 음수 기호만 허용
                          style: { textAlign: 'right' }
                        }}
                        sx={{ 
                          width: '80px',
                          '& .MuiInputBase-input': { 
                            padding: '4px 8px',
                            fontSize: '0.813rem'
                          }
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${merchant?.deductionRate?.amount?.toLocaleString() || '0'}(${merchant?.deductionRate?.percentage || '0'}%)`}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      value={merchant?.chargeAmount || '0'}
                      readOnly
                      InputProps={{
                        readOnly: true,
                        sx: {
                          '& .MuiInputBase-input': {
                            textAlign: 'right',
                            padding: '4px 8px',
                            fontSize: '0.813rem',
                            cursor: 'default',
                            backgroundColor: '#f8f9fa',
                            color: merchant?.chargeAmount?.startsWith('-') ? '#ff0000' : 'inherit'
                          }
                        }
                      }}
                      sx={{ width: '120px' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">{merchant.chargeMoney}</StyledTableCell>
                  <StyledTableCell align="right">{merchant.points}</StyledTableCell>
                  <StyledTableCell align="center">{merchant.managerId}</StyledTableCell>
                  <StyledTableCell align="center">{merchant.loginId}</StyledTableCell>
                  <StyledTableCell align="center">{merchant.name}</StyledTableCell>
                  <StyledTableCell align="center">{merchant.phoneNumber}</StyledTableCell>
                  <StyledTableCell align="center">{merchant.withdrawalStatus}</StyledTableCell>
                  <StyledTableCell align="center">관리</StyledTableCell>
                  <StyledTableCell align="center">{merchant.registDate}</StyledTableCell>
                  <StyledTableCell align="center">{merchant.modifyDate}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>
      </Paper>

      <DetailPopup 
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        data={selectedDetail}
      />
    </>
  );
}

export default CashdamdangTables; 
import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

function InjeungData() {
  // 더미 데이터 생성
  const dummyData = {
    '거래금액': {
      거래건수: 393,
      합계: 0,
      '01월': 118471180,
      '02월': 128471180,
      '03월': 138471180,
      '04월': 148471180,
      '05월': 158471180,
      '06월': 168471180
    },
    '비인증 거래금액': {
      거래건수: 19,
      합계: 0,
      '01월': 48170000,
      '02월': 58170000,
      '03월': 68170000,
      '04월': 78170000,
      '05월': 88170000,
      '06월': 98170000
    },
    '인증 거래금액': {
      거래건수: 350,
      합계: 0,
      '01월': 35486680,
      '02월': 45486680,
      '03월': 55486680,
      '04월': 65486680,
      '05월': 75486680,
      '06월': 85486680
    },
    '구인증 거래금액': {
      거래건수: 24,
      합계: 0,
      '01월': 34814500,
      '02월': 44814500,
      '03월': 54814500,
      '04월': 64814500,
      '05월': 74814500,
      '06월': 84814500
    }
  };

  // 합계 계산
  Object.values(dummyData).forEach(data => {
    data.합계 = ['01월', '02월', '03월', '04월', '05월', '06월']
      .reduce((sum, month) => sum + data[month], 0);
  });

  const tableCellStyle = {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '8px',
    textAlign: 'center',
    minWidth: '120px',
  };

  // 막대 차트 옵션
  const barChartOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    legend: {
      data: ['거래금액', '비인증 거래금액', '인증 거래금액', '구인증 거래금액'],
      bottom: 0,
      textStyle: { fontSize: 11 }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '4%',
      bottom: '15%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'],
      axisLabel: {
        fontSize: 11,
        color: '#666'
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => {
          return (value / 100000000).toFixed(1) + '억';
        },
        fontSize: 11,
        color: '#666'
      }
    },
    series: Object.entries(dummyData).map(([name, data]) => ({
      name: name,
      type: 'bar',
      barWidth: '15%',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'].map(month => data[month])
    }))
  };

  // 도넛 차트 옵션
  const donutChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      right: '10%',
      top: 'center',
      textStyle: { fontSize: 11 }
    },
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['40%', '50%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      data: [
        { 
          value: dummyData['비인증 거래금액']['01월'], 
          name: '비인증 거래금액' 
        },
        { 
          value: dummyData['인증 거래금액']['01월'], 
          name: '인증 거래금액' 
        },
        { 
          value: dummyData['구인증 거래금액']['01월'], 
          name: '구인증 거래금액' 
        }
      ]
    }]
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>월별 인증/비인증 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">구분</StyledTableCell>
                  <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell className="primary-header">합계</StyledTableCell>
                  {['01월', '02월', '03월', '04월', '05월', '06월'].map((month) => (
                    <StyledTableCell key={month} className="secondary-header">{month}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.entries(dummyData).map(([name, data]) => (
                  <StyledTableRow key={name}>
                    <StyledTableCell className="sticky-column">{name}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      textAlign: 'right',
                      backgroundColor: '#f8f9fa'
                    }}>{data.거래건수.toLocaleString()}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      textAlign: 'right',
                      backgroundColor: '#f8f9fa'
                    }}>{data.합계.toLocaleString()}</StyledTableCell>
                    {['01월', '02월', '03월', '04월', '05월', '06월'].map((month) => (
                      <StyledTableCell key={month} sx={{ textAlign: 'right' }}>
                        {data[month].toLocaleString()}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        <Box sx={{ width: '100%', mt: 3 }}>
          <ReactECharts 
            option={barChartOption}
            style={{ height: '300px' }}
            className="echarts-for-react boot-chart"
          />
        </Box>
      </Box>
    </Paper>
  );
}

export default InjeungData;

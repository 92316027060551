import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, Divider, Chip } from '@mui/material';
import { 
  CreditCard, 
  Warning, 
  Campaign, 
  Update, 
  AccessTime,
  CalendarMonth,
  Info
} from '@mui/icons-material';

const noticeDetails = [
  {
    id: 1,
    title: "12월 무이자할부 안내",
    date: "2024.12.01",
    icon: <CreditCard sx={{ fontSize: '2rem', color: '#2185d0' }} />,
    content: [
      {
        type: "text",
        content: "Mainpay#",
      },
      {
        type: "text",
        content: "[12월 신용카드 무이자 행사 안내]",
      },
      {
        type: "list",
        items: [
          "현대카드: 2~3개월",
          "하나카드: 2~3개월",
          "국민카드: 2~3개월",
          "롯데카드: 2~3개월",
          "삼성카드: 2~3개월",
          "신한카드: 2~5개월",
          "NH농협카드: 2~6개월",
          "비씨카드: 2~6개월",
          "우리카드: 2~6개월"
        ]
      }
    ]
  },
  {
    id: 2,
    title: "카드사 불가 업종 안내",
    date: "2024.11.28",
    icon: <Warning sx={{ fontSize: '2rem', color: '#ff9800' }} />,
    content: [
      {
        type: "text",
        content: "카드사 규정에 따른 거래 제한 업종을 안내드립니다.",
      },
      {
        type: "list",
        items: [
          "도박/사행성 업종",
          "대출/금융 업종",
          "성인용품 업종",
          "주류 판매 업종",
          "담배 판매 업종"
        ]
      },
      {
        type: "text",
        content: "위 업종은 카드사 정책에 따라 거래가 제한될 수 있습니다."
      }
    ]
  },
  {
    id: 3,
    title: "불량거래 가맹점에 대한 안내",
    date: "2024.11.25",
    icon: <Campaign sx={{ fontSize: '2rem', color: '#f44336' }} />,
    content: [
      {
        type: "text",
        content: "최근 불량거래 증가에 따른 주의사항 안내드립니다.",
      },
      {
        type: "list",
        items: [
          "허위매출 거래 주의",
          "현금화 거래 주의",
          "불법 상품 거래 주의",
          "타인 카드 무단 사용 주의"
        ]
      }
    ]
  },
  {
    id: 4,
    title: "업데이트 안내 ver.2.1.1",
    date: "2024.11.20",
    icon: <Update sx={{ fontSize: '2rem', color: '#4caf50' }} />,
    content: [
      {
        type: "text",
        content: "시스템 업데이트 내역을 안내드립니다.",
      },
      {
        type: "list",
        items: [
          "결제 시스템 안정성 강화",
          "모바일 UI/UX 개선",
          "보안 시스템 업데이트",
          "거래 내역 조회 속도 개선"
        ]
      }
    ]
  },
  {
    id: 5,
    title: "고객센터 업무시간 안내",
    date: "2024.11.15",
    icon: <AccessTime sx={{ fontSize: '2rem', color: '#9c27b0' }} />,
    content: [
      {
        type: "text",
        content: "고객센터 운영시간 안내드립니다.",
      },
      {
        type: "list",
        items: [
          "평일: 09:00 ~ 18:00",
          "점심시간: 12:00 ~ 13:00",
          "주말 및 공휴일 휴무"
        ]
      },
      {
        type: "text",
        content: "* 긴급 문의는 24시간 가능합니다."
      }
    ]
  }
];

const NoticeDetails = () => {
  const { id } = useParams();
  const notice = noticeDetails[parseInt(id) - 1];

  if (!notice) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Info sx={{ fontSize: '4rem', color: '#9e9e9e' }} />
        <Typography variant="h5" sx={{ mt: 2 }}>
          존재하지 않는 공지사항입니다.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 800, margin: '0 auto' }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: '12px' }}>
        {/* 헤더 영역 */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          {notice.icon}
          <Box sx={{ ml: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
              {notice.title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <CalendarMonth sx={{ fontSize: '0.9rem', color: '#666' }} />
              <Typography variant="body2" sx={{ ml: 1, color: '#666' }}>
                {notice.date}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* 컨텐츠 영역 */}
        <Box sx={{ mt: 3 }}>
          {notice.content.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              {item.type === "text" ? (
                <Typography 
                  sx={{ 
                    fontSize: '1rem',
                    lineHeight: 1.7,
                    color: '#333'
                  }}
                >
                  {item.content}
                </Typography>
              ) : item.type === "list" ? (
                <Box sx={{ mt: 2 }}>
                  {item.items.map((listItem, i) => (
                    <Box 
                      key={i} 
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5
                      }}
                    >
                      <Chip 
                        size="small" 
                        label={`${i + 1}`}
                        sx={{ 
                          mr: 2,
                          backgroundColor: '#2185d0',
                          color: 'white',
                          minWidth: '28px'
                        }} 
                      />
                      <Typography sx={{ color: '#444' }}>
                        {listItem}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default NoticeDetails;

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 필요한 컴포넌트들 import
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';
import ResultTable from '../../Common/SettlementResultData/SetResultTables';
import ResultText from '../../Common/SettlementResultData/SetResultText'; // 추가
import SettlementHoldDetailsTables from './SettlementHoldDetailsTables';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';

function SettlementHoldDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);
  const [selectedRows, setSelectedRows] = useState([]);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      if (!isMobile && !isTablet) {
        setSearchPanelOpen(true);
      } else if (isMobile || isTablet) {
        setSearchPanelOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile, isTablet]);

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    noticeBox: { 
      px: 0,
      mx: 0,
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      mb: 2,
      ml: { xs: '10px', md: '0px' }
    },
    tableContainer: {
      marginLeft: { xs: '10px', md: '40px' },
      marginRight: { xs: '10px', md: '40px' },
      maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 400px)' },
      overflowY: 'auto',
      overflowX: 'auto',
      width: '100%',
      backgroundColor: '#fff', 
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)', 
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    },
    closeButton: {
      position: 'absolute',
      left: '-20px',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: '#fff',
      borderRadius: '50%',
      boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
      '&:hover': {
        backgroundColor: '#f5f5f5'
      }
    }
  };

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="정산보류 내역" />
        <ResultText />
        <ResultTable />
        <SettlementHoldDetailsTables 
          searchPanelOpen={searchPanelOpen}
          setSearchPanelOpen={setSearchPanelOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default SettlementHoldDetails;

import React from 'react';
import ButtonWidget from '../../Common/TransferButton';

const OutputTable = () => {
    const containerStyle = {
        width: '100%',
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: '-ms-autohiding-scrollbar',
    };

    const tableWrapperStyle = {
        minWidth: '1000px',
    };

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
        margin: '20px 0',
        tableLayout: 'fixed',
    };

    const thStyle = {
        padding: '12px 15px',
        textAlign: 'center',
        backgroundColor: '#4A90E2',
        color: 'white',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        width: '20%',
    };

    const tdStyle = {
        padding: '12px 15px',
        textAlign: 'center',
        borderBottom: '1px solid #e6e6e6',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        width: '20%',
    };

    return (
        <div style={containerStyle}>
            <ButtonWidget showFirst={true} />
            <div style={tableWrapperStyle}>
                {/* 첫 번째 테이블: 잔액 정보 */}
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>234 잔액</th>
                            <th style={thStyle}>740 잔액</th>
                            <th style={thStyle}>741 잔액</th>
                            <th style={thStyle}>742 잔액</th>
                            <th style={thStyle}>하이픈 잔액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyle}>5,567,497</td>
                            <td style={tdStyle}>150</td>
                            <td style={tdStyle}>9,960,088</td>
                            <td style={tdStyle}>0</td>
                            <td style={tdStyle}>13,712</td>
                        </tr>
                    </tbody>
                </table>

                {/* 두 번째 테이블: 거래 정보 */}
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>총 건수</th>
                            <th style={thStyle}>총 금액</th>
                            <th style={thStyle}>성공/실패 건수</th>
                            <th style={thStyle}>성공금액</th>
                            <th style={thStyle}>실패금액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyle}>59</td>
                            <td style={tdStyle}>33,584,075</td>
                            <td style={tdStyle}>59 / 0</td>
                            <td style={tdStyle}>33,584,075</td>
                            <td style={tdStyle}>0</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OutputTable;

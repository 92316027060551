import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Box } from '@mui/material';
import styled from '@emotion/styled';

// 스타일드 컴포넌트 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.amount': {
    color: '#2185d0',
    textAlign: 'right',
  },
  '&.negative': {
    color: '#db2828',
    textAlign: 'right',
  },
  '&.total': {
    color: '#21ba45',
    textAlign: 'right',
    fontWeight: 'bold',
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #dee2e6',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    width: '100%',
    maxWidth: '1800px',
    padding: '0px',
    '@media (max-width: 1025px)': {
      maxHeight: 'calc(100vh - 250px)',
      '& table': {
        minWidth: '1200px'
      }
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 1025px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  },
  blueSection: {
    backgroundColor: '#EBF3FF !important',
  },
  skySection: {
    backgroundColor: '#E3FAFC !important',
  }
};

const ScSummaryTable = ({ startDate, endDate }) => {
  const dummyData = [
    {
      transactionDate: '2024-12-10 00:00:00 ~ 2024-12-11 00:00:00',
      creator: 'admin',
      approvalAmount: 36082940,
      cancelAmount: -2703500,
      totalAmount: 33379440,
      offsetAmount: 33379440,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 270,
      settlementAmount: 2091667,
      commission: 0,
      depositAmount: 0,
      executionAmount: 0,
      completionDate: '2024-12-10 14:40:20',
      reportCreatorId: 'admin',
    },
    // 추가 데이터...
  ];

  return (
    <TableContainer component={StyledTableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell className="primary-header">거래일시</StyledTableCell>
            <StyledTableCell className="primary-header">생성자</StyledTableCell>
            <StyledTableCell className="primary-header">승인금액</StyledTableCell>
            <StyledTableCell className="primary-header">취소금액</StyledTableCell>
            <StyledTableCell className="primary-header">합계</StyledTableCell>
            <StyledTableCell className="primary-header">상계금액</StyledTableCell>
            <StyledTableCell className="primary-header">보류금액</StyledTableCell>
            <StyledTableCell className="primary-header">이전보류반영금액</StyledTableCell>
            <StyledTableCell className="secondary-header">정산대상건수</StyledTableCell>
            <StyledTableCell className="secondary-header">정산반영금액</StyledTableCell>
            <StyledTableCell className="secondary-header">수수료(VAT포함)</StyledTableCell>
            <StyledTableCell className="secondary-header">보증금액</StyledTableCell>
            <StyledTableCell className="secondary-header">정산집행액</StyledTableCell>
            <StyledTableCell className="secondary-header">정산완료일시</StyledTableCell>
            <StyledTableCell className="secondary-header">정산보고생성아이디</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">{row.transactionDate}</StyledTableCell>
              <StyledTableCell align="center">{row.creator}</StyledTableCell>
              <StyledTableCell align="right">{row.approvalAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right" className="negative">{row.cancelAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right" className="total">{row.totalAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.offsetAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.holdAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.previousHoldAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.settlementCount}</StyledTableCell>
              <StyledTableCell align="right">{row.settlementAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.commission.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.depositAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.executionAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="center">{row.completionDate}</StyledTableCell>
              <StyledTableCell align="center">{row.reportCreatorId}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScSummaryTable;
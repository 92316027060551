import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Box,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// AccountModalOne과 동일한 스타일 컴포넌트 사용
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '800px',  // 더 작은 너비
    minHeight: '400px', // 더 작은 높이
    maxHeight: '90vh',
    borderRadius: '8px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

const StyledTableCell = styled(TableCell)({
  padding: '6px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  height: '32px',
  whiteSpace: 'nowrap',
  '&.no-cell': {
    width: '45px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 8px'
  },
  '&.label-cell': {
    width: '200px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 12px'
  },
  '&.value-cell': {
    width: '300px',
    color: '#495057',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    padding: '6px 12px'
  }
});

const AccountNoModal = ({ open, onClose, data }) => {
  const tableData = [
    { no: 1, label: '유형', value: data?.type || '영업1' },
    { no: 2, label: '아이디', value: data?.id || 'Att250109' },
    { no: 3, label: '상호명', value: data?.companyName || '20250109 시사사' },
    { no: 4, label: '대표자명', value: data?.representative || '20250109 시사사' },
    { no: 5, label: '연락처', value: data?.phone || '' },
    { no: 6, label: '휴대폰번호', value: data?.mobile || '' },
    { no: 7, label: '이메일', value: data?.email || 'bb@naver.com' },
    { no: 8, label: '은행명', value: data?.bankName || '' },
    { no: 9, label: '예금주', value: data?.accountHolder || '' },
    { no: 10, label: '계좌번호', value: data?.accountNumber || '' }
  ];

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth={false}
    >
      <StyledDialogTitle>
        계정별 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 3, pt: 4 }}>
        <TableContainer>
          <Table size="small" sx={{ '& td': { height: '32px' } }}>
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.no}>
                  <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                  <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                  <StyledTableCell className="value-cell">{row.value}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default AccountNoModal;

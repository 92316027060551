import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Box,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// 스타일 컴포넌트
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '1400px',
    minHeight: '800px',
    maxHeight: '90vh',
    borderRadius: '8px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

const StyledTableCell = styled(TableCell)({
  padding: '6px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  height: '32px',
  whiteSpace: 'nowrap',
  '&.no-cell': {
    width: '45px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 8px'
  },
  '&.label-cell': {
    width: '200px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 12px'
  },
  '&.value-cell': {
    width: '300px',
    color: '#495057',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    padding: '6px 12px'
  }
});

const formatValue = (value) => {
  if (typeof value === 'string' && value.includes('\n')) {
    return value.split('\n').join(' ');
  }
  return value;
};

const AccountModalOne = ({ open, onClose, data }) => {
  const leftTableData = [
    { no: 1, label: '로그인아이디', value: data?.userId || '' },
    { no: 2, label: '상호명', value: '1' },
    // { no: 5, label: '가맹점명', value: data?.companyName || '' },
    { no: 3, label: '대표자명', value: data?.representative || '' },
    { no: 4, label: '사업자/개인', value: data?.businessType || '' },
    // { no: 8, label: '세금계산서', value: `${data?.taxable || ''}\n${data?.withholding || ''}` },
    { no: 5, label: '사업자번호/주민번호', value: `${data?.businessNumber || ''}\n${data?.residentNumber || ''}` },
    { no: 6, label: '은행명', value: '새마을금고' },
    { no: 7, label: '예금주', value: data?.representative || '' },
    { no: 8, label: '계좌번호', value: '9002345667788' },
    { no: 9, label: '업종', value: '1' },
    { no: 10, label: '품명', value: '1' },
    { no: 11, label: '연락처', value: '010-111-1111' },
    { no: 12, label: '휴대번호', value: '010-333-1111' },
    { no: 13, label: '이메일', value: 'naver.com' },
    { no: 14, label: '주소', value: '' },
    { no: 15, label: '유형', value: data?.accountType || '' },
    { no: 16, label: '과세/비원천', value: `${data?.taxable || ''}\n${data?.withholding || ''}` },
    { no: 17, label: '포인트', value: '0' },
    { no: 18, label: '적립금', value: '0' },
    // { no: 19, label: '수수료', value: data?.fee || '' },
    // { no: 20, label: '수수료율', value: data?.commission || '' }
  ];

  const rightTableData = [
    { no: 19, label: '비회원에게 송금 정액', value: '500' },
    { no: 20, label: '비회원에게 송금 정률', value: '0.3' },
    { no: 21, label: '회원에게 송금 정액', value: '500' },
    { no: 22, label: '회원에게 송금 정률', value: '0.3' },
    { no: 23, label: '나의계좌 출금 수수료 정액', value: '500' },
    { no: 24, label: '나의계좌 출금 수수료 정률(%)', value: '0.3' },
    { no: 25, label: '어드민 입금 수수료 정책', value: '0' },
    { no: 26, label: '어드민 입금 수수료 정률(%)', value: '0' },
    { no: 27, label: '마스터 입금 수수료 정액', value: '0' },
    { no: 28, label: '마스터 입금 수수료 정률(%)', value: '0' },
    { no: 29, label: '건한도', value: '3,000,000' },
    { no: 30, label: '일한도', value: '200,000,000' },
    { no: 31, label: '월한도', value: '3,000,000,000' },
    { no: 32, label: '마스터', value: data?.master || '' },
    { no: 33, label: '지사', value: data?.branch || '' },
    { no: 34, label: '총판', value: data?.distributor || '' },
    { no: 35, label: '대리점', value: data?.agency || '' },
    { no: 36, label: '리테일', value: data?.retail || '' },
    { no: 37, label: '영업1', value: data?.sales1 || '' },
    { no: 38, label: '영업2', value: data?.sales2 || '' },
    { no: 39, label: '영업3', value: data?.sales3 || '' }
  ];

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth={false}
    >
      <StyledDialogTitle>
        계정별 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ 
        p: 3,
        pt: 4
      }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" sx={{ '& td': { height: '32px' } }}>
                <TableBody>
                  {leftTableData.map((row) => (
                    <TableRow key={row.no}>
                      <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                      <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                      <StyledTableCell className="value-cell">
                        {formatValue(row.value)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" sx={{ '& td': { height: '32px' } }}>
                <TableBody>
                  {rightTableData.map((row) => (
                    <TableRow key={row.no}>
                      <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                      <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                      <StyledTableCell className="value-cell">
                        {formatValue(row.value)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default AccountModalOne;

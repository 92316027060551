import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Paper, 
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Drawer,
  IconButton,
  Modal
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CancelIcon from '@mui/icons-material/Cancel';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import axios from 'axios';
import ExcelJS from 'exceljs';

// 로컬 컴포넌트 import
import Notice from '../../Notice/Notice';
import MaechulGamangTables from './MaechulGamangTables';
import SummaryTable from '../../Common/PaymentResultData/PayHisSummaryTable';
import Breadcrumb from '../../Common/Breadcrumb';
import DownloadButtons from '../../Common/DownloadButtons';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../Common/PaymentResultData/PayHisResultText';
// import ResultTable from '../../Common/PaymentResultData/PayHisResultTables';
import ReceiptModal from '../../Common/receiptModal';
import RMquestion from '../../Common/RMquestion';
import SetOff from '../../Common/SetOff';
import CurrentLocation from '../../Common/CurrentLocation';
import NewResultTables from '../../NewResultTables';

// 스타일 import
const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '280px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },

  closeButton: {
    position: 'absolute',
    left: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  sideOptions: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    borderRadius: '12px 0 0 12px',
    boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
    padding: '15px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  sideButton: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
    width: '20px',
    height: '40px',
    borderRadius: '4px 0 0 4px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '@media (max-width: 768px)': {
      width: '30px',
      height: '50px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  searchPaper: { 
    width: 'calc(100% - 20px)',
    margin: '0 auto',
    p: 2,
    mb: 2,
    overflowX: 'auto',
    borderRadius: '12px',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)',
    border: '1px solid #edf2f9',
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  searchContainer: { 
    minWidth: { xs: '100%', md: '1500px' }
  },
  searchField: {
    fontSize: '0.8rem'
  },
  formControl: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      backgroundColor: '#fff',
      '&:hover fieldset': {
        borderColor: '#51cbce'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#51cbce'
      }
    }
  },
  inputLabel: { 
    fontSize: '0.8rem',
    backgroundColor: 'white',
    px: 0.5,
    color: '#9A9A9A',
    '&.Mui-focused': {
      color: '#51cbce'
    }
  },
  select: { 
    fontSize: '0.8rem',
    '& .MuiSelect-select': {
      pt: 1,
      pb: 0.5
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0 3px 8px rgba(81, 203, 206, 0.1)'
    }
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      color: '#9A9A9A',
      '&.Mui-checked': {
        color: '#51cbce'
      }
    }
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '320px',
      boxSizing: 'border-box',
      mt: '64px',
      height: 'calc(100% - 64px)',
      border: 'none',
      backgroundColor: 'transparent',
      '@media (max-width: 768px)': {
        width: '100%',
        mt: '56px',
        height: 'calc(100% - 56px)'
      }
    }
  }
};

function PaymentHistory() {
  // 날짜 상태
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  
  // 검색 옵션 상태
  const [dateSearchType, setDateSearchType] = useState('');
  const [pgType, setPgType] = useState('');
  const [excludePg, setExcludePg] = useState(false);
  const [storeMid, setStoreMid] = useState('');
  const [walletType, setWalletType] = useState('');
  const [settlementCycle, setSettlementCycle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [masterSearch, setMasterSearch] = useState('');
  const [branchSearch, setBranchSearch] = useState('');
  const [totalSearch, setTotalSearch] = useState('');
  const [agencySearch, setAgencySearch] = useState('');
  const [retailSearch, setRetailSearch] = useState('');
  const [sales1Search, setSales1Search] = useState('');
  const [sales2Search, setSales2Search] = useState('');
  const [sales3Search, setSales3Search] = useState('');
  const [depositCheck, setDepositCheck] = useState('');
  const [merchantSearch, setMerchantSearch] = useState('');
  const [regularPayment, setRegularPayment] = useState('');
  const [duplicateCheck, setDuplicateCheck] = useState('');
  const [customerInfo, setCustomerInfo] = useState('');
  const [mid, setMid] = useState('');
  const [tid, setTid] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [productName, setProductName] = useState('');
  const [approvalNo, setApprovalNo] = useState('');
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [authType, setAuthType] = useState('');
  const [taxType, setTaxType] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [installmentType, setInstallmentType] = useState('');
  const [cancelStatus, setCancelStatus] = useState('');
  const [highAmountSort, setHighAmountSort] = useState('');
  const [excelDownload, setExcelDownload] = useState('');
  const [individualSettlement, setIndividualSettlement] = useState('');
  const [settlementTotal, setSettlementTotal] = useState('');
  const [individualSales, setIndividualSales] = useState('');
  const [salesTotal, setSalesTotal] = useState('');
  const [reserveTotal, setReserveTotal] = useState('');
  const [searchType, setSearchType] = useState('');

  // 새로운 상태 추가
  const [selectedTab, setSelectedTab] = useState('payment');

  // 취소 옵션 섹션에 대한 ref 생성
  const cancelOptionRef = useRef(null);
  
  // 선택된 승인번호들을 관리하는 상태 추가
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const [isSetOffModalOpen, setIsSetOffModalOpen] = useState(false);
  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);

  // 테이블 데이터를 저장할 state 추가
  const [tableData, setTableData] = useState([]);
  const [getAllPaymentData, setGetAllPaymentData] = useState(null);

  // dummyData를 PaymentHistory로 이동
  const dummyData = [...Array(10)].map((_, index) => ({
    no: index + 1,
    merchantName: `가맹점${index + 1}`,
    merchantStatus: [
      ['block'],
      ['sms'],
      ['sms', 'recurring'],
      ['block', 'recurring'],
      [],
      ['recurring'],
      ['block', 'sms'],
      ['sms'],
      ['recurring'],
      ['block']
    ][index],
    transactionDate: `2024-01-${String(index + 1).padStart(2, '0')} 14:${index}0:00`,
    amount: index % 3 === 0 ? -(1000 * (index + 1)) : (1000 * (index + 1)),
    installment: ['일시불', '3개월', '6개월'][index % 3],
    approvalNo: `APPR${String(index + 1).padStart(8, '0')}`,
    cardCompany: ['국민카드', '신한카드', '삼성카드', '하나카드'][index % 4],
    cardNumber: `${9410 + index}-${String(93 + index).padStart(2, '0')}**-****-${String(2700 + index).padStart(4, '0')}`,
    productName: `상품${index + 1}`,
    phoneNumber: `010-1234-${String(index + 1).padStart(4, '0')}`,
    // ... 나머지 필드들
  }));

  // 크박스 선택 시 실행 핸들러
  const handleCheckboxChange = (checked, approvalNo) => {
    if (checked) {
      setSelectedApprovals(prev => [...prev, approvalNo]);
    } else {
      setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
    }
  };

  // 날짜 검색 핸들러
  const handleDateSearch = (value) => {
    const today = new Date();
    let start = new Date();
    
    switch(value) {
      case '1': // 오늘
        setStartDate(today.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '2': // 어제
        start.setDate(today.getDate() - 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(start.toISOString().split('T')[0]);
        break;
      case '3': // 지난7일
        start.setDate(today.getDate() - 7);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '5': // 이달
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '13': // 지난3개월
        start = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      default:
        break;
    }
    setDateSearchType(value);
  };

  // 검색 효과 추가
  useEffect(() => {
    // 검색 조건이 변경될 마다 색 실행
    const handleSearch = () => {
      // 실제로 API 호나 데이터 필터링이 들어갈 자리
      console.log('Search with:', {
        dateSearchType,
        pgType,
        storeMid,
        walletType,
        settlementCycle,
        // ... 기타 검색 조건들
      });
    };

    handleSearch();
  }, [
    dateSearchType,
    pgType,
    storeMid,
    walletType,
    settlementCycle,
    // ... 검색에 사용될 state들
  ]);

  // 다운로드 버튼 부분 수정
  const downloadButtons = [
    { label: '개별정산', value: 'individual', color: '#2196f3' },
    { label: '정산합계', value: 'settlement', color: '#4caf50' },
    { label: '개별영업', value: 'sales', color: '#ff9800' },
    { label: '영업합계', value: 'salesTotal', color: '#f44336' },
    { label: '유보합계', value: 'reserve', color: '#9c27b0' },
  ];

  // 상단 새로운 state 추가
  const [rowCount, setRowCount] = useState(''); // 데이터개수를 위한 별도 state

  // 사이드드 옵션 핸들러
  const handleSideOptionClick = (action) => {
    switch(action) {
      case 'summary':
        // 하단 합계표로 스크롤
        const summaryElement = document.querySelector('.summary-table');
        if (summaryElement) {
          summaryElement.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'pg':
        // PG사 관련 액션
        console.log('PG사 옵션 클릭');
        break;
      // ... 필요한 다른 액션들
    }
  };

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Received search params:', searchParams);  // 디버깅용
    
    // 검색 파라미터 업데이트
    setSearchParams(searchParams);
    
    // 데이터 개수 설정 (필요한 경우)
    if (searchParams.dataCount) {
      setLimit(Number(searchParams.dataCount));
    }
  };

  // searchPanelOpen의 초기값을 true로 설정
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);

  // 화면 크기 감지를 위한 상태 추가
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      // 모바일에서 데스크탑으로 전환시 서치패널 자동으로 열기
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 동적 스타일 계산 수정
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    }
  };

  // 영수증 모달 상태 추가
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const [receiptData, setReceiptData] = useState(null);

  // 영수증 아이콘 클릭 핸들러
  const handleReceiptClick = (rowData) => {
    // rowData에서 필요한 데이터 매핑
    const receiptInfo = {
      paymentType: rowData.paymentType,
      cardType: rowData.cardCompany,
      transactionDate: rowData.transactionDate,
      cardNumber: rowData.cardNumber,
      installment: rowData.installment,
      approvalNo: rowData.approvalNo,
      taxAmount: rowData.amount,
      vat: rowData.fee,
      totalAmount: rowData.paymentAmount,
      storeName: rowData.merchantName,
      businessNo: rowData.mid,
      ownerName: '대표자명',  // 데이터에 있다면 매핑
      phoneNumber: rowData.phoneNumber,
      address: '가맹점 주소'  // 이터에 있다면 매핑
    };

    setReceiptData(receiptInfo);
    setIsReceiptOpen(true);
  };

  const [limit, setLimit] = useState(10); // 기본값 10개

  // 데이터 조회 함수
  const getPaymentHistory = async (limitCount = limit) => {
    try {
      // API 호출 로직
      const response = await axios.get('/payments/', {
        params: {
          skip: 0,
          limit: limitCount
        }
      });
      // 데이터 처리 로직...
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // 검색 조건을 저장할 state
  const [searchParams, setSearchParams] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    limit: 100
  });

  // SearchOptions의 확인 버튼 클릭 시에만 호출되는 핸들러
  const handleSearchSubmit = (params) => {
    console.log('SearchOptions에서 받은 검색 조건:', params);
    setSearchParams({
      startDate: params.startDate,
      endDate: params.endDate,
      limit: Number(params.limit)
    });
  };

  // 데이터 업데이트 핸들러 수정
  const handleDataUpdate = ({ data, getAllData }) => {
    setTableData(data);
    setGetAllPaymentData(() => getAllData);
  };

  // 엑셀 다운로드 함수 수정
  const handleExcelDownload = async () => {
    try {
      if (!getAllPaymentData) {
        console.warn('데이터를 가져오는 함수가 준비되지 않았습니다.');
        return;
      }

      const allData = await getAllPaymentData();
      
      if (!allData || allData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('결제내역');

      // 선택 컬럼만 제외하고 모든 헤더 포함
      const headers = [
        '가맹점명', '거래일시', '결제금액', '할부', '승인번호', '카드사', '카드번호',
        '상품', '연락처', '영수증', '결제형식', '계정/상세', '수수료(%)', '월렛잔액',
        '정산금(%)', '지급액(%)', '유보액(%)', '환입금', '미수금', '보증금(%)', 'PG', 'MID', 'TID'
      ];

      // 각 컬럼의 정렬 방식 정의
      const columnAlignments = {
        '가맹점명': 'left',
        '거래일시': 'left',
        '결제금액': 'right',
        '할부': 'center',
        '승인번호': 'left',
        '카드사': 'left',
        '카드번호': 'left',
        '상품': 'left',
        '연락처': 'left',
        '영수증': 'center',
        '결제형식': 'left',
        '계정/상세': 'left',
        '수수료(%)': 'right',
        '월렛잔액': 'right',
        '정산금(%)': 'right',
        '지급액(%)': 'right',
        '유보액(%)': 'right',
        '환입금': 'right',
        '미수금': 'right',
        '보보증금(%)': 'right',
        'PG': 'left',
        'MID': 'left',
        'TID': 'left'
      };

      worksheet.columns = headers.map(header => ({
        header,
        width: 15,
        style: {
          alignment: { 
            horizontal: columnAlignments[header], 
            vertical: 'middle' 
          }
        }
      }));

      // 데이터 행행 추가 (정렬에 맞춰서)
      const rows = allData.map(payment => [
        payment.cname,  // 좌측 정렬
        payment.trxDate.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6'),  // 좌측 정렬
        `${Number(payment.amount).toLocaleString()}원`,  // 우측 정렬
        payment.installment === '00' ? '일시불' : `${Number(payment.installment)}개월`,  // 중앙 정렬
        payment.authCd,  // 좌측 정렬
        payment.issuer,  // 좌측 정렬
        payment.bin.replace(/(\d{4})([0-9*]{4})([0-9*]{4})([0-9*]{4})/, '$1-$2-$3-$4'),  // 좌측 정렬
        payment.goodsname,  // 좌측 정렬
        payment.usertel,  // 좌측 정렬
        '',  // 영수증 (중앙 정렬)
        payment.pay_type === '2' ? '단말기' : payment.pay_type === '1' ? '수기' : payment.pay_type,  // 좌측 정렬
        '',  // 계정/상세 (좌측 정렬)
        `${payment.admin_fee} / ${payment.payfee}%`,  // 우측 정렬
        Number(payment.balance_com).toLocaleString(),  // 우측 정렬
        '-',  // 정산금 (우측 정렬)
        '-',  // 지급액 (우측 정렬)
        '-',  // 유보액 (우측 정렬)
        Number(payment.balance_refund).toLocaleString(),  // 우측 정렬
        Number(payment.balance_mem).toLocaleString(),  // 우측 정렬
        `${payment.balance_block} / ${payment.deposit}%`,  // 우측 정렬
        payment.van,  // 좌측 정렬
        payment.mchtId,  // 좌측 정렬
        payment.tmnId  // 좌측 정렬
      ]);

      worksheet.addRows(rows);

      // 컬럼 너비 자동 조정 수정
      worksheet.columns.forEach((column, index) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
          // 기본 폰트 크기를 9로 설정
          cell.font = { 
            size: 9,
            name: '맑은 고딕'
          };
          
          const cellValue = cell.value !== null && cell.value !== undefined ? cell.value.toString() : '';
          const length = [...cellValue].reduce((acc, char) => {
            return acc + (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(char) ? 1.8 : 1);
          }, 0);
          if (length > maxLength) {
            maxLength = length;
          }

          // 정렬 속성 적용
          const header = headers[index];
          cell.alignment = {
            horizontal: columnAlignments[header],
            vertical: 'middle'
          };
        });
        
        column.width = Math.max(6, maxLength + 1);
      });

      // 스타일 적용
      worksheet.eachRow((row, rowNumber) => {
        row.height = 18;
        row.eachCell((cell, colNumber) => {
          // 테두리 스타일
          cell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };

          // 헤더 행 스타일
          if (rowNumber === 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF1976D2' }
            };
            cell.font = {
              size: 9,
              color: { argb: 'FFFFFFFF' },
              bold: true,
              name: '맑은 고딕'
            };
            cell.alignment = {
              horizontal: 'center',
              vertical: 'middle'
            };
          }
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `결제내역_${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading excel:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - 패널이 닫혔을 때만 표시 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer 수정 */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearchSubmit}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={handleExcelDownload}  // 엑셀 다운로드 핸들러 전달
          isMobile={isMobile}
        />
      </Drawer>

      {/* Notice 영역 */}
      <Box sx={{ 
        ...styles.noticeBox,
        pl: '-5px',
        pr: '-5px'
      }}>
        <Notice />
        <CurrentLocation mainTitle="지급통계" subTitle="가맹별" />
        
      </Box>

      {/* 테이블과 버튼 사이에 추가 여백을 위한 Box */}
      <Box sx={{ mb: 2 }} />

      {/* ResultText 추가 */}
      <ResultText />

      {/* NewResultTables 추가 */}
      <NewResultTables />

      {/* ResultTable 추가 */}
      {/* <ResultTable /> */}

      {/* 테이블 부분만 컴포넌트로 교체 */}
      <MaechulGamangTables 
        searchParams={searchParams}
        onCheckboxChange={handleCheckboxChange}
        selectedApprovals={selectedApprovals}
        setSelectedApprovals={setSelectedApprovals}
        searchPanelOpen={searchPanelOpen}
        onReceiptClick={handleReceiptClick}
        onDataUpdate={handleDataUpdate}
      />
      
      {/* 하단 합계 표 추가 */}
      <SummaryTable searchPanelOpen={searchPanelOpen} />

      {/* 마지막에 QuickMenu 추가 */}
      <QuickMenu />

      {/* 영수증 모달 추가 */}
      <ReceiptModal
        open={isReceiptOpen}
        onClose={() => setIsReceiptOpen(false)}
        receiptData={receiptData}
      />

      

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />

      {/* SetOff 팝업 수정 */}
      <Modal
        open={isSetOffModalOpen}
        onClose={() => setIsSetOffModalOpen(false)}
        aria-labelledby="setoff-modal-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiModal-backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <Box sx={{ 
          width: '95%',
          maxWidth: '1000px',
          minWidth: '1300px',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          height: '95vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <SetOff onClose={() => setIsSetOffModalOpen(false)} />
        </Box>
      </Modal>

      
    </Box>
  );
}

export default PaymentHistory;

import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel
} from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import ExcelJS from 'exceljs';

// 테이블 래퍼 스타일 추가
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 테이블 셀 스타일 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

const PaymentHistoryDBCheck = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');

  // 더미 데이터
  const rows = [
    {
      no: 1,
      delete_flag: '일반',
      date: '2024-03-15 10:12:11',
      order_no: 'ORD20240315001',
      approval_no: 'APV123456789',
      amount: '50,000',
      match: '일치'
    },
    {
      no: 2,
      delete_flag: '일반',
      date: '2024-03-14 15:22:43',
      order_no: 'ORD20240314002',
      approval_no: 'APV987654321',
      amount: '75,000',
      match: '불일치'
    }
  ];

  // 5. 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: '결제내역 DB체크',
    fileNamePrefix: 'payment_history_check_'
  };

  // 6. 컬럼 매핑 정의
  const columnMapping = {
    'No.': 'no',
    '상태': 'delete_flag',
    '등록일': 'date',
    '주문번호': 'order_no',
    '승인번호': 'approval_no',
    '결제 금액': 'amount',
    '일치여부': 'match'
  };

  // 7. 엑셀 헤더 정의
  const headers = ['No.', '상태', '등록일', '주문번호', '승인번호', '결제 금액', '일치여부'];

  // 3. 컬럼 정렬 설정
  const columnAlignments = {
    'No.': 'center',
    '상태': 'center',
    '등록일': 'center',
    '주문번호': 'center',
    '승인번호': 'center',
    '결제 금액': 'right',
    '일치여부': 'center'
  };

  // 4. 엑셀 스타일 설정
  const defaultCellStyle = {
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    font: {
      size: 10,
      name: '맑은 고딕'
    }
  };

  const headerCellStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1976D2' }
    },
    font: {
      size: 10,
      color: { argb: 'FFFFFFFF' },
      bold: true,
      name: '맑은 고딕'
    },
    height: 30
  };

  const defaultRowHeight = 25;

  // 2. 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );

    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };

    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );

    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      if (!rows || rows.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header),
        style: {
          alignment: {
            horizontal: columnAlignments[header],
            vertical: 'middle'
          }
        }
      }));

      worksheet.addRows(rows);

      worksheet.eachRow((row, rowNumber) => {
        row.height = defaultRowHeight;
        row.eachCell(cell => Object.assign(cell, defaultCellStyle));
        
        if (rowNumber === 1) {
          row.height = headerCellStyle.height;
          row.eachCell(cell => Object.assign(cell, headerCellStyle));
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 파일 선택 핸들러
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  // 파일 선택 버튼 클릭 핸들러
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  // 파일 취소 핸들러
  const handleCancelFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // 정렬 핸들러
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = [...rows].sort((a, b) => {
    const isAsc = order === 'asc';
    if (orderBy === 'no') {
      return isAsc ? a.no - b.no : b.no - a.no;
    }
    return isAsc
      ? String(a[orderBy]).localeCompare(String(b[orderBy]))
      : String(b[orderBy]).localeCompare(String(a[orderBy]));
  });

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <PaymentsIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          결제내역 DB 검사
        </Typography>
      </Box>

      <Paper elevation={0} sx={{ 
        borderRadius: '8px', 
        overflow: 'hidden',
        width: '100%'
      }}>
        <Box sx={{ p: 2, borderBottom: '1px solid #e0e0e0' }}>
          {/* 파일 업로드 영역 */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 3,
              border: '1px solid #e0e0e0',
              borderRadius: '8px'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2,
              mb: 2
            }}>
              {/* 파일 선택 영역 */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                  accept=".xlsx,.xls"
                />
                {selectedFile && (
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    backgroundColor: '#f5f5f5',
                    padding: '4px 8px',
                    borderRadius: '4px'
                  }}>
                    <Typography variant="body2" color="textSecondary">
                      {selectedFile.name}
                    </Typography>
                    <IconButton
                      onClick={handleCancelFile}
                      size="small"
                      sx={{
                        width: '20px',
                        height: '20px',
                        color: '#d32f2f',
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} size="xs" />
                    </IconButton>
                  </Box>
                )}
                <Tooltip title="파일 선택">
                  <IconButton
                    onClick={handleFileButtonClick}
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: '1px solid #1976d2',
                      color: '#1976d2',
                      '&:hover': {
                        backgroundColor: '#1976d210',
                      }
                    }}
                  >
                    <UploadFileIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="엑셀 양식 다운로드">
                  <IconButton
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: '1px solid #2e7d32',
                      color: '#2e7d32',
                      '&:hover': {
                        backgroundColor: '#2e7d3210',
                      }
                    }}
                  >
                    <SaveAltIcon />
                  </IconButton>
                </Tooltip>
              </Box>

            
            </Box>

            {/* 검색 옵션 영역 */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2,
              mt: 2
            }}>
              {/* 데이터 개수 선택 */}
              <RadioGroup
                row
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(Number(e.target.value))}
                sx={{ gap: 2 }}
              >
                {[50, 100, 200, 500].map((value) => (
                  <FormControlLabel
                    key={value}
                    value={value === 'All' ? -1 : value}
                    control={<Radio size="small" />}
                    label={value}
                  />
                ))}
              </RadioGroup>

              {/* 검색어 입력 */}
              <TextField
                size="small"
                placeholder="검색어를 입력하세요"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{ width: '300px' }}
              />

              {/* 엑셀 다운로드 */}
              <Tooltip title="엑셀 다운로드">
                <IconButton
                  onClick={handleExcelDownload}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </Box>

        <StyledTableWrapper>
          <TableContainer sx={{ 
            width: '100%',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              height: '8px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#555'
              }
            }
          }}>
            <Table sx={{ 
              minWidth: '1200px',
              tableLayout: 'fixed'
            }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: '60px' }}>No.</StyledTableCell>
                  <StyledTableCell sx={{ width: '100px' }}>상태</StyledTableCell>
                  <StyledTableCell sx={{ width: '160px' }}>등록일</StyledTableCell>
                  <StyledTableCell sx={{ width: '160px' }}>주문번호</StyledTableCell>
                  <StyledTableCell sx={{ width: '140px' }}>승인번호</StyledTableCell>
                  <StyledTableCell sx={{ width: '120px' }}>결제 금액</StyledTableCell>
                  <StyledTableCell sx={{ width: '80px' }}>일치여부</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.no}>
                    <StyledTableCell>{row.no}</StyledTableCell>
                    <StyledTableCell>{row.delete_flag}</StyledTableCell>
                    <StyledTableCell>{row.date}</StyledTableCell>
                    <StyledTableCell>{row.order_no}</StyledTableCell>
                    <StyledTableCell>{row.approval_no}</StyledTableCell>
                    <StyledTableCell align="right">{row.amount}</StyledTableCell>
                    <StyledTableCell>{row.match}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>
      </Paper>
    </Box>
  );
};

export default PaymentHistoryDBCheck;

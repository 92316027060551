import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

const ChartWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

function GamengData() {
  // 30개의 가맹점 더미 데이터 생성
  const generateDummyData = () => {
    const data = {};
    for(let i = 1; i <= 30; i++) {
      const monthlyData = {
        가맹점명: `가맹점${i}`,
        아이디: `m${70000000 + i}`,
        건수합계: Math.floor(Math.random() * 100) + 20,
        합계: 0, // 합계 초기화
      };
      
      // 월별 데이터 생성
      for(let month = 1; month <= 12; month++) {
        monthlyData[`${String(month).padStart(2, '0')}월`] = Math.floor(Math.random() * 30000000);
      }
      
      // 월별 데이터 합계 계산
      monthlyData.합계 = Array.from({ length: 12 }, (_, i) => 
        monthlyData[`${String(i + 1).padStart(2, '0')}월`]
      ).reduce((sum, value) => sum + value, 0);
      
      data[`${100 + i}`] = monthlyData;
    }
    return data;
  };

  const dummyData = generateDummyData();

  // 차트 옵션
  const chartOption = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff',
      borderWidth: 1,
      borderColor: '#fff',
      textStyle: {
        color: '#666',
        fontSize: 11
      },
      padding: 10,
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: Object.values(dummyData).map(item => item.가맹점명),
      bottom: 0,
      type: 'scroll',
      width: '80%',
      textStyle: {
        color: '#666666',
        fontSize: 12
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '60px',
      top: '20px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['01월', '02월', '03월', '04월', '05월', '06월', 
             '07월', '08월', '09월', '10월', '11월', '12월'],
      axisLine: {
        lineStyle: { color: '#eaeaea' }
      },
      axisLabel: {
        color: '#666',
        fontSize: 11
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#666',
        fontSize: 11,
        formatter: (value) => {
          if (value === 0) return '0';
          return (value / 10000) + '만';
        }
      },
      splitLine: {
        lineStyle: {
          color: '#eaeaea',
          type: 'dashed'
        }
      }
    },
    series: Object.values(dummyData).map((item, index) => ({
      name: item.가맹점명,
      type: 'line',
      data: [
        item['01월'], item['02월'], item['03월'], item['04월'],
        item['05월'], item['06월'], item['07월'], item['08월'],
        item['09월'], item['10월'], item['11월'], item['12월']
      ],
      symbol: 'circle',
      symbolSize: 6,
      itemStyle: {
        color: `hsl(${(index * 360) / Object.keys(dummyData).length}, 70%, 50%)`
      }
    }))
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>가맹점별 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">가맹점명</StyledTableCell>
                  <StyledTableCell>아이디</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">합계</StyledTableCell>
                  {Array.from({ length: 12 }, (_, i) => (
                    <StyledTableCell key={i} align="right" className="secondary-header">
                      {String(i + 1).padStart(2, '0')}월
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.values(dummyData).map((data, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="sticky-column">{data.가맹점명}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>{data.아이디}</StyledTableCell>
                    <StyledTableCell className="primary-header" sx={{ textAlign: 'right' }}>
                      {data.건수합계.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell className="primary-header" sx={{ textAlign: 'right' }}>
                      {data.합계.toLocaleString()}
                    </StyledTableCell>
                    {Array.from({ length: 12 }, (_, i) => (
                      <StyledTableCell key={i} className="secondary-header" sx={{ textAlign: 'right' }}>
                        {data[`${String(i + 1).padStart(2, '0')}월`].toLocaleString()}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        <ChartWrapper>
          <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
            <ReactECharts 
              option={chartOption}
              style={{ height: '400px' }}
              className="echarts-for-react boot-chart"
            />
          </Box>
        </ChartWrapper>
      </Box>
    </Paper>
  );
}

export default GamengData;

import React from 'react';
import { Box, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const CurrentLocation = ({ mainTitle, subTitle }) => {
  return (
    <Box sx={{ 
      mb: 2,
      ml: '12px'
    }}>
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        aria-label="breadcrumb"
      >
        <Link
          to="#"
          style={{
            textDecoration: 'none',
            color: '#333',
            fontSize: '1.1rem',
            fontWeight: '700'
          }}
        >
          {mainTitle}
        </Link>
        <Typography 
          color="text.primary" 
          sx={{ 
            fontSize: '0.9rem',
            fontWeight: 600,
            color: '#1976d2'
          }}
        >
          {subTitle}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default CurrentLocation;

import React from 'react';
import { Box } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const MerchantStatusIcons = ({ statuses }) => {
  const getIcon = (status) => {
    switch (status) {
      case 'block':
        return <BlockIcon sx={{ fontSize: '0.875rem', color: '#dc3545' }} />;  // 아이콘 크기 축소
      case 'sms':
        return <TextsmsIcon sx={{ fontSize: '0.875rem', color: '#4caf50' }} />;  // 아이콘 크기 축소
      case 'recurring':
        return <AutorenewIcon sx={{ fontSize: '0.875rem', color: '#2196f3' }} />;  // 아이콘 크기 축소
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'inline-flex', gap: 0.5, alignItems: 'center', ml: 0.5 }}>
      {statuses.map((status, index) => (
        <Box key={index}>
          {getIcon(status)}
        </Box>
      ))}
    </Box>
  );
};

export default MerchantStatusIcons;
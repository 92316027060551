import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

const ChartWrapper = styled(Box)({
  width: '100%',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

function PgData() {
  // 더미 데이터 생성
  const dummyData = {
    거래건수: [6536, 110, 6426],
    합계: [215037504, -2942504, 217980008],
    'PG사A': [26281590, -106000, 26387590],
    'PG사B': [118284582, -810400, 119094982],
    'PG사C': [70471332, -2026104, 72497436]
  };

  const tableCellStyle = {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '8px',
    textAlign: 'center',
    minWidth: '120px',
  };

  // 차트 옵션 수정
  const chartOption = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff',
      borderWidth: 1,
      borderColor: '#fff',
      textStyle: {
        color: '#666',
        fontSize: 11
      },
      padding: 10,
      formatter: function(params) {
        return `${params[0].name}<br/>` +
          params.map(param => {
            return `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>` +
            `${param.seriesName}: <span style="font-weight:bold">${param.value.toLocaleString()}원</span>`
          }).join('<br/>');
      }
    },
    legend: {
      data: ['PG사A', 'PG사B', 'PG사C'],
      type: 'plain',
      textStyle: { color: '#666666', fontSize: 12 },
      icon: 'circle',
      itemGap: 15,
      itemWidth: 8,
      itemHeight: 8,
      orient: 'vertical',
      right: 0,
      top: 'center'
    },
    grid: {
      left: '3%',
      right: '15%',
      bottom: '3%',
      top: '8%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'],
      axisLabel: {
        color: '#666',
        fontSize: 11
      },
      axisLine: {
        lineStyle: {
          color: '#eaeaea'
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#666',
        fontSize: 11,
        formatter: (value) => {
          if (value === 0) return '0';
          return (value / 100000000) + '억';
        }
      },
      splitLine: {
        lineStyle: {
          color: '#eaeaea',
          type: 'dashed'
        }
      }
    },
    series: [
      {
        name: 'PG사A',
        type: 'line',
        data: [26281590, 28284582, 27471332, 29789012, 31234567, 30567890],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          width: 2
        }
      },
      {
        name: 'PG사B',
        type: 'line',
        data: [118284582, 120583041, 119471332, 121789012, 123234567, 122567890],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          width: 2
        }
      },
      {
        name: 'PG사C',
        type: 'line',
        data: [70471332, 72583041, 71471332, 73789012, 75234567, 74567890],
        showSymbol: false,
        smooth: true,
        lineStyle: {
          width: 2
        }
      }
    ]
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>PG사별 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">구분</StyledTableCell>
                  <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell className="primary-header">합계</StyledTableCell>
                  <StyledTableCell className="secondary-header">01월</StyledTableCell>
                  <StyledTableCell className="secondary-header">02월</StyledTableCell>
                  <StyledTableCell className="secondary-header">03월</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">실 매출금액</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa'
                  }}>{dummyData['거래건수'][0].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa'
                  }}>{dummyData['합계'][0].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['PG사A'][0].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['PG사B'][0].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['PG사C'][0].toLocaleString()}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow className="negative-amount">
                  <StyledTableCell className="sticky-column">취소금액</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa'
                  }}>{dummyData['거래건수'][1].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa'
                  }}>{dummyData['합계'][1].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['PG사A'][1].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['PG사B'][1].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>{dummyData['PG사C'][1].toLocaleString()}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column" sx={{ fontWeight: 'bold' }}>결제금액</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa',
                    fontWeight: 'bold'
                  }}>{dummyData['거래건수'][2].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa',
                    fontWeight: 'bold'
                  }}>{dummyData['합계'][2].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    fontWeight: 'bold'
                  }}>{dummyData['PG사A'][2].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    fontWeight: 'bold'
                  }}>{dummyData['PG사B'][2].toLocaleString()}</StyledTableCell>
                  <StyledTableCell sx={{ 
                    textAlign: 'right',
                    fontWeight: 'bold'
                  }}>{dummyData['PG사C'][2].toLocaleString()}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        <ChartWrapper>
          <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
            <ReactECharts 
              option={chartOption}
              style={{ height: '300px' }}
              className="echarts-for-react boot-chart"
            />
          </Box>
        </ChartWrapper>
      </Box>
    </Paper>
  );
}

export default PgData;

import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled
} from '@mui/material';

// 스타일 컴포넌트 정의
const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',  // 테이블과 버튼 사이 간격
  width: '100%'
});

const StyledTableContainer = styled(TableContainer)({
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  overflow: 'hidden',
  border: '1px solid #e0e7ff',
  width: 'auto',
  minWidth: '300px',  // 모바일 대응을 위해 최소 너비 축소
  flex: '1',
  '@media (max-width: 768px)': {
    width: '100%'  // 모바일에서는 전체 너비
  }
});

const StyledTableCell = styled(TableCell)({
  padding: '8px 12px',  // 패딩 축소
  fontSize: '0.875rem',
  borderBottom: '1px solid #e0e7ff',
  '&.label': {
    backgroundColor: '#f8faff',
    fontWeight: 600,
    width: '100px',  // 너비 축소
    color: '#2c3e50',
    borderRight: '1px solid #e0e7ff'
  },
  '&.value': {
    color: '#34495e',
    fontWeight: 500,
    backgroundColor: '#ffffff',
    width: '100px'  // 값 셀도 너비 제한
  },
  '&.highlight': {
    color: '#4070f4'
  }
});

const CashResultTables = () => {
  const resultData = {
    전액: '6,989',
    적립금: '0',
    포인트: '10,000'
  };

  return (
    <StyledBox>
      <StyledTableContainer component={Paper}>
        <Table size="small">  {/* 테이블 사이즈 축소 */}
          <TableBody>
            <TableRow>
              <StyledTableCell className="label">전액:</StyledTableCell>
              <StyledTableCell className="value">{resultData.전액}</StyledTableCell>
              <StyledTableCell className="label">적립금:</StyledTableCell>
              <StyledTableCell className="value">{resultData.적립금}</StyledTableCell>
              <StyledTableCell className="label">포인트:</StyledTableCell>
              <StyledTableCell className="value highlight">{resultData.포인트}</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
      {/* 버튼은 부모 컴포넌트에서 이 컴포넌트 옆에 배치하면 됩니다 */}
    </StyledBox>
  );
};

export default CashResultTables;
import React, { useState, useEffect } from 'react';
import { 
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuickMenu from '../../Common/QuickMenu';
import StatisticsGiganSearch from '../../Common/StatisticsGiganSearch';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import GamengData from './bunseokWolSection/GamengData';
import WolData from './bunseokWolSection/WolData';
import WolgwaseData from './bunseokWolSection/WolgwaseData';
import WolseobjaData from './bunseokWolSection/WolseobjaData';
import PgData from './bunseokWolSection/PgData';
import InjeungData from './bunseokWolSection/InjeungData';

function BunseokWol() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '10px'
    },
    ml: {
      xs: '10px',
      md: '50px'
    },
    '@media (max-width: 960px)': {
      ml: '10px',
      pr: '10px'
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      <Notice />
      <CurrentLocation mainTitle="통계" subTitle="분석월별" />
      
      <Box sx={{ mb: '20px' }} />
      
      <Box>
        <GamengData />
        <WolData />
        <WolgwaseData />
        <WolseobjaData />
        <PgData />
        <InjeungData />
      </Box>

      <Box sx={{ mb: '20px' }} />

      <QuickMenu />

      {!searchPanelOpen && (
        <Tooltip title="검색 패널 열기" placement="left">
          <IconButton
            onClick={() => setSearchPanelOpen(true)}
            sx={{
              position: 'fixed',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#fff',
              borderRadius: '12px 0 0 12px',
              boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              },
              zIndex: 900
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      )}

      {searchPanelOpen && (
        <StatisticsGiganSearch 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
        />
      )}
    </Box>
  );
}

export default BunseokWol; 
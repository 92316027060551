import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField
} from '@mui/material';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';

const ServerModule = () => {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');

  // 더미 데이터
  const rows = [
    {
      no: 172,
      type: 'update',
      module: 'p_balance_division',
      description: '포인트 잔액을 분할하여 업데이트합니다.'
    },
    {
      no: 171,
      type: 'update',
      module: 'balance_reject',
      description: '잔액 거부 상태를 업데이트합니다.'
    },
    {
      no: 170,
      type: 'update',
      module: 'shop_delete',
      description: '상점 데이터를 삭제합니다.'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ];

  // 4. 엑셀 스타일 설정
  const defaultCellStyle = {
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    font: {
      size: 10,
      name: '맑은 고딕'
    }
  };

  const headerCellStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1976D2' }
    },
    font: {
      size: 10,
      color: { argb: 'FFFFFFFF' },
      bold: true,
      name: '맑은 고딕'
    },
    height: 30
  };

  const defaultRowHeight = 25;

  // 5. 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: '서버모듈정보',
    fileNamePrefix: 'server_module_'
  };

  // 6. 컬럼 매핑 정의
  const columnMapping = {
    'No': 'no',
    'TYPE': 'type',
    'MODULE': 'module',
    '상세내용': 'description'
  };

  // 7. 엑셀 헤더 정의
  const headers = ['No', 'TYPE', 'MODULE', '상세내용'];

  // 2. 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );
    return maxDataLength * 2;
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      if (!rows || rows.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header)
      }));

      worksheet.addRows(rows);

      worksheet.eachRow((row, rowNumber) => {
        row.height = defaultRowHeight;
        row.eachCell(cell => Object.assign(cell, defaultCellStyle));
        
        if (rowNumber === 1) {
          row.height = headerCellStyle.height;
          row.eachCell(cell => Object.assign(cell, headerCellStyle));
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 버튼 정의 수정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2',
      onClick: () => console.log('Copy clicked')
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: handleExcelDownload
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2',
      onClick: () => console.log('Print clicked')
    }
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };
    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IntegrationInstructionsIcon sx={{ color: '#00acc1' }} />
            <Typography variant="h6">서버 모듈 정보</Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 2,
            alignItems: 'center'
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ 
                minWidth: '200px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '40px'
                }
              }}
            />
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                  onClick={button.onClick}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'no'}
                      direction={orderBy === 'no' ? order : 'asc'}
                      onClick={() => handleRequestSort('no')}
                    >
                      No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">TYPE</TableCell>
                  <TableCell align="center">MODULE</TableCell>
                  <TableCell align="center">상세내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.no}>
                    <TableCell align="center">{row.no}</TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">{row.module}</TableCell>
                    <TableCell>{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default ServerModule;

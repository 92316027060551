import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExcelJS from 'exceljs';
import { useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// 공통 컴포넌트 import
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import AccountNoSearch from '../../Common/ManagementData/AccountNoSearch';
import QuickMenu from '../../Common/QuickMenu';
import AccountNoTables from './AccountNoTables';

// 스타일 정의
const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden'
  },
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    pl: '50px',
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)',
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '280px',
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      borderLeft: '1px solid #e0e0e0',
      height: 'calc(100% - 64px)',
      top: '64px'
    }
  }
};

function AccountNo() {
  // 검색 패널 상태
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchParams, setSearchParams] = useState({
    startDate: '',
    endDate: '',
    limit: 10
  });
  const [tableData, setTableData] = useState([]);
  const [getAllMerchantData, setGetAllMerchantData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearchSubmit = (params) => {
    setSearchParams({
      startDate: params.startDate,
      endDate: params.endDate,
      limit: Number(params.limit)
    });
  };

  // 데이터 업데이트 핸들러
  const handleDataUpdate = ({ data, getAllData }) => {
    setTableData(data);
    setGetAllMerchantData(() => getAllData);
  };

  // 엑셀 다운로드 핸들러 수정
  const handleExcelDownload = async () => {
    try {
      if (!getAllMerchantData) {
        console.warn('데이터를 가져오는 함수가 준비되지 않았습니다.');
        return;
      }

      const allData = await getAllMerchantData();
      
      if (!allData || allData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('계정관리');

      // 헤더 설정 - columns 배열과 동일하게 맞춤
      const headers = [
        'No',
        '상호명(치환여부)',
        '대표자명',
        '아이디',
        '사업자/개인',
        '유형',
        '계정수수료(VAT별도)',
        '수수료',
        '세금',
        '원천',
        '사업자번호',
        '주민번호',
        '취소권한',
        '정산여부',
        '정산일',
        '월렛사용여부',
        '영업1',
        '영업2',
        '영업3',
        '대리점',
        '리테일',
        '지사',
        '총판',
        '시니어마스터',
        '마스터',
        '등록일',
        '수정일',
        '작업자 아이디'
      ];

      // 정렬 설정
      const columnAlignments = {
        'No': 'center',
        '상호명(치환여부)': 'left',
        '대표자명': 'left',
        '아이디': 'center',
        '사업자/개인': 'center',
        '유형': 'center',
        '계정수수료(VAT별도)': 'center',
        '수수료': 'center',
        '세금': 'center',
        '원천': 'center',
        '사업자번호': 'center',
        '주민번호': 'center',
        '취소권한': 'center',
        '정산여부': 'center',
        '정산일': 'center',
        '월렛사용여부': 'center',
        '영업1': 'center',
        '영업2': 'center',
        '영업3': 'center',
        '대리점': 'center',
        '리테일': 'center',
        '지사': 'center',
        '총판': 'center',
        '시니어마스터': 'center',
        '마스터': 'center',
        '등록일': 'center',
        '수정일': 'center',
        '작업자 아이디': 'center'
      };

      // 컬럼 매핑 정의
      const columnMapping = {
        'No': 'id',
        '상호명(치환여부)': 'companyName',
        '대표자명': 'representative',
        '아이디': 'userId',
        '사업자/개인': 'businessType',
        '유형': 'accountType',
        '계정수수료(VAT별도)': 'fee',
        '수수료': 'commission',
        '세금': 'tax',
        '원천': 'withholding',
        '사업자번호': 'businessNumber',
        '주민번호': 'residentNumber',
        '취소권한': 'cancelAuth',
        '정산여부': 'settlementStatus',
        '정산일': 'settlementDate',
        '월렛사용여부': 'walletUse',
        '영업1': 'sales1',
        '영업2': 'sales2',
        '영업3': 'sales3',
        '대리점': 'agency',
        '리테일': 'retail',
        '지사': 'branch',
        '총판': 'distributor',
        '시니어마스터': 'seniorMaster',
        '마스터': 'master',
        '등록일': 'registDate',
        '수정일': 'modifyDate',
        '작업자 아이디': 'workerId'
      };

      // 각 컬럼별 최대 길이 찾기
      const getMaxColumnWidth = (data, header) => {
        const fieldName = columnMapping[header];
        
        // 해당 컬럼의 모든 데이터를 검사하여 가장 긴 길이 찾기
        const maxDataLength = Math.max(
          header.length,
          ...data.map(row => {
            const value = String(row[fieldName] || '');
            return value.length;
          })
        );

        // 한글은 2.5자, 영문/숫자는 1자로 계산하여 실제 표시 너비 계산
        const getTextWidth = (text) => {
          return Array.from(text).reduce((width, char) => {
            return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
          }, 0);
        };

        const maxWidth = Math.max(
          getTextWidth(header),
          ...data.map(row => getTextWidth(String(row[fieldName] || '')))
        );

        // 여유 공간 추가 (약 20%)
        return maxWidth + Math.ceil(maxWidth * 0.2);
      };

      // 각 컬럼의 너비 설정
      worksheet.columns = headers.map(header => ({
        header,
        width: getMaxColumnWidth(allData, header),
        style: { 
          alignment: { 
            horizontal: columnAlignments[header],
            vertical: 'middle'
          }
        }
      }));

      // 데이터 행 추가
      const rows = allData.map(merchant => [
        merchant.id,
        merchant.companyName,
        merchant.representative,
        merchant.userId,
        merchant.businessType,
        merchant.accountType,
        merchant.fee,
        merchant.commission,
        merchant.tax,
        merchant.withholding,
        merchant.businessNumber,
        merchant.residentNumber,
        merchant.cancelAuth,
        merchant.settlementStatus,
        merchant.settlementDate,
        merchant.walletUse,
        merchant.sales1,
        merchant.sales2,
        merchant.sales3,
        merchant.agency,
        merchant.retail,
        merchant.branch,
        merchant.distributor,
        merchant.seniorMaster,
        merchant.master,
        merchant.registDate,
        merchant.modifyDate,
        merchant.workerId
      ]);

      worksheet.addRows(rows);

      // 스타일 적용
      worksheet.eachRow((row, rowNumber) => {
        row.height = 25;
        row.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.font = { size: 10, name: '맑은 고딕' };
          
          // 숫자 형식이 필요한 컬럼들
          if (['fee', 'commission'].includes(headers[cell.col - 1])) {
            cell.numFmt = '0.00%';
          }

          // 한 줄로만 표시
          cell.alignment = {
            ...cell.alignment,
            vertical: 'middle'
          };
        });

        // 헤더 행 스타일
        if (rowNumber === 1) {
          row.height = 30;
          row.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF1976D2' }
            };
            cell.font = {
              size: 10,
              color: { argb: 'FFFFFFFF' },
              bold: true,
              name: '맑은 고딕'
            };
          });
        }
      });

      // 엑셀 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `계정관리승인 전_${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading excel:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: searchPanelOpen && !isMobile ? '280px' : '50px',
    width: '100%',
    transition: 'padding-right 0.3s ease',
    backgroundColor: 'transparent'
  };

  const noticeBoxStyle = {
    ...styles.noticeBox,
    width: 'calc(100% - 0px)',
    transition: 'width 0.3s ease'
  };

  return (
    <Box sx={styles.contentWrapper}>
      <Box component="main" sx={mainBoxStyle}>
        <Box sx={{ ...noticeBoxStyle, pr: searchPanelOpen && !isMobile ? '80px' : '0px' }}>
          <Notice />
        </Box>

        <Box sx={{ ...noticeBoxStyle, pr: searchPanelOpen && !isMobile ? '80px' : '0px' }}>
          <CurrentLocation mainTitle="관리" subTitle="계정관리 승인전" />
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mt: 3,
          mb: 2,
          gap: 2,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => navigate('/management/account/register')}
            sx={{
              backgroundColor: '#2196f3',
              '&:hover': {
                backgroundColor: '#1976d2'
              },
              fontSize: '0.95rem',
              py: 1,
              px: 3
            }}
          >
            계정 등록하기
          </Button>
        </Box>

        <Box sx={{ 
          mt: 3,
          width: '100%',
          transition: 'width 0.3s ease',
          pr: searchPanelOpen && !isMobile ? '80px' : '0px'
        }}>
          <AccountNoTables 
            onDataUpdate={handleDataUpdate}
          />
        </Box>
      </Box>

      {/* 좌측 사이드바 */}
      <QuickMenu />

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            zIndex: 1200,
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
        hideBackdrop={true}
        BackdropProps={{
          invisible: true
        }}
      >
        <AccountNoSearch 
          onSearch={handleSearchSubmit}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={handleExcelDownload}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default AccountNo;

import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Paper, 
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Drawer,
  IconButton,
  Modal
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CancelIcon from '@mui/icons-material/Cancel';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import axios from 'axios';
import ExcelJS from 'exceljs';

// 로컬 컴포넌트 import
import Notice from '../../Notice/Notice';
import MaechulYeongeopTables from './MaechulYeongeopTables';
import SummaryTable from '../../Common/PaymentResultData/PayHisSummaryTable';
import Breadcrumb from '../../Common/Breadcrumb';
import DownloadButtons from '../../Common/DownloadButtons';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../Common/PaymentResultData/PayHisResultText';
import ReceiptModal from '../../Common/receiptModal';
import RMquestion from '../../Common/RMquestion';
import SetOff from '../../Common/SetOff';
import CurrentLocation from '../../Common/CurrentLocation';
import NewResultTables from '../../NewResultTables';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '280px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: {
    width: '100%',
    mb: 2
  },
  searchPanel: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '250px',
    height: '100%',
    backgroundColor: '#fff',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    zIndex: 1000,
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateX(0)',
    '&.closed': {
      transform: 'translateX(100%)'
    }
  },
  sideButton: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
    width: '20px',
    height: '40px',
    borderRadius: '4px 0 0 4px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '@media (max-width: 768px)': {
      width: '30px',
      height: '50px'
    }
  },
};

function MaechulYeongeop() {
  // States
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [isSetOffModalOpen, setIsSetOffModalOpen] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);
  const [searchParams, setSearchParams] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // Handlers
  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedApprovals([...selectedApprovals, id]);
    } else {
      setSelectedApprovals(selectedApprovals.filter(approvalId => approvalId !== id));
    }
  };

  const handleReceiptClick = (data) => {
    setReceiptData(data);
    setIsReceiptOpen(true);
  };

  const handleDataUpdate = (newData) => {
    console.log('Data updated:', newData);
  };

  const toggleSearchPanel = () => {
    setSearchPanelOpen(!searchPanelOpen);
  };

  // 화면 크기 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      // 모바일에서 데스크탑으로 전환시 서치패널 자동으로 열기
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - 패널이 닫혔을 때만 표시 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={styles.sideButton}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer 수정 */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: 250,
            mt: '64px',
            pt: 2,
            px: 2,
            backgroundColor: 'transparent',
            boxShadow: 'none'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <SearchOptions 
          onClose={() => setSearchPanelOpen(false)}
          searchPanelOpen={searchPanelOpen}
          isMobile={isMobile}
        />
      </Drawer>

      {/* 상단 공지사항 */}
      <Box sx={styles.noticeBox}>
        <Notice />
        <CurrentLocation 
          mainTitle="지급통계" 
          subTitle="영업별" 
          onToggleSearchPanel={toggleSearchPanel}
          searchPanelOpen={searchPanelOpen}
        />
      </Box>

      {/* 검색 결과 텍스트 */}
      <ResultText />

      {/* 산출 표 */}
      <NewResultTables />

      {/* 메인 테이블 */}
      <MaechulYeongeopTables 
        searchParams={searchParams}
        onCheckboxChange={handleCheckboxChange}
        selectedApprovals={selectedApprovals}
        setSelectedApprovals={setSelectedApprovals}
        searchPanelOpen={searchPanelOpen}
        onReceiptClick={handleReceiptClick}
        onDataUpdate={handleDataUpdate}
      />
      
      {/* 하단 합계 표 */}
      <SummaryTable searchPanelOpen={searchPanelOpen} />

      {/* 우측 검색 패널 */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={searchPanelOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: 250,
            mt: '64px',
            pt: 2,
            px: 2
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1">검색 옵션</Typography>
          <IconButton onClick={toggleSearchPanel} size="small">
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <SearchOptions 
          onClose={toggleSearchPanel}
          searchPanelOpen={searchPanelOpen}
        />
      </Drawer>

      {/* 좌측 퀵 메뉴 */}
      <QuickMenu />

      {/* 모달들 */}
      <ReceiptModal
        open={isReceiptOpen}
        onClose={() => setIsReceiptOpen(false)}
        receiptData={receiptData}
      />

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />

      <Modal
        open={isSetOffModalOpen}
        onClose={() => setIsSetOffModalOpen(false)}
        aria-labelledby="setoff-modal-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiModal-backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <Box sx={{ 
          width: '95%',
          maxWidth: '1000px',
          minWidth: '1300px',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          height: '95vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <SetOff onClose={() => setIsSetOffModalOpen(false)} />
        </Box>
      </Modal>
    </Box>
  );
}

export default MaechulYeongeop;
